<template>
  <div>
    <VoteResult v-if="voteCondition == 'RESULT'" />
    <VoteForm v-else-if="voteCondition == 'FORM'" />
    <VoteProcess v-else-if="voteCondition == 'PROCESS'" />
  </div>
</template>

<script>
import VoteForm from "@/components/Vote/VoteForm";
import VoteProcess from "@/components/Vote/VoteProcess";
import VoteResult from "@/components/Vote/VoteResult";
import { getVote } from "@/api/index";

export default {
  name: "VoteDetail",
  components: {
    VoteForm,
    VoteProcess,
    VoteResult,
  },
  data() {
    return {
      voteCondition: "RESULT",
    };
  },
  methods: {
    getVote(id) {
      getVote(id).then((res) => {
        this.vote = res.data;
        if (this.vote.status_code != "VOTE_STATUS_PUBLISH") {
          this.$store.commit("ERROR", "존재하지 않는 투표입니다.");
          this.$router.push({ name: "voteList" });
        }
        this.checkAndMove(this.vote);
      });
    },
    checkAndMove(vote) {
      let leftMinutes = parseInt(
        (new Date(vote.to_datetime).getTime() - new Date().getTime()) /
          (1000 * 60)
      );
      let beforeMinutes = parseInt(
        (new Date(vote.from_datetime).getTime() - new Date().getTime()) /
          (1000 * 60)
      );

      if (leftMinutes < 0) {
        // 기간 만료 시
        this.voteCondition = "RESULT";
      } else if (beforeMinutes > 0) {
        // 투표 가능시간 이전
        this.$store.commit("ERROR", "투표 시작시간 이후 투표가능합니다.");
        this.$router.push({ name: "voteList" });
      } else if (vote.vote_yn == "Y") {
        // 이미 투표진행 경우
        this.voteCondition = "PROCESS";
      } else {
        this.voteCondition = "FORM";
      }
    },
  },
  created() {
    this.getVote(this.$route.params.id);
  },
};
</script>
