<template>
  <li :class="item.use_yn == 'R' ? 'ready' : ''">
    <a class="l-btn black--text" v-if="item.use_yn == 'R'">
      <span class="text-item">{{ facility.name }}</span>
      <span class="date-item">오픈 준비중</span>
    </a>
    <span @click="moveto(false)" class="l-btn black--text" v-else>{{
      facility.name
    }}</span>
    <a @click="moveto(true)" class="r-btn">
      <span :class="item.is_reservable ? 'low-box' : 'ng-box'">
        <span class="top-box">
          <span v-if="item.is_reservable">예약</span>
        </span>
        <span class="bottom-box">
          <Icons01 v-if="facility.icon == 'Icons01'" />
          <Icons02 v-else-if="facility.icon == 'Icons02'" />
          <Icons03 v-else-if="facility.icon == 'Icons03'" />
          <Icons04 v-else-if="facility.icon == 'Icons04'" />
          <Icons05 v-else-if="facility.icon == 'Icons05'" />
          <Icons06 v-else-if="facility.icon == 'Icons06'" />
          <Icons07 v-else-if="facility.icon == 'Icons07'" />
          <Icons08 v-else-if="facility.icon == 'Icons08'" />
          <Icons09 v-else-if="facility.icon == 'Icons09'" />
          <Icons10 v-else-if="facility.icon == 'Icons10'" />
          <Icons11 v-else-if="facility.icon == 'Icons11'" />
          <Icons12 v-else-if="facility.icon == 'Icons12'" />
          <Icons13 v-else-if="facility.icon == 'Icons13'" />
        </span>
      </span>
    </a>
  </li>
</template>

<script>
import Icons01 from "@/assets/images/dwelling/icons01.svg"; // 입주민 독서실
import Icons02 from "@/assets/images/dwelling/icons02.svg"; // 북카페
import Icons03 from "@/assets/images/dwelling/icons03.svg"; // 게스트하우스
import Icons04 from "@/assets/images/dwelling/icons04.svg"; // 바베큐장
import Icons05 from "@/assets/images/dwelling/icons05.svg"; // 바이크스테이션
import Icons06 from "@/assets/images/dwelling/icons06.svg"; // 카셰어링
import Icons07 from "@/assets/images/dwelling/icons07.svg"; // 카페Lynn
import Icons08 from "@/assets/images/dwelling/icons08.svg"; // 실내체육관
import Icons09 from "@/assets/images/dwelling/icons09.svg"; // 휘트니스센터
import Icons10 from "@/assets/images/dwelling/icons10.svg"; // 골프연습장
import Icons11 from "@/assets/images/dwelling/icons11.svg"; // OA존
import Icons12 from "@/assets/images/dwelling/icons12.svg"; // 공구대여서비스
import Icons13 from "@/assets/images/dwelling/icons13.svg"; // 금융지원

export default {
  name: "FacilityCard",
  props: ["item"],
  components: {
    Icons01,
    Icons02,
    Icons03,
    Icons04,
    Icons05,
    Icons06,
    Icons07,
    Icons08,
    Icons09,
    Icons10,
    Icons11,
    Icons12,
    Icons13,
  },
  computed: {
    facility() {
      if (this.item.facility) {
        return this.item.facility;
      }
      return {};
    },
    path() {
      if (this.item.path) {
        return this.item.path;
      }
      return { info: "/dwelling", reservation: "/dwelling" };
    },
  },
  methods: {
    moveto(isReservation=false) {
      let path = this.path.info
      let is = this.item.is_reservable
      if (isReservation){
        path = this.path.reservation
      }
    
      if (is) {
        this.$router.push({
          path: path,
        });
      }
    },
  },
};
</script>
