import { AuthConnection, AuthBlobConnection, toQueries } from "@/api/config.js";

const prefix = "csm?endpoint=api";

/** 하자 APIs */
export function getDefect(id) {
  return AuthConnection.get(`${prefix}/site/smartlynn-defects/${id}`);
}

export function getDefects(data) {
  return AuthConnection.get(
    `${prefix}/site/smartlynn-defects?${toQueries(data)}`
  );
}

export function getFile(id) {
  return AuthBlobConnection.get(`${prefix}/site/defect-files/${id}`).then(
    (res) => {
      return URL.createObjectURL(res.data);
    }
  );
}

export function getSiteDefectItems(params) {
  return AuthConnection.get(
    `${prefix}/site/smartlynn-defectitems/options?${toQueries(params)}`
  );
}

export function postDefect(data) {
  return AuthConnection.post(`${prefix}/site/defects/custom`, data);
}

export function putDefect(id, data) {
  return AuthConnection.put(`${prefix}/site/defects/${id}`, data);
}

export function deleteDefect(id) {
  return AuthConnection.delete(`${prefix}/site/defects/${id}`);
}

export function postDefectFile(data) {
  const form = new FormData();

  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });

  return AuthConnection.post(`${prefix}/site/defect-files`, form);
}

export function getEnums() {
  return AuthConnection.get(`${prefix}/enums`);
}

export function getCauseByPartDetail(id) {
  return AuthConnection.get(`${prefix}/defect/cause/${id}`);
}

export function getSil(id) {
  return AuthConnection.get(`utils/sil/${id}`);
}
