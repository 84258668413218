<template>
    <v-container>
        <strong class="t-cont-title">
            융자 신청내역
            <span class="ft-r">[단위:원]</span>
        </strong>
        <table class="loan-table">
            <colgroup>
                <col width="95px">
                <col width="120px">
                <col width="*">
            </colgroup>
            <tr class="head">
                <th>융자구분/<br/>이자구분</th>
                <th>신청일자/<br/>융자사</th>
                <th>신청금액</th>
            </tr>
            <tr class="up">
                <td>알선융자</td>
                <td>2020.05.14</td>
                <td>42,200,000</td>
            </tr>
            <tr class="down">
                <td>무이자</td>
                <td colspan="2">광주은행수도권금융센터</td>
            </tr>
            <tr class="up">
                <td>알선융자</td>
                <td>2020.06.15</td>
                <td>42,200,000</td>
            </tr>
            <tr class="down">
                <td>무이자</td>
                <td colspan="2">광주은행수도권금융센터</td>
            </tr>
            <tr class="up">
                <td>알선융자</td>
                <td>2020.10.15</td>
                <td>42,200,000</td>
            </tr>
            <tr class="down">
                <td>무이자</td>
                <td colspan="2">광주은행수도권금융센터</td>
            </tr>
            <tr class="total">
                <td>합계</td>
                <td></td>
                <td>211,000,000</td>
            </tr>
        </table>
        <div class="order-list">
            <p>※실제 융자내역은 은행에 직접 확인하시기 바랍니다.</p>
            <p>※융자신청 금액은 개별 중도 상환 여부에 따라 상기 내역과 상이할 수 있습니다. 정확한 금액은 대출하신 은행으로 문의하시기 바랍니다.</p>
        </div>
        <ul class="under-list">
            <li>고객님이 납부하신 단지의 납부정보를 조회하실 수 있습니다</li>
            <li>계약자인 경우에 한해 계약현장의 분양대금 수납조회가 가능합니다</li>
            <li>린라이프에서 제공하는 분양대금조회서비스는 일반적으로 분양대금 입금일 익일에 조회가 가능합니다</li>
        </ul>
    </v-container>
</template>

<script>
export default {
    components: { 
    },
    name: "SetLoan",
    data(){
        return {
        }
    }
};
</script>
