import { AuthConnection, Connection } from "./config.js";

export function getFacilityList(query) {
  return AuthConnection.get(`/facility?${query}`);
}

export function getFacility(id) {
  return AuthConnection.get(`/facility/${id}`);
}

export function checkFacilityReservation(id, query) {
  return AuthConnection.get(
    `/facility/reservation/check/${id}?${query}`
  );
}

export function postFacilityReservationSeed(data) {
  return AuthConnection.post("/facility/reservationseed", data);
}

export function getFacilityReservationSeedList(query) {
  return AuthConnection.get(`/facility/reservationseed?${query}`);
}

export function delFacilityReservationSeed(id) {
  return AuthConnection.delete(`/facility/reservationseed/${id}`);
}
