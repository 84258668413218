<template>
	<v-container class="bg-gray vh-full pd20 lynn-web-in">
		<div class="web-side"></div>
		<div class="location-box np">
			<div class="web-logo">
				<Weblogo width="55" height="30"/>
			</div>
			<a @click="$router.go(-1)" class="back">
				<Back />
			</a>
			<strong class="lo-title">입주 하자 신청</strong>
		</div>
		<v-card max-width="750" class="mx-auto bg-gray nops waitform">
			<div>
				<v-form>
					<v-subheader class="pd0 fts16 ftw5 mt15">하자 정보</v-subheader>
					<v-select
						ref="village"
						item-text="village_name"
						item-value="id"
						label="하자 분류"
					></v-select>
					<v-select
						ref="room_type"
						item-text="text"
						item-value="id"
						label="실-부위"
					></v-select>
					<v-select
						ref="room_type"
						item-text="text"
						item-value="id"
						label="상세부위"
					></v-select>
					<v-file-input
						truncate-length="15"
						label="하자 사진"
					></v-file-input>
					<v-menu
						v-model="menu2"
						:close-on-content-click="false"
						max-width="290"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								label="접수 일시"
								v-model="move_out_date"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="move_out_date"
							:min="move_out_min"
							:max="move_out_max"
							:allowed-dates="allowedDates"
							:show-current.sync="moveout_pickerDate"
							@change="menu2 = false"
							:day-format="(date) => new Date(date).getDate()"
							locale="KR"
							full-width
							no-title
						></v-date-picker>
					</v-menu>
					<v-select
						item-text="text"
						item-value="value"
						ref="move_in_month"
						label="선택창 추가"
					></v-select>
					<v-subheader class="pd0 fts16 ftw5 mt15">추가 입력</v-subheader>
					<v-text-field
						ref="name"
						maxlength="20"
						label="이름"
						readonly
					></v-text-field>
					<v-text-field
						label="주소"
						ref="address"
						maxlength="100"
						required
					></v-text-field>
					<v-row class="mt8">
						<v-col class="pd0 mg0 text-left" cols="2">
							<strong class="ds-b fts16 ftw4">선택</strong>
						</v-col>
						<v-col class="text-left">
							<v-radio-group
								row
								class="pd0 mg0"
								ref="gender"
							>
								<v-radio value="M" label="선택1"></v-radio>
								<v-radio value="W" label="선택2"></v-radio>
							</v-radio-group>
						</v-col>
					</v-row>
					<v-textarea
						name="input-1-1"
						label="접수 내용"
					></v-textarea>
					<v-textarea
						name="input-1-2"
						label="하자 내용"
					></v-textarea>
					<div class="tems-box">
						<strong class="ds-b pd0 fts14 ftw5 mt15 text-left">
							개인정보 수집 및 이용
						</strong>
						<ul class="tems-item">
							<li>
								<strong>수집이용 목적</strong>
								<p>단지안내 및 상담</p>
							</li>
							<li>
								<strong>수집항목</strong>
								<p>필요하면 넣어주세요 불라불라</p>
							</li>
							<li>
								<strong>보유 및 이용기간</strong>
								<p>1년(정보주체의 삭제 요청 시 즉시 파기)</p>
							</li>
						</ul>
					</div>
					<v-row class="mt0">
						<v-radio-group class="mt5" ref="privacyAgree" row>
							<v-checkbox
								label="개인정보 수집 및 이용 동의"
							></v-checkbox>
						</v-radio-group>
					</v-row>
				</v-form>

				<v-card-actions class="justify-center pd0 mt20">
					<v-btn
						class="pd10 color-2 bgc-4 pwidth48 mr-p4 fts18 ftw7"
						min-height="50"
						>뒤로가기</v-btn
					>
					<v-btn
						color="primary"
						class="fts18 ftw7 mw-400 pwidth48 mg0 fts18 ftw7"
						min-height="50"
						>입주 하자 신청</v-btn
					>
				</v-card-actions>
			</div>
		</v-card>
		
	</v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

export default {
  name: "inspectionAdd",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
