<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in pd0" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <!-- <div class="web-logo">
        <Weblogo />
      </div> -->
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">퇴거 신청</strong>
    </div>

    <!-- 분양, 계약 구분에 따라 메뉴 변경 -->
    <!-- 분양 -->
    <v-container class="pd0">
      <div class="tab-contents">
        <div class="web-contbox">
          <div class="tab-contents">
            <v-container class="pd16">
              <!-- <strong class="t-center-title">퇴거 신청</strong> -->
              <strong class="t-cont-title">퇴거 신청 안내사항</strong>
              <ul class="bull-list">
                <li style="font-size: 14px">
                  퇴거신청 변경 기준이 부적합하실 경우 신청이 취소될 수
                  있습니다.
                </li>
                <li style="font-size: 14px">
                  구비서류를 필히 지참하시어 방문바랍니다.
                </li>
                <li style="font-size: 14px">
                  만기퇴거는 임대차 종료 2개월전, 중도퇴거는 퇴거희망일
                  3개월전까지 날짜 협의가 이루어져야합니다.
                </li>
              </ul>
              <strong class="t-cont-title">퇴거 신청 구분</strong>
              <div class="mb10">
                <v-radio-group
                  class="pd0 mg0"
                  column
                  v-model="condition"
                  :disabled="isRequestExist || !contractInfo.moveInDate"
                  @change="conditionInit"
                >
                  <v-radio
                    value="TERMINATE_BEFORE_PERIOD"
                    label="중도 퇴거"
                  ></v-radio>
                  <v-radio
                    value="TERMINATE_ON_PERIOD"
                    label="만기 퇴거"
                  ></v-radio>
                </v-radio-group>
              </div>
              <strong class="t-cont-title">퇴거 희망일</strong>
              <div class="mb20">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-if="!isCondition"
                      label="퇴거 신청 구분을 먼저 선택해주세요."
                      :disabled="isRequestExist || !isCondition"
                      v-model="move_out_date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      label="퇴거 희망일"
                      :disabled="isRequestExist || !isCondition"
                      v-model="move_out_date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="move_out_date"
                    :min="move_out_min"
                    :max="move_out_max"
                    :allowed-dates="allowedDates"
                    :show-current.sync="moveout_pickerDate"
                    @change="menu2 = false"
                    :day-format="(date) => new Date(date).getDate()"
                    locale="KR"
                    full-width
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
              <strong class="t-cont-title">계약 정보</strong>
              <table class="t-table table1">
                <colgroup>
                  <col width="65px" />
                  <col width="*" />
                </colgroup>
                <tr>
                  <th>계약일자</th>
                  <td>{{ contractInfo.contractDate }}</td>
                </tr>
                <tr v-if="contractInfo.leaseInfo != null">
                  <th>계약기간</th>
                  <td>
                    {{
                      contractInfo.leaseInfo.rentFrom != null &&
                      contractInfo.leaseInfo.rentTo != null
                        ? contractInfo.leaseInfo.rentFrom +
                          " ~ " +
                          contractInfo.leaseInfo.rentTo
                        : "임차인이 임대주택을 인도받은 날로부터 24개월이 되는 날까지"
                    }}
                  </td>
                </tr>
                <tr>
                  <th>동</th>
                  <td>{{ home.dong_no }}동</td>
                </tr>
                <tr>
                  <th>호</th>
                  <td>{{ home.ho_no }}호</td>
                </tr>
                <tr v-if="contractInfo.leaseInfo != null">
                  <th>보증금</th>
                  <td>{{ contractInfo.leaseInfo.rentDeposit | comma }} 원</td>
                </tr>
                <tr v-if="contractInfo.leaseInfo != null">
                  <th>임대료</th>
                  <td>{{ contractInfo.leaseInfo.rent | comma }} 원</td>
                </tr>
                <tr>
                  <th>입주일</th>
                  <td>{{ contractInfo.moveInDate }}</td>
                </tr>
              </table>

              <strong class="t-cont-title">체결 방법</strong>
              <div class="text-set">
                <p>장소 : 커뮤니티센터 내 통합관리센터</p>
                <p>
                  구비서류 필참
                  <a href="#n" @click.prevent.stop="dialog = true"
                    >구비서류 보기</a
                  >
                </p>
              </div>
              <strong class="t-cont-title">퇴거 신청 방문 예약</strong>
              <div>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-if="!isMoveOutDate"
                      label="퇴거 희망일을 먼저 지정해주세요."
                      :disabled="isRequestExist || !isMoveOutDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      label="퇴거 신청 방문 희망일"
                      :disabled="isRequestExist || !isMoveOutDate"
                      v-model="date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    :min="visit_min"
                    :max="visit_max"
                    :allowed-dates="allowedDates"
                    :show-current.sync="visit_pickerDate"
                    @change="menu = false"
                    :day-format="(date) => new Date(date).getDate()"
                    locale="KR"
                    full-width
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="address-set">
                <p>{{ home.village_name }}</p>
                <p>{{ home.dong_no }}동 {{ home.ho_no }}호</p>
                <p>임차인 {{ contractInfo.contractorName }}</p>
                <p>
                  신청일 : {{ today.substr(0, 4) }}년 {{ today.substr(5, 2) }}월
                  {{ today.substr(8, 2) }}일
                </p>
              </div>
              <div v-if="isRequestExist">
                <v-btn
                  color="grey lighten-3"
                  class="fts18 ftw7 mw-700 pwidth70 grey--text text--lighten-1"
                  min-height="50"
                  elevation="0"
                  >접수완료</v-btn
                >
                <v-btn
                  color="primary"
                  class="fts18 ftw7 mw-700 pwidth20 ml-2"
                  min-height="50"
                  elevation="0"
                  @click="cancelDialog = true"
                  >신청취소</v-btn
                >
              </div>
              <div v-else>
                <v-btn
                  v-if="!is_form_ready"
                  color="grey lighten-3"
                  class="fts18 ftw7 mw-700 pwidth100 grey--text text--lighten-1"
                  min-height="50"
                  elevation="0"
                  >퇴거 신청하기</v-btn
                >
                <v-btn
                  v-else
                  color="primary"
                  class="fts18 ftw7 mw-700 pwidth100"
                  min-height="50"
                  elevation="0"
                  :disabled="!is_form_ready"
                  @click="postRentalContractChangeAPI()"
                  >퇴거 신청하기</v-btn
                >
              </div>
            </v-container>
          </div>
        </div>
      </div>

      <v-dialog v-model="cancelDialog" max-width="290">
        <v-card>
          <v-card-title class="justify-center"> </v-card-title>
          <v-card-text class="text-left ftw7">
            계약 해지 방문예약을 취소하시겠습니까?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1"
              text
              @click="delRentalContractChangeAPI(id)"
            >
              예
            </v-btn>
            <v-btn color="primary darken-1" text @click="cancelDialog = false">
              아니오
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-container class="pd20 bgc-3 vh-full">
          <strong class="close-title" style="background-color: #ffffff">
            구비서류안내
            <a @click="dialog = false"><Close /></a>
          </strong>
          <div class="pd0 text-left">
            <strong class="ds-b lh24 fts14 ftw5 color-5">본인방문</strong>
            <ul class="pd0 mb24">
              <li class="fts14 ftw24 color-5">·임대차계약서 원본</li>
              <li class="fts14 ftw24 color-5">·신분증</li>
              <li class="fts14 ftw24 color-5">·환불 받을 본인명의 통장사본</li>
              <li class="fts14 ftw24 color-5">·인감도장</li>
              <li class="fts14 ftw24 color-5">·인감증명서 1부</li>
              <li class="fts14 ftw24 color-5">·주민등록등본 1부</li>
            </ul>
            <ul class="pd0 mb24">
              <li class="fts14 ftw24 color-5">
                *임대차계약서 원본이 없으실 경우 경찰서 신고 후 분실 신고증을
                필히 지참하셔야 합니다.
              </li>
              <li class="fts14 ftw24 color-5">
                *임대차계약서 원본은 회수됩니다.
              </li>
            </ul>
            <strong class="ds-b lh24 fts14 ftw5 color-5">대리인 방문</strong>
            <ul class="pd0 mb24">
              <li class="fts14 ftw24 color-5">·본인방문 구비서류</li>
              <li class="fts14 ftw24 color-5">·대리인 신분증</li>
              <li class="fts14 ftw24 color-5">·위임장</li>
              <li class="fts14 ftw24 color-5">·위임용 인감증명서 1부</li>
              <li class="fts14 ftw24 color-5">·가족관계증명서</li>
            </ul>
          </div>
          <v-btn
            color="primary"
            elevation="0"
            class="fts18 ftw7 mw-700 pwidth100 mt30"
            min-height="50"
            @click="dialog = false"
            >확인</v-btn
          >
        </v-container>
      </v-dialog>
    </v-container>
    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          퇴거 신청 방문예약을 취소하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="delRentalContractChangeAPI(id)"
          >
            예
          </v-btn>
          <v-btn color="primary darken-1" text @click="cancelDialog = false">
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
.sel-cus .v-text-field__details {
  min-height: auto;
}
.sel-cus .v-input__slot {
  margin: 0;
}
.sel-cus .v-input input {
  max-height: 40px;
}
.sel-cus .v-input .v-label {
  text-align: center;
}
.sel-cus .v-select__selection {
  max-width: 100%;
  width: 100%;
  padding-left: 28px;
  text-align: center;
}
</style>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Close from "@/assets/images/contract/close.svg";
import { getContract } from "@/api/contract.js";
import {
  postRentalContractChange,
  getRentalContractChangeList,
  delRentalContractChange,
  getVillage,
} from "@/api/index.js";
import {
  get_now_string,
  add_month,
  isWeekend,
  add_day,
  isFuture,
} from "@/utils/time.js";
export default {
  components: {
    Back,
    Close,
  },
  name: "LeaveRequest",
  data() {
    return {
      contractInfo: {},
      condition: "",
      isRequestExist: false,
      id: 0,

      // Utils
      tabIndex: 0,
      subTabIndex: 0,
      menu: false,
      menu2: false,
      date: "",
      move_out_date: "",

      today: get_now_string(),
      min: null,
      pickerDate: null,
      cancelDialog: false,
      dialog: false,
      move_out_notify_before: "",
      sudden_out_notify_before: "",
    };
  },
  computed: {
    homeList() {
      return this.$store.getters.getHomeList;
    },
    home() {
      if (this.$route.query.home) {
        return this.homeList.find((item) => {
          return item.id == Number(this.$route.query.home);
        });
      }
      return this.$store.getters.getHome;
    },
    user() {
      return this.$store.getters.getUser;
    },
    is_form_ready() {
      return Boolean(this.date && this.condition);
    },
    btnText() {
      if (this.is_form_ready) return "접수완료";
      return "퇴거 신청하기";
    },
    isCondition() {
      if (this.condition != "" && this.condition != null) {
        return true;
      } else {
        return false;
      }
    },
    isMoveOutDate() {
      if (this.move_out_date) {
        return true;
      } else {
        return false;
      }
    },
    move_out_min() {
      let to = this.today;
      if (this.condition == "TERMINATE_BEFORE_PERIOD") {
        // 중도퇴거
        to = add_day(this.today, this.sudden_out_notify_before);
      } else if (this.condition == "TERMINATE_ON_PERIOD") {
        // 만기퇴거
        to = this.contractInfo.leaseInfo.rentTo;
      }

      if (!isFuture(this.today, to)) {
        to = this.today;
      }
      return to;
    },
    move_out_max() {
      if (this.condition == "TERMINATE_BEFORE_PERIOD") {
        return this.contractInfo.leaseInfo.rentTo;
      } else if (this.condition == "TERMINATE_ON_PERIOD") {
        return add_day(this.contractInfo.leaseInfo.rentTo, 30);
      } else {
        return "";
      }
    },
    moveout_pickerDate: {
      get() {
        console.log("moveout_pickerDate");
        return this.move_out_min.slice(0, 7);
      },
      set() {},
    },
    visit_min() {
      return this.today;
    },
    visit_max() {
      if (this.condition == "TERMINATE_BEFORE_PERIOD") {
        // 중도퇴거
        return add_day(this.move_out_date, this.sudden_out_notify_before * -1);
      } else if (this.condition == "TERMINATE_ON_PERIOD") {
        // 만기퇴거
        return add_day(this.move_out_date, this.move_out_notify_before * -1);
      } else {
        return "";
      }
    },
    visit_pickerDate: {
      get() {
        return this.visit_max.slice(0, 7);
      },
      set() {},
    },
  },
  methods: {
    getRentalContractChangeListAPI() {
      let query = `user_id=${this.user.id}&&home_id=${this.home.id}&&change_type_list=['TERMINATE_BEFORE_PERIOD','TERMINATE_ON_PERIOD']&&status=['REQUEST_STATUS_REQUESTED']`;
      getRentalContractChangeList(query).then((res) => {
        if (res.data.length) {
          let item = res.data[0];
          this.isRequestExist = true;
          this.condition = item.change_type;
          this.date = item.visit_date;
          this.move_out_date = item.move_out_date_form;
          this.id = item.id;
        } else {
          this.date = "";
          this.id = 0;
          this.isRequestExist = false;
        }
      });
    },
    getContractInfo() {
      let query = `contract_id=${this.home.id}`;
      getContract(this.home.id).then((result) => {
        this.contractInfo = result.data;

        if (!this.contractInfo.moveInDate) {
          this.$store.commit("ERROR", "퇴거신청 기간이 아닙니다.");
        }
      });
    },
    postRentalContractChangeAPI() {
      let data = {
        user: this.user.id,
        home: this.home.id,
        rental_from_type: "",
        rental_to_type: "",
        change_type: this.condition,
        visit_datetime: this.date,
        move_out_date: this.move_out_date,
      };
      postRentalContractChange(data)
        .then((res) => {
          this.$store.commit("SUCCESS", "퇴거 신청되었습니다.");
          this.getRentalContractChangeListAPI();
        })
        .catch((err) => {
          this.$store.commit("ERROR", "퇴거 신청에 실패하였습니다.");
        });
    },
    delRentalContractChangeAPI(id) {
      delRentalContractChange(id)
        .then((re) => {
          this.$store.commit("SUCCESS", "방문예약이 취소 되었습니다.");
          this.cancelDialog = false;
          this.getRentalContractChangeListAPI();
        })
        .catch((err) => {
          console.log("실패...");
          console.log(err);
        });
    },
    allowedDates(val) {
      if (this.home.is_holiday_apply_to_move_out) {
        return !isWeekend(val) && !this.home.holidays.includes(val);
      } else {
        return !isWeekend(val);
      }
    },
    conditionInit() {
      this.move_out_date = "";
      this.date = "";
    },
  },
  created() {
    this.getRentalContractChangeListAPI();
    this.getContractInfo();

    getVillage(this.home.village).then((res) => {
      this.move_out_notify_before = res.data.move_out_notify_before;
      this.sudden_out_notify_before = res.data.sudden_out_notify_before;
    });

    this.min = add_month(this.today, 3); // 3 개월 후 부터 가능.
    this.pickerDate = this.min.slice(0, 7);
  },
};
</script>
