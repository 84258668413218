<template>
    <v-container class="pd16">
        <strong class="t-center-title">계약 조건 변경 신청</strong>
        <strong class="t-cont-title">계약 조건 변경 안내사항</strong>
        <ul class="bull-list">
            <li>신청 후 담당자가 승인여부를 결정하여 별도 안내 드립니다</li>
            <li>기타 궁금하신 사항은 통합관리센터로 문의 주시기 바랍니다</li>
        </ul>
        <strong class="t-cont-title">임대 조건 변경</strong>
        <div class="change-box">
            <div class="left">
                <strong>변경 전</strong>
                <div class="c-textbox">
                    표준
                </div>
            </div>
            <div class="center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16.243" viewBox="0 0 15 16.243">
                    <g id="Icon_feather-arrow-right-circle" data-name="Icon feather-arrow-right-circle" transform="translate(-10.5 -9.879)">
                        <path id="패스_591" data-name="패스 591" d="M18,24l6-6-6-6" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                        <path id="패스_592" data-name="패스 592" d="M12,18H24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    </g>
                </svg>
            </div>
            <div class="right">
                <strong>변경 후</strong>
                <div class="c-cont">
                    <v-container class="pd0">
                        <v-select v-model="e1" class="sel-cus" :items="items" label="계약정보 주소" menu-props="auto" hide-details single-line></v-select>
                    </v-container>
                </div>
            </div>
        </div>
        <table class="colorfull-table">
            <colgroup>
                <col width="33%">
                <col width="33%">
                <col width="34%">
            </colgroup>
            <tr>
                <th class="trans"></th>
                <th class="cto1 text-right">임대보증금</th>
                <th class="cto1 text-right">임대료</th>
            </tr>
            <tr>
                <td class="cto2 text-left">표준</td>
                <td class="cto2 text-right">102,400,000</td>
                <td class="cto2 text-right">135,500</td>
            </tr>
            <tr>
                <td class="cto3 text-left">전환 I</td>
                <td class="cto3 text-right">102,400,000</td>
                <td class="cto3 text-right">135,500</td>
            </tr>
            <tr>
                <td class="cto1 text-left">증액</td>
                <td class="cto1 text-right">-100</td>
                <td class="cto1 text-right">500</td>
            </tr>
        </table>
        <strong class="t-cont-title">계약 변경 방문 예약</strong>
        <div>
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    label="계약 변경 희망일"
                    v-model="date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="date"
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-dialog>
        </div>
        <div class="address-set">
            <p>충북혁신도시 우미린스테이</p>
            <p>405동 1902호</p>
            <p>임차인 홍길동</p>
            <p>신청일 : 2021년 3월 11일</p>
        </div>
        <v-btn color="primary" class="fts18 ftw7 mw-700 pwidth100" min-height="50">계약조건 변경 신청하기</v-btn>
    </v-container>
</template>

<script>
export default {
    components: { 
    },
    name: "SetChange",
    data(){
        return {
            picker: new Date().toISOString().substr(0, 10),
            items: [
                '전환 I',
                '전환 II',
            ],
            e1: '전환 I'
        }
    }
};
</script>
