<template>
  <v-container class="pad nb web-contract" >
   <strong
      class="ds-b fts16 ftw4 lh20 text-left color-6 mb15"
      v-if="isDYCIS"
    >
  편의시설
    </strong>
    <div @click="moveToDYCIS" v-if="isDYCIS">
        <div class="dw-sbox">
          <div class="bg">
            <img src="@/assets/images/dwelling/topbg2.png" />
          </div>
          <div class="text-box">
            <strong>편의시설 예약 (대양 CIS)</strong>
            <!-- <p>단지에 있는 시설들을 확인하고 예약할 수 있습니다</p> -->
          </div>
        </div>
      </div>
      <div v-show="facilityList.length">
    <strong
      class="ds-b fts16 ftw4 lh20 text-left color-6 mb15"
      v-if="educationList.length"
    >
      교육/육아
    </strong>
    <ul class="list-box" v-if="educationList.length">
      <FacilityCard
        :item="item"
        v-for="(item, idx) in educationList"
        :key="'Education-card-' + idx"
      />
    </ul>
    <strong
      class="ds-b fts16 ftw4 lh20 text-left color-6 mb15"
      v-if="sharingList.length"
    >
      공유/편의
    </strong>
    <ul class="list-box" v-if="sharingList.length">
      <FacilityCard
        :item="item"
        v-for="(item, idx) in sharingList"
        :key="'Sharing-card-' + idx"
      />
    </ul>
    <strong
      class="ds-b fts16 ftw4 lh20 text-left color-6 mb15"
      v-if="healthList.length"
    >
      건강
    </strong>
    <ul class="list-box" v-if="healthList.length">
      <FacilityCard
        :item="item"
        v-for="(item, idx) in healthList"
        :key="'Health-card-' + idx"
      />
    </ul>
    <strong
      class="ds-b fts16 ftw4 lh20 text-left color-6 mb15"
      v-if="etcList.length"
    >
      기타
    </strong>
    <ul class="list-box" v-if="etcList.length">
      <FacilityCard
        :item="item"
        v-for="(item, idx) in etcList"
        :key="'ETC-card-' + idx"
      />
    </ul>
    </div>
  </v-container>
</template>

<script>
/*icons*/

import { getFacilityList } from "@/api/dwelling";
import FacilityCard from "@/components/Dwelling/FacilityCard.vue";

export default {
  components: {
    FacilityCard,
  },
  name: "FacilityGrid",
  data() {
    return {};
  },
  computed: {
    facilityList() {
      return this.$store.getters.getFacilityList;
    },
    educationList() {
      return this.facilityList.filter((i) => {
        return i.facility.category_code == "FACILITY_CATEGORY_EDUCATION";
      });
    },
    sharingList() {
      return this.facilityList.filter((i) => {
        return i.facility.category_code == "FACILITY_CATEGORY_SHARING";
      });
    },
    healthList() {
      return this.facilityList.filter((i) => {
        return i.facility.category_code == "FACILITY_CATEGORY_HEALTH";
      });
    },
    etcList() {
      return this.facilityList.filter((i) => {
        return i.facility.category_code == "FACILITY_CATEGORY_ETC";
      });
    },
     user() {
      return this.$store.getters.getUser;
    },
    home(){
      return this.$store.getters.getTenantHome;
    },
    isDYCIS(){
      /*
      2486 : 신진주 역세권
      3905 : 파주운정3차(파주 운정신도시 우미 린 파크힐스)
      */
      console.log(this.home && [2486,3905].includes(this.home.village))
      return this.home && [2486,3905].includes(this.home.village);
    }
  },
  methods: {
    getFacilityListAPI() {
      getFacilityList("is_simple=Y").then((res) => {
        const facilityList = res.data;
        this.$store.commit("SET_FACILITY_LIST", facilityList);
      });
    },
    moveToDYCIS(){
      const idToken = this.$store.getters.getIdToken
      const path = `${process.env.VUE_APP_BASE}/facilityHome`
      const reservation = `https://woomi.dycis.co.kr/API/webviewcall/?userToken=${idToken}&redirectUrl=${path}`
      // replace url
      window.location.replace(reservation)
    }
  },
  created() {
    this.getFacilityListAPI();
  },
};
</script>
