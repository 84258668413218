<template>
  <!-- 공지 리스트 -->
  <v-card
    max-width="750"
    class="mx-auto nops"
    style="border-radius: 0"
    v-if="!item.title"
  >
    <v-card-text class="pd0">
      <div class="sel-inbtn type3 max mt10">
        <v-text-field
          class="sel-cus ft-l"
          style="width: 90%"
          label="검색"
          hide-details
          single-line
          name="name"
          id="id"
          v-model="search"
          v-on:keyup.enter="getBulletinListAPI()"
        ></v-text-field>
        <a @click="getBulletinListAPI()" class="ft-r">
          <v-icon>mdi-magnify</v-icon>
        </a>
      </div>
      <div class="tab-contents">
        <div>
          <ul class="dan-list">
            <li v-for="item in items" :key="'board-' + item.id">
              <a @click="noticeInView(item.id)">
                <strong class="kitbox"> {{ item.title }}</strong>
                <div class="bottom-cont">
                  <span class="name">{{ item.writer }}</span>
                  <span class="date">{{ item.createdAtDate }}</span>
                </div>
              </a>
            </li>
            <li v-if="!items.length" class="no-list">
              <router-link to="/notice">
                등록된 공지사항이 없습니다.
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </v-card-text>

    <div class="text-center">
      <v-pagination v-model="page" :length="lastPage"></v-pagination>
    </div>
  </v-card>

  <!-- 공지 내용 -->
  <v-card max-width="750" class="mx-auto nops" v-else>
    <v-card-text>
      <strong class="mb3 ds-b fts14 lh20 color-9 ftw4 text-left">{{
        item.writer
      }}</strong>
      <ul class="board-list mb10">
        <li class="mb0">
          <a class="pb15">
            {{ item.title }}
          </a>
        </li>
      </ul>
      <ul class="file-downbox" v-show="item.files ? item.files.length : false">
        <li v-for="file in item.files" :key="'file-' + file.url">
          <a @click="download(file)" target="_blank">
            {{ file.filename }}
            <Files />
          </a>
        </li>
      </ul>
      <p class="board-text" v-html="item.parsed_contents"></p>
      <v-btn
        color="primary"
        elevation="0"
        class="fts18 ftw7 mw-700 pwidth100 mt30"
        min-height="50"
        @click="item = {}"
        >목록으로</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getBulletinUtils,
  getBulletinList,
  getVillageList,
  getBulletin,
} from "@/api/index";
import { downloadByURL } from "@/utils/utils";

import Files from "@/assets/images/cs/file.svg";
export default {
  name: "VillageNotice",
  props: ["village_id"],
  components: {
    Files,
  },
  data() {
    return {
      search: null,
      noticeSubItems: [],

      items: [],
      item: {},
      downloadItems: [],

      villageList: [],
      // Utils
      page: 1,
      lastPage: 1,
      filter: "ALL",

      type_show: true,
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
    tenantHome() {
      return this.$store.getters.getTenantHome;
    },
  },
  watch: {
    page() {
      this.getBulletinListAPI();
    },
  },
  methods: {
    getBulletinUtilsAPI() {
      getBulletinUtils("parent_type=BULLETIN_LINNLIFE_NOTICE").then((res) => {
        this.noticeSubItems = res.data.subItems;
      });
    },
    getBulletinListAPI() {
      // query
      let query = `type=BULLETIN_LINNLIFE_NOTICE&village_id=${this.village_id}`;
      query += `&page=${this.page}`;
      if (this.search) query += `&search=${this.search}`;

      getBulletinList(query).then((res) => {
        this.items = res.data.data;
        this.lastPage = res.data.total_page;
      });
    },
    // Utils
    setFilter(code) {
      this.filter = code;
      this.getBulletinListAPI();
    },
    noticeInView(id) {
      getBulletin(id).then((res) => {
        this.item = res.data;
      });
    },
    download(file) {
      downloadByURL(file.url, file.filename);
    },
  },
  created() {
    this.getBulletinListAPI();
    this.getBulletinUtilsAPI();
  },
};
</script>
