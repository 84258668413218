<template>
  <v-card max-width="750" class="mx-auto nops">
    <v-card-text class="pd0">
      <div class="sel-inbtn type3 max mt10">
        <v-text-field
          class="sel-cus ft-l"
          style="width: 90%"
          label="검색"
          hide-details
          single-line
          name="name"
          id="id"
          v-model="search"
          v-on:keyup.enter="getBulletinListAPI()"
        ></v-text-field>
        <a @click="getBulletinListAPI()" class="ft-r">
          <v-icon>mdi-magnify</v-icon>
        </a>
      </div>
      <ul class="board-list">
        <li v-for="item in items" :key="'board-' + item.id">
          <!--<a @click="getBulletinAPI(item.id)"> Q. {{ item.title }} </a>-->
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item>
              <div class="faq-box" v-html="item.parsed_contents"></div>
            </v-list-item>
          </v-list-group>
        </li>
        <li v-if="!items.length">
          <a> 등록된 도움말이 없습니다. </a>
        </li>
      </ul>
      <!-- <div class="cbtn-box">
          <router-link to="/qna">직접 문의하기</router-link>
        </div> -->
    </v-card-text>
    <div class="text-center">
      <v-pagination v-model="page" :length="lastPage"></v-pagination>
    </div>
  </v-card>
</template>

<style>
.board-list .v-list-item__title {
  align-self: flex-start;
  text-align: left;
  overflow:visible;
  text-overflow:inherit;
  white-space:normal;
  line-height:20px;
  padding-left:20px;
  position:relative;
  word-break: keep-all;
  font-size:14px;
}
.board-list .v-list-item__title:after {
  content:"Q.";
  display:inline-block;
  position:absolute;
  top:0;
  left:0;
  line-height:20px;
  font-size:14px;
  color:#f36628;
}
.board-list .v-list-item {
  padding: 0 15px !important;
}
.board-list .faq-box {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #555555;
}
</style>

<script>
import { getBulletinList, getBulletinUtils, getBulletin } from "@/api/index";

export default {
  name: "VillageFaq",
  props: ["village_id"],
  data() {
    return {
      search: null,
      subItems: [],
      items: [],
      item: {},
      villageList: [],
      page: 1,
      lastPage: 1,
      filter: "ALL",
      type_show: true,
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
    tenantHome() {
      return this.$store.getters.getTenantHome;
    },
  },
  watch: {
    page() {
      this.getBulletinListAPI();
    },
  },
  methods: {
    getBulletinUtilsAPI() {
      getBulletinUtils("parent_type=BULLETIN_LINNLIFE_HELP").then((res) => {
        this.subItems = res.data.subItems;
      });
    },
    getBulletinListAPI() {
      // query
      let query = `type=BULLETIN_LINNLIFE_HELP&village_id=${this.village_id}`;
      query += `&page=${this.page}`;
      if (this.search) query += `&search=${this.search}`;

      getBulletinList(query).then((res) => {
        this.items = res.data.data;
        this.lastPage = res.data.total_page;
      });
    },
    getBulletinAPI(id) {
      getBulletin(id).then((res) => {
        this.item = res.data;
      });
    },
    // Utils
    setFilter(code) {
      this.filter = code;
      this.getBulletinListAPI();
    },
  },
  created() {
    this.getBulletinListAPI();
    this.getBulletinUtilsAPI();
  },
};
</script>
