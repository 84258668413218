
/**
 * @param {*} val 
 * @returns 번호 형식 010-xxxx-xxxx
 */
export function hyphen(val) {
  if (val != "" && val != null && val != undefined) {
    return val.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-");
  }
}

/**
 * 
 * @param {*} val 
 * @returns 3자리마다 콤마(,)
 */
export function comma(val) {
  if (val == 0) return 0;
  if (val != "" && val != null && val != undefined) {
    val = Number(val);
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}