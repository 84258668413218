<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in" fluid>
    <div class="web-side"></div>
    <div class="location-box">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="back()" class="back">
        <Back />
      </a>
      <strong class="lo-title"> {{ village.display_name }} </strong>
      <div class="icon-box">
        <a href="#n" class="share" style="z-index: 100" @click.stop="sharing"
          ><Iconshare
        /></a>
      </div>
    </div>
    <v-container class="pd0">
      <div class="tabs-box">
        <ul class="tab-menu">
          <li
            v-for="(content, i) in village.contents"
            :class="{ active: content.id === selected_id }"
            :key="i"
          >
            <a href="#n" @click="selected_id = content.id">
              {{ content.title }}
            </a>
          </li>
          <!-- 단지안내에서 공지사항,도움말 삭제  -->
          <!-- <li :class="{ active: selected_id === 'notice' }">
            <a @click="selected_id = 'notice'"> 공지사항 </a>
          </li>
          <li :class="{ active: selected_id === 'faq' }">
            <a @click="selected_id = 'faq'"> 도움말 </a>
          </li> -->
        </ul>
      </div>
      <VillageContents
        :village="village"
        :content="selected_content"
        @refresh="init"
        v-if="selected_content.id"
      />
      <VillageNotice :village_id="village.id" v-if="selected_id == 'notice'" />
      <VillageFaq :village_id="village.id" v-if="selected_id == 'faq'" />
    </v-container>

    <v-dialog v-model="isPopup" max-width="600px">
      <v-card>
        <v-card-title class="text-h6">공지사항</v-card-title>
        <v-card-text v-if="village.popup_contents && village.popup_contents.length">
          <div v-html="village.popup_contents[0]"></div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="isPopup = false">확인</v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import Weblogo from "@/assets/images/common/new-logo.svg";

import VillageContents from "@/components/VillageInfo/VillageContents";
import VillageNotice from "@/components/VillageInfo/VillageNotice";
import VillageFaq from "@/components/VillageInfo/VillageFaq";

import Iconshare from "@/assets/images/icon/icon-share.svg";
import Back from "@/assets/images/icon/icon-back.svg";

import { getVillage } from "@/api/index.js";

export default {
  components: {
    VillageContents,
    Iconshare,
    Back,
    Weblogo,
    VillageNotice,
    VillageFaq,
    // ContractInfo,
  },
  name: "VillageInfo",
  data() {
    return {
      openTab: 0,

      village: {
        contents: [],
      },

      selected_id: 0,
      isPopup: false,
    };
  },
  metaInfo() {
    if (this.village.id == 1) {
      return {
        title: "파주 운정신도시 우미 린 더 퍼스트 공식홈페이지",
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: "파주 운정신도시 우미 린 더 퍼스트",
          },
          {
            vmid: "keywords",
            property: "keywords",
            content: "파주운정, 우미린, 린더퍼스트",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: "파주 운정신도시 우미 린 더 퍼스트 공식홈페이지",
          },
          {
            vmid: "og:url",
            property: "og:url",
            content: "https://hub.smartlynn.co.kr/villageInfo/1",
          },
          {
            vmid: "description",
            property: "description",
            content: "파주 운정신도시 우미 린 더 퍼스트 공식홈페이지",
          },
        ],
      };
    } else if (this.village.id == 3) {
      return {
        title: "충북혁신도시우미린스테이 공식홈페이지",
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: "충북혁신도시우미린스테이",
          },
          {
            vmid: "keywords",
            property: "keywords",
            content: "충북혁신도시우미린스테이, 충북혁신도시, 우미린, 린스테이",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: "충북혁신도시우미린스테이  공식홈페이지",
          },
          {
            vmid: "og:url",
            property: "og:url",
            content: "https://hub.smartlynn.co.kr/villageInfo/3",
          },
          {
            vmid: "description",
            property: "description",
            content: "충북혁신도시우미린스테이  공식홈페이지",
          },
        ],
      };
    }
  },
  computed: {
    selected_content() {
      let content = this.village.contents.find(
        (item) => item.id == this.selected_id
      );
      if (!content) content = { id: 0, childs: [], is_rent: false };
      return content;
    },
  },
  methods: {
    init() {
      this.getVillageContents(this.$route.params.id);
    },
    getVillageContents(id) {
      getVillage(id).then((res) => {
        this.village = res.data;
        if (this.village.popup_contents.length > 0) this.isPopup = true;
        try {
          this.selected_id = res.data.contents[0].id;
        } catch (err) {
          console.log(err);
        }
      });
    },
    moveTo(path) {
      this.$router.push({ path: path });
    },
    sharing() {
      let val = window.location.href.replace("#n", "");
      const element = document.createElement("textarea");
      element.value = val;
      element.setAttribute("readonly", "");
      element.style.position = "absolute";
      element.style.left = "-9999px";
      document.body.appendChild(element);
      element.select();

      var meta = document.createElement("meta");
      meta.setAttribute("property", "og:title");
      meta.setAttribute("content", this.village.display_name);
      document.head.appendChild(meta);

      if (navigator.share) {
        // 공유박스가 뜰 때
        navigator.share({
          title: this.village.display_name,
          url: val,
        });
      } else {
        // 공유박스가 안 뜬다면 flutter sharing 호출
        try {
          window.flutter_inappwebview
            .callHandler("doShare", this.village.display_name, val)
            .then(function (result) {
              console.log(
                "@@@ flutterInAppWebViewPlatformReady doShare  result: ",
                result
              );
            });
        } catch (e) {
          // 그래도 안 된다면 공유복사~
          var returnValue = document.execCommand("copy");
          if (!returnValue) {
            throw new Error("copied nothing");
          } else {
            this.$store.commit("SUCCESS", "공유 링크가 복사되었습니다.");
          }
        }
      }

      document.head.removeChild(meta);
      document.body.removeChild(element);
    },
    back() {
      if (this.$store.state.hub) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: "villageInfoList",
          params: { tabIndex: 0 },
        });
      }
    },
  },
  created() {
    this.init();
  },
};
</script>
