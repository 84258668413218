<template>
  <v-container class="vh-footer ps-re lynn-mobile pd0" fluid>
    <div class="location-box">
      <a @click="$router.go(-1)" class="back"><Back /></a>
      <strong class="lo-title">잔금납부 안내</strong>
    </div>
    <div class="self-contbox">
      <strong class="t-cont-title">납입금액<span>[금액 단위:원]</span></strong>
      <div class="text-set">
        <p>
          납부일자에 따라 금액이 변동될수 있으니 납부가능한 일자에 맞춰
          조회하시기 바랍니다
        </p>
      </div>
      <div class="sel-inbtn type4">
        <div class="left">
          <label>기준일</label>
        </div>
        <div class="right">
          <!--클릭 하면 달력-->
          <v-text-field
            v-model="search"
            class="sel-cus"
            label="기준일"
            hide-details
            single-line
            name="name"
            id="id"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </div>
      </div>
      <strong class="t-sub-title pl5">아파트</strong>
      <table class="t-table cont-table">
        <tr class="bottom">
          <td class="bg2">
            <div class="inbox">
              <div class="left"><span class="tx4">약정일자</span></div>
              <div class="right"><span class="tx4">2021.08.15</span></div>
            </div>
            <div class="inbox">
              <div class="left"><span class="tx4">약정금액</span></div>
              <div class="right"><span class="tx4">2,500,000</span></div>
            </div>
            <div class="inbox">
              <div class="left">
                <span class="tx4"
                  >연체금액<span class="small">(적용일수 : 50)</span></span
                >
              </div>
              <div class="right"><span class="tx4">100,000</span></div>
            </div>
          </td>
        </tr>
        <tr class="bottom">
          <td class="bg2">
            <div class="left"><span class="tx1">납부금액</span></div>
            <div class="right"><span class="tx1">2,600,000</span></div>
          </td>
        </tr>
        <tr>
          <td class="bg2">
            <span class="tx1">납부계좌 (고객별 가상계좌)</span>
            <span class="tx1">수협 0000-0000-0000</span>
            <span class="tx1">우미건설홍길동</span>
          </td>
        </tr>
      </table>

      <strong class="t-sub-title pl5">별도품목</strong>
      <table class="t-table cont-table">
        <tr class="bottom">
          <td class="bg2">
            <div class="inbox">
              <div class="left"><span class="tx4">약정금액</span></div>
              <div class="right"><span class="tx4">2,500,000</span></div>
            </div>
            <div class="inbox">
              <div class="left">
                <span class="tx4"
                  >연체금액<span class="small">(적용일수 : 50)</span></span
                >
              </div>
              <div class="right"><span class="tx4">100,000</span></div>
            </div>
          </td>
        </tr>
        <tr class="bottom">
          <td class="bg2">
            <div class="left"><span class="tx1">납부금액</span></div>
            <div class="right"><span class="tx1">2,600,000</span></div>
          </td>
        </tr>
        <tr>
          <td class="bg2">
            <span class="tx1">납부계좌 (고객별 가상계좌)</span>
            <span class="tx1">수협 0000-0000-0000</span>
            <span class="tx1">우미건설홍길동</span>
          </td>
        </tr>
      </table>

      <strong class="t-sub-title pl5">후불이자</strong>
      <table class="t-table cont-table">
        <tr class="bottom">
          <td class="bg2">
            <div class="left"><span class="tx1">납부금액</span></div>
            <div class="right"><span class="tx1">3,577,185</span></div>
          </td>
        </tr>
        <tr>
          <td class="bg2">
            <span class="tx1">납부계좌 (고객별 가상계좌)</span>
            <span class="tx1">수협 0000-0000-0000</span>
            <span class="tx1">우미건설홍길동</span>
          </td>
        </tr>
      </table>

      <strong class="t-sub-title pl5">보증수수료</strong>
      <table class="t-table cont-table">
        <tr class="bottom">
          <td class="bg2">
            <div class="left"><span class="tx1">납부금액</span></div>
            <div class="right"><span class="tx1"></span></div>
          </td>
        </tr>
        <tr>
          <td class="bg2">
            <span class="tx1">납부계좌 (고객별 가상계좌)</span>
            <span class="tx1">수협 0000-0000-0000</span>
            <span class="tx1">우미건설홍길동</span>
          </td>
        </tr>
      </table>

      <div class="text-set mt0 mb15">
        <p>
          ※ 별도품목, 후불이자, 보증수수료 순으로 수납처리 됨으로 참고하시기
          바랍니다.
        </p>
      </div>
      <table class="t-table cont-table">
        <tr class="bottom">
          <td class="bg1">
            <div class="left"><span class="tx2">합계</span></div>
            <div class="right"><span class="tx2">138,783,525</span></div>
          </td>
        </tr>
        <tr>
          <td class="bg3">
            <div class="text-right"><span class="tx2">입금증 지참</span></div>
          </td>
        </tr>
      </table>
      <div class="text-set mt0 mb30">
        <p>
          ※ 선납세대의 경우 할인, 미납세대의 경우 연체료가 반영된 금액입니다.
          상세사항은 수납내역 화면을 참고바라며, 고객별 가상계좌 이오니 해당호수
          이외에 공유가 불가한점 안내드립니다.
        </p>
      </div>

      <strong class="t-cont-title"
        >대출(실행)내역<span>[금액 단위:원]</span></strong
      >
      <table class="t-table cont-table">
        <tr class="bottom">
          <td class="bg2">
            <div class="inbox">
              <div class="left"><span class="tx1">은행</span></div>
              <div class="right">
                <span class="tx1">금액/</span>
                <span class="tx1">상환문의 연락처</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bg2">
            <div class="left">
              <span class="tx3"
                >광주은행수도권금융센터광주은행수도권금융센터</span
              >
            </div>
            <div class="right">
              <span class="tx3">197,740,000</span>
              <span class="tx5">031-8017-7910</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bg2">
            <div class="left">
              <span class="tx3"
                >광주은행수도권금융센터광주은행수도권금융센터</span
              >
            </div>
            <div class="right">
              <span class="tx3">197,740,000</span>
              <span class="tx5">031-8017-7910</span>
            </div>
          </td>
        </tr>
        <tr class="bottom">
          <td class="bg1">
            <div class="left"><span class="tx2">합계</span></div>
            <div class="right"><span class="tx2">138,783,525</span></div>
          </td>
        </tr>
        <tr>
          <td class="bg3">
            <div class="text-right">
              <span class="tx2">대출상환 영수증 지참</span>
            </div>
          </td>
        </tr>
      </table>
      <div class="text-set mt0 mb30">
        <p>
          ※ 상기금액은 최초 신청한 금액으로 개별상환은 미반영 되어 있으니
          상환내역은 해당은행으로 문의바랍니다
        </p>
      </div>

      <strong class="t-cont-title"
        >선수관리비<span>[금액 단위:원]</span></strong
      >
      <table class="t-table cont-table">
        <colgroup>
          <col width="33%" />
          <col width="34%" />
          <col width="33%" />
        </colgroup>
        <tr class="bottom">
          <td class="bg2 text-left"><span class="tx1">구분</span></td>
          <td class="bg2 text-center"><span class="tx1">타입</span></td>
          <td class="bg2 text-right"><span class="tx1">금액</span></td>
        </tr>
        <tr class="bottom">
          <td class="bg2 text-left"><span class="tx3">선수관리비</span></td>
          <td class="bg2 text-center"><span class="tx3">84A</span></td>
          <td class="bg2 text-right"><span class="tx3">100,000</span></td>
        </tr>
        <tr>
          <td class="bg2" colspan="3">
            <span class="tx1">납부계좌 (고객별 가상계좌)</span>
            <span class="tx1">수협 0000-0000-0000</span>
            <span class="tx1">우미건설홍길동</span>
          </td>
        </tr>
      </table>
      <div class="text-set mt0 mb30">
        <p>
          ※ 선수관리비 입금후 관리사무소 내방하셔서 영수증 수령하시기 바랍니다.
        </p>
        <p>
          ※ 상기 각 항목에 대한 납부확인이 완료된 후 입주증 발급 및 키불출이
          진행됩니다.
        </p>
      </div>

      <strong class="t-cont-title">입주증 발급순서</strong>
      <ul class="darrow-list">
        <li class="text">입금</li>
        <li class="arrow"><Darrow /></li>
        <li class="text">관리사무소 선수관리비 영수증 수령</li>
        <li class="arrow"><Darrow /></li>
        <li class="text">입주지원센터 입주증 발급</li>
        <li class="arrow"><Darrow /></li>
        <li class="text">
          <span class="ds-b">대출상환 및 상환영수증 발행</span>
          <span class="ds-b color-9">(대출세대에 한함, FAX 043-287-7808)</span>
        </li>
        <li class="text">키불출</li>
      </ul>

      <div class="warmes-box pwidth100">
        <strong>※ 주의 사항</strong>
        <p>
          본 화면은 계약자님의 이해를 돕기 위한 것으로
          <span class="under">실제와 다를 수 있으며</span>, 법적 책임 소재에
          대한 증빙으로 사용될 수 없습니다. 정확한 계약 내용은 해당 계약서를
          확인하여 주시고, 납부 및 미납 내역은 해당 고객센터에 문의하여 주시기
          바랍니다.
        </p>
      </div>
      <v-btn
        color="primary"
        elevation="0"
        class="fts18 ftw7 mw-400 pwidth100 mt30"
        min-height="50"
        >확인</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Darrow from "@/assets/images/icon/icon-darrow.svg";

export default {
  name: "BalancePayment",
  components: {
    Back,
    Darrow,
  },
  computed: {},
  methods: {},
};
</script>
