<template>
  <v-container class="pd16">
    <strong class="t-center-title">계약 조건 변경 방문 예약</strong>
    <strong class="t-cont-title">계약 조건 변경 안내사항</strong>
    <ul class="bull-list">
      <li style="font-size: 14px">
        계약조건 변경 기준이 부적합하실 경우 신청이 취소될 수 있습니다.
      </li>
      <li style="font-size: 14px">구비서류를 필히 지참하시어 방문바랍니다.</li>
    </ul>
    <strong class="t-cont-title">임대 조건 변경</strong>
    <div class="change-box">
      <div class="left">
        <strong>변경 전</strong>
        <div class="c-textbox">{{ fromCondition.DS_OPTION }}</div>
      </div>
      <div class="center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16.243"
          viewBox="0 0 15 16.243"
        >
          <g
            id="Icon_feather-arrow-right-circle"
            data-name="Icon feather-arrow-right-circle"
            transform="translate(-10.5 -9.879)"
          >
            <path
              id="패스_591"
              data-name="패스 591"
              d="M18,24l6-6-6-6"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              id="패스_592"
              data-name="패스 592"
              d="M12,18H24"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
          </g>
        </svg>
      </div>
      <div class="right">
        <strong>변경 후</strong>
        <div class="c-cont">
          <v-container class="pd0">
            <v-select
              :disabled="isRequestExist"
              v-model="toCondition"
              class="sel-cus"
              style="font-size: 14px"
              :items="conditonItems"
              return-object
              item-text="DS_OPTION"
              label="계약정보 주소"
              menu-props="auto"
              hide-details
              single-line
            ></v-select>
          </v-container>
        </div>
      </div>
    </div>
    <table class="colorfull-table">
      <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="34%" />
      </colgroup>
      <tr>
        <th class="trans"></th>
        <th class="cto1 text-right">임대보증금</th>
        <th class="cto1 text-right">임대료</th>
      </tr>
      <tr>
        <td class="cto2 text-left">{{ fromCondition.DS_OPTION }}</td>
        <td class="cto2 text-right">
          {{ fromCondition.AM_DEPOSIT | comma }}
        </td>
        <td class="cto2 text-right">
          {{ fromCondition.AM_LEASE | comma }}
        </td>
      </tr>
      <tr>
        <td class="cto3 text-left">{{ toCondition.DS_OPTION }}</td>
        <td class="cto3 text-right" v-if="is_same_condition">
          {{
            fromCondition.AM_DEPOSIT_UP
              ? fromCondition.AM_DEPOSIT_UP
              : fromCondition.AM_DEPOSIT | comma
          }}
        </td>
        <td class="cto3 text-right" v-else>
          {{ toCondition.AM_SELLING | comma }}
        </td>

        <!-- <td class="cto3 text-right" v-if="is_same_condition">
          {{ fromCondition.AM_LEASE_UP | comma }}
        </td> -->
        <td class="cto3 text-right">
          {{ toCondition.AM_MLEASE | comma }}
        </td>
      </tr>
      <tr>
        <td class="cto1 text-left">증액</td>
        <td class="cto1 text-right">
          {{ depositDiff | comma }}
        </td>
        <td class="cto1 text-right">
          {{ rentDiff | comma }}
        </td>
      </tr>
    </table>
    <strong class="t-cont-title">상세 안내</strong>
    <div class="text-set">
      <p>장소 : 커뮤니티센터 내 통합관리센터</p>
      <p>
        구비서류 필참
        <a href="#n" @click.prevent.stop="dialog = true">구비서류 보기</a>
      </p>
    </div>
    <strong class="t-cont-title">계약 변경 방문 예약</strong>
    <div>
      <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-if="availableDay.from == availableDay.to && !isRequestExist"
            label="계약 변경 방문 희망일"
            disabled
            :value="`계약일로부터 ${change_rental_condition_due_date}일 이내 방문 신청 가능합니다.`"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
          <v-text-field
            v-else
            label="계약 변경 방문 희망일"
            v-model="date"
            readonly
            v-bind="attrs"
            :disabled="isRequestExist"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :min="availableDay.from"
          :max="availableDay.to"
          :show-current="availableDay.from"
          :allowed-dates="allowedDates"
          @change="menu = false"
          :day-format="(date) => new Date(date).getDate()"
          locale="KR"
          full-width
          no-title
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="address-set">
      <p>{{ home.village_name }}</p>
      <p>{{ home.dong_no }}동 {{ home.ho_no }}호</p>
      <p>임차인 {{ contractor.name }}</p>
      <p>
        신청일 : {{ today.substr(0, 4) }}년 {{ today.substr(5, 2) }}월
        {{ today.substr(8, 2) }}일
      </p>
    </div>

    <div v-if="isRequestExist">
      <v-btn
        color="grey lighten-3"
        class="fts18 ftw7 mw-700 pwidth70 grey--text text--lighten-1"
        min-height="50"
        elevation="0"
        >접수완료</v-btn
      >
      <v-btn
        color="primary"
        class="fts18 ftw7 mw-700 pwidth20 ml-2"
        min-height="50"
        elevation="0"
        @click="cancelDialog = true"
        >신청취소</v-btn
      >
    </div>
    <div v-else>
      <v-btn
        v-if="!is_form_ready"
        color="grey lighten-3"
        class="fts18 ftw7 mw-700 pwidth100 grey--text text--lighten-1"
        min-height="50"
        elevation="0"
        >계약조건 변경 신청하기</v-btn
      >
      <v-btn
        v-else
        color="primary"
        class="fts18 ftw7 mw-700 pwidth100"
        min-height="50"
        elevation="0"
        :disabled="!is_form_ready"
        @click="postRentalContractChangeAPI()"
        >계약조건 변경 신청하기</v-btn
      >
    </div>
    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          계약조건 변경 방문예약을 취소하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="delRentalContractChangeAPI(id)"
          >
            예
          </v-btn>
          <v-btn color="primary darken-1" text @click="cancelDialog = false">
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-container class="pd20 bgc-3 vh-full">
        <strong class="close-title" style="background-color: #ffffff">
          구비서류안내
          <a @click="dialog = false"><Close /></a>
        </strong>
        <div class="pd0 text-left">
          <strong class="ds-b lh24 fts14 ftw5 color-5">준비중입니다.</strong>
          <!-- <strong class="ds-b lh24 fts14 ftw5 color-5">본인방문</strong>
          <ul class="pd0 mb24">
            <li class="fts14 ftw24 color-5">·신분증</li>
            <li class="fts14 ftw24 color-5">·인감도장</li>
            <li class="fts14 ftw24 color-5">·인감증명서 1부</li>
            <li class="fts14 ftw24 color-5">·주민등록등본 1부</li>
          </ul>

          <ul class="pd0 mb24">
            <li class="fts14 ftw24 color-5">
              *임대차계약서 원본이 없으실 경우 경찰서 신고 후 분실 신고증을 필히
              지참하셔야 합니다.
            </li>
            <li class="fts14 ftw24 color-5">
              *임대차계약서 원본은 회수됩니다.
            </li>
          </ul>

          <strong class="ds-b lh24 fts14 ftw5 color-5"
            >대리인 방문(대리인은 가족에 한함)</strong
          >
          <ul class="pd0 mb24">
            <li class="fts14 ftw24 color-5">·본인방문 구비서류</li>
            <li class="fts14 ftw24 color-5">·대리인신분증</li>
            <li class="fts14 ftw24 color-5">·위임장</li>
            <li class="fts14 ftw24 color-5">·위임용 인감증명서 1부</li>
            <li class="fts14 ftw24 color-5">·가족관계증명서</li>
          </ul>

          <strong class="ds-b lh24 fts14 ftw5 color-5"
            >전세자금대출 외 채권양도 또는 채권압류세대</strong
          >
          <ul class="pd0 mb24">
            <li class="fts14 ftw24 color-5">
              ·채권자(채권압류자)의 재계약 동의서
            </li>
          </ul>

          <strong class="ds-b lh24 fts14 ftw5 color-5">관리비 연체세대</strong>
          <ul class="pd0 mb24">
            <li class="fts14 ftw24 color-5">
              ·관리비 완납증명서(관리사무소 발급용)
            </li>
          </ul>

          <ul class="pd0">
            <li class="fts14 ftw24 color-5">
              *제출하는 서류는 계약일 기준 3개월 이내 발급한 것만 유효하오니
              방문 전 반드시 발급일자를 확인하시기 바랍니다.
            </li>
          </ul> -->
        </div>
        <v-btn
          color="primary"
          elevation="0"
          class="fts18 ftw7 mw-700 pwidth100 mt30"
          min-height="50"
          @click="dialog = false"
          >확인</v-btn
        >
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import Close from "@/assets/images/contract/close.svg";
import { getContract, getContractor, getLeaseRenew } from "@/api/contract.js";
import {
  postRentalContractChange,
  delRentalContractChange,
  getRentalContractChangeList,
} from "@/api/index.js";
import { get_now_string, isFuture, add_day, isWeekend } from "@/utils/time.js";
export default {
  components: { Close },
  name: "ContractChange",
  data() {
    return {
      conditonItems: [],
      fromCondition: {},
      toCondition: {},

      contractOption: "",
      contractor: {},
      contractDate: "",

      isRequestExist: false, // 접수중인
      id: 0,

      // Utils
      cancelDialog: false,
      today: get_now_string(),
      picker: new Date().toISOString().substr(0, 10),
      date: "",
      menu: false,
      dialog: false,
    };
  },
  computed: {
    homeList() {
      return this.$store.getters.getHomeList;
    },
    home() {
      if (this.$route.query.home) {
        return this.homeList.find((item) => {
          return item.id == Number(this.$route.query.home);
        });
      }
      return this.$store.getters.getHome;
    },
    user() {
      return this.$store.getters.getUser;
    },
    depositDiff() {
      if (this.is_same_condition) {
        return this.fromCondition.AM_DEPOSIT_UP || 0;
      }

      return this.toCondition.AM_SELLING - this.fromCondition.AM_DEPOSIT || 0;
    },
    rentDiff() {
      // if (this.is_same_condition) {
      //   return (
      //     this.fromCondition.AM_LEASE_UP - this.fromCondition.AM_LEASE || 0
      //   );
      // }
      return this.toCondition.AM_MLEASE - this.fromCondition.AM_LEASE || 0;
    },
    is_form_ready() {
      return Boolean(
        this.date &&
          this.fromCondition.DS_OPTION &&
          this.toCondition.DS_OPTION &&
          this.fromCondition.DS_OPTION !== this.toCondition.DS_OPTION
      );
    },
    is_same_condition() {
      if (this.fromCondition && this.toCondition) {
        return this.fromCondition.DS_OPTION == this.toCondition.DS_OPTION;
      }
      return false;
    },
    change_rental_condition_due_date() {
      if (this.home) {
        return this.home.change_rental_condition_due_date;
      } else {
        return "";
      }
    },
    availableDay() {
      if (this.home) {
        // 오늘~계약일에서 90일 전까지.
        let from = this.today;
        let to = add_day(
          this.contractDate,
          this.home.change_rental_condition_due_date * -1
        );

        // if (!isFuture(this.today, from)) {
        //   from = this.today;
        // }

        // 만일 계약일에서 90일 전이 이미 지난 날짜라면 오늘 날짜로 고정하여 활성화를 막는다.
        if (!isFuture(this.today, to)) {
          to = this.today;
        }
        return { from: from, to: to };
      } else {
        return { from: "-", to: "-" };
      }
    },
  },
  methods: {
    getRentalContractChangeListAPI() {
      let query = `user_id=${this.user.id}&&home_id=${this.home.id}&&change_type=CHANGE&&status=['REQUEST_STATUS_REQUESTED']`;
      getRentalContractChangeList(query).then((res) => {
        if (res.data.length) {
          let item = res.data[0];
          this.isRequestExist = true;
          this.date = item.visit_date;
          this.id = item.id;
          this.getLeaseRenewAPI(item.rental_to_type);
        } else {
          this.date = "";
          this.id = 0;
          this.isRequestExist = false;
          this.getContractAPI();
        }
      });
    },
    getContractAPI() {
      // 현재 임대 계약 정보
      let query = ``;
      this.getLeaseRenewAPI(null);
      getContract(this.home.id).then((res) => {
        this.contractDate = res.data.contractDate;

        if (
          this.availableDay.from == this.availableDay.to &&
          !this.isRequestExist
        ) {
          this.$store.commit("ERROR", "계약변경 신청 기간이 아닙니다.");
        }
      });
    },
    getContractorAPI() {
      // 계약자 정보
      let query = `contract_id=${this.home.id}`;
      getContractor(this.home.id).then((res) => {
        this.contractor = res.data;
      });
    },
    getLeaseRenewAPI(to) {
      getLeaseRenew(this.home.id, "is_renew=N").then((res) => {
        this.conditonItems = res.data.conditions;
        this.fromCondition = res.data.info;
        this.fromCondition.DS_OPTION = "";

        if (this.conditonItems.length) {
          this.toCondition = this.conditonItems[0];
          this.fromCondition.DS_OPTION = this.toCondition.CONT_DS_OPTION;
          this.conditonItems.forEach((item) => {
            if (to) {
              if (item.DS_OPTION == to) {
                this.toCondition = item;
              }
            }
          });
        }
      });
    },
    postRentalContractChangeAPI() {
      let data = {
        user: this.user.id,
        home: this.home.id,
        rental_from_type: this.fromCondition.DS_OPTION,
        rental_to_type: this.toCondition.DS_OPTION,
        change_type: "CHANGE",
        visit_datetime: this.date,
      };
      postRentalContractChange(data)
        .then((res) => {
          this.$store.commit("SUCCESS", "계약조건 변경 신청되었습니다.");
          this.getRentalContractChangeListAPI();
        })
        .catch((err) => {
          this.$store.commit("ERROR", "계약조건 변경 신청에 실패하였습니다.");
        });
    },
    delRentalContractChangeAPI(id) {
      delRentalContractChange(id)
        .then((re) => {
          this.$store.commit("SUCCESS", "방문예약이 취소 되었습니다.");
          this.cancelDialog = false;
          this.getRentalContractChangeListAPI();
        })
        .catch((err) => {
          console.log("실패...");
          console.log(err);
        });
    },
    allowedDates(val) {
      if (this.home.is_holiday_apply_to_change_rental_condition) {
        return !isWeekend(val) && !this.home.holidays.includes(val);
      } else {
        return !isWeekend(val);
      }
    },
  },
  created() {
    this.getRentalContractChangeListAPI();
    this.getContractorAPI();
  },
  mounted() {},
};
</script>
