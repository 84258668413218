import { AuthConnection } from "./config.js";

export function depositCheck(home_id) {
    return AuthConnection.get(`/report/bill/deposit/${home_id}/html`);
}

export function rentFeeCheck(home_id, year) {
    return AuthConnection.get(`/report/bill/rent/${home_id}/${year}/html`);
}

export function renewalCheck(home_id) {
    return AuthConnection.get(`/report/renewal/${home_id}/html`);
}

export function rightCheck(home_id) {
    return AuthConnection.get(`/report/right/${home_id}/html`);
}

export function getPdfBlob(url) {
    return AuthConnection.get(`${url}`, {
        responseType: "blob"
    });
}