<template>
  <v-container class="vh-footer pd20 lynn-web-in bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.push({ name: 'csCenter' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">투표하기</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops bg-gray">
      <div class="vote-incont">
        <!-- 제목 -->
        <div class="vtc-title">
          <p>투표발행자 (EX : 관리사무소, 입주민 대표)</p>
          <strong>제목(EX: 충북 우미 린 101동 동대표 투표)</strong>
        </div>

        <!-- 일반 텍스트 상자 -->
        <ul class="vtc-box">
          <li class="vtc-ntext">
            내용(max 100 자) ex: 동대표 후보 소개 및 공략에 대한 정보는 게시판
            ㅇㅇㅇ 공지사항을 확인해주세요.
          </li>
          <li class="vtc-date">
            <div class="left">
              <strong>투표 시작일</strong>
              <p>2021-06-01 09:00</p>
            </div>
            <div class="right">
              <strong>투표 종료일</strong>
              <p>2021-06-03 23:59</p>
            </div>
          </li>
          <li class="vtc-ntext">투표번호 : 000001</li>
        </ul>

        <!-- 투표 폼 -->
        <ul class="vtc-box">
          <li class="vtc-ftitle">
            <strong
              >충북혁신도시 우미린스테이 405동 대표 후보를 투표해주세요.</strong
            >
            <p>중복투표 가능</p>
          </li>
          <li>
            <div class="vote-checkbox">
              <input type="radio" id="vote_001" name="voteset01" />
              <label for="vote_001" class="icon-box">
                <Vote class="check" />
                <VoteDone class="done" />
              </label>
              <label for="vote_001" class="text-box">박후보</label>
            </div>
          </li>
          <li>
            <div class="vote-checkbox">
              <input type="radio" id="vote_002" name="voteset01" />
              <label for="vote_002" class="icon-box">
                <Vote class="check" />
                <VoteDone class="done" />
              </label>
              <label for="vote_002" class="text-box"
                >박 하늘별님구름햇님보다사랑스러우리</label
              >
            </div>
          </li>
          <li>
            <div class="vote-checkbox">
              <input type="radio" id="vote_003" name="voteset01" />
              <label for="vote_003" class="icon-box">
                <Vote class="check" />
                <VoteDone class="done" />
              </label>
              <label for="vote_003" class="text-box"
                >프라이인드로스테쭈젠댄마리소피아수인레나테엘리자벳피아루이</label
              >
            </div>
          </li>
        </ul>

        <!-- 투표 진행사항 폼 -->
        <strong class="vote-formtitle">투표 진행사항</strong>
        <ul class="vtc-box">
          <li class="vtc-ftitle">
            <strong
              >충북혁신도시 우미린스테이 405동 대표 후보를 투표해주세요.</strong
            >
            <p>중복투표 가능</p>
          </li>
          <li>
            <div class="vote-ingbox">
              <div class="text-box">
                <strong>총 유권자 수</strong>
                <p>312,000</p>
              </div>
              <div class="text-box">
                <strong>투표 참여자 수</strong>
                <p>156,000</p>
              </div>
              <div class="text-box end">
                <strong>투표율</strong>
                <p>50.0%</p>
              </div>
              <div class="progress">
                <span class="in-progress" style="width: 50%"></span>
              </div>
            </div>
          </li>
          <li class="vtc-my">
            <strong>나의 투표 사항</strong>
            <p>박후보</p>
          </li>
        </ul>

        <!-- 투표 결과 폼 -->
        <strong class="vote-formtitle">투표 결과</strong>
        <ul class="vtc-box">
          <li>
            <div class="vote-complete light">
              <!-- 1위 (당첨자 에겐 light 클래스 추가) -->
              <div class="title">
                <strong>1위(공동)</strong>
                <p>30.0%(52표)</p>
              </div>
              <div class="progress">
                <span class="in-progress" style="width: 30%"></span>
              </div>
              <p>박 하늘별님구름햇님보다사랑스러우리</p>
            </div>
          </li>
          <li>
            <div class="vote-complete light">
              <!-- 1위 (당첨자 에겐 light 클래스 추가) -->
              <div class="title">
                <strong>1위(공동)</strong>
                <p>30.0%(52표)</p>
              </div>
              <div class="progress">
                <span class="in-progress" style="width: 30%"></span>
              </div>
              <p>박후보</p>
            </div>
          </li>
          <li>
            <div class="vote-complete">
              <div class="title">
                <strong>3위</strong>
                <p>15.0%(26표)</p>
              </div>
              <div class="progress">
                <span class="in-progress" style="width: 15%"></span>
              </div>
              <p>프라이인드로스테쭈젠댄마리소피아수인레나테엘리자벳피아루이</p>
            </div>
          </li>
          <li>
            <div class="vote-complete">
              <div class="title">
                <strong>4위</strong>
                <p>7.5%(13표)</p>
              </div>
              <div class="progress">
                <span class="in-progress" style="width: 7.5%"></span>
              </div>
              <p>김대표</p>
            </div>
          </li>
          <li>
            <div class="vote-complete">
              <div class="title">
                <strong>5위</strong>
                <p>1.7%(1표)</p>
              </div>
              <div class="progress">
                <span class="in-progress" style="width: 1.7%"></span>
              </div>
              <p>홍길동</p>
            </div>
          </li>
        </ul>

        <!-- 안내메세지 -->
        <div class="vtc-etc">
          <strong>※ 투표 안내</strong>
          <p>
            투표는 아파트 입주자대표회의의 구성원들에 의해 생성되며 관련한
            문의는 대표자 또는 투표 생성자에게 문의해 주세요~
          </p>
        </div>

        <!-- 유저 정보 -->
        <ul class="vtc-user">
          <li>충북혁신도시 우미린스테이</li>
          <li>405동 1902호</li>
          <li>투표자 홍길동</li>
        </ul>
        <a href="#none" class="vtc-btn" @click="votepopup = true">투표하기</a>
      </div>
    </v-card>

    <!-- 투표 재확인 다이얼로그 -->
    <v-dialog v-model="votepopup" max-width="320">
      <v-card>
        <!--확인팝업-->
        <div class="vote-popup">
          <p class="vp-text">
            "투표는 한 번 제출하면 수정하실 수 없습니다. 아래 내용으로
            투표하시겠습니까?
          </p>
          <p class="vp-name">사항 : 김대표</p>
          <div class="vp-btnbox">
            <a href="#none" @click="votepopup = false">아니오</a>
            <a href="#none">예</a>
          </div>
        </div>

        <!--확인팝업2-->
        <div class="vote-popup">
          <p class="vp-text">투표는 세대주만 가능합니다</p>
          <div class="vp-btnbox">
            <a href="#none" @click="votepopup = false">확인</a>
          </div>
        </div>

        <!--투표 완료팝업-->
        <div class="vote-popup">
          <p class="vp-icon"><VoteBig /></p>
          <p class="vp-vtitie">투표완료</p>
          <p class="vp-text center">소중한 한 표 고맙습니다!</p>
          <div class="vp-btnbox">
            <a href="#none" @click="votepopup = false">확인</a>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import Vote from "@/assets/images/btn/btn-vote.svg";
import VoteDone from "@/assets/images/btn/btn-vote-round.svg";
import VoteBig from "@/assets/images/btn/btn-vote-big.svg";

export default {
  name: "Faq",
  components: {
    Back,
    Weblogo,
    Vote,
    VoteDone,
    VoteBig,
  },
  data: () => ({
    votepopup: false,
  }),
  computed: {},
  methods: {},
};
</script>
