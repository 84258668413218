<template>
  <v-container class="vh-footer pd20 lynn-web-in bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a
        @click="$router.push({ name: 'facilityInfo', params: { id: id } })"
        class="back"
      >
        <Back />
      </a>
      <strong class="lo-title">{{ facility.name }} 신청</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops bg-gray">
      <div class="vote-incont">
        <!-- 일반 텍스트 상자 -->
        <!-- <ul class="vtc-box" v-if="reservation.description">
          <li class="vtc-ntext" v-html="reservation.description"></li>
        </ul> -->

        <strong class="vote-formtitle" v-if="reservation.description"
          >신청안내</strong
        >
        <v-expansion-panels accordion v-if="reservation.description">
          <v-expansion-panel>
            <v-expansion-panel-header @click="infoClick = true"
              >사용수칙 및 주의사항</v-expansion-panel-header
            >
            <v-expansion-panel-content
              style="max-height: 240px; overflow: auto"
              class="text-left"
              ><p v-html="reservation.description"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-checkbox
          class="mt5"
          label="동의합니다."
          v-model="check"
          v-if="reservation.description"
          :disabled="!infoClick"
        ></v-checkbox>

        <strong class="vote-formtitle">신청정보</strong>
        <strong class="vote-formtitle ftw4 mt0">날짜선택</strong>
        <div v-if="is_daily_reservation">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date"
                label="체크인"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date"
              :min="min"
              :max="max"
              @change="
                checkFacilityReservationAPI(form.date, form.end_date);
                menu = false;
              "
              :day-format="(date) => new Date(date).getDate()"
              locale="KR"
              full-width
              no-title
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.end_date"
                label="체크아웃"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.end_date"
              :min="form.date"
              :max="max"
              @change="
                checkFacilityReservationAPI(form.date, form.end_date);
                menu2 = false;
              "
              :day-format="(date) => new Date(date).getDate()"
              locale="KR"
              full-width
              no-title
            ></v-date-picker>
          </v-menu>
        </div>
        <div v-else>
          <v-date-picker
            v-if="is_daily_reservation"
            :min="min"
            :max="max"
            locale="KR"
            full-width
            no-title
            v-model="form.date"
            @change="checkFacilityReservationAPI(form.date, form.end_date)"
          ></v-date-picker>

          <v-date-picker
            v-else
            v-model="form.date"
            :min="min"
            :max="max"
            :allowed-dates="allowedDates"
            @change="sync"
            :picker-date.sync="pickerDate"
            :day-format="(date) => new Date(date).getDate()"
            locale="KR"
            full-width
            no-title
          ></v-date-picker>
        </div>

        <v-subheader class="pd0" v-if="allowed_times.length"
          >방문시간</v-subheader
        >

        <v-slide-group
          :value="allowed_times.indexOf(selectedTime)"
          v-if="!is_daily_reservation"
        >
          <v-slide-item
            class="mr10 nh-50"
            v-for="(time, idx) in allowed_times"
            :key="'reservation-time-' + idx"
            v-slot="{ active, toggle }"
          >
            <v-btn
              :value="time"
              :disabled="time.disabled"
              :input-value="active"
              active-class="v-active"
              v-bind:class="[
                {
                  'v-active': time.id === selectedTime.id,
                },
                'pd10 color-2 bgc-3',
              ]"
              depressed
              @click="
                toggle;
                selectedTime = time;
              "
            >
              {{ time.text }} <br />
              {{ time.tooltip }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>

        <div v-if="is_daily_reservation">
          <strong class="vote-formtitle ftw4"> 사용인원 </strong>
          <v-text-field
            class="mt-0 pt-0"
            v-model="form.number_of_users"
            hide-details
            type="number"
          ></v-text-field>
        </div>
        <strong class="vote-formtitle ftw4" v-if="mainOptions.length">
          옵션명
        </strong>
        <ul class="vtc-box">
          <li
            v-for="option in mainOptions"
            :key="'facility-option-' + option.id"
          >
            <div
              :class="
                option.is_disabled ? 'vote-checkbox disabled' : 'vote-checkbox'
              "
            >
              <input
                type="radio"
                name="voteset01"
                :id="`Fac_${option.id}`"
                :disabled="option.is_disabled"
                @click="select(option, true)"
              />
              <label :for="`Fac_${option.id}`" class="icon-box-custom">
                <ReservableChecked class="check" v-if="checkIsIn(option)" />
                <Disabled class="done" v-else-if="option.is_disabled" />
                <Reservable class="done" v-else />
              </label>
              <label :for="`Fac_${option.id}`" class="text-box">
                {{ option.name }}
                <span class="ft-r ds-ib ftw4">
                  {{ option.price | comma }}
                </span>
              </label>
            </div>
          </li>
        </ul>
        <strong class="vote-formtitle ftw4" v-if="subOptions.length">
          옵션명
        </strong>
        <ul class="vtc-box">
          <li
            v-for="option in subOptions"
            :key="'facility-sub-option-' + option.id"
          >
            <div class="vote-checkbox">
              <input
                type="checkbox"
                :id="`Fac_${option.id}`"
                :disabled="option.is_disabled"
                @click="select(option, false)"
              />
              <label :for="`Fac_${option.id}`" class="icon-box-custom">
                <ReservableChecked class="check" v-if="checkIsIn(option)" />
                <Disabled class="done" v-else-if="option.is_disabled" />
                <Reservable class="done" v-else />
              </label>
              <label :for="`Fac_${option.id}`" class="text-box">
                {{ option.name }}
                <span class="ft-r ds-ib ftw4">
                  {{ option.price | comma }}
                </span>
              </label>
            </div>
          </li>
        </ul>

        <div class="mt20 mb20 after">
          <span class="ds-b fts20 ft-l ftw7 lh29">합계</span>
          <span class="ds-b fts20 ft-r ftw7 lh29">{{ priceSum | comma }}</span>
        </div>

        <!-- 유저 정보 -->
        <ul class="vtc-user">
          <li class="title">신청인 {{ user.name }}</li>
          <li>{{ tenantHome.village_name }}</li>
          <li>{{ tenantHome.dong_ho_text }}</li>
          <li>연락처 : {{ user.mobile_form }}</li>
        </ul>

        <v-btn
          v-if="!is_form_ready"
          color="grey lighten-3"
          class="fts18 ftw7 mw-700 pwidth100 mt25 grey--text text--lighten-1"
          min-height="50"
          elevation="0"
          >신청하기</v-btn
        >
        <v-btn
          v-else
          @click="postFacilityReservationSeedAPI()"
          color="primary"
          class="fts18 ftw7 mw-700 pwidth100 mt25"
          min-height="50"
          elevation="0"
          >신청하기</v-btn
        >
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import Disabled from "@/assets/images/btn/checkmark_disabled.svg";
// import DisabledChecked from "@/assets/images/btn/checkmark_disabled_selected.svg";
import Reservable from "@/assets/images/btn/checkmark_unselected.svg";
import ReservableChecked from "@/assets/images/btn/checkmark_selected.svg";

import {
  getFacility,
  checkFacilityReservation,
  postFacilityReservationSeed,
} from "@/api/dwelling";
import {
  get_now_string,
  isFuture,
  add_day,
  add_month,
  set_date,
  daysOfPeriod,
} from "@/utils/time.js";

export default {
  name: "FacilityReservation",
  components: {
    Back,
    Weblogo,
    Disabled,
    Reservable,
    ReservableChecked,
  },
  data() {
    return {
      id: 0,
      item: {},
      form: {
        master: null,
        reservation_time: {},
        reservation_options: [],
        user: null,
        date: "",
        end_date: "",
      },
      today: add_day(get_now_string(), 1),
      end_of_next_month: null,

      // Options
      facilityOptions: [],
      selectedOptions: [],

      // Times
      pickerDate: null,
      selectedTime: {},
      allowed_dates: [],
      allowed_times: [],

      priceSum: 0,

      check: false,
      infoClick: false,

      menu: false,
      menu2: false,
    };
  },
  watch: {
    pickerDate(newval, oldval) {
      this.next_prev_month(newval);
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    tenantHome() {
      return this.$store.getters.getTenantHome;
    },
    facility() {
      if (this.item.facility) {
        return this.item.facility;
      }
      return {};
    },
    reservation() {
      if (this.item.reservation) {
        return this.item.reservation;
      }
      return {};
    },
    kind_code() {
      return this.reservation.kind_code;
    },
    is_daily_reservation() {
      return this.kind_code == "FACILITY_RESERVATION_TYPE_GUEST_HOUSE";
    },
    min() {
      let date = this.reservation.start_date;
      if (!isFuture(this.today, date)) {
        date = this.today;
      }
      return date;
    },
    max() {
      let date = this.reservation.end_date;

      if (!date) {
        return this.end_of_next_month;
      } else if (!isFuture(this.today, date)) {
        date = this.today;
      }

      if (isFuture(this.end_of_next_month, date)) {
        date = this.end_of_next_month;
      }
      return date;
    },
    mainOptions() {
      return this.facilityOptions.filter((i) => {
        return !i.is_sub_option;
      });
    },
    subOptions() {
      return this.facilityOptions.filter((i) => {
        return i.is_sub_option;
      });
    },
    is_form_ready() {
      if (this.is_daily_reservation) {
        return this.selectedOptions.findIndex((i) => !i.is_sub_option) > -1;
      } else {
        return this.selectedOptions.length && this.selectedTime.id;
      }
    },
  },
  methods: {
    getFacilityAPI(id) {
      this.id = id;
      getFacility(id).then((res) => {
        this.item = res.data;
        if (this.item.is_seat_reservation) {
          // Pussh to reservation
          this.$router.push({
            name: "facilitySeatReservation",
            params: { id: this.item.id },
          });
        }

        if (this.item.use_yn == "N") {
          this.noExist("존재하지 않는 시설 입니다.");
        } else if (!this.item.reservation) {
          this.noExist("예약 가능한 시간이 존재하지 않습니다.");
        }
        this.form.master = this.item.reservation.id;

        this.form.date = this.item.reservation.start_date; // 초기 선택 날짜 지정
        if (!isFuture(this.today, this.form.date)) {
          this.form.date = this.today;
        }

        if (this.is_daily_reservation) {
          this.form.end_date = add_day(this.item.reservation.start_date, 1); // 초기 선택 날짜 지정
          this.form.number_of_users = 2;
          if (!isFuture(this.today, this.form.end_date)) {
            this.form.end_date = add_day(this.today, 1);
          }
        }

        if (!this.is_daily_reservation) {
          this.getReservationDetailAPI(this.form.date);
        }
        this.checkFacilityReservationAPI(this.form.date, this.form.end_date);
      });
    },
    checkFacilityReservationAPI(date, end_date = "") {
      let query = `date=${date}&&is_option=Y`;
      if (this.is_daily_reservation) {
        if (isFuture(this.form.end_date, this.form.date)) {
          this.form.end_date = add_day(this.form.date, 1);
          end_date = this.form.end_date;
        }

        query = `date=${date}&&end_date=${end_date}&&is_option=Y`;
      }
      checkFacilityReservation(this.reservation.id, query).then((res) => {
        this.facilityOptions = res.data;

        let index = -1;
        let index_disabled = -1;
        this.selectedOptions.forEach((item, idx, object) => {
          index = this.facilityOptions.findIndex((val) => item.id == val.id);

          if (index < 0) {
            object.splice(idx, 1);
          } else {
            if (this.facilityOptions[index].is_disabled) {
              object.splice(idx, 1);
            }
          }
        });

        this.getpriceSum();
      });
    },
    getReservationDetailAPI() {
      if (!this.form.master) return;
      if (!this.form.date) return "";

      let date;

      date = this.form.date;
      this.pickerDate = this.form.date.slice(0, 7);

      let query = `date=${this.form.date}`;

      if (this.is_daily_reservation) {
        let query = `date=${this.form.date}&&end_date=${this.form.end_date}`;
      }

      checkFacilityReservation(this.reservation.id, query).then((re) => {
        this.allowed_dates = re.data.allowed_dates;
        this.allowed_times = re.data.allowed_times;

        // 가능한 첫 시간을 지정한다.
        for (let idx in this.allowed_times) {
          if (this.selectedTime) {
            if (this.selectedTime == this.allowed_times[idx].id) {
              // 수정 시, 선택 된 시간으로 스크롤.
              this.selectedTime = this.allowed_times[idx];
              break;
            }
          } else if (!this.allowed_times[idx].disabled) {
            this.selectedTime = this.allowed_times[idx];
            break;
          }
        }
        this.getpriceSum();
      });
    },
    postFacilityReservationSeedAPI() {
      if (!this.check && this.reservation.description) {
        this.$store.commit(
          "ERROR",
          "사용수칙 및 주의사항을 읽고 동의해주세요."
        );
        return;
      } else if (this.is_daily_reservation && !this.form.number_of_users) {
        this.$store.commit("ERROR", "사용인원을 입력해 주세요.");
        return;
      }

      this.form.user = this.user.id;
      this.form.reservation_options = [];

      this.selectedOptions.forEach((i) => {
        this.form.reservation_options.push(i.id);
      });

      this.form.reservation_time = this.selectedTime.id;

      postFacilityReservationSeed(this.form)
        .then((res) => {
          this.$store.commit("SUCCESS", "예약이 완료되었습니다.");
          this.$router.push({ name: "facilityHistory" });
        })
        .catch((err) => {
          this.$store.commit("ERROR", err.response.data);
          if (!this.is_daily_reservation) {
            this.getReservationDetailAPI(this.form.date);
          }
          this.checkFacilityReservationAPI(this.form.date, this.form.end_date);
        });
    },
    // Functions
    select(option, is_main = false) {
      if (option.is_disabled) return;

      let is_already_in_index = -1;

      this.selectedOptions.forEach((item, index, object) => {
        if (is_main && !item.is_sub_option) {
          object.splice(index, 1);
        } else if (option.id == item.id) {
          is_already_in_index = index;
        }
      });

      if (is_main) {
        this.selectedOptions.push(option);
      } else if (is_already_in_index > -1) {
        this.selectedOptions.splice(is_already_in_index, 1);
      } else {
        this.selectedOptions.push(option);
      }

      this.getpriceSum();
    },
    // Utils
    noExist(text) {
      this.$router.push({ name: "dwelling" });
      this.$store.commit("ERROR", text);
    },
    checkIsIn(option) {
      let is_already_in = false;

      this.selectedOptions.forEach((item, index, object) => {
        if (option.id == item.id) {
          is_already_in = true;
        }
      });
      return is_already_in;
    },
    getpriceSum() {
      let sum = 0;
      this.selectedOptions.forEach((i) => {
        sum += i.price;
      });

      if (this.is_daily_reservation) {
        sum = sum * daysOfPeriod(this.form.date, this.form.end_date);
      }

      this.priceSum = sum;
    },
    allowedDates(val) {
      return this.allowed_dates.indexOf(val) !== -1;
    },
    sync() {
      this.checkFacilityReservationAPI(this.form.date, this.form.end_date);
      this.getReservationDetailAPI(null);
    },
    next_prev_month(newval) {
      this.form.date = `${newval}-01`;
      this.getReservationDetailAPI();
    },
  },
  created() {
    if (this.$route.params.id || !this.user || !this.tenantHome) {
      this.end_of_next_month = set_date(add_month(get_now_string(), 2), 1);
      this.end_of_next_month = add_day(this.end_of_next_month, -1);
      this.getFacilityAPI(this.$route.params.id);
    } else {
      this.noExist("존재하지 않는 시설 입니다.");
    }
  },
};
</script>
