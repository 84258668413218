<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">사전점검 예약</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <ReservationView reservation_code="PRE_CHECK_RESERVATION" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Weblogo from "@/assets/images/common/new-logo.svg";
import ReservationView from "@/components/Reservation/ReservationView";

import { getReservationList, delReservation } from "@/api/index.js";
import Back from "@/assets/images/icon/icon-back.svg";

export default {
  name: "PreCheckReservation",
  components: {
    ReservationView,
    Back,
    Weblogo,
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {},
  created() {
    if (!this.user) {
      this.$router.push({ name: "login" });
    }
  },
};
</script>
