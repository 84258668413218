<template>
  <v-container class="pd16">
    <strong class="t-cont-title">계약정보</strong>
    <table class="t-table table1">
      <colgroup>
        <col width="65px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>계약자명</th>
        <td>{{ contractInfo.contractorName }}</td>
      </tr>
      <tr>
        <th>계약일자</th>
        <td>
          {{ contractInfo.contractDate }}
        </td>
      </tr>
      <tr>
        <th>재산의 표시</th>
        <td>
          {{ contractInfo.assetAddress }}
        </td>
      </tr>
      <tr>
        <th>주택의 유형</th>
        <td>
          {{
            contractInfo.assetCategory == ""
              ? "아파트"
              : contractInfo.assetCategory
          }}
        </td>
      </tr>
      <tr>
        <th>면적타입(㎡)</th>
        <td>{{ contractInfo.areaType }}</td>
      </tr>
      <tr>
        <th>입주 예정일</th>
        <td>
          {{ getMoveinText() }}
        </td>
      </tr>
      <tr>
        <th colspan="2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.005"
            height="15.005"
            viewBox="0 0 15.005 15.005"
          >
            <g
              id="Icon_ionic-md-information-circle-outline"
              data-name="Icon ionic-md-information-circle-outline"
              transform="translate(-3.375 -3.375)"
            >
              <path
                id="패스_501"
                data-name="패스 501"
                d="M10.877,4.89A5.985,5.985,0,1,1,6.643,6.643,5.963,5.963,0,0,1,10.877,4.89m0-1.515a7.5,7.5,0,1,0,7.5,7.5,7.5,7.5,0,0,0-7.5-7.5Z"
                transform="translate(0 0)"
                fill="#F9A36C"
              />
              <path
                id="패스_502"
                data-name="패스 502"
                d="M18.038,18.19H16.523V13.681h1.515Zm0-5.988H16.523V10.687h1.515Z"
                transform="translate(-6.403 -3.561)"
                fill="#F9A36C"
              />
            </g>
          </svg>
          공정에 따라 변경될 수 있으며, 이 경우 추후 개별 통보합니다.
        </th>
      </tr>
    </table>

    <strong class="t-cont-title">
      계약면적
      <span>[면적단위:㎡]</span>
      <p>{{ contractInfo.totalArea }}</p>
    </strong>
    <table class="t-table table2">
      <colgroup>
        <col width="112px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>공급면적<br />{{ contractArea }}</th>
        <td>
          <div
            class="line-box"
            v-for="supplyArea in contractInfo.supplyArea"
            :key="supplyArea.name"
          >
            <div class="left">{{ supplyArea.name }}</div>
            <div class="right">{{ supplyArea.area }}</div>
          </div>
        </td>
      </tr>
      <tr>
        <th>기타공용면적<br /></th>
        <td>
          <div
            class="line-box"
            v-for="sharedArea in contractInfo.sharedArea"
            :key="sharedArea.name"
          >
            <div class="left" v-if="sharedArea.area != 0">
              {{ sharedArea.name }}
            </div>
            <div class="right" v-if="sharedArea.area != 0">
              {{ sharedArea.area }}
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>대지면적<br /></th>
        <td>
          <div class="line-box">
            <div class="right">
              {{ contractInfo.landArea }}
            </div>
          </div>
        </td>
      </tr>
      <!-- <tr>
        <th>면적 합계</th>
        <td>
          <div class="line-box">
            <div class="right">
              {{ contractInfo.totalArea }}
            </div>
          </div>
        </td>
      </tr> -->
    </table>

    <strong class="t-cont-title">
      공급대금 및 납부일정
      <span>[금액단위:원]</span>
    </strong>
    <table class="t-table table3 mb15">
      <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="34%" />
      </colgroup>
      <tr class="total">
        <th colspan="3">계약금</th>
      </tr>
      <tr
        v-for="payment in contractInfo.sellInfo.depositDownPayment"
        v-bind:key="'D' + payment.count"
      >
        <th>
          {{
            payment.count == 0
              ? ""
              : (payment.count > 10 ? payment.count - 10 : payment.count) +
                "회차"
          }}
        </th>
        <td>{{ payment.paymentDate }}</td>
        <td>{{ payment.balance | comma }}</td>
      </tr>
      <tr>
        <th class="ftw5">소계</th>
        <td></td>
        <td>
          {{
            getTotalBalance(contractInfo.sellInfo.depositDownPayment) | comma
          }}
        </td>
      </tr>
      <tr class="total">
        <th colspan="3">중도금</th>
      </tr>
      <tr
        v-for="payment in contractInfo.sellInfo.depositSecondPayment"
        v-bind:key="'S' + payment.count"
      >
        <th>{{ payment.count == 0 ? "" : payment.count + "회차" }}</th>
        <td>{{ payment.paymentDate }}</td>
        <td>{{ payment.balance | comma }}</td>
      </tr>
      <tr>
        <th class="ftw5">소계</th>
        <td></td>
        <td>
          {{
            getTotalBalance(contractInfo.sellInfo.depositSecondPayment) | comma
          }}
        </td>
      </tr>
      <!-- <tr class="total">
        <th>잔금</th>
        <td>입주지정기간</td>
        <td v-if="contractInfo.sellInfo.depositBalance">
          {{ contractInfo.sellInfo.depositBalance.balance | comma }}
        </td>
      </tr> -->
      <tr class="total">
        <th colspan="3">잔금</th>
      </tr>
      <tr
        v-for="(payment, idx) in contractInfo.sellInfo.depositBalance"
        v-bind:key="'B' + idx"
      >
        <th>{{ payment.count == 0 ? "" : payment.count + "회차" }}</th>
        <td style="white-space: nowrap;">
          <span
            v-if="
              payment.paymentStartDate &&
                payment.paymentDate != payment.paymentStartDate
            "
            >{{ payment.paymentStartDate }} ~
          </span>
          {{ payment.paymentDate }}
        </td>
        <td>{{ payment.balance | comma }}</td>
      </tr>
      <tr>
        <th class="ftw5">소계</th>
        <td></td>
        <td>
          {{ getTotalBalance(contractInfo.sellInfo.depositBalance) | comma }}
        </td>
      </tr>
    </table>

    <table class="t-table table4">
      <colgroup>
        <col width="33%" />
        <col width="*" />
      </colgroup>
      <tr class="total">
        <th>합계</th>
        <td>
          {{
            (contractInfo.sellInfo.landPrice +
              contractInfo.sellInfo.constructPrice +
              contractInfo.sellInfo.vat)
              | comma
          }}
        </td>
      </tr>
      <tr>
        <th>대지비</th>
        <td>{{ contractInfo.sellInfo.landPrice | comma }}</td>
      </tr>
      <tr>
        <th>건축비</th>
        <td>{{ contractInfo.sellInfo.constructPrice | comma }}</td>
      </tr>
      <tr>
        <th>부가세</th>
        <td>{{ contractInfo.sellInfo.vat | comma }}</td>
      </tr>
    </table>

    <strong class="t-cont-title">납부처 및 납부방법</strong>
    <table class="t-table table5">
      <colgroup>
        <col width="80px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>납부기관</th>
        <td>{{ contractInfo.bankName }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ contractInfo.bankAccount }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ contractInfo.accountHolder }}</td>
      </tr>
      <tr>
        <th>납부방법</th>
        <td>무통장 입금</td>
      </tr>
    </table>

    <div class="order-list">
      <!-- <p>
        ※상기 계좌는 고객별 가상계좌번호로서 입금시 분양대금 납부계좌로 이체되어
        관리됩니다.
      </p> -->
      <p>
        ※견본주택 및 분양사무실에서는 일체의 현금을 취급하지 않으며, 각 회차별
        약정금액 중 상기 납부계좌에 입금되지 않은 금액에 대해서는 어떠한
        경우라도 당사에서 일체 책임지지 않습니다.
      </p>
      <p>
        ※분양대금 납부시에는 상기 납부계좌에 계약자 본인명의 및 동·호수로
        무통장입금하여 주시기바랍니다.(계약자 본인 이외의 명의로 분양대금
        입금시, 이를 증명할 수 있는 일체의 서류를 계약자 본인이 준비하여
        제출하여야 함)
      </p>
    </div>
  </v-container>
</template>

<script>
import { getContract, getContractor } from "@/api/contract.js";
export default {
  components: {},
  name: "StatusForSell",
  data() {
    return {
      contractArea: 0, //계약면적
      contractInfo: {
        contractNo: "",
        contractType: "",
        contractorName: "",
        contractorIdentityNumber: "",
        contractDate: "",
        contractorAddress: "",
        contractorMobile: "",
        moveInDate: "",
        assetAddress: "",
        assetCategory: "",
        assetType: "",
        assetAreaType: "",
        contractArea: 0,
        dedicatedArea: 0,
        residentialSharedArea: 0,
        ohterSharedArea: 0,
        contractOption: "",
        bankName: "",
        bankAccount: "",
        accountHolder: "",
        corpRegNumber: "",
        customerSupportTelephone: "",
        sellInfo: {},
        leaseInfo: null,
      },
      contractor: {},
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    loadContractInfo() {
      // 분양 계약정보
      let query = `contract_id=${this.home.id}`;

      getContract(this.home.id).then((result) => {
        this.contractInfo = result.data;
        console.log(
          "contractInfo",
          this.contractInfo.sellInfo.depositBalance[0]
        );
        // 평형타입
        // 전용면적의 소숫점 앞자리, 평형타입의 알파벳
        var areaType = "";
        this.contractInfo.supplyArea.forEach((element) => {
          if (element.name == "전용면적") {
            areaType = element.area.toString().split(".")[0];
          }
        });
        areaType += this.contractInfo.assetDesignType;
        this.contractInfo.areaType = areaType;

        //계약면적
        this.contractInfo.supplyArea.filter((item) => {
          if (item.name == "전용면적" || item.name == "주거공용") {
            this.contractArea += Number(item.area);
          }
        });
        this.contractArea = this.contractArea.toFixed(4);

        // 주택의 유형
        if (this.contractInfo.assetCategory == "A") {
          this.contractInfo.assetCategory = "아파트";
        } else if (this.contractInfo.assetCategory == "S") {
          this.contractInfo.assetCategory = "상가";
        } else if (this.contractInfo.assetCategory == "F") {
          this.contractInfo.assetCategory = "공장";
        } else if (this.contractInfo.assetCategory == "W") {
          this.contractInfo.assetCategory = "업무시설";
        }
      });

      // 계약자 정보
      getContractor(this.home.id).then((result) => {
        this.contractor = result.data;
      });

      // TODO 공급대금 및 납부 일정
    },
    getTotalBalance(paymentList) {
      // TODO Object 넘어오는 현상 해결
      if (!paymentList) return 0;
      else if (!Array.isArray(paymentList)) return 0;

      return paymentList.reduce((acc, cur, i) => {
        return acc + cur.balance;
      }, 0);
    },
    // Utils
    getMoveinText() {
      let start = this.contractInfo.sellInfo.moveInDateStart;
      let end = this.contractInfo.sellInfo.moveInDateEnd;
      let date = this.contractInfo.moveInDate;

      if (start != end) {
        // 입주 예정일의 경우 수시로 바뀌게됨, 계약자에게 대략적인 정보만 공유
        // return start + " ~ " + end;
        return start.substr(0, 4) + "년 " + start.substr(5, 2) + "월 예정";
      } else {
        return date.substr(0, 4) + "년 " + date.substr(5, 2) + "월 예정";
      }
    },
  },
  created() {
    this.loadContractInfo();
  },
};
</script>
