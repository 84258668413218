<template>
    <v-container class="pd16">
        <strong class="t-cont-title">계약정보</strong>
        <table class="t-table table1">
            <colgroup>
                <col width="65px">
                <col width="*">
            </colgroup>
            <tr>
                <th>계약자명</th>
                <td>김희수</td>
            </tr>
            <tr>
                <th>계약일자</th>
                <td>2021년 1월 1일</td>
            </tr>
            <tr>
                <th>전화번호</th>
                <td>010-1234-1234</td>
            </tr>
            <tr>
                <th>주소</th>
                <td>서울시 강남구 역삼동 1-1 AA아파트 120동 902호</td>
            </tr>
            <tr>
                <th>재산의 표시</th>
                <td>서울시 강남구 도곡동 도곡신도시 A-1BL 우미린 1차 201동 3201호</td>
            </tr>
            <tr>
                <th>주택의 유형</th>
                <td>민영주택</td>
            </tr>
            <tr>
                <th>평형타입</th>
                <td>90.41 A ㎡</td>
            </tr>
            <tr>
                <th>입주 예정일</th>
                <td>2022년 12월경</td>
            </tr>
            <tr>
                <th colspan="2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.005" height="15.005" viewBox="0 0 15.005 15.005">
                        <g id="Icon_ionic-md-information-circle-outline" data-name="Icon ionic-md-information-circle-outline" transform="translate(-3.375 -3.375)">
                            <path id="패스_501" data-name="패스 501" d="M10.877,4.89A5.985,5.985,0,1,1,6.643,6.643,5.963,5.963,0,0,1,10.877,4.89m0-1.515a7.5,7.5,0,1,0,7.5,7.5,7.5,7.5,0,0,0-7.5-7.5Z" transform="translate(0 0)" fill="#F9A36C"/>
                            <path id="패스_502" data-name="패스 502" d="M18.038,18.19H16.523V13.681h1.515Zm0-5.988H16.523V10.687h1.515Z" transform="translate(-6.403 -3.561)" fill="#F9A36C"/>
                        </g>
                    </svg>
                    공정에 따라 변경될 수 있으며, 이 경우 추후 개별 통보합니다.
                </th>
            </tr>
        </table>
        <strong class="t-cont-title">
            계약면적
            <span>[면적단위:㎡]</span>
            <p>총 172.0660</p>
        </strong>
        <table class="t-table table2">
            <colgroup>
                <col width="112px">
                <col width="*">
            </colgroup>
            <tr>
                <th>공급면적<br/>113.8758</th>
                <td>
                    <div class="line-box">
                        <div class="left">전용</div>
                        <div class="right">90.4172</div>
                    </div>
                    <div class="line-box">
                        <div class="left">주거공용</div>
                        <div class="right">23.4586</div>
                    </div>
                </td>
            </tr>
            <tr>
                <th>기타공용면적<br/>58.1902</th>
                <td>
                    <div class="line-box">
                        <div class="left">기타공용</div>
                        <div class="right">7.9659</div>
                    </div>
                    <div class="line-box">
                        <div class="left">지하주차장</div>
                        <div class="right">50.2243</div>
                    </div>
                </td>
            </tr>
            <tr>
                <th>세대당 대지지분<br/>63.1276</th>
                <td>
                    <div class="line-box">
                        <div class="right">63.1276</div>
                    </div>
                </td>
            </tr>
        </table>
        <strong class="t-cont-title">
            공급대금 및 납부일정
            <span>[금액단위:원]</span>
        </strong>
        <table class="t-table table3 mb15">
            <colgroup>
                <col width="33%">
                <col width="33%">
                <col width="34%">
            </colgroup>
            <tr class="total">
                <th colspan="3">계약금</th>
            </tr>
            <tr>
                <th>1회차</th>
                <td>계약체결시</td>
                <td>64,800,000</td>
            </tr>
            <tr>
                <th>2회차</th>
                <td>2021.07.30</td>
                <td>64,800,000</td>
            </tr>
            <tr class="total">
                <th colspan="3">중도금</th>
            </tr>
            <tr>
                <th>1회차</th>
                <td>2021.10.15</td>
                <td>64,800,000</td>
            </tr>
            <tr>
                <th>2회차</th>
                <td>2021.10.15</td>
                <td>64,800,000</td>
            </tr>
            <tr class="total">
                <th>잔금</th>
                <td>입주지정기간</td>
                <td>129,600,000</td>
            </tr>
        </table>
        <table class="t-table table4">
            <colgroup>
                <col width="33%">
                <col width="*">
            </colgroup>
            <tr class="total">
                <th>합계</th>
                <td>648,000,000</td>
            </tr>
            <tr>
                <th>대지비</th>
                <td>406,360,800</td>
            </tr>
            <tr>
                <th>건축비</th>
                <td>219,672,000</td>
            </tr>
            <tr>
                <th>부가세</th>
                <td>21,967,200</td>
            </tr>
        </table>
        <strong class="t-cont-title">납부처 및 납부방법</strong>
        <table class="t-table table5">
            <colgroup>
                <col width="80px">
                <col width="*">
            </colgroup>
            <tr>
                <th>납부기관</th>
                <td>KB국민은행</td>
            </tr>
            <tr>
                <th>납부계좌</th>
                <td>856790-71-001881</td>
            </tr>
            <tr>
                <th>예금주</th>
                <td>(주)우미토건</td>
            </tr>
            <tr>
                <th>납부방법</th>
                <td>무통장 입금</td>
            </tr>
        </table>
        <div class="set-text">
            <p>상기계좌는 고객별 가상계좌번호로서 입금시 분양대금 납부계좌 [KB국민은행 393337-04-007170]로 이체되어 관리됩니다.</p>
            <p>견본주택 및 분양사무실에서는 일체의 현금을 취급하지 않으며, 각 회차별 약정금액 중 상기 납부계좌에 입금되지 않은 금액에 대해서는 어떠한 경우라도 당사에서 일체 책임지지 않습니다.</p>
            <p>분양대금 납부시에는 상기 납부계좌에 계약자 본인명의 및 동·호수로 무통장 입금하여 주시기바랍니다.</p>
            <p>(계약자 본인 이외의 명의로 분양대금 입금시, 이를 증명할 수 있는 일체의 서류를 계약자 본인이 준비하여 제출하여야 함)</p>
        </div>
    </v-container>
</template>

<script>
export default {
    components: { 
    },
    name: "SetContracting",
    data(){
        return {
        }
    }
};
</script>
