<template>
    <v-container>
        <strong class="t-cont-title">대납이자 납부·상환 내역</strong>
        <table class="t-table table5 mb30">
            <colgroup>
                <col width="100px">
                <col width="*">
            </colgroup>
            <tr class="bold">
                <th>총 대납이자 금액</th>
                <td>12,024,202</td>
            </tr>
            <tr class="bold">
                <th>최근 대납일</th>
                <td>2020.12.27</td>
            </tr>
        </table>
        <table class="t-table table5 mb0">
            <colgroup>
                <col width="100px">
                <col width="*">
            </colgroup>
            <tr class="bold">
                <th>상환일자</th>
                <td>상환금액</td>
            </tr>
            <tr>
                <th>2020.01.31</th>
                <td>9,031,665</td>
            </tr>
            <tr>
                <th>2020.01.31</th>
                <td>9,031,665</td>
            </tr>
        </table>
        <table class="loan-table">
            <colgroup>
                <col width="100px">
                <col width="*">
            </colgroup>
            <tr class="total">
                <td>합계</td>
                <td>18,063,330</td>
            </tr>
        </table>
    </v-container>
</template>

<script>
export default {
    components: { 
    },
    name: "SetPayment",
    data(){
        return {
        }
    }
};
</script>
