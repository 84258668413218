import DefectList from "@/views/DefectViews/DefectList.vue";
import DefectAdd from "@/views/DefectViews/DefectAdd.vue";
import DefectDetail from "@/views/DefectViews/DefectDetail.vue";

var defectRoutes = [
  {
    path: "/defects/pre/list",
    name: "DefectPreList",
    component: DefectList,
    meta: {
      bottomNav: true,
      activeMenu: "contract",
    },
  },
  {
    path: "/defects/pre/add",
    name: "DefectPreAdd",
    component: DefectAdd,
    meta: {
      bottomNav: true,
      activeMenu: "contract",
    },
  },
  {
    path: "/defects/pre/:id",
    name: "DefectPreDetail",
    component: DefectDetail,
    meta: {
      bottomNav: true,
      activeMenu: "contract",
    },
  },
  {
    path: "/defects/list",
    name: "DefectList",
    component: DefectList,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },
  {
    path: "/defects/add",
    name: "DefectAdd",
    component: DefectAdd,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },
  {
    path: "/defects/:id",
    name: "DefectDetail",
    component: DefectDetail,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },
];

export { defectRoutes };
