<template>
  <v-container class="vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">글쓰기</strong>
      <a class="add-set" @click="postFreeboard()">등록</a>
    </div>
    <v-card max-width="750" class="mx-auto nops">
      <v-card-text class="pl0 pr0">
        <div class="lg-title add">
          <div class="lg-input">
            <input
              type="text"
              placeholder="제목"
              v-model="form.title"
              maxlength="1000"
            />
            <span class="effact"></span>
          </div>
          <!-- 사진첨부 X -->
          <!-- <div class="lg-picture">
            <a @click="addImg()"
              ><Picture /><span>사진첨부 {{ imageFiles.length }}/10</span></a
            >
            <input
              type="file"
              style="display: none"
              ref="images"
              accept="image/*"
              @change="onFilePicked"
              multiple
            />
          </div> -->
        </div>
        <div class="lg-addbox">
          <ul class="lg-picturebox">
            <li v-for="(file, i) in imageFiles" :key="i">
              <v-img :src="file.preview" contain max-width="100%"></v-img>
              <a @click="removeFile(i)" class="del"><PicDel /></a>
            </li>
          </ul>
          <div class="lgtext-arfea">
            <v-textarea
              auto-grow
              placeholder="아파트 단지 이웃들에게 하고싶은 이야기를 해보세요."
              v-model="form.contents"
            ></v-textarea>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>
.lgtext-arfea .v-input__slot:after {
  display: none !important;
}
.lgtext-arfea .v-input__slot:before {
  display: none !important;
}
</style>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import Picture from "@/assets/images/lounge/picture.svg";
import PicDel from "@/assets/images/lounge/pic-delete.svg";

import { postFreeboard, getFreeboard, putFreeboard } from "@/api/index";

export default {
  name: "LoungeView",
  components: {
    Back,
    Weblogo,
    //Picture,
    PicDel,
  },
  data() {
    return {
      imageFiles: [],
      form: {
        title: "",
        contents: "",
      },
    };
  },
  computed: {},
  methods: {
    postFreeboard() {
      if (this.form.id) {
        putFreeboard(this.form.id, this.form).then((res) => {
          //console.log(res);
          this.$store.commit("SUCCESS", "수정되었습니다.");
          this.$router.push({ name: "loungeList" });
        });
      } else {
        postFreeboard(this.form).then((res) => {
          //console.log(res);
          this.$store.commit("SUCCESS", "등록되었습니다.");
          this.$router.push({ name: "loungeList" });
        });
      }
    },
    addImg() {
      if (this.imageFiles.length > 9) {
        this.$store.commit("ERROR", "사진 첨부는 10개까지 가능합니다.");
        return;
      } else {
        this.$refs.images.click();
      }
    },
    onFilePicked(e) {
      if (this.imageFiles.length + this.$refs.images.files.length > 10) {
        this.$store.commit("ERROR", "사진 첨부는 10개까지 가능합니다.");
        return;
      }

      for (let i = 0; i < this.$refs.images.files.length; i++) {
        this.imageFiles = [
          ...this.imageFiles,
          {
            file: this.$refs.images.files[i],
            preview: URL.createObjectURL(this.$refs.images.files[i]),
          },
        ];
      }
    },
    removeFile(i) {
      this.imageFiles.splice(i, 1);
    },
    getContents(id) {
      getFreeboard(id).then((res) => {
        console.log(res.data);
        this.form = res.data;
      });
    },
  },
  created() {
    // 수정
    if (this.$route.params.id) {
      this.getContents(this.$route.params.id);
    }
  },
};
</script>
