<template>
  <v-container class="vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="back()" class="back">
        <Back />
      </a>
      <strong class="lo-title">도움말</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops">
      <v-autocomplete
        class="sel-cus mt-5"
        :items="villageList"
        item-text="village_name"
        item-value="id"
        v-model="village_id"
        label="단지"
        no-data-text="해당하는 단지가 존재하지 않습니다."
        @change="getBulletinListAPI()"
      ></v-autocomplete>
      <v-card-text class="pd0">
        <div class="sel-inbtn type3 max mt10">
          <v-text-field
            class="sel-cus ft-l"
            style="width: 90%"
            label="검색"
            hide-details
            single-line
            name="name"
            id="id"
            v-model="search"
            v-on:keyup.enter="getBulletinListAPI()"
          ></v-text-field>
          <a @click="getBulletinListAPI()" class="ft-r">
            <v-icon>mdi-magnify</v-icon>
          </a>
        </div>
        <ul class="select-btnlist" v-show="type_show">
          <li v-for="item in subItems" :key="`subtype-${item.id}`">
            <a
              href="#n"
              :class="filter == item.code ? 'active' : ''"
              @click="setFilter(item.code)"
              >{{ item.code_name }}</a
            >
          </li>
        </ul>
        <ul class="board-list">
          <li v-for="item in items" :key="'board-' + item.id">
            <!--<a @click="getBulletinAPI(item.id)"> Q. {{ item.title }} </a>-->
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item>
                <div class="faq-box" v-html="item.parsed_contents"></div>
              </v-list-item>
            </v-list-group>
          </li>
          <li v-if="!items.length">
            <a> 등록된 도움말이 없습니다. </a>
          </li>
        </ul>
        <!-- <div class="cbtn-box">
          <router-link to="/qna">직접 문의하기</router-link>
        </div> -->
      </v-card-text>
      <div class="text-center">
        <v-pagination v-model="page" :length="lastPage"></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<style>
.board-list .v-list-item__title {
  align-self: flex-start;
  text-align: left;
  overflow: visible;
  text-overflow: inherit;
  white-space: normal;
  line-height: 20px;
  padding-left: 20px;
  position: relative;
  word-break: keep-all;
  font-size: 14px;
}
.board-list .v-list-item__title:after {
  content: "Q.";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
  font-size: 14px;
  color: #f36628;
}
.board-list .v-list-item {
  padding: 0 15px !important;
}
.board-list .faq-box {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #555555;
}
</style>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import {
  getBulletinList,
  getBulletinUtils,
  getBulletin,
  getVillageList,
} from "@/api/index";

export default {
  name: "Faq",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      search: null,
      subItems: [],
      items: [],
      item: {},
      village_id: "0",
      villageList: [],
      page: 1,
      lastPage: 1,
      filter: "ALL",
      type_show: true,
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
    tenantHome() {
      return this.$store.getters.getTenantHome;
    },
  },
  watch: {
    page() {
      this.getBulletinListAPI();
    },
  },
  methods: {
    init() {
      // 입주,계약 회원인 경우 유저의 단지를 디폴트로 설정.
      if (this.$route.params.village_id) {
        this.village_id = this.$route.params.village_id;
        this.type_show = false;
      } else if (this.tenantHome) {
        this.village_id = this.tenantHome.village;
        this.type_show = false;
      } else if (this.home) {
        this.village_id = this.home.village;
        this.type_show = false;
      }
    },
    getBulletinUtilsAPI() {
      getBulletinUtils("parent_type=BULLETIN_LINNLIFE_HELP").then((res) => {
        this.subItems = res.data.subItems;
      });
    },
    getBulletinListAPI() {
      //단지가 0이 아닐 때, type_sub 를 "ALL"로 바꿔주고 영역을 숨긴다.
      this.type_show = true;

      // query
      let query = `type=BULLETIN_LINNLIFE_HELP&village_id=${this.village_id}`;
      query += `&page=${this.page}`;
      if (this.search) query += `&search=${this.search}`;
      if (this.filter != "ALL") query += `&type_sub=${this.filter}`;

      getBulletinList(query).then((res) => {
        this.items = res.data.data;
        this.lastPage = res.data.total_page;
      });
    },
    getBulletinAPI(id) {
      getBulletin(id).then((res) => {
        this.item = res.data;
      });
    },
    getVillageListAPI() {
      // 단지 정보 세팅
      getVillageList("is_simple=Y&&is_available=true").then((result) => {
        this.villageList = result.data;
        this.villageList.unshift({ village_name: "우미건설 도움말", id: "0" });
        this.getBulletinListAPI();
      });
    },
    // Utils
    setFilter(code) {
      this.filter = code;
      this.getBulletinListAPI();
    },
    back() {
      if (this.$route.params.village_id) {
        this.$router.push({
          name: "villageInfo",
          params: { id: this.$route.params.village_id },
        });
      } else {
        this.$router.push({ name: "csCenter" });
      }
    },
  },
  created() {
    this.getVillageListAPI();
    this.getBulletinUtilsAPI();

    // this should be after above API calling
    this.init();
  },
};
</script>
