<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="moveToList()" class="back">
        <Back />
      </a>
      <strong class="lo-title">AS 접수 내용</strong>
    </div>

    <v-card flat min-width="300px" class="content">
      <v-toolbar flat color="white" style="border-radius:10px;">
        <v-toolbar-title
          style="width:100%"
          :class="`font-weight-bold ${item.color}--text`"
        >
          <v-btn icon :color="item.color">
            <v-icon>{{ item.icon }}</v-icon>
          </v-btn>
          {{ item.text }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-0 pt-0">
        <!-- <v-card-subtitle class="py-0"> 처리 상황 </v-card-subtitle> -->
        <v-stepper vertical elevation="0">
          <v-stepper-step
            :complete="item.step >= 0"
            step="1"
            :color="item.step == 1 ? 'primary' : 'grey'"
          >
            접수중
            <small class="pt-1" v-if="item.step >= 0">{{
              item.createdAtText
            }}</small>
          </v-stepper-step>
          <v-stepper-content step="1"> </v-stepper-content>
          <v-stepper-step
            :complete="item.step >= 2"
            step="2"
            :color="item.step == 2 ? 'primary' : 'grey'"
          >
            AS 처리중
            <small class="pt-1" v-if="item.step >= 2">{{
              item.dateText
            }}</small>
          </v-stepper-step>
          <v-stepper-content step="2"> </v-stepper-content>
          <v-stepper-step
            :complete="item.step >= 3"
            step="3"
            :color="item.step == 3 ? 'primary' : 'grey'"
          >
            AS 처리 완료
            <small class="pt-1" v-if="item.step >= 3">{{
              item.completeAtText
            }}</small>
          </v-stepper-step>
          <v-stepper-content step="3"> </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-card-subtitle class="py-0 pb-3"> 접수 내용 </v-card-subtitle>
        <v-list subheader two-line>
          <v-list-item v-if="item.siteDefectItem">
            <v-list-item-content>
              <v-list-item-title>부위 및 원인</v-list-item-title>
              <v-chip-group column>
                <v-chip
                  filter
                  small
                  color="primary"
                  readonly
                  class="font-weight-bold cusType"
                >
                  {{ item.siteDefectItem.areaName }}
                  <v-icon small> mdi-chevron-right </v-icon>
                  {{ item.siteDefectItem.partName }}
                  <v-icon small> mdi-chevron-right </v-icon>
                  {{ item.siteDefectItem.partDetailName }}
                  <v-icon small> mdi-chevron-right </v-icon>
                  {{ item.cause.name }}
                </v-chip>
              </v-chip-group>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>설 명</v-list-item-title>
              <div
                class="black--text mt-2 ml-2"
                v-html="item.requirement"
              ></div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>첨부 사진</v-list-item-title>
              <v-list-item-subtitle
                @click="openFiles('AS 사진', item.beforeFiles)"
                v-if="item.beforeFiles"
              >
                <v-btn text :color="item.beforeFiles.length ? 'black' : 'grey'">
                  <v-icon small class="mr-1"> mdi-image-multiple </v-icon>
                  {{ item.beforeFiles.length }}건 열어보기
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <!-- 처리내용 보여주지 않음 (2024.01.17) -->
      <!-- <v-divider v-if="item.step == 4"></v-divider>
      <v-card-text v-if="item.step == 4">
        <v-card-subtitle class="py-0 pb-3"> 처리 내용 </v-card-subtitle>
        <v-list subheader two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>첨부 사진</v-list-item-title>
              <v-list-item-subtitle
                @click="openFiles('AS 처리 사진', item.afterFiles)"
                v-if="item.afterFiles"
              >
                <v-btn text :color="item.afterFiles.length ? 'black' : 'grey'">
                  <v-icon small class="mr-1"> mdi-image-multiple </v-icon>
                  {{ item.afterFiles.length }}건 열어보기
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text> -->
    </v-card>
    <v-row>
      <v-col>
        <v-btn
          block
          large
          class="mt-2 deep-orange--text"
          elevation="0"
          color="white"
          @click="openCancel = true"
          :disabled="item.step != 1"
        >
          접수 취소
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          large
          elevation="0"
          color="white"
          @click="moveToList()"
        >
          목록으로
        </v-btn>
      </v-col>
    </v-row>
    <!-- <input
      id="file-id"
      type="file"
      hidden
      multiple
      accept="image/png, image/gif, image/jpeg"
      @change="choosFile"
    /> -->

    <!--   <v-dialog v-model="openSignature" fullscreen eager>
      <SignatureDialog
        :dialog="openSignature"
        @close="closeSign"
        @save="saveSign"
      />
    </v-dialog> -->

    <v-dialog v-model="openFileDialog" width="800">
      <v-card>
        <v-card-title class="text-h7">
          {{ fileDialogText }}
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-for="file in files" :key="file.id">
            <!-- <span class="text-h8">{{ file.name }}</span> -->
            <v-img
              class="mt-2"
              :src="file.url"
              max-width="800"
              v-if="file.url"
              @click="download(file.url, file.name)"
            ></v-img>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" variant="flat" color="primary" @click="openFileDialog = false">
            닫 기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openCancel" max-width="400">
      <v-card>
        <v-card-title class="justify-center">
          AS 접수를 취소하시겠습니까?
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" dark color="grey" @click="openCancel = false"
            >닫 기</v-btn
          >
          <v-btn elevation="0" color="primary" @click="cancel()">확 인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
// import SignatureDialog from "@/views/DefectViews/SignatureDialog.vue";
import * as defect from "@/api/defect";

export default {
  name: "DefectDetail",
  components: {
    Back,
    Weblogo,
    // SignatureDialog,
  },
  data() {
    return {
      id: 0,
      type: "AFTER",
      work: {
        site: {},
        defectDescription: "거실 대리석 부분에 오염 부분이 있습니다.",
        beforeFiles: [],
        afterFiles: [],
      },

      item: {},

      images: {},
      // Utils
      totalUpdated: 0,
      openSignature: false,
      openFileDialog: false,
      fileDialogText: "",
      files: [],

      openCancel: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    getItem() {
      // TODO: 본인의 하자만 볼 수 있도록 처리 필요
      const id = this.$route.params.id;

      defect.getDefect(id).then((res) => {
        console.log(res.data);
        if (res.data) {
          this.item = this.parse(res.data);
        }
      });
    },
    // putItem(data) {
    //   defect.putDefect(this.$route.params.id, data).then((res) => {
    //     window.location.reload();
    //   });
    // },

    parse(item) {
      item.createdAtText = `${this.timeForm(item.createdAt)}`;
      item.receiptAtText = `${this.timeForm(item.receiptAt)}`;
      item.visitAtText = `${this.timeForm(item.visitAt)}`;
      item.completeAtText = `${this.timeForm(item.completeAt)}`;

      // WORK_DONE (작업완료) 는 OPEN (작업시지)와 같이 '처리중'으로 표시.
      if (item.defectStatus === "WORK_DONE") {
        item.defectStatus = "OPEN";
      }

      item.title = `${item.siteDefectItem.areaName} > ${item.siteDefectItem.partName} > ${item.siteDefectItem.partDetailName}`;

      item.beforeFiles = item.files.filter((file) => file.fileType == "BEFORE");
      item.afterFiles = item.files.filter((file) => file.fileType == "DONE"); //처리 완료 사진의 상태는 DONE

      this.setStepInfo(item);

      return item;
    },
    timeForm(text) {
      if (!text) return "";
      return text.replace("T", " ").slice(0, 16);
    },
    setStepInfo(item) {
      item.step = {
        CANCELLED: 0,
        REQUESTED: 1,
        OPEN: 2,
        //WORK_ORDERED: 3,
        CLOSE: 3,
      }[item.defectStatus];

      // if (item.defectStatus == "OPEN" && item.visitAt && this.type != 'PRE') {
      //   item.step = 3;
      // }

      if (item.step == 0) {
        item.text = "AS미대상";
        item.icon = "mdi-cancel";
        item.color = "red";
      } else if (item.step == 1) {
        item.text = "접수중";
        item.icon = "mdi-timer-sand-empty";
        item.color = "success";
      } else if (item.step == 2 && this.type == 'PRE') {
        // 사전점검의 경우 WORK_DONE, OPEN 모두 처리중 상태로 보이며 방문 일시를 보이지 않는다.
        item.text = "AS 처리중";
        item.icon = "mdi-timer-sand-empty";
        item.color = "primary";
        item.dateText = item.receiptAtText;
      } else if (item.step == 2 && item.visitAt) {
        // 방문 예정일이 있는 사후 점검
        item.text = "업체 방문 예정";
        item.icon = "mdi-account-hard-hat-outline";
        item.color = "primary";
        item.dateText = item.visitAtText;
      } else if (item.step == 2) {
        // 방문 예정일이 정해지지 않은 사후 점검
        item.text = "업체 방문 일정 조율 중";
        item.icon = "mdi-timer-sand-empty";
        item.color = "success";
        item.dateText = item.receiptAtText;
      } else if (item.step == 3) {
        item.text = "AS 처리 완료";
        item.icon = "mdi-check";
        item.color = "grey";
      }
    },
    openFiles(text, files) {
      this.fileDialogText = `${text} (${files.length} 건)`;

      files.forEach((file) => {
        if (this.images[file.id]) {
          file.url = this.images[file.id];
        } else {
          defect.getFile(file.id).then((res) => {
            file.url = res;
            this.images[file.id] = res;
            this.$forceUpdate();
          });
        }
      });
      this.files = files;

      this.openFileDialog = true;
    },
    download(url, filename) {
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    cancel() {
      defect.deleteDefect(this.$route.params.id).then(() => {
        this.$store.commit("INFO", "정상적으로 취소되었습니다.");

        this.moveToList()
      });
    },
    moveToList() {
      if (this.$route.name == "DefectPreDetail") {
          this.$router.push({ name: "DefectPreList", query: { type: this.type, scrollY: this.$route.query.scrollY || 0  } });
        } else {
          this.$router.push({ name: "DefectList", query: { type: this.type, scrollY: this.$route.query.scrollY || 0  } });
        }
    },
    moveToAdd() {
      if (this.$route.name == "DefectPreDetail") {
          this.$router.push({ name: "DefectPreAdd", query: { type: this.type } });
        } else {
          this.$router.push({ name: "DefectAdd", query: { type: this.type } });
        }
    },
  },
  created() {
    this.type = this.$route.query.type || "AFTER";
    this.getItem();
  }
};
</script>

<style scoped>
.content {
  text-align: left;
}

.v-stepper--vertical {
  padding-bottom: 0px;
}

.v-stepper--vertical .v-stepper__step {
  padding: 12px 16px 12px;
}

.v-stepper {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  box-shadow: none;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: -11px -42px -13px 28px;
}

.v-stepper--vertical .v-stepper__content {
  padding: 4px 60px 16px 23px;
  width: auto;
}

.v-list-item__content {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  overflow: hidden;
  padding: 0px 0;
}
</style>
