<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pad nb mt15 mt-p3 web-contract">
      <div class="dw-box" v-if="feeAmount">
        <div class="bg"><Topbg /></div>
        <div class="text-box">
          <div class="tb-in">
            <div class="top-box">
              <strong>{{ thisYear }}년 {{ thisMonth }}월 관리비</strong>
              <a @click="$router.push({ name: 'mgmtFee' })" href="#none"
                ><MoewW
              /></a>
            </div>
            <p>
              {{ feeAmount }}
            </p>
            <span
              >전월대비
              {{ feeDiffToLastMonth }}
            </span>
          </div>
        </div>
      </div>
      <router-link to="/facilityHome">
        <div class="dw-sbox">
          <div class="bg">
            <img src="@/assets/images/dwelling/topbg2.png" />
          </div>
          <div class="text-box">
            <strong>시설 안내·예약</strong>
            <p>단지에 있는 시설들을 확인하고 예약할 수 있습니다</p>
          </div>
        </div>
      </router-link>
      <!-- 2024.11.01 신규 추가화면 -->
      <a @click="movetoLynnPass()" v-if="is_development">
        <div class="dw-sbox type3">
          <div class="bg">
            <img src="@/assets/images/dwelling/lynnpass.png" />
          </div>
          <div class="text-box">
            <strong>출입문 개방</strong>
            <p>휴대폰으로 간편하게 출입문을 개방할 수 있습니다.</p>
          </div>
        </div>
      </a>
      <!-- 2024.11.01 신규 추가화면 -->
      <router-link
        to="/defects/list?type=AFTER"
        v-if="home.is_defect_after_open"
      >
        <div class="dw-sbox type2">
          <div class="bg">
            <img src="@/assets/images/dwelling/topbg3.png" />
          </div>
          <div class="text-box">
            <strong>AS접수</strong>
            <p>AS 접수 및 접수 목록을 확인 할 수 있습니다</p>
          </div>
        </div>
      </router-link>
      <div class="smart-box" v-if="home.is_iot_enabled">
        <div class="text-box">
          <strong>스마트홈</strong>
          <a @click="movetoFlutter()"><MoewB /></a>
        </div>
        <div class="btn-box">
          <button type="button" @click="callElevatorAction()">
            <span class="icon"><Eve /></span>
            <span class="text">엘리베이터</span>
          </button>
          <button type="button" @click="lightOffAction()">
            <span class="icon"><Light /></span>
            <span class="text">전체소등</span>
          </button>
        </div>
      </div>
      <div class="dw-board">
        <div class="dwb-title">
          <strong>단지소식</strong>
          <a
            @click="
              $router.push({
                name: 'noticeVillage',
                query: { type_sub_code: '' },
              })
            "
            href="#none"
            ><MoewB
          /></a>
        </div>
        <ul class="dwb-list">
          <li
            v-for="(item, idx) in notices"
            :key="'Education-card-' + idx"
            :item="item"
          >
            <router-link
              :to="{
                name: 'noticeInVillage',
                params: { id: item.id, type: 'notice' },
              }"
            >
              <a href="#none">{{ item.title }}</a>
            </router-link>
          </li>

          <li v-if="notices.length == 0" class="no-list">
            단지 소식이 없습니다
          </li>
        </ul>
      </div>
      <div class="dw-board mb0">
        <div class="dwb-title">
          <strong>입주민투표</strong>
          <a @click="$router.push({ name: 'voteList' })" href="#none"
            ><MoewB
          /></a>
        </div>
        <ul class="dwb-vote">
          <li v-for="vote in voteList" :key="'vote' + vote.id">
            <a @click="checkAndMove(vote)">
              <div class="icons">
                <span :class="['date', { end: vote.is_done }]">{{
                  dayCalculate(vote.to_datetime)
                }}</span>
                <span
                  :class="['set', { end: vote.vote_yn == 'Y' || vote.is_done }]"
                  >{{
                    vote.vote_yn == "Y"
                      ? "투표완료" + "-" + vote.voter_name
                      : "미투표"
                  }}</span
                >
              </div>
              <p>
                {{ vote.title }}
              </p>
            </a>
          </li>
          <li v-if="voteList.length == 0" class="no-list">
            진행중인 투표가 없습니다.
          </li>
        </ul>
      </div>
      <div class="text-center" v-if="loadingBar">
        <v-progress-circular
          fullscreen
          indeterminate
          color="red"
          style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
        ></v-progress-circular>
      </div>
    </v-container>
    <v-dialog v-model="votepopup" max-width="320">
      <v-card>
        <div class="vote-popup">
          <p class="vp-text">{{ votepopupmsg }}</p>
          <div class="vp-btnbox">
            <a href="#none" @click="votepopup = false">확인</a>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <SurveyDialog v-if="survey.dialog" :survey="survey" @close="survey.dialog = false" />
  </v-container>
</template>

<script>
import Topbg from "@/assets/images/dwelling/topbg.svg";
import Eve from "@/assets/images/dwelling/btn-eve.svg";
import Light from "@/assets/images/dwelling/btn-light.svg";
import MoewW from "@/assets/images/dwelling/more-white.svg";
import MoewB from "@/assets/images/dwelling/more-black.svg";
import { getFee, getBulletinList, getMonth, getVoteList } from "@/api/index";
import { lightOff, callElevator } from "@/api/smartLynn.js";

import SurveyDialog from "@/components/Survey/SurveyDialog";
import { checkSurvey } from "@/api/survey";
export default {
  components: {
    Topbg,
    Eve,
    Light,
    MoewW,
    MoewB,
    SurveyDialog
  },
  name: "DwellingIntro",
  data() {
    return {
      votepopup: false,
      votepopupmsg: "투표는 세대주만 가능합니다",
      voteList: [],
      notices: [],
      onChange: false,
      fee: [],
      feeAmount: "",
      feeDiffToLastMonth: "",
      thisYear: "",
      thisMonth: "",
      maxYear: "",
      maxMonth: "",
      loadingBar: false,
      localeStringOption: {
        style: "currency",
        currency: "KRW",
      },
      survey:{
        dialog:false,
        popup_period: null
      }
    };
  },
  computed: {
    home() {
      return this.$store.getters.getTenantHome;
    },
    user() {
      return this.$store.getters.getUser;
    },
    is_development() {
      return process.env.NODE_ENV !== "production";
    },
  },
  methods: {
    getVoteList() {
      getVoteList().then((res) => {
        this.voteList = res.data;
        console.log(this.voteList);
      });
    },
    lightOffAction() {
      this.loadingBar = true;
      lightOff()
        .then((result) => {
          console.log(result.data);
          this.loadingBar = false;
          this.$store.commit("SUCCESS", "전체소등 완료.");
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("ERROR", "작동되지 않았습니다.");
          this.loadingBar = false;
          return false;
        });
    },
    callElevatorAction() {
      this.loadingBar = true;
      callElevator()
        .then((result) => {
          console.log(result.data);
          this.loadingBar = false;
          this.$store.commit("SUCCESS", "엘리베이터를 호출하였습니다.");
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("ERROR", "작동되지 않았습니다.");
          this.loadingBar = false;
          return false;
        });
    },
    getFeeList() {
      this.loadingBar = true;
      getFee(this.home.id, this.thisYear, this.thisMonth)
        .then((result) => {
          console.log(result.data);
          this.loadingBar = false;
          this.fee = result.data;
          console.log(this.fee);
          try {
            this.feeAmount = this.fee[0].amount.toLocaleString(
              "ko-KR",
              this.localeStringOption
            );
            let diffAmount = this.fee[0].diff_to_last_month;
            let raiseOrDown = "";
            if (diffAmount > 0) raiseOrDown = " 증가";
            else if (diffAmount < 0) {
              raiseOrDown = " 감소";
              diffAmount = diffAmount * -1;
            }
            this.feeDiffToLastMonth =
              diffAmount.toLocaleString("ko-KR", this.localeStringOption) +
              raiseOrDown;
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => {
          this.$store.commit("ERROR", "데이터가 없습니다.");
          this.loadingBar = false;
          this.setDate(1);
          return false;
        });
    },
    setDate(add) {
      let setDate = new Date(this.thisYear, this.thisMonth - 1);
      setDate.setMonth(setDate.getMonth() + add);

      let maxDate = new Date(this.maxYear, this.maxMonth - 1);

      if (setDate > maxDate) {
        this.$store.commit("ERROR", "데이터가 없습니다.");
        return false;
      }
      this.thisYear = setDate.getFullYear();
      this.thisMonth = setDate.getMonth() + 1;
      this.getFeeList();
    },
    getNoticeList() {
      getBulletinList("type=BULLETIN_LINNLIFE_NOTICE&menu=VILLAGE").then(
        (res) => {
          this.notices = res.data.data;
          // TODO
          // this.lastPage = res.data.total_page
        }
      );
    },
    dayCalculate(to_datetime) {
      var now = new Date();
      var datetime = new Date(to_datetime);

      var day = Math.ceil(
        (now.getTime() - datetime.getTime()) / (1000 * 3600 * 24)
      );

      var text = "";
      if (day > 0) {
        text = "투표종료";
      } else if (day == 0) {
        var time = new Date(0, 0, 0, 0, 0, 0, datetime - now);
        text = time.getHours() + "시 " + time.getMinutes() + "분 남음";
      } else {
        text = "D-" + day * -1;
      }

      return text;
    },
    checkAndMove(vote) {
      let leftMinutes = parseInt(
        (new Date(vote.to_datetime).getTime() - new Date().getTime()) /
          (1000 * 60)
      );
      let beforeMinutes = parseInt(
        (new Date(vote.from_datetime).getTime() - new Date().getTime()) /
          (1000 * 60)
      );

      if (leftMinutes < 0) {
        // 기간 만료 시
        this.$router.push({ name: "voteDetail", params: { id: vote.id } });
      } else if (beforeMinutes > 0) {
        // 투표 가능시간 이전
        this.votepopup = true;
        this.votepopupmsg = `투표 시작시간 ${vote.from_datetime} 이후 투표가능합니다.`;
      } else if (vote.vote_yn == "Y") {
        // 이미 투표진행 경우
        this.$router.push({ name: "voteDetail", params: { id: vote.id } });
      } else {
        this.$router.push({ name: "voteDetail", params: { id: vote.id } });
      }
    },
    fallbackFunction() {
      let browserInfo = navigator.userAgent.toLowerCase();
      if (browserInfo.indexOf("android") > 0) {
        window.location.replace("market://details?id=com.woomi.lynnlife");
      } else if (
        browserInfo.indexOf("iphone") > 0 ||
        browserInfo.indexOf("ipad") > 0
      ) {
        window.location.replace("https://apps.apple.com/app/id1559671877");
      } else {
        this.$store.commit(
          "SUCCESS",
          "모바일 스마트린 앱을 설치하여야 이용이 가능합니다."
        );
        return;
      }
    },
    movetoLynnPass() {
      try {
        this.loadingBar = true;

        let browserInfo = navigator.userAgent.toLowerCase();
        if (browserInfo.indexOf("wikey_flutter") > 0) {
          // 웹뷰인 경우
          if (this.user.is_accepted) {
            window.flutter_inappwebview
              .callHandler("gotoLynnpass", this.$store.getters.getIdToken)
              .then(function(result) {
                this.loadingBar = false;
                console.log(
                  "@@@ flutterInAppWebViewPlatformReady gotoLynnpass result: ",
                  result
                );
              });
          } else {
            this.loadingBar = false;
            this.$store.commit("SUCCESS", "입주자를 위한 서비스입니다.");
          }
        } else {
          // 모바일 웹
          window.location.href = "woomi://com.woomi.lynnlife";
          setTimeout(this.fallbackFunction, 250); // 설치된 어플이 없을 경우 market으로 이동
          this.loadingBar = false;
        }
      } catch (e) {
        this.loadingBar = false;
        console.log(e);
      }
    },
    movetoFlutter() {
      try {
        this.loadingBar = true;

        let browserInfo = navigator.userAgent.toLowerCase();
        if (browserInfo.indexOf("wikey_flutter") > 0) {
          // 웹뷰인 경우
          if (this.user.is_accepted) {
            window.flutter_inappwebview
              .callHandler("gotoSmartHome", this.$store.getters.getIdToken)
              .then(function(result) {
                this.loadingBar = false;
                console.log(
                  "@@@ flutterInAppWebViewPlatformReady gotoSmartHome  result: ",
                  result
                );
              });
          } else {
            this.loadingBar = false;
            this.$store.commit("SUCCESS", "입주자를 위한 서비스입니다.");
          }
        } else {
          // 모바일 웹
          window.location.href = "woomi://com.woomi.lynnlife";
          setTimeout(this.fallbackFunction, 250); // 설치된 어플이 없을 경우 market으로 이동
          this.loadingBar = false;
        }
      } catch (e) {
        this.loadingBar = false;
        console.log(e);
      }
    },
     checkSurvey() {

      const query = {
        location: "tenant",
        skip_ids: this.$store.getters.getSurveySkipList,
      }
      checkSurvey(this.home.id,query).then(({data})=>{
        console.log(data)
        this.survey = data
        this.survey.dialog = true
      })
    }
  },
  created() {
    getMonth(this.home.id).then((res) => {
      if (this.$route.params.year) {
        this.thisYear = this.$route.params.year;
        this.thisMonth = this.$route.params.month;
      } else {
        this.thisYear = res.data.year;
        this.thisMonth = res.data.month;
      }
      this.maxYear = res.data.year;
      this.maxMonth = res.data.month;
      this.getFeeList();
    });
    this.getNoticeList();
    this.getVoteList();
    this.checkSurvey();
  },
};
</script>
