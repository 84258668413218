function setSnackbar(setting) {
  return {
    dialog: true,
    icon: setting.icon,
    text: setting.text,
    color: setting.color,
    timeout: 3000,
    bottom: true,

    // extra options: 현재는 미사용.
    left: false,
    right: false,
    centered: false,
    top: false,
  };
}

function setDialog(setting) {
  return {
    show: true,
    title: setting.title,
    content: setting.content,
    button: setting.button,
  };
}

export default {
  INFO_UPDATE(state, data) {
    state.info[data.type] = data.text;
  },
  SUCCESS(state, text) {
    let setting = {
      icon: "mdi-check-circle",
      color: "#65C8D0",
      text: text,
    };
    state.snackbar = setSnackbar(setting);
  },
  INFO(state, text) {
    let setting = {
      icon: "mdi-check-circle",
      color: "#65C8D0",
      text: text,
    };
    state.snackbar = setSnackbar(setting);
  },
  ERROR(state, text) {
    let setting = {
      icon: "mdi-alert-circle",
      color: "#FF6868",
      text: text,
    };
    state.snackbar = setSnackbar(setting);
  },
  SNACKBAR_CLOSE(state) {
    state.snackbar.dialog = false;
  },
  DIALOG_CLOSE(state) {
    state.dialog.show = false;
  },
  DIALOG_OPEN(state, setting) {
    /*
      EX.예시
      this.$store.commit("DIALOG_OPEN", {
        title: "파주 운정 우미 린 더 퍼스트",
        content: "견본주택 방문예약을 삭제 하시겠습니까?",
        button: [             // button 2개 사용 가능~
          {
            text: "방문예약 삭제",
            callback() {
              alert("방문예약 삭제");
            },
          }
        ],
      });
      //callback() 실행 후 dialog close 자동 실행되므로 취소 이벤트의 경우 callback() 빈 함수로 보내면 됨!
    */
    state.dialog = setDialog(setting);
  },
  SET_VILLAGE_LIST(state, data) {
    state.data.villageList = data;
  },
  SET_INTERESTED_LIST(state, data) {
    state.data.interestedList = data;
  },
  SET_VERIFICATON(state, data) {
    state.data.verification = data;
  },
  SET_USERVERIFICATIONINFO(state, data) {
    state.data.userVerificationInfo = data;
  },
  SET_WINVERIFICATON(state, data) {
    state.data.winVerification = data;
  },
};
