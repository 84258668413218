<template>
    <v-container class="pd16">
        <strong class="t-center-title">계약 해지 신청</strong>
        <strong class="t-cont-title">계약 해지 안내사항</strong>
        <ul class="bull-list">
            <li>접수 후 담당자가 해지 방법을 별도 안내해 드리겠습니다</li>
            <li>미입주 계약 해지 시에는 위약금이 발생할 수 있습니다.</li>
        </ul>
        <strong class="t-cont-title">계약 해지 신청 구분</strong>
        <div class="mb20">
            <v-radio-group class="pd0 mg0" column mandatory>
                <v-radio label="미입주 해지"></v-radio>
                <v-radio label="중도 퇴거"></v-radio>
                <v-radio label="만기 퇴거"></v-radio>
            </v-radio-group>
        </div>
        <strong class="t-cont-title">계약 정보</strong>
        <table class="t-table table1">
            <colgroup>
                <col width="65px">
                <col width="*">
            </colgroup>
            <tr>
                <th>계약일자</th>
                <td>2021-01-01</td>
            </tr>
            <tr>
                <th>계약기간</th>
                <td>2021-01-01 ~ 2026-01-09</td>
            </tr>
            <tr>
                <th>동</th>
                <td>8</td>
            </tr>
            <tr>
                <th>층/호</th>
                <td>15층 07호</td>
            </tr>
            <tr>
                <th>보증금</th>
                <td>50,000,000 원</td>
            </tr>
            <tr>
                <th>임대료</th>
                <td>200,000 원</td>
            </tr>
            <tr>
                <th>입주일</th>
                <td>2021-01-10</td>
            </tr>
            <tr>
                <th>비고</th>
                <td>-</td>
            </tr>
        </table>
        <strong class="t-cont-title">체결 기한 및 방법</strong>
        
        <div class="text-set">
            <p>재계약 일자  : 2021.03.21 ~ 2021.04.14</p>
            <p>장소 : 커뮤니티센터 내 통합관리센터</p>
            <p>구비서류 필참 (서류 없을 시 재계약은 불가합니다)</p>
            <a href="#n">구비서류 보기</a>
        </div>
        <strong class="t-cont-title">계약 변경 방문 예약</strong>
        <div>
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    label="계약 변경 희망일"
                    v-model="date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="date"
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-dialog>
        </div>
        <div class="address-set">
            <p>충북혁신도시 우미린스테이</p>
            <p>405동 1902호</p>
            <p>임차인 홍길동</p>
            <p>신청일 : 2021년 3월 11일</p>
        </div>
        <v-btn color="primary" class="fts18 ftw7 mw-700 pwidth100" min-height="50">계약 해지 신청하기</v-btn>
    </v-container>
</template>

<script>
export default {
    components: { 
    },
    name: "SetTermination",
    data(){
        return {
            picker: new Date().toISOString().substr(0, 10),
        }
    }
};
</script>
