import { SmartLynnConnection } from "./config.js";

export function lightOff() {
  const bodyData = {
    commandList: [
      {
        command: "power",
        value: "off",
      },
    ],
  };
  return SmartLynnConnection.put(`/light`, bodyData);
}

export function callElevator() {
  return SmartLynnConnection.post(`/elevator`);
}
