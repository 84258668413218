<template>
    <v-container class="bg-gray vh-footer pd20">
        <v-card max-width="750" class="mx-auto bg-gray nops">
        <v-card-title class="justify-center color-1 fts18">
            대기신청
        </v-card-title>
            <div>
                <v-form>
                    <v-select
                        :items="villageList"
                        item-text="village_name"
                        item-value="id"
                        label="단지 선택"
                    ></v-select>
                    <v-select
                        :items="villageType"
                        item-text="village_type"
                        item-value="id"
                        label="신청주택형"
                    ></v-select>
                    <v-row class="mt8">
                        <v-col class="pd0 mg0 text-left" cols="2">
                            <strong class="ds-b fts16 ftw4">선호층</strong>
                        </v-col>
                            <v-col class="text-left">
                            <v-radio-group row class="pd0 mg0">
                                <v-radio value="1~4층" label="1~4층"></v-radio>
                                <v-radio value="5층 이상" label="5층 이상"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-select
                        :items="Days"
                        item-text="Days_day"
                        item-value="id"
                        label="이사일정"
                    ></v-select>
                    <v-subheader class="pd0 fts16 ftw5 mt15">신청자 정보</v-subheader>
                    <v-text-field
                        type="text"
                        label="이름"
                        required
                        readonly
                    ></v-text-field>
                    <v-text-field
                        type="text"
                        label="주소"
                        :counter="12"
                        required
                    ></v-text-field>
                    <v-text-field
                        type="number"
                        label="나이"
                        :counter="2"
                        required
                    ></v-text-field>
                    <v-row class="mt8">
                        <v-col class="pd0 mg0 text-left" cols="2">
                            <strong class="ds-b fts16 ftw4">성별</strong>
                        </v-col>
                            <v-col class="text-left">
                            <v-radio-group row class="pd0 mg0">
                                <v-radio value="남" label="남"></v-radio>
                                <v-radio value="여" label="여"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-text-field
                        type="number"
                        label="휴대전화"
                        :counter="12"
                        required
                    ></v-text-field>
                    <v-text-field
                        type="email"
                        label="이메일"
                        required
                    ></v-text-field>
                    <v-row class="mt15">
                        <v-expansion-panels accordion>
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    >개인정보 수집 및 이용</v-expansion-panel-header
                                >
                                <v-expansion-panel-content
                                    style="max-height: 240px; overflow: auto"
                                    class="text-left"
                                >
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>
                    <v-row class="mt0">
                        <v-checkbox
                            class="mt5"
                            v-model="privacyCheck"
                            label="개인정보 수집 및 이용 동의"
                        ></v-checkbox>
                    </v-row>
                </v-form>
                
                <v-card-actions class="justify-center pd0 mt20">
                    <v-btn class="pd10 color-2 bgc-4 pwidth48 mr-p4 fts18 ftw7" min-height="50">뒤로가기</v-btn>
                    <v-btn color="primary" class="fts18 ftw7 mw-400 pwidth48 mg0 fts18 ftw7" min-height="50">변경사항 저장</v-btn>
                </v-card-actions>
                <v-btn
                color="primary"
                class="fts18 ftw7 mw-400 pwidth100 mt15"
                min-height="50"
                block
                >
                대기신청
                </v-btn>
            </div>
        </v-card>
    </v-container>
</template>

<style>
.v-input__slot {margin-bottom:5px;}
</style>

<script>
export default {
  name: "WaitForm",
  components: {
  },
  data() {
    return{
      villageList: [],
      villageType: [],
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    init() {
      this.villageList = [
        {
          village_name: "경산 하양 지구",
          id: "1", // master id
        },
        {
          village_name: "파주 운정",
          id: "2", // master id
        },
      ];
      this.villageType = [
        {
          village_type: "70m²",
          id: "1", // master id
        },
        {
          village_type: "78m²A",
          id: "2", // master id
        },
        {
          village_type: "78m²B",
          id: "3", // master id
        },
        {
          village_type: "84m²A",
          id: "4", // master id
        },
        {
          village_type: "84m²B",
          id: "5", // master id
        },
      ]
    },
  },
  created() {
    this.init();
  },
};
</script>
