<template>
  <v-container class="bg-gray vh-full pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">신규 회원등록</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <v-form v-model="valid" ref="signform">
          <v-text-field v-model.trim="form.name" label="이름" required :rules="nameRules"></v-text-field>
          <v-text-field v-model.trim="form.email" label="아이디(이메일)" :rules="emailRules" required></v-text-field>
          <v-text-field v-model.trim="form.password" label="비밀번호" :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword"
            :rules="passwordRules" required></v-text-field>
          <v-text-field v-model.trim="form.mobile" label="휴대폰번호" :rules="phoneRules" required
            maxlength="11"></v-text-field>
        </v-form>
      </v-card-text>
      <v-btn color="primary" class="fts18 ftw7 mw-400 pwidth100" min-height="50" :disabled="!is_form_ready"
        @click="signUp()">회원 등록하기</v-btn>
    </v-card>
  </v-container>
</template>

<script>
import { getVillageList } from "@/api/index";
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

export default {
  components: { Back, Weblogo },
  name: "SignUpPage",
  data() {
    return {
      // data
      form: {
        name: "",
        password: "",
        email: "",
        mobile: "",
      },

      villageList: [],

      // Conditions
      showPassword: false,

      // Rules
      valid: false,
      nameRules: [
        (v) => !!v || "이름을 입력하세요.",
        (v) => v.length < 20 || "이름은 20자까지만 가능합니다.",
      ],
      passwordRules: [
        (v) => !!v || "비밀번호를 입력하세요",
        (v) => v.length > 7 || "8자 이상으로 입력해주세요.",
      ],
      emailRules: [
        (v) => !!v || "아이디를 입력하세요.",
        (v) => /.+@.+/.test(v) || "아이디는 이메일 형식이어야합니다.",
        (v) => !/[\s]/g.test(v) || "공백을 제외하고 입력해주세요.",
      ],
      phoneRules: [
        (v) => !!v || "휴대전화번호 '-'를 제외하고 입력해주세요.",
        (v) =>
          /^[0-9]+$/.test(v) || "휴대전화번호 '-'를 제외하고 입력해주세요.",
        (v) =>
          v.length < 13 || "휴대전화번호 '-'를 제외하고 정확히 입력해주세요.",
        (v) => v.length == 11 || "정확한 전화번호를 입력해주세요.",
      ],
    };
  },
  computed: {
    is_form_ready() {
      return (
        this.form.name &&
        this.form.password &&
        this.form.email &&
        this.form.mobile &&
        this.$refs.signform.validate()
      );
    },
    mobile_with_code() {
      return "+82" + this.form.mobile;
    },
  },
  methods: {
    // APIs
    get_villageList() {
      // 단지 정보 세팅
      getVillageList().then((result) => {
        this.villageList = result.data;
      });
    },
    signUp() {
      if (!this.form.name || !this.form.password || !this.form.email || !this.form.mobile) {
        this.$store.commit("ERROR", "모든 정보를 입력해주세요.");
        return;
      } else if (this.form.password.length < 8) {
        this.$store.commit("ERROR", "비밀번호는 8자 이상으로 입력해주세요.");
        return;

      } else if (this.form.mobile.length != 11) {
        this.$store.commit("ERROR", "휴대전화번호를 정확히 입력해주세요. ex) 01012345678");
        return;
      } else if (this.form.email.indexOf("@") == -1 || this.form.email.indexOf(".") == -1) {
        this.$store.commit("ERROR", "이메일 형식을 확인해주세요.");
        return;
      }
      // Cognito 회원가입 + 인증코드 발송
      this.$store
        .dispatch("signUp", {
          username: this.form.email,
          password: this.form.password,
          attributes: {
            phone_number: this.mobile_with_code,
            name: this.form.name,
          },
        })
        .then((data) => {
          this.$store.commit("SUCCESS", "인증코드가 발송되었습니다.");
          this.$router.push({
            name: "confirm",
            params: { email: this.form.email, userData: this.form },
          });
        })
        .catch((err) => {
          // Logging err
          this.$store.dispatch("LOGGING", {
            error: err,
            tags: `SignUpPage|cognitoAPI|signUp(username=${this.form.email},phone_number=${this.mobile_with_code},name=${this.form.name})`,
            etc: {}, // Put anythings you want to log as object
          });

          if (err.code == "UsernameExistsException") {
            this.$store.commit("ERROR", "이미 가입된 이메일입니다.");
          } else if (err.code == "InvalidParameterException") {
            this.$store.commit(
              "ERROR",
              "이메일, 전화번호 양식 확인 및 정보를 정확히 기입해주세요."
            );
          } else if (err.code == "NetworkError") {
            this.$store.commit(
              "ERROR",
              "네트워크 환경을 확인해주세요.(사내망과 같은 일부 환경에서는 계정 생성이 원할하지 않을 수 있습니다)"
            );
          } else {
            console.log(err);
            this.$store.commit(
              "ERROR",
              "가입할 수 없습니다. 관리자에게 문의하여 주십시오."
            );
          }
          return false;
        });
    },
  },
  created() {
    this.get_villageList();
  },
};
</script>
