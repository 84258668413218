<template>
  <v-container class="vh-footer pd20 lynn-web-in overout">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.push({ name: 'dwelling' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">공지사항</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops" style="border-radius: 0">
      <v-card-text class="pd0">
        <div class="tabs-box full" style="margin-top: 40px">
          <ul class="tab-menu">
            <li v-bind:class="{ active: openTab === 0 }">
              <a href="#n" @click="openTab = 0">공지사항</a>
            </li>
            <li v-bind:class="{ active: openTab === 1 }">
              <a href="#n" @click="openTab = 1">자료실</a>
            </li>
          </ul>
        </div>
        <div class="sel-inbtn type3 max">
          <!-- <v-text-field
            v-model="search"
            class="sel-cus"
            label="검색"
            hide-details
            single-line
            name="name"
            id="id"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field> -->
          <v-text-field
            class="sel-cus ft-l"
            style="width: 90%"
            label="검색"
            hide-details
            single-line
            name="name"
            id="id"
            v-model="search"
            v-on:keyup.enter="getBulletinListAPI()"
          ></v-text-field>
          <a @click="getBulletinListAPI()" class="ft-r">
            <v-icon>mdi-magnify</v-icon>
          </a>
        </div>
        <div class="tab-contents" style="margin-top: 40px">
          <div v-if="openTab == 0">
            <!-- 공지사항 -->
            <ul class="dan-list">
              <li v-for="item in items" :key="'board-' + item.id">
                <router-link
                  :to="{
                    name: 'noticeInVillage',
                    params: { id: item.id, type: 'notice' },
                  }"
                >
                  <strong class="kitbox"> {{ item.title }}</strong>
                  <div class="bottom-cont">
                    <span class="name">{{ item.writer }}</span>
                    <span class="date">{{ item.createdAtDate }}</span>
                  </div>
                </router-link>
              </li>
              <li v-if="!items.length" class="no-list">
                <router-link to="/notice">
                  등록된 공지사항이 없습니다.
                </router-link>
              </li>
            </ul>

            <div class="text-center">
              <v-pagination
                v-model="noticePage"
                :length="noticeLastPage"
              ></v-pagination>
            </div>
          </div>

          <div v-if="openTab == 1">
            <!-- 자료실 -->
            <ul class="dan-list">
              <li v-for="item in downloadItems" :key="'board-' + item.id">
                <router-link
                  :to="{
                    name: 'noticeInVillage',
                    params: { id: item.id, type: 'download' },
                  }"
                >
                  <strong class="kitbox"> {{ item.title }}</strong>
                  <div class="bottom-cont">
                    <span class="name">{{ item.writer }}</span>
                    <span class="date">{{ item.createdAtDate }}</span>
                  </div>
                </router-link>
              </li>
              <li v-if="!downloadItems.length" class="no-list">
                <router-link to="/notice">
                  등록된 자료가 없습니다.
                </router-link>
              </li>
            </ul>

            <div class="text-center">
              <v-pagination
                v-model="downPage"
                :length="downLastPage"
              ></v-pagination>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import { getBulletinList } from "@/api/index";

export default {
  name: "Notice",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      search: null,

      items: [],
      downloadItems: [],

      // Utils
      openTab: 0,

      noticePage: 1,
      noticeLastPage: 1,

      downPage: 1,
      downLastPage: 1,
    };
  },
  computed: {},
  watch:{
    noticePage(){
        this.getBulletinListAPI()
    }
  },
  methods: {
    getBulletinListAPI() {
      let no_query = `type=BULLETIN_LINNLIFE_NOTICE&&menu=VILLAGE`;
      no_query += `&page=${this.noticePage}`;
      if (this.search) no_query += `&search=${this.search}`;
      getBulletinList(no_query).then((res) => {
        this.items = res.data.data;
        this.noticeLastPage = res.data.total_page
      });

      let do_query = `type=BULLETIN_LINNLIFE_DOWNLOAD&&menu=VILLAGE`;
      do_query += `&page=${this.downPage}`;
      if (this.search) do_query += `&search=${this.search}`;
      getBulletinList(do_query).then((res) => {
        this.downloadItems = res.data.data;
        this.downLastPage = res.data.total_page
      });
    },
  },
  created() {
    this.getBulletinListAPI();
  },
};
</script>
