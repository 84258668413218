<template>
  <v-container class="vh-footer pd20 lynn-web-in overout bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.push({ name: 'Expenses' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">관리비 상세</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops bg-gray" style="border-radius:0;">
      <v-card-text class="pd0">
        <div class="expp-tables">
          <strong>4월 관리비 상세내역</strong>
          <table>
            <colgroup>
              <col width="*"/>
              <col width="95px"/>
              <col width="95px"/>
            </colgroup>
            <tr class="titles">
              <th>항목</th>
              <th>금액</th>
              <th>증감</th>
            </tr>
            <tr class="subtitle">
              <td>합계</td>
              <td>25,200</td>
              <td><span class="up">+2000</span></td>
            </tr>
            <tr>
              <td>일반관리비</td>
              <td>32,350</td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>청소비</td>
              <td>24,020</td>
              <td><span class="up">+1000</span></td>
            </tr>
            <tr>
              <td>경비비</td>
              <td>10,830</td>
              <td><span class="down">-1,000</span></td>
            </tr>
            <tr>
              <td>소독비</td>
              <td>890</td>
              <td><span>-</span></td>
            </tr>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

export default {
  name: "LoungeList",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
