<template>
  <v-container class="vh-full bg-gray ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pad nb mt15 mt-p3 web-contract">
			<div class="dw-box">
				<div class="bg"><Topbg/></div>
				<div class="text-box">
					<div class="tb-in">
						<div class="top-box">
							<strong>2021년 4월 관리비</strong>
							<a href="#none"><MoewW/></a>
						</div>
						<p>312,000</p>
						<span>전월비교 +5,000</span>
					</div>
				</div>
			</div>
			<div class="dw-sbox">
				<div class="bg"><img src="@/assets/images/dwelling/topbg2.png"/></div>
				<div class="text-box">
					<strong>시설 안내·예약</strong>
					<p>단지에 있는 시설들을 확인하고 예약할 수 있습니다</p>
				</div>
			</div>
			<div class="smart-box">
				<div class="text-box">
					<strong>스마트홈</strong>
					<a href="#none"><MoewB/></a>
				</div>
				<div class="btn-box">
					<button type="button">
						<span class="icon"><Eve/></span>
						<span class="text">엘리베이터</span>
					</button>
					<button type="button">
						<span class="icon"><Light/></span>
						<span class="text">전체소등</span>
					</button>
				</div>
			</div>
			<div class="dw-board">
				<div class="dwb-title">
					<strong>단지소식</strong>
					<a href="#none"><MoewB/></a>
				</div>
				<ul class="dwb-list">
					<li><a href="#none">입대의 투표</a></li>
					<li><a href="#none">쓰레기 분리수거 안내</a></li>
					<li><a href="#none">반려견 산책 시 주의</a></li>
					<li><a href="#none">주차장 청소 안내</a></li>
					<li><a href="#none">놀이터 정비 안내</a></li>
					<li class="no-list">단지 소식이 없습니다</li>
				</ul>
			</div>
			<div class="dw-board mb0">
				<div class="dwb-title">
					<strong>입주민투표</strong>
					<a href="#none"><MoewB/></a>
				</div>
				<ul class="dwb-vote">
					<li>
						<a href="#none">
							<div class="icons">
								<span class="date">D-2</span>
								<span class="set">미투표</span>
							</div>
							<p>충북혁신도시 우미린스테이 입주민 대표 투표 405동</p>
						</a>
					</li>
					<li>
						<a href="#none">
							<div class="icons">
								<span class="date">D-3</span>
								<span class="set end">투표완료</span>
							</div>
							<p>공용시설 공기정화 시설 증설</p>
						</a>
					</li>
					<li>
						<a href="#none">
							<div class="icons">
								<span class="date end">종료</span>
								<span class="set end">미투표</span>
							</div>
							<p>주말 푸드트럭 운영 수요조사</p>
						</a>
					</li>
					<li class="no-list">진행중인 투표가 없습니다.</li>
				</ul>
			</div>
		</v-container>
  </v-container>
</template>

<script>

import Topbg from "@/assets/images/dwelling/topbg.svg";
import Eve from "@/assets/images/dwelling/btn-eve.svg";
import Light from "@/assets/images/dwelling/btn-light.svg";
import MoewW from "@/assets/images/dwelling/more-white.svg";
import MoewB from "@/assets/images/dwelling/more-black.svg";
export default {
  components: {
		Topbg,
		Eve,
		Light,
		MoewW,
		MoewB,
  },
  name: "DwellingIntro",
  data: () => ({}),
  computed: {
  },
};
</script>
