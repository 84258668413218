export default {
  state: {
    facilityList: [],

    surveySkipList: []
  },
  getters: {
    getFacilityList(state) {
      return state.facilityList;
    },
    getSurveySkipList(state) {
      return state.surveySkipList;
    }
  },
  mutations: {
    SET_FACILITY_LIST(state, data) {
      state.facilityList = data;
    },
    ADD_TO_SURVEY_SKIP_LIST(state, id) {
      state.surveySkipList.push(id);
    }
  },
};
