<template>
  <v-container class="vh-footer pd20 lynn-web-in bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.push({ name: 'mypage' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">휴면 계정 해제 안내</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops bg-gray">
      <v-card-subtitle class="fts14 lh24 color-2">
				회원님의 계정은 1년간 접속하지 않아 휴면 계정<br/>
				상태로 전환되었습니다.<br/>
				휴면 상태를 해제 하시겠습니까?
			</v-card-subtitle>
      <div class="vote-incont">
        <!-- 일반 텍스트 상자 -->
        <ul class="vtc-box">
          <li class="vtc-ntext">
            휴면계정이란?<br/>
						정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조 및 동법 시행령 제16조에 따라 1년 또는 별도 지정한 기간 동안 로그인하지 않으셨거나 이용이 없는 계정을 휴면으로 전환하고 개인정보를 별도로 분리하여 보관하는 것을 의미합니다.
          </li>
        </ul>
				<div class="cbtn-box mt60">
					<a class="double act" href="#n">로그아웃</a>
					<a class="double disa" @click.stop="dialog = true">해제하기</a>
				</div>
      </div>
    </v-card>

		<v-dialog v-model="dialog" max-width="320">
      <div class="dormant-pop">
				<strong>휴면 계정 해제 완료</strong>
				<p>
					회원님의 계정의 휴면 상태가 해제 되었습니다.<br/>
					다시 돌아오셔서 매우 기쁩니다!<br/>
					모든 서비스의 이용이 가능합니다.
				</p>
				<a @click="dialog = false">확인</a>
			</div>
    </v-dialog>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import { withdrawal } from "@/api/sign.js";

export default {
  name: "Withdrawal",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
			dialog: false,
    };
  },
  computed: {},
  methods: {
  },
};
</script>
