<template>
  <div class="complete-box">
    <div class="conin-tbtn text-right mb10">
      <a href="#n">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
        >
          <path
            id="Icon_material-add-circle-outline"
            data-name="Icon material-add-circle-outline"
            d="M10.7,6.5H9.3V9.3H6.5v1.4H9.3v2.8h1.4V10.7h2.8V9.3H10.7ZM10,3a7,7,0,1,0,7,7A7,7,0,0,0,10,3Zm0,12.6A5.6,5.6,0,1,1,15.6,10,5.607,5.607,0,0,1,10,15.6Z"
            transform="translate(-3 -3)"
            fill="#f36628"
          />
        </svg>
        <span class="ds-ib v_m lh14 fts14 ftw5 ml5" @click="addReservation()"
          >방문예약 추가</span
        >
      </a>
    </div>
    <!-- 신청내역이 있을 때 -->
    <div
      class="mt20 comin-box"
      v-for="request in requestList"
      :key="request.request_id"
    >
      <div class="comin-cont">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
          방문예약 단지
        </strong>
        <span class="text-left ds-b fts14 lh24 color-5">
          {{ request.village_name }}
        </span>
      </div>
      <div class="comin-cont">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
          방문 예정 일자
        </strong>
        <span class="text-left ds-b fts14 lh24 color-5">
          {{ request.date.substr(0, 4) }}년 {{ request.date.substr(5, 2) }}월
          {{ request.date.substr(8, 2) }}일 {{ request.timeinfo.text }}
        </span>
      </div>
      <div class="comin-cont">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
          신청자
        </strong>
        <span class="text-left ds-b fts14 lh24 color-5">
          {{ request.name }}
        </span>
      </div>
      <div class="comin-cont">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
          전화번호
        </strong>
        <span class="text-left ds-b fts14 lh24 color-5">
          {{ request.contact }}
        </span>
      </div>
      <v-card-actions class="pt-0 pb-0">
        <v-btn
          color="#3c3c3c"
          text
          :ripple="false"
          class="ftw5 fts14 no-background-hover"
          >{{ request.status_text }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-tooltip top :disabled="request.actions.is_deletable">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                color="#3c3c3c"
                text
                :ripple="false"
                class="ftw5 fts14 no-background-hover mr-5"
                :disabled="!request.actions.is_deletable"
                @click="delPopup(request.id)"
              >
                <v-icon>mdi-delete</v-icon> 취소
              </v-btn>
            </div>
          </template>
          <span>{{ request.actions.no_delete_text }}</span>
        </v-tooltip>
        <v-tooltip top :disabled="request.actions.is_deletable">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                color="#f36628"
                text
                :ripple="false"
                class="ftw5 fts14 no-background-hover"
                :disabled="!request.actions.is_editable"
                @click="goModify(request.id)"
              >
                <v-icon>mdi-pencil</v-icon> 상세·수정
              </v-btn>
            </div>
          </template>
          <span>{{ request.actions.no_edit_text }}</span>
        </v-tooltip>
      </v-card-actions>
    </div>
    <!-- 신청 내역이 없을 때 -->
    <div class="complete-box" v-if="requestList.length == 0">
      <div class="comin-box">
        <div class="comin-cont no-mes">
          <span class="text-center ds-b fts14 lh24 color-5">
            신청내역이 없습니다
          </span>
        </div>
      </div>
    </div>

    <v-btn
      color="primary"
      class="fts18 ftw7 mw-400 pwidth100 mt15"
      min-height="50"
      block
      @click="goDashboard()"
    >
      확인
    </v-btn>

    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          예약을 취소하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="delReservationAPI(delId)"
          >
            예
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="
              cancelDialog = false;
              delId = '';
            "
          >
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getReservationList, delReservation } from "@/api/index.js";

export default {
  name: "VisitReservationView",
  components: {},
  props: ["reservation_code", "title"],
  data() {
    return {
      requestList: [],
      delId: "",
      cancelDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    // APIs
    getReservationListAPI() {
      /*
            유저의 예약 내역 조회
            TODO: 존재할 경우, form에 넣고 본 예약은 이제 수정, 취소만 되도록.
        */

      let query = `user_id=${this.user.id}&&code=${this.reservation_code}`;

      getReservationList(query).then((re) => {
        // List 형식으로 유저의 본 예약코드(사전점검) 내역을 가져온다.
        this.requestList = re.data;
      });
    },
    delPopup(id) {
      this.delId = id;
      this.cancelDialog = true;
    },
    delReservationAPI() {
      delReservation(this.delId)
        .then((re) => {
          this.$store.commit("SUCCESS", "예약이 취소 되었습니다.");
          this.getReservationListAPI();
        })
        .catch((err) => {
          console.log("실패...");
          console.log(err);
        });

      this.cancelDialog = false;
    },
    goDashboard() {
      this.$router.push({ name: "mypage" });
    },
    goModify(id) {
      this.$router.push({
        name: "visitReservationForm",
        params: { id: id },
      });
    },
    addReservation() {
      this.$router.push({
        name: "visitReservationForm",
        params: { id: 0 },
      });
    },
  },
  created() {
    this.getReservationListAPI();
  },
};
</script>
