export function copy(val) {
  return JSON.parse(JSON.stringify(val));
}

// export function downloadByURLwithFetch(url, filename) {
//   fetch(url).then(function(t) {
//     return t.blob().then(b => {
//       var a = document.createElement('a')
//       a.href = URL.createObjectURL(b)
//       a.setAttribute('download', filename)
//       a.click()
//     })
//   })
// }

export function downloadByURL(url, filename) {
  var a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  a.click();
}

// 이미지파일 크기 줄이는 함수 (callback 방식)
export function downSize(file, callback) {
  // FileReader 객체 생성
  const reader = new FileReader();

  // 파일 로드 완료 시 호출되는 콜백 함수
  reader.onload = function(e) {
    const img = new Image();
    img.src = e.target.result;

    // 이미지 다운사이징
    const maxWidth = 1280; // 원하는 최대 너비
    const maxHeight = 1280; // 원하는 최대 높이
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    img.onload = function() {
      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > maxWidth || img.height > maxHeight) {
        const aspectRatio = img.width / img.height;
        if (img.width > maxWidth) {
          newWidth = maxWidth;
          newHeight = maxWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = maxHeight * aspectRatio;
        }
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // 리사이즈된 이미지를 Blob으로 변환
      canvas.toBlob(function(blob) {
        // Blob을 File 객체로 변환 (이미지 파일 이름은 변경 가능)
        const resizedFile = new File([blob], file.name, {
          type: file.type,
        });

        // 리사이즈된 파일 객체를 사용할 수 있음
        callback(resizedFile);
      }, file.type);
    };
  };

  // 파일을 읽기 시작
  reader.readAsDataURL(file);
}

// 이미지파일 크기 줄이는 함수 (return 방식)
export function getDownSizeFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // 파일 로드 완료 시 호출되는 콜백 함수
    reader.onload = function(e) {
      const img = new Image();
      img.src = e.target.result;

      // 이미지 다운사이징
      const maxWidth = 1280; // 원하는 최대 너비
      const maxHeight = 1280; // 원하는 최대 높이
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      img.onload = function() {
        let newWidth = img.width;
        let newHeight = img.height;

        if (img.width > maxWidth || img.height > maxHeight) {
          const aspectRatio = img.width / img.height;
          if (img.width > maxWidth) {
            newWidth = maxWidth;
            newHeight = maxWidth / aspectRatio;
          }
          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = maxHeight * aspectRatio;
          }
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // 리사이즈된 이미지를 Blob으로 변환
        canvas.toBlob(function(blob) {
          // Blob을 File 객체로 변환 (이미지 파일 이름은 변경 가능)
          const resizedFile = new File([blob], file.name, {
            type: file.type,
          });

          // 리사이즈된 파일 객체를 사용할 수 있음
          resolve(resizedFile);
        }, file.type);
      };
    };

    // 파일을 읽기 시작
    reader.readAsDataURL(file);
  });
}

const isDevelopment = process.env.NODE_ENV !== "production";

export { isDevelopment };
