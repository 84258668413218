import { AuthConnection, Connection } from "./config.js";

/*
    Title:
        APIs
    Auth:
        Token or None
    Terms:
        village: 단지정보
*/

export function loadNICEMain(data) {
  /*  
        단지 목록을 불러옴.
        output:
            status: 200
            data: 
                [
                    { id:1, name:"우미 00 단지", address:"서울시 ...", ... },
                    ...
                ]
    */
  return AuthConnection.post(`/nice/main`, data);
}

export function loadNICEMainWithNoToken(data) {
  /*  
        단지 목록을 불러옴.
        output:
            status: 200
            data: 
                [
                    { id:1, name:"우미 00 단지", address:"서울시 ...", ... },
                    ...
                ]
    */
  return Connection.post(`/nice/main/noauth`, data);
}
