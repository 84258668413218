<template>
  <v-container class="vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.push({ name: 'csCenter' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">입주민 투표</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops">
      <div class="vote-box">
				<strong class="vote-title">※ 투표는 세대주만 가능합니다</strong>
				<ul class="vote-list">
					<li>
						<router-link to="/csCenter/voteForm">
							<div class="vote-state">
								<span class="vote-date">D-2</span>
								<span class="vote-type">미투표</span>
							</div>
							<div class="vote-text">충북혁신도시 우미린스테이 입주민 대표 투표 405동</div>
							<div class="vote-name">충북혁신도시 우미린스테이 임시입주자대표위원회</div>
						</router-link>
					</li>
					<li>
						<router-link to="/csCenter/voteForm">
							<div class="vote-state">
								<span class="vote-date">01시 30분 14초 남음</span>
								<span class="vote-type">미투표</span>
							</div>
							<div class="vote-text">충북혁신도시 우미린스테이 입주민 대표 투표 405동</div>
							<div class="vote-name">충북혁신도시 우미린스테이 임시입주자대표위원회</div>
						</router-link>
					</li>
					<li>
						<router-link to="/csCenter/voteForm">
							<div class="vote-state">
								<span class="vote-date end">종료</span>
								<span class="vote-type end">미투표</span>
							</div>
							<div class="vote-text">충북혁신도시 우미린스테이 입주민 대표 투표 405동</div>
							<div class="vote-name">충북혁신도시 우미린스테이 임시입주자대표위원회</div>
						</router-link>
					</li>
					<li>
						<router-link to="/csCenter/voteForm">
							<div class="vote-state">
								<span class="vote-date end">종료</span>
								<span class="vote-type end">투표완료</span>
							</div>
							<div class="vote-text">충북혁신도시 우미린스테이 입주민 대표 투표 405동</div>
							<div class="vote-name">충북혁신도시 우미린스테이 임시입주자대표위원회</div>
						</router-link>
					</li>
				</ul>
			</div>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

export default {
  name: "Faq",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
    };
  },
  computed: {
    
  },
  methods: {
    
  },
};
</script>
