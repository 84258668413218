import { AuthConnection } from "@/api/config.js";
import { serverSignUp, signupcheck } from "@/api/sign";
import {
  postLog,
  getUserData,
  getVillageList,
  getInterestedCustomerList,
  getReservationMasterList,
} from "@/api/index";
import router from "@/router/index";

export default {
  LOGGING({ commit }, data) {
    /*
        App 전반에서 호출하기 편리하도록 Actions로 구성함.
        호출 시, Client 단에 발생한 에러를 Server에 저장함

        Usage:
            this.$store.dispatch("LOGGING", {
                error: err,
                tags: `file:App.vue|api:lynnlife|name:getUserData|input:${q}`,
                etc: {}, // Put anythings you want to log as object
              });

    */
    postLog(data);
  },
  SIGNUP_CHECK({ commit }, data) {
    let status_text = "";
    let home_id = 0;

    if (!(data.village_id && data.dong_no && data.ho_no)) {
      // 정보 입력중...
      status_text = "단지 지정 및 동, 호를 입력하세요.";
      commit("INFO_UPDATE", {
        type: "signupcheckwarn",
        text: status_text,
      });
      commit("INFO_UPDATE", {
        type: "signupcheckHomeId",
        text: home_id,
      });
    } else {
      signupcheck(data).then((res) => {
        if (res.data.status == 200) {
          status_text = null;
          home_id = res.data.home_id;
        } else {
          status_text = res.data.status_text;
        }
        commit("INFO_UPDATE", {
          type: "signupcheckwarn",
          text: status_text,
        });

        commit("INFO_UPDATE", {
          type: "signupcheckHomeId",
          text: home_id,
        });
      });
    }
  },
  USER_REFRESH({ commit, state }, useCache = false) {
    /*
        유저 관련정보를 최신화.
        유저정보, 지정세대 등등의 변경이 있을 시 호출.
    */
    try {
      if (AuthConnection.defaults.headers.Authorization) {
        let now = new Date();
        let expiredAt = new Date(state.auth.expiredAt);
        let noHomeExists = state.auth.user && state.auth.user.home_list.length == 0
        if (!noHomeExists && useCache && expiredAt && expiredAt > now) {
          console.log("useCache")
        } else {
          getUserData("").then((res) => {
            commit("SET_USER", res.data);
          });
        }
      } else {
        commit("CLEAR");
      }
    } catch (err) {
      console.log(err);
    }
  },
  GET_VILLAGES({ commit }, query) {
    return new Promise((resolve, reject) => {
      getVillageList(query)
        .then((res) => {
          commit("SET_VILLAGE_LIST", res.data);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  GET_INTERESTED_LIST({ commit }) {
    getInterestedCustomerList("")
      .then((res) => {
        commit("SET_INTERESTED_LIST", res.data);
      })
      .catch((err) => {
        commit("SET_INTERESTED_LIST", []);
      });
  },
  CHECK_RESERVATION({ commit }, data) {
    getReservationMasterList(data.query).then((re) => {
      if (!re.data.length) {
        commit("ERROR", data.msg);
      } else {
        router.push({ name: data.routerName });
      }
    });
  },
};
