<template>
  <div>
    <div class="this-header" v-show="title">
      <div class="web-vs web-header">
        <Weblogo width="55" height="30" />
        <strong>{{ title }}</strong>
        <Profile v-show="!noProfile" />
      </div>
      <div class="web-vs side-nav">
        <v-app-bar-nav-icon @click="drawer = true">
          <IconNavback />
        </v-app-bar-nav-icon>
      </div>
      <v-app-bar
        height="72px"
        style="left: 50%; transform: translate(-50%, 0)"
        max-width="760"
        width="100%"
        :class="'pa-3 pl-2 pr-2 mb-vs ' + bgColor"
        flat
        app
        clipped-left
      >
        <v-app-bar-nav-icon @click="drawer = true">
          <IconNavback />
        </v-app-bar-nav-icon>
        <v-toolbar-title class="pl-0" @click="drawer = true">
          <strong class="ds-b ft-l fts21 ftw5 lh48">{{ title }}</strong>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <Profile v-show="!noProfile" />
      </v-app-bar>
      <!-- 계약,주거 관련 navigation -->
      <v-navigation-drawer
        v-model="drawer"
        fixed
        left
        temporary
        class="pwidth90 pheight100 mw-400"
        v-if="!['NONMEMBER', 'MEMBER'].includes(userState) && switchMode"
      >
        <div class="nav-header" @click="drawer = !drawer">
          <a><IconNavboxback /></a>
          <strong>SMART LYNN</strong>
        </div>
        <v-list nav dense>
          <v-list-group no-action :value="menu == 'contract'">
            <template v-slot:activator>
              <v-list-item-content
                ><v-list-item-title class="text-left text-h6"
                  >계약/입주</v-list-item-title
                ></v-list-item-content
              >
            </template>
            <!-- 분양메뉴 -->
            <v-list-item v-if="!is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click="moveto('/contractInfo/status/sell')" style="color: #3c3c3c"
                    >계약내용 조회</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="!is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click="moveto('/contractInfo/payment/payment')"
                    style="color: #3c3c3c"
                    >납부현황 조회</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="!is_rent && is_contractor && !hideMenuCustom01">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click="moveto('/contractInfo/loan/loan')" style="color: #3c3c3c"
                    >융자현황 조회</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="!is_rent && is_contractor && !hideMenuCustom01">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click="moveto('/contractInfo/interest/interest')"
                    style="color: #3c3c3c"
                    >대납이자·보증수수료</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="!is_rent && is_contractor && !hideMenuCustom01">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click="moveto('/contractInfo/balance/balance')"
                    style="color: #3c3c3c"
                    >잔금납부 안내</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <!-- 임대 메뉴 -->
            <v-list-item v-if="is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click="moveto('/contractInfo/status/rent')" style="color: #3c3c3c"
                    >계약내용 조회</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click="moveto('/contractInfo/receipt/deposit')"
                    style="color: #3c3c3c"
                    >보증금·임대료 조회</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click="moveto('/contractInfo/loan/loan')" style="color: #3c3c3c"
                    >융자현황 조회</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click="moveto('/contractInfo/interest/interest')"
                    style="color: #3c3c3c"
                    >대납이자·보증수수료</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click="moveto('/contractInfo/request/change')"
                    style="color: #3c3c3c"
                    >계약 변경 방문 예약</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click="moveto('/contractInfo/renew/renew')" style="color: #3c3c3c"
                    >계약 갱신 방문 예약</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click="moveto('/contractInfo/cancel/cancel')"
                    style="color: #3c3c3c"
                    >계약 해지 방문 예약</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click="moveto('/contractInfo/before/leave')" style="color: #3c3c3c"
                    >중도 퇴거 신청</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="is_rent && is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click="moveto('/contractInfo/on/leave')" style="color: #3c3c3c"
                    >만기 퇴거 신청</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="is_contractor && reservableList.length > 0">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link to="/contract" style="color: #3c3c3c">
                    기타 방문 예약
                    <!-- <span class="nav-state on"
                      >{{ reservableList.length }}건</span
                    > -->
                    <!-- on 빠지면 불꺼짐 -->
                  </router-link></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>

            <!-- 계약 회원이 아닐 때.. -->
            <v-list-item v-if="!is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click="moveto('/contractInfo/status/sell')" style="color: #3c3c3c"
                    >계약내용 조회</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-if="!is_contractor">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click="moveto('/contractInfo/payment/payment')"
                    style="color: #3c3c3c"
                    >납부현황 조회</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list-group>
          <v-list-group no-action :value="menu == 'dwelling'">
            <template v-slot:activator>
              <v-list-item-content
                ><v-list-item-title class="text-left text-h6"
                  >린라이프</v-list-item-title
                ></v-list-item-content
              >
            </template>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click.prevent.stop="dwellingMoveTo('/mgmtFee')"
                    style="color: #3c3c3c"
                    >관리비</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-content
                ><v-list-item-title
                  class="text-left text-subtitle-1"
                  @click.prevent.stop="dwellingMoveTo('/loungeList')"
                  >라운지</v-list-item-title
                ></v-list-item-content
              >
            </v-list-item> -->
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click.prevent.stop="dwellingMoveTo('/facilityHome')"
                    style="color: #3c3c3c"
                    >시설 안내·예약</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click.prevent.stop="dwellingMoveTo('/voteList')"
                    style="color: #3c3c3c"
                    >입주민 투표</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a
                    @click="
                      $router.push({
                        name: 'noticeVillage',
                        query: { type_sub_code: '' },
                      })
                    "
                    style="color: #3c3c3c"
                    >단지소식</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list-group>
          <v-list-group no-action :value="menu == 'mypage'">
            <template v-slot:activator>
              <v-list-item-content
                ><v-list-item-title class="text-left text-h6"
                  >마이페이지</v-list-item-title
                ></v-list-item-content
              >
            </template>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link
                    :to="{ name: 'mypage', params: { tabIndex: 3 } }"
                    style="color: #3c3c3c"
                    >나의 예약 및 신청현황</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item v-show="user.is_accepted">
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1">
                  <router-link to="/familyMember" style="color: #3c3c3c"
                    >가족 구성원 관리</router-link
                  >
                </v-list-item-title></v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link to="/memberInfoUpdate" style="color: #3c3c3c"
                    >회원정보 수정</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list-group>
          <v-list-group no-action :value="menu == 'csCenter'">
            <template v-slot:activator>
              <v-list-item-content
                ><v-list-item-title class="text-left text-h6"
                  ><router-link :to="{ name: 'csCenter' }" style="color: #3c3c3c"
                    >고객지원</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </template>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link :to="{ name: 'notice' }" style="color: #3c3c3c"
                    >공지사항</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link to="/faq" style="color: #3c3c3c"
                    >도움말</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item-group>
            <a
              @click="switchTo('villageInfoList')"
              style="color: #3c3c3c"
              v-if="switchMode && !['NONMEMBER', 'MEMBER'].includes(userState)"
            >
              <v-list-item :ripple="false">
                <v-list-item-icon>
                  <v-list-item-title class="text-left text-subtitle-1"
                    >단지 안내 바로가기</v-list-item-title
                  >
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </a>
          </v-list-item-group>
        </v-list>

        <v-list
          nav
          dense
          v-if="!switchMode && !['NONMEMBER', 'MEMBER'].includes(userState)"
        >
          <v-list-item-group>
            <a style="color: #3c3c3c" @click="switchTo('contract')">
              <v-list-item :ripple="false">
                <v-list-item-icon>
                  <v-list-item-title class="text-left text-subtitle-1"
                    >SMART LYNN 바로가기</v-list-item-title
                  >
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </a>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <!-- Lynn Life 소개 navigation -->
      <v-navigation-drawer
        v-model="drawer"
        fixed
        left
        temporary
        class="pwidth90 pheight100 mw-400"
        v-else
      >
        <div class="nav-header pt24" @click="drawer = !drawer">
          <a><IconNavboxback /></a>
          <strong>SMART LYNN</strong>
        </div>
        <v-list nav dense>
          <v-list-group no-action :value="menu == 'info'">
            <template v-slot:activator>
              <v-list-item-content
                ><v-list-item-title class="text-left text-h6"
                  >단지안내</v-list-item-title
                ></v-list-item-content
              >
            </template>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click.stop="routerVillage(0)" style="color: #3c3c3c"
                    >분양단지</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click.stop="routerVillage(1)" style="color: #3c3c3c"
                    >공사단지</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><a @click.stop="routerVillage(2)" style="color: #3c3c3c"
                    >입주단지</a
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list-group>
          <!-- <v-list-group no-action :value="menu == 'schedule'">
            <template v-slot:activator>
              <v-list-item-content
                ><v-list-item-title class="text-left text-h6"
                  >분양일정</v-list-item-title
                ></v-list-item-content
              >
            </template>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link
                    :to="{ name: 'parcellingCalendar' }"
                    style="color: #3c3c3c"
                    >분양일정</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list-group> -->
          <v-list-item :value="menu == 'schedule'">
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                ><router-link
                  :to="{ name: 'parcellingCalendar' }"
                  class="text-left text-h6 color-6"
                  >분양일정</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <!-- <v-list-group no-action :value="menu == 'introduce'">
            <template v-slot:activator>
              <v-list-item-content
                ><v-list-item-title class="text-left text-h6"
                  >SMART LYNN 소개</v-list-item-title
                ></v-list-item-content
              >
            </template>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link
                    :to="{ name: 'smartLynn' }"
                    style="color: #3c3c3c"
                    >SMART LYNN 소개</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list-group> -->
          <v-list-item :value="menu == 'introduce'">
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                ><router-link
                  :to="{ name: 'smartLynn' }"
                  class="text-left text-h6 color-6"
                  >SMART LYNN 소개</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-group
            no-action
            :value="menu == 'mypage'"
            v-if="$store.state.auth.isLoggedIn"
          >
            <template v-slot:activator>
              <v-list-item-content
                ><v-list-item-title class="text-left text-h6"
                  >마이페이지</v-list-item-title
                ></v-list-item-content
              >
            </template>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link
                    :to="{ name: 'mypage', params: { tabIndex: 3 } }"
                    style="color: #3c3c3c"
                    >나의 예약 및 신청현황</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link to="/memberInfoUpdate" style="color: #3c3c3c"
                    >회원정보 수정</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list-group>
          <v-list-group no-action :value="menu == 'csCenter'">
            <template v-slot:activator>
              <v-list-item-content
                ><v-list-item-title class="text-left text-h6"
                  ><router-link :to="{ name: 'csCenter' }" style="color: #3c3c3c"
                    >고객지원</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </template>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link :to="{ name: 'notice' }" style="color: #3c3c3c"
                    >공지사항</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                ><v-list-item-title class="text-left text-subtitle-1"
                  ><router-link to="/faq" style="color: #3c3c3c"
                    >도움말</router-link
                  ></v-list-item-title
                ></v-list-item-content
              >
            </v-list-item>
          </v-list-group>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                ><router-link
                  :to="{ name: 'reservationList' }"
                  class="text-left text-h6 color-6"
                  >당첨자/예비당첨자 방문예약</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                ><router-link
                  :to="{ name: 'resaleReservationList' }"
                  class="text-left text-h6 color-6"
                  >전매(명의변경) 방문예약</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                ><router-link
                  :to="{ name: 'precheckReservationList' }"
                  class="text-left text-h6 color-6"
                  >사전점검 방문예약</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                ><router-link
                  :to="{ name: 'visitReservationForm' }"
                  class="text-left text-h6 color-6"
                  >견본주택 방문예약</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                ><router-link
                  :to="{ name: 'moveinReservationList' }"
                  class="text-left text-h6 color-6"
                  >입주예약</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item-group>
            <a
              style="color: #3c3c3c"
              @click="switchTo('contract')"
              v-if="!switchMode && !['NONMEMBER', 'MEMBER'].includes(userState)"
            >
              <v-list-item :ripple="false">
                <v-list-item-icon>
                  <v-list-item-title class="text-left text-subtitle-1"
                    >계약·입주·주거서비스 가기</v-list-item-title
                  >
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </a>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
    <v-fab-transition>
      <v-btn
        v-show="readyToScroll"
        color="rgba(255,255,255,0.85)"
        elevation="2"
        fixed
        bottom
        right
        @click="scrollToTop()"
        fab
        dark
        style="margin-bottom: 56px"
        small
      >
        <v-icon color="primary" large>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<style>
a.router-link-active {
  color: #e66a1f !important;
}
.v-list-item {
  margin-bottom: 0 !important;
}
</style>

<script>
import Nav from "@/components/nav";
import Profile from "@/components/Profile";
import IconNavback from "@/assets/images/icon/icon-navback.svg";
import IconNavboxback from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

import { getReservableList } from "@/api/index.js";

export default {
  components: {
    // Nav,
    Profile,
    IconNavback,
    IconNavboxback,
    Weblogo,
  },
  name: "Header",
  data() {
    return {
      drawer: false,
      group: null,
      readyToScroll: false,
      reservableList: [],
    };
  },
  computed: {
    header() {
      if (this.$route.meta.header) {
        return this.$route.meta.header;
      } else {
        return {
          title: "",
        };
      }
    },
    title() {
      return this.header.title;
    },
    noProfile() {
      return this.header.noProfile;
    },
    bgColor() {
      if (this.header.bgWhite) {
        return "bg-white";
      } else {
        return "bg-gray";
      }
    },
    menu() {
      return this.$route.meta.activeMenu;
    },
    userState() {
      return this.$store.getters.getUserState;
    },
    switchMode() {
      return this.$store.state.switchMode;
    },
    home() {
      return this.$store.getters.getHome;
    },
    user() {
      return this.$store.getters.getUser;
    },
    hub() {
      return this.$store.state.hub;
    },
    is_rent() {
      if (this.home) {
        return this.home.is_rent;
      }
      return false;
    },
    is_contractor() {
      if (this.home) {
        return this.home.is_contractor;
      }
      return false;
    },
    hideMenuCustom01() {
      return this.home && this.home.erp_village_id == "AA00P0167D";
    },
  },
  methods: {
    moveto(path) {
      if (!this.user) {
        this.$router.push({ name: "login" });
        return;
      }
      if (path == "/contract" && !this.user.is_verified) {
        this.$store.commit("SUCCESS", "계약자를 위한 서비스입니다.");
        return;
      }

      if (path.indexOf("contractInfo") > 0 && !this.is_contractor) {
        this.$store.commit("SUCCESS", "계약자를 위한 서비스입니다.");
        return;
      }

      /*
        'RENEW' = '갱신'
        'CHANGE' = '변경'
        'TERMINATE_BEFORE_CONTRACT' = '미입주 해지'
        'TERMINATE_BEFORE_PERIOD' = '중도 퇴거'
        'TERMINATE_ON_PERIOD' = '만기 퇴거'

        home.contract_reservation.RENEW = true or false
        home.contract_reservation.CHANGE = true or false
        ...
      */
      if (path == "/contractInfo/balance/balance") {
        if (!this.home.is_display_balance_info) {
          this.$store.commit("ERROR", "잔금납부 안내 기간이 아닙니다.");
          return;
        }
      } else if (path == "/contractInfo/request/change") {
        // 계약 변경 신청 가능 여부
        if (!this.home.contract_reservation.CHANGE) {
          this.$store.commit("ERROR", "계약변경 신청기간이 아닙니다.");
          return;
        }
      } else if (path == "/contractInfo/renew/renew") {
        // 계약 갱신 신청 가능 여부
        if (!this.home.contract_reservation.RENEW) {
          this.$store.commit("ERROR", "계약갱신 신청기간이 아닙니다.");
          return;
        }
      } else if (path == "/contractInfo/cancel/cancel") {
        // 계약 해지
        if (!this.home.contract_reservation.TERMINATE_BEFORE_CONTRACT) {
          this.$store.commit("ERROR", "계약해지 신청기간이 아닙니다.");
          return;
        }
      } else if (path == "/contractInfo/before/leave") {
        // 중도퇴거
        if (!this.home.contract_reservation.TERMINATE_BEFORE_PERIOD) {
          this.$store.commit("ERROR", "중도퇴거 신청기간이 아닙니다.");
          return;
        }
      } else if (path == "/contractInfo/on/leave") {
        // 만기퇴거
        if (!this.home.contract_reservation.TERMINATE_ON_PERIOD) {
          this.$store.commit("ERROR", "만기퇴거 신청기간이 아닙니다.");
          return;
        }
      }

      if (!path) return;
      else if (this.$route.path == path) return;

      this.$router.push({ path: path });
    },
    switchTo(link) {
      this.$store.state.switchMode = !this.$store.state.switchMode;

      if (link == "contract") {
        // 계약,주거 서비스 이동
        if ("CONTRACTOR" == this.userState) {
          this.$router.push({ name: "contract" });
        } else if ("TENANT" == this.userState) {
          this.$router.push({ name: "dwelling" });
        }
      } else {
        this.$router.push({ name: link });
      }
    },
    wait() {
      this.$store.commit("SUCCESS", "서비스 준비중입니다.");
    },
    routerVillage(tab) {
      if (this.$route.name == "villageInfoList") {
        this.$store.state.navTabIndex = tab;
        this.drawer = !this.drawer;
      } else {
        this.$router.push({
          name: "villageInfoList",
          params: { tabIndex: tab },
        });
      }
    },
    routerVillage2(tab) {
      if (this.$route.name == "villageInfoList") {
        this.$store.state.navTabIndex = tab;
      } else {
        this.$router.push({
          name: "villageInfoList",
          params: { tabIndex: tab },
        });
      }
    },
    scrollDetect() {
      this.readyToScroll = window.scrollY > 100;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    dwellingMoveTo(path) {
      //this.user.is_accepted == true 이면 입주자로 간주됨
      if (!this.user.is_accepted) {
        this.$store.commit("SUCCESS", "입주자를 위한 서비스입니다.");
        return;
      }

      if (!path) return;
      else if (this.$route.path == path) return;

      this.$router.push({ path: path });
    },

    getReservableListAPI() {
      getReservableList(this.home.id, "").then((res) => {
        this.reservableList = res.data;
      });
    },
  },
  created() {
    // 비회원, 일반회원인 경우엔 switchMode를 false로 유지
    if (["NONMEMBER", "MEMBER"].includes(this.userState)) {
      this.$store.state.switchMode = false;
    }
    if (this.is_contractor) {
      this.getReservableListAPI();
    }
    window.addEventListener("scroll", this.scrollDetect);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollDetect);
  },
};
</script>
