<template>
    <v-container class="pd16">
        <strong class="t-cont-title">계약정보</strong>
        <table class="t-table table1">
            <colgroup>
                <col width="95px">
                <col width="*">
            </colgroup>
            <tr>
                <th>계약자명</th>
                <td>김희수</td>
            </tr>
            <tr>
                <th>주민등록번호</th>
                <td>856790-71-001881</td>
            </tr>
            <tr>
                <th>계약일자</th>
                <td>서울시 강남구 역삼동 1-1<br/>AA아파트 120동 902호</td>
            </tr>
            <tr>
                <th>주소</th>
                <td>서울시 강남구 역삼동 1-1 AA아파트 120동 902호</td>
            </tr>
            <tr>
                <th>전화번호</th>
                <td>010-1234-1234</td>
            </tr>
            <tr>
                <th>주택의 표시</th>
                <td>서울시 강남구 도곡동 도곡신도시 A-1BL 우미린 1차 201동 3201호</td>
            </tr>
            <tr>
                <th>주택의 유형/종류</th>
                <td>아파트/공공지원민간임대</td>
            </tr>
            <tr>
                <th>평형타입</th>
                <td>70.56 ㎡</td>
            </tr>
            <tr>
                <th>임대의무기간</th>
                <td>8 년</td>
            </tr>
        </table>
        <strong class="t-cont-title">
            계약면적
            <span>[면적단위:㎡]</span>
            <p>총 172.0660</p>
        </strong>
        <table class="t-table table2">
            <colgroup>
                <col width="112px">
                <col width="*">
            </colgroup>
            <tr>
                <th>공급면적<br/>113.8758</th>
                <td>
                    <div class="line-box">
                        <div class="left">전용</div>
                        <div class="right">90.4172</div>
                    </div>
                    <div class="line-box">
                        <div class="left">주거공용</div>
                        <div class="right">23.4586</div>
                    </div>
                </td>
            </tr>
            <tr>
                <th>기타공용면적<br/>58.1902</th>
                <td>
                    <div class="line-box">
                        <div class="left">기타공용</div>
                        <div class="right">7.9659</div>
                    </div>
                    <div class="line-box">
                        <div class="left">지하주차장</div>
                        <div class="right">50.2243</div>
                    </div>
                </td>
            </tr>
            <tr>
                <th>면적 합계</th>
                <td>
                    <div class="line-box">
                        <div class="right">129.5002</div>
                    </div>
                </td>
            </tr>
        </table>
        
        <strong class="t-cont-title">계약정보</strong>
        <table class="t-table table1">
            <colgroup>
                <col width="95px">
                <col width="*">
            </colgroup>
            <tr>
                <th>임대보증금</th>
                <td>102,400,000 원</td>
            </tr>
            <tr>
                <th>월 임대료</th>
                <td>135,000 원</td>
            </tr>
            <tr>
                <th>특약조건</th>
                <td>4년</td>
            </tr>
            <tr>
                <th>임대차 계약기간</th>
                <td>임차인이 임대주택을 인도받은 날로부터 24개월이 되는 날까지</td>
            </tr>
        </table>
        <strong class="t-cont-title">
            임대보증금 약정
            <span class="ft-r">[단위:원]</span>
        </strong>
        <table class="t-table table6">
            <colgroup>
                <col width="33%">
                <col width="33%">
                <col width="*">
            </colgroup>
            <tr class="head">
                <td class="bold">구분</td>
                <td>계약체결시</td>
                <td>약정금액</td>
            </tr>
            <tr>
                <th>계약금</th>
                <td>계약체결시</td>
                <td>2,5000,000</td>
            </tr>
            <tr>
                <th>중도금</th>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <th>잔금</th>
                <td>입주일</td>
                <td>99,900,000</td>
            </tr>
            <tr class="total">
                <td class="bold">합계</td>
                <td></td>
                <td>648,000,000</td>
            </tr>
        </table>
        <table class="t-table table4">
            <colgroup>
                <col width="33%">
                <col width="*">
            </colgroup>
            <tr class="total">
                <th>합계</th>
                <td>648,000,000</td>
            </tr>
            <tr>
                <th>대지비</th>
                <td>406,360,800</td>
            </tr>
            <tr>
                <th>건축비</th>
                <td>219,672,000</td>
            </tr>
            <tr>
                <th>부가세</th>
                <td>21,967,200</td>
            </tr>
        </table>
        <strong class="t-cont-title">납부처 및 납부방법</strong>
        <table class="t-table table1 mb15">
            <colgroup>
                <col width="95px">
                <col width="*">
            </colgroup>
            <tr>
                <th>납부기관</th>
                <td>NH농협은행</td>
            </tr>
            <tr>
                <th>납부계좌</th>
                <td>856790-71-001881</td>
            </tr>
            <tr>
                <th>예금주</th>
                <td>㈜우미케이비뉴스테이제1호<br/>위탁관리부동산투자회사</td>
            </tr>
        </table>
        <table class="t-table table1">
            <colgroup>
                <col width="95px">
                <col width="*">
            </colgroup>
            <tr>
                <th>임대사업자명</th>
                <td>우미케이비뉴스테이관리신탁</td>
            </tr>
            <tr>
                <th>주소</th>
                <td>서울시 강남구 도곡동 261-1</td>
            </tr>
            <tr>
                <th>법인등록번호</th>
                <td>856790-71-001881</td>
            </tr>
            <tr>
                <th>임대사업자번호</th>
                <td>856790-71-001881</td>
            </tr>
            <tr>
                <th>사업자번호</th>
                <td>856790-71-001881</td>
            </tr>
            <tr>
                <th>전화번호</th>
                <td>031-1234-1234</td>
            </tr>
        </table>
    </v-container>
</template>

<script>
export default {
    components: { 
    },
    name: "SetContracting",
    data(){
        return {
        }
    }
};
</script>
