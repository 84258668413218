<template>
  <v-container class="vh-full bg-gray ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pad nb mt15 mt-p3 web-contract">
      <ul class="list-box">
        <li>
          <a href="" class="l-btn" @click.prevent.stop="preCheck()"
            >사전점검 예약</a
          >

          <a href="" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconfirst /></span>
            </span>
          </a>
        </li>
        <!-- <li>
          <a href="" class="l-btn" @click.prevent.stop="wait()">A/S 접수</a>
          <a href="" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconsecond /></span>
            </span>
          </a>
        </li> -->
        <li>
          <router-link :to="{ name: 'moveInReservationView' }" class="l-btn"
            >입주 예약</router-link
          >
          <a href="" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconthird /></span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent">
          <router-link :to="{ name: 'leaveRequest' }" class="l-btn"
            >퇴거 신청</router-link
          >
          <a href="" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconforth /></span>
            </span>
          </a>
        </li>
      </ul>
    </v-container>
  </v-container>
</template>

<script>
import Iconfirst from "@/assets/images/homein/1.svg"; //사전점검 예약
//import Iconsecond from "@/assets/images/homein/2.svg"; //A/S 접수
import Iconthird from "@/assets/images/homein/3.svg"; //입주 예약
import Iconforth from "@/assets/images/homein/4.svg"; //퇴거 신청
import { get_now_string } from "@/utils/time.js";
import { getReservationMasterList } from "@/api/index.js";
export default {
  components: {
    Iconfirst,
    //Iconsecond,
    Iconthird,
    Iconforth,
  },
  name: "Homein",
  data: () => ({}),
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    wait() {
      this.$store.commit("SUCCESS", "서비스 준비중입니다.");
    },
    preCheck() {
      let data = {
        query: `user_id=${
          this.user.id
        }&&code=PRE_CHECK_RESERVATION&&date=${get_now_string()}&&home=${
          this.home.id
        }`,
        msg: "사전점검 신청기간이 아닙니다.",
        routerName: "preCheck",
      };
      this.$store.dispatch("CHECK_RESERVATION", data);
    },
  },
};
</script>
