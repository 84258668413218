<template>
  <v-container class="vh-footer pd20 bg-gray lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">개인정보 처리방침</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text class="pd0">
        <section class="visual policy">
          <h3>개인정보 처리방침</h3>
          <div class="gry_bg">
            <div class="text_wrap">
              <p>
                우미는 (이하 ‘회사’라 칭함) 「개인정보보호법」, 「정보통신망 이용촉진 및 정보보호에 관한 법률」 을 준수하고 있으며, 정보주체의 개인정보 보호 및 권익을
                보호하고 <br>
                개인정보와 관련한 정보주체의 고충을 원활하게 처리할 수 있도록 다음과 같은 개인정보 처리방침을 제정하고 이를 준수하고 있습니다.
              </p>
              <br>
              <p>
                회사의 "개인정보 처리방침"은 관련 법률 및 지침의 변경 또는 내부운영 방침의 변경에 따라 변경 될 수 있습니다. 회사의 "개인정보 처리방침"이 변경될
                경우<br>
                관련 사항을 홈페이지 (https://www.lynn.co.kr)를 통하여 공지합니다.
              </p>
            </div>
          </div>
        </section>
        <div class="contents_wrap policy">
          <section>
            <div class="sec">
              <ul class="list">
                <li><a href="#n01">01. 개인정보의 수집 및 이용목적</a></li>
                <li><a href="#n02">02. 개인정보 수집항목 및 수집방법</a></li>
                <li><a href="#n03">03. 개인정보의 처리 및 보유기간</a></li>
                <li><a href="#n04">04. 개인정보의 제3자 공유 및 제공</a></li>
                <li><a href="#n05">05. 개인정보의 처리 위탁</a></li>
                <li><a href="#n06">06. 개인정보의 파기절차 및 방법</a></li>
                <li><a href="#n07">07. 정보주체와 법정대리인의 권리•의무 및 그 행사방법</a></li>
                <li><a href="#n08">08. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</a> </li>
                <li><a href="#n09">09. 개인정보관련 기술적 관리적 물리적 대책</a></li>
                <li><a href="#n10">10. 개인정보 관리책임자</a></li>
                <li><a href="#n11">11. 권익침해 구제방법</a></li>
                <li><a href="#n12">12. 고지의무</a></li>
              </ul>
            </div>

            <div class="sec" id="n01">
              <h4>01. 개인정보의 수집 및 이용 목적</h4>
              <p>회사는 회사가 운영하는 인터넷 사이트와 앱을 통합하여 운영합니다. 따라서 이용자는 당사가 운영하는 브랜드 사이트와 생활플랫폼 앱을 동일 아이디(이메일)와 비밀번호(P/W)로 로그인하여 이용할 수 있습니다.</p> <!--220106_검수수정-->
              <p class="exp">회사는 다음과 같은 이유로 개인정보를 수집합니다. </p>
              <ul class="text_wrap line-add">
                <li>
                  <dl>
                    <dt>가. 홈페이지 서비스 제공 및 회원관리</dt>
                    <dd>- 이용자 식별 및 본인여부 확인, 홈페이지 이용, 맞춤형 서비스 제공, 사용자 통계 및 분석을 통한 서비스 개선, 홈페이지에서 제공하는
                        각종 서비스 신청 및 이용</dd>
                    <dd>- 아이디(이메일), 휴대전화 인증: 회원제 서비스 이용에 따른 식별 절차에 이용</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>나. 마케팅 및 광고에 활용</dt>
                    <dd>- 분양/브랜드 홍보, 이벤트 및 광고성 정보의 제공</dd>
                  </dl>
                </li>
              </ul>
            </div>
            <div class="sec" id="n02">
              <h4>02. 개인정보 수집항목 및 수집방법</h4>
              <p class="exp">회사는 회원가입 시 서비스 이용을 위해 필요한 최소한의 개인정보만을 수집합니다.</p>
              <ul class="text_wrap inner-add">
                <li>
                  <dl>
                    <dt>가. 수집항목</dt>
                    <dd>1) 회원가입: 이름, 아이디(이메일), 실명인증 값 </dd>
                    <dd>2) 본인확인: 성명, 생년월일, 성별, 휴대전화번호, 내/외국인 정보 </dd>
                    <dd>3) 관심고객 등록: 이름, 주소, 휴대전화번호, 이메일 </dd>
                    <dd>4) 견본주택 방문예약: 이름, 방문자 휴대전화번호, 방문단지 </dd>
                    <dd>5) 사전 점검 예약: 이름, 방문자 휴대전화번호, 방문단지 </dd>
                    <dd>6) 입주 예약: 이름, 입주자 휴대전화번호, 입주단지 </dd>
                    <dd>7) A/S문의: 이름, 휴대전화번호, 이메일, 입주단지 </dd>
                    <dd>8) 1:1문의: 이름, 휴대전화번호, 이메일, 계약단지 </dd>
                    <dd>9) 이벤트 및 고객만족도 설문조사: 이름, 휴대전화번호, 계약(입주)단지 동호수 </dd>
                  </dl>
                </li>
                <li>
                  나. 개인정보 수집방법: 고객이 홈페이지에서 직접 입력 및 자동 수집, 본인인증 시 확인
                </li>
              </ul>
            </div>
            <div class="sec" id="n03">
              <h4>03. 개인정보의 처리 및 보유기간</h4>
              <p class="exp">회사는 개인정보 수집 및 처리목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 기간
                  동안 보유합니다.</p>
              <ul class="text_wrap line-add">
                <li>
                  <dl>
                    <dt>가. 회원관리</dt>
                    <dd>- 홈페이지 회원에 관한 기록: 회원 탈퇴 시 파기 </dd>
                    <dd>- 본인 의사 및 요청에 의한 탈퇴 시 파기 </dd>
                    <dd>- 단순 문의 및 상담 고객에 관한 기록: 문의사항 처리 완료 후 3개월 (사후 A/S 대응을 위한 보관) </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>나. 관심단지 등록 정보 및 견본주택방문</dt>
                    <dd>- 분양종료 또는 정보주체의 삭제 요청 시</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>다. A/S 관련사항</dt>
                    <dd>- A/S 처리: 입주지정일로부터 10년</dd>
                    <dd>- A/S 접수 및 처리 기록: 접수일로부터 10년</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>라. 1:1문의</dt>
                    <dd>- 접수일로부터 5년</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>마. 이벤트 및 고객만족도 설문조사</dt>
                    <dd>- 참여일로부터 이벤트 및 설문조사 종료 시까지(경품 발송 완료 시)</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>바. 그 외 관련 법령에 의한 정보보유</dt>
                    <dd>- 본인확인에 관한 기록 : 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제23조의 3 (본인확인기관의 지정 등) 에 따라 6개월
                        이내</dd>
                    <dd>- 웹사이트 방문기록 보존 이유 : 「통신비밀보호법」 시행령 제41조(전기통신사업자의 협조의무 등)의 2항에 따라 3개월 이상</dd>
                  </dl>
                </li>
              </ul>

            </div>
            <div class="sec" id="n04">
              <h4>04. 개인정보의 제3자 공유 및 제공</h4>
              <p>회사는 정보주체의 개인정보를 "1. 개인정보의 수집 및 이용 목적"에서 고지한 범위 내에서 사용합니다.</p>
              <p>정보주체의 사전 동의 없이는 동 범위를 초과하여 이용하지 않으며, 원칙적으로 정보주체의 개인정보를 외부에 공개하지 않습니다.</p>
              <p class="exp">다만, 아래의 경우에 한해서는 예외로 합니다.</p>

              <ul class="text_wrap line-add">
                <li>
                  - 정보주체들이 사전에 공개에 동의한 경우 <br>
                  - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우 <br>
                  - 통계작성, 학술연구나 시장조사를 위해 특정 개인을 식별할 수 없는 형태로 가공하여 제공하는 경우
                </li>
                <li>
                  <dl>
                    <dt>우미 관계사</dt>
                    <dd>- 제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 자택주소, 자택전화번호, 생년월일, 이름</dd>
                    <dd>- 제공받는 자의 보유.이용기간: 이용목적 달성 후 지체없이 파기</dd>
                  </dl>
                </li>
              </ul>
            </div>
            <div class="sec" id="n05">
                <h4>05. 개인정보처리의 위탁</h4>
                <p class="exp">회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록
                  <br> 필요한사항을 규정하고 있습니다. </p>
                <p class="exp">회사의 개인정보 위탁업체 및 위탁업무 내용은 아래와 같습니다.</p>
                <table>
                  <thead>
                    <tr>
                      <th>위탁업무내용</th>
                      <th>위탁업체</th>
                      <th>개인정보의 보유 및 이용기간</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>전산시스템 구축 및 유지보수</td>
                      <td>휴먼플러스, 타이거컴퍼니, 파도시스템</td>
                      <td>위탁계약 종료 혹은 처리중지 요청 시 까지</td>
                    </tr>
                    <tr>
                      <td>홈페이지 운영 및 유지보수</td>
                      <td>더위버크리에이티브</td>
                      <td>위탁계약 종료 혹은 처리중지 요청 시 까지</td>
                    </tr>
                    <tr>
                      <td>분양대행/마케팅</td>
                      <td>삼일산업, 마켓리더, SDPMC, 위드알앤씨, 엠비앤홀딩스, 정원 C&amp;D</td>
                      <td>위탁계약 종료 혹은 처리중지 요청 시 까지</td>
                    </tr>
                    <tr>
                      <td>입주대행</td>
                      <td>드림하우스, 가온하우스, 플랜하우스, 태현에이치씨, 하우스앤피플</td>
                      <td>위탁계약 종료 혹은 처리중지 요청 시 까지</td>
                    </tr>
                    <tr>
                      <td>고객상담, A/S접수</td>
                      <td>시공협력사(단지내AS센터 또는 관리사무소에서 확인가능), 하자보수 업무 위탁사, 심우건설, <br>명일건설, 영송건설, 전승건설, 심우종합건설</td>
                      <td>위탁계약 종료 혹은 처리중지 요청 시 까지</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div class="sec" id="n06">
                <h4>06. 개인정보의 파기절차 및 방법</h4>
                <p class="exp">
                  회사는 개인정보 보유기간의 경과, 개인정보의 처리 목적 달성 등 정보주체의 개인정보가 불필요하게 되었을 때에는 지체 없이 개인정보를 파기하며,
                  <br>구체적인 파기 절차 및 방법은 다음과 같습니다.
                </p>
                <ul class="text_wrap line-add">
                    <li>
                        <dl>
                            <dt>가. 파기절차</dt>
                            <dd>- 정보주체로부터 수집된 정보는 목적이 달성된 후 별도의 DB로 옮겨져 (종이의 경우 별도의 서류함) "3. 개인정보의 처리 및
                                보유기간"에 따라 일정 기간 저장된 후 지체</dd>
                            <dd>- 없이 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유 이외의 다른 목적으로 이용되지 않습니다.
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>나. 파기방법</dt>
                            <dd>- 개인정보가 기록된 출력물은 분쇄하거나 소각을 통하여 파기합니다.</dd>
                            <dd>- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 파기합니다.</dd>
                        </dl>
                    </li>
                </ul>
            </div>
            <div class="sec" id="n07">
              <h4>07. 정보주체와 법정대리인의 권리∙의무 및 그 행사방법</h4>
              <p class="exp">고객님은 정보주체와 법정대리인으로서 다음과 같은 권리를 행사할 수 있습니다.</p>
              <ul class="text_wrap line-add">
                <li>
                  <dl>
                    <dt>가. 개인정보 열람 요구</dt>
                    <dd>- 회사에서 보유하고 있는 개인정보파일은 「개인정보보호법」 제35조(개인정보의 열람)에 따라 열람을 요구할 수 있습니다. 개인정보 열람
                        요구 시 법 제35조</dd>
                    <dd>- 제4항에 의하여 열람을 제한할 수 있습니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>나. 개인정보 정정•삭제 요구</dt>
                    <dd>- 회사에서 보유하고 있는 개인정보파일은 「개인정보보호법」 제36조(개인정보의 정정•삭제)에 따라 정정•삭제를 요구할 수 있습니다.
                        다만, 다른 법령에서 그 개인</dd>
                    <dd>- 정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>다. 개인정보 처리정지 요구</dt>
                    <dd>- 회사에서 보유하고 있는 개인정보파일은 「개인정보보호법」 제37조(개인정보의 처리정지 등)에 따라 처리정지를 요구할 수 있습니다.
                    </dd>
                    <dd>- 개인정보 처리정지 요구 시 법 제37조 제2항에 의하여 처리정지 요구를 거절할 수 있습니다.</dd>
                    <dd></dd>
                    <dd>① 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</dd>
                    <dd>② 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</dd>
                    <dd>③ 개인정보를 처리하지 않으면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지
                        의사를 명확하게 밝히지 아니한 경우</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>마. 개인정보의 열람, 정정•삭제, 처리정지 요구 접수/처리 담당</dt>
                    <dd>
                      - 성명: 신민호<br>
                      - 소속: 홍보팀<br>
                      - 직책: 부장<br>
                      - 전화번호: 02-6914-1746
                    </dd>
                  </dl>
                </li>
              </ul>

            </div>
            <div class="sec" id="n08">
              <h4>08. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h4>
              <p class="exp">
                ‘회사’는 고객의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’등을 운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 고객님의
                브라우저에<br>
                보내는 아주 작은 텍스트 파일로서 고객님의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
              </p>
            </div>

            <div class="sec" id="n09">
              <h4>09. 개인정보관련 기술적 · 관리적 · 물리적 대책</h4>
              <p class="exp">
                  회사는 「개인정보보호법」 제2제29조(안전조치의무)에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.
              </p>
              <ul class="text_wrap line-add">
                <li>
                  <dl>
                    <dt>가. 내부관리계획의 수립 및 시행</dt>
                    <dd>- 회사의 내부관리계획은 행정안전부의 내부관리 지침을 준수하여 시행합니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>나. 개인정보 취급 담당자의 최소화 및 교육</dt>
                    <dd>- 개인정보를 취급하는 담당자를 최소화하여 지정하고 개인정보보호 교육 및 개인정보를 관리하는 대책을 시행하고 있습니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>다. 개인정보에 대한 접근 제한</dt>
                    <dd>
                      - 개인정보처리시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제에 필요한 조치를 하고 있으며 침입차단시스템을
                      이용하여
                      <br>외부로부터의 무단 접근을 통제하고 있습니다.
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>라. 접속기록의 보관 및 위•변조 방지</dt>
                    <dd>-「개인정보보호법」 제29조(안전조치의무)와 표준지침 및 고시 제8조 접속기록의 보관 및 위•변조방지 제1항에 따라 개인정보처리시스템에
                      접속한 기록
                      <br>(웹 로그, 요약정보 등)을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위•변조 및 도난, 분실되지 않도록 보안기능을
                      사용하고 있습니다.
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>마. 개인정보의 암호화</dt>
                    <dd>
                      - 정보주체의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도
                      보안기능을 사용하고
                      <br>있습니다.
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>바. 해킹 등에 대비한 기술적 대책</dt>
                    <dd>
                      - 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신•점검을 하며
                      외부로부터 접근이
                      <br>통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는
                      물론 불법적으로 정보를 변경
                      <br>하는 등의 시도를 탐지하고 있습니다
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>사. 비인가자에 대한 출입 통제</dt>
                    <dd>
                      - 개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다. 또한
                      개인정보가 기록된
                      <br>문서의 안전한 보관을 위하여 잠금 장치 설치 등 물리적 조치를 취하고 있습니다.
                    </dd>
                  </dl>
                </li>
              </ul>

            </div>

            <div class="sec" id="n10">
                <h4>10. 개인정보 관리책임자</h4>
                <p class="exp">회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호책임자를 지정하고
                    있습니다.</p>
                <table>
                  <thead>
                    <tr>
                        <th colspan="4">개인정보보호책임자</th>
                    </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td>성명</td>
                      <td>신민호</td>
                      <td>부서명</td>
                      <td>홍보팀</td>
                    </tr>
                    <tr>
                      <td>직책</td>
                      <td>팀장</td>
                      <td>전화</td>
                      <td>02-6914-1746</td>
                    </tr>
                    <tr>
                      <td>팩스</td>
                      <td>02-6914-1899</td>
                      <td>이메일</td>
                      <td>kilsh@wm.co.kr</td>
                    </tr>
                </tbody>
              </table>
            </div>
            <div class="sec" id="n11">
              <h4>11. 권익침해 구제방법</h4>
              <p class="exp">
                정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.(아래의 기관은 회사와는 별개의 기관으로서,<br>
                회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.)
              </p>
              <ul>
                <li>▶ 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
                <li>▶ 개인정보 분쟁조정위원회 : 02-2100-2499 (kopico.go.kr)</li>
                <li>▶ 대검찰청 사이버수사과 : (국번없이) 1301 (spo.go.kr)</li>
                <li>▶ 경찰청 사이버안전국 : (국번없이) 182 (cyberbureau.police.go.kr)</li>
              </ul>
            </div>
            <div class="sec pdboth noBorder" id="n12">
              <h4>12. 고지의무</h4>
              <p class="exp">현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 홈페이지를 통해 고지할 것입니다.</p>
              <ul>
                <li>- 개인정보처리방침 버전번호 : 1.1</li>
                <li>- 개인정보처리방침 공고일자: 2024년 01월 08일</li>
                <li>- 개인정보처리방침 시행일자: 2024년 01월 15일</li>
              </ul>
            </div>
          </section>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import { getBulletin } from "@/api/index";

export default {
  name: "Private",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      item: {},
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style>
.policy {
  letter-spacing: -0.5px;
  font-weight: 400;
  text-align:left;
  word-break:keep-all;
}
.policy h3 {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  padding: 40px 0 28px;
}
.policy .gry_bg {
  text-align: left;
  font-size: 16px;
  background-color: #e1e1e1;
  max-width: 1200px;
  margin: 0 auto;
}
.policy .gry_bg .text_wrap {
  padding: 40px;
}
.policy .text_wrap p {
  margin-bottom:0;
}
.policy .select_wrap button:hover {
  background: #812B29;
}
.policy .sec {
  color: #333333;
  font-size: 16px;
  padding-top: 30px;
}
.policy .sec.pdboth {
  padding-bottom:30px;
  padding-top: 30px;
  border-bottom: 1px solid #ddd;
}
.policy .sec.noBorder {
  padding-bottom: 0px;
  border: none;
}
.policy .sec.pdtable {
  padding-top: 0px;
  padding-bottom: 50px;
  border-bottom: 1px solid #B5A18C;
}
.policy .sec.pt70 {
  padding-top: 50px;
}
.policy .sec.pt100 {
  padding-top: 60px;
}
.policy .sec .mt30 {
  margin-top: 20px;
}
.policy .sec h4 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
}
.policy .sec .exp {
  padding-bottom: 20px;
  margin-bottom:0;
}
.policy .sec .exp_2 {
  padding: 20px 0;
}
.policy .sec .text_wrap li {
  padding: 2px 0;
}
.policy .sec .text_wrap li span {
  display: block;
  padding-left: 20px;
}
.policy .sec .text_wrap.line-add li {
  padding: 12px 0;
}
.policy .sec .text_wrap.line-add li dd {
  padding: 0 0 2px;
}
.policy .sec .text_wrap.inner-add li {
  padding: 12px 0;
}
.policy .sec .text_wrap.inner-add li dd {
  padding: 0 0 2px 20px;
}
.policy .sec table {
  width: 100%;
  text-align: center;
}
.policy .sec table tr {
  height: 62px;
}
.policy .sec table tr th {
  background-color: #f8f8f8;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #DDDDDD;
  font-weight: 500;
}
.policy .sec table tr td {
  border-bottom: 1px solid #DDDDDD;
  padding: 20px;
}
.policy .sec .tb dt {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  background-color: #F8F8F8;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #DDDDDD;
  font-weight: 300;
}
.policy .sec .tb dd {
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DDDDDD;
}
.policy .sec .tb span {
  text-align: center;
  flex: 1;
}
.policy .sec .text_bind {
  padding-top: 20px;
}
.policy .sec:last-child {
  padding-bottom: 0;
}
.policy.visual {
  height: 100%;
}
.policy .list {
  border: 1px solid #ccc;
  padding: 15px;
  height: 305px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}
.policy .list li {
  flex: 0 0 45px;
  display: inline-block;
  padding-left: 30px;
  font-size: 16px;
  line-height: 45px;
}
.policy.policy_list {
  margin-bottom: 100px;
}
.policy.policy_view .notice_detail .top {
  margin-top: 60px;
}
.policy.policy_view .notice_detail .content {
  padding: 90px 0 100px;
}
.policy.policy_view .notice_detail .content .gray_box {
  background: #F8F8F8;
  padding: 40px;
}
.policy.policy_view .notice_detail .content .gray_box dl dt {
  font-size: 22px;
  font-weight: 500;
}
.policy.policy_view .notice_detail .content .gray_box dl dd {
  margin-top: 20px;
  word-break: keep-all;
}
.policy.policy_view .notice_detail .content .sec:first-child {
  padding-top: 0;
}
.policy.policy_view .notice_detail .content .change {
  all: unset;
  font-weight: 500;
  color: red;
}
@media (max-width:760px) {
  .policy {
    letter-spacing: -0.3px;
  }
  .policy h3 {
    font-size: 24px;
    padding: 30px 0 20px;
  }
  .policy .gry_bg {
    font-size: 14px;
  }
  .policy .gry_bg .text_wrap {
    padding: 20px;
  }
  .policy .sec {
    font-size: 14px;
    padding-top: 20px;
  }
  .policy .sec.pdboth {
    padding-bottom:20px;
    padding-top: 20px;
  }
  .policy .sec.pdtable {
    padding-bottom: 30px;
  }
  .policy .sec.pt70 {
    padding-top: 30px;
  }
  .policy .sec.pt100 {
    padding-top: 40px;
  }
  .policy .sec .mt30 {
    margin-top: 15px;
  }
  .policy .sec h4 {
    font-size: 16px;
  }
  .policy .sec .exp {
    padding-bottom: 20px;
    margin-bottom:0;
  }
  .policy .sec .exp_2 {
    padding: 20px 0;
  }
  .policy .sec .text_wrap li {
    padding: 2px 0;
  }
  .policy .sec .text_wrap li span {
    padding-left: 15px;
  }
  .policy .sec .text_wrap.line-add li {
    padding: 10px 0;
  }
  .policy .sec .text_wrap.line-add li dd {
    padding: 0 0 2px;
  }
  .policy .sec .text_wrap.inner-add li {
    padding: 10px 0;
  }
  .policy .sec .text_wrap.inner-add li dd {
    padding: 0 0 2px 15px;
  }
  .policy .sec table {
    width: 100%;
    text-align: center;
  }
  .policy .sec table tr {
    height: 62px;
  }
  .policy .sec table tr th {
    background-color: #f8f8f8;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #DDDDDD;
    font-weight: 500;
  }
  .policy .sec table tr td {
    border-bottom: 1px solid #DDDDDD;
    padding: 15px;
  }
  .policy .sec .tb dt {
    padding: 15px 0;
    display: flex;
    font-weight: 400;
  }
  .policy .sec .tb dd {
    padding: 18px 0;
    display: flex;
  }
  .policy .sec .tb span {
    text-align: center;
    flex: 1;
  }
  .policy .sec .text_bind {
    padding-top: 15px;
  }
  .policy .sec:last-child {
    padding-bottom: 0;
  }
  .policy.visual {
    height: 100%;
  }
  .policy .list {
    padding: 15px;
    height: auto;
  }
  .policy .list li {
    flex: 0 0 100%;
    padding-left: 0;
    font-size: 14px;
    line-height: 1.2;
    margin-bottom:15px;
  }
  .policy .list li:last-of-type {
    margin-bottom:0;
  }
  .policy.policy_list {
    margin-bottom: 40px;
  }
  .policy.policy_view .notice_detail .top {
    margin-top: 30px;
  }
  .policy.policy_view .notice_detail .content {
    padding: 70px 0 80px;
  }
  .policy.policy_view .notice_detail .content .gray_box {
    padding: 30px;
  }
  .policy.policy_view .notice_detail .content .gray_box dl dt {
    font-size: 22px;
    font-weight: 500;
  }
  .policy.policy_view .notice_detail .content .gray_box dl dd {
    margin-top: 20px;
    word-break: keep-all;
  }
}
</style>