<template>
  <v-card>
    <v-card-title> {{ item.facility.name }} 사용 신청서 </v-card-title>
    <v-container>
      <div class="comin-cont">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
          >동, 호수</strong
        >
        <span class="text-left ds-b fts14 lh24 color-5">{{
          item.home.dong_ho_text
        }}</span>
      </div>
      <v-divider></v-divider>
      <div class="comin-cont">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
          >연락처</strong
        >
        <span class="text-left ds-b fts14 lh24 color-5">{{
          item.user.mobile_form
        }}</span>
      </div>
      <v-divider></v-divider>

      <div class="comin-cont">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
          >성 명</strong
        >
        <span class="text-left ds-b fts14 lh24 color-5">{{
          item.user.name
        }}</span>
      </div>
      <v-divider></v-divider>

      <div class="comin-cont">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
          >신청일</strong
        >
        <span class="text-left ds-b fts14 lh24 color-5">{{
          item.request_date
        }}</span>
      </div>
      <v-divider></v-divider>

      <div class="comin-cont">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
          >사용 기간</strong
        >
        <span class="text-left ds-b fts14 lh24 color-5">{{
          item.date_form
        }}</span>
      </div>
      <v-divider></v-divider>

      <div class="comin-cont" v-if="item.reservation_seat">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
          >좌석번호</strong
        >
        <span class="text-left ds-b fts14 lh24 color-5">{{
          item.reservation_seat.seat_name
        }}</span>
        <span
          class="text-left ds-b fts14 lh24 color-5"
          v-if="item.reservation_seat.price"
          >{{ item.reservation_seat.price | comma }}원</span
        >
        <v-divider></v-divider>
      </div>

      <div
        class="comin-cont"
        v-for="option in mainOptions(item.reservation_options)"
        :key="'history-option-' + option.id"
      >
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">{{
          option.type_text
        }}</strong>
        <span class="text-left ds-b fts14 lh24 color-5">{{ option.name }}</span>
        <span class="text-left ds-b fts14 lh24 color-5"
          >{{
            (option.price * getDays(item.date, item.end_date)) | comma
          }}원</span
        >
      </div>
      <v-divider
        v-show="mainOptions(item.reservation_options).length"
      ></v-divider>
      <div
        class="comin-cont"
        v-for="option in subOptions(item.reservation_options)"
        :key="'history-option-' + option.id"
      >
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">{{
          option.type_text
        }}</strong>
        <span class="text-left ds-b fts14 lh24 color-5">{{ option.name }}</span>
        <span class="text-left ds-b fts14 lh24 color-5"
          >{{
            (option.price * getDays(item.date, item.end_date)) | comma
          }}원</span
        >
      </div>
      <v-divider
        v-show="subOptions(item.reservation_options).length"
      ></v-divider>

      <div class="comin-cont" v-if="item.number_of_users">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
          >사용인원</strong
        >
        <span class="text-left ds-b fts14 lh24 color-5"
          >{{ item.number_of_users }} 명</span
        >
        <v-divider></v-divider>
      </div>
      <div class="vote-incont" v-if="item.reservation.description">
        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
          신청안내
        </strong>
        <v-card style="max-height:200px;overflow-y: auto;" class="pa-3 ma-3">
          <div class="comin-cont">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
              사용수칙 및 주의사항
            </strong>
            <span
              class="text-left ds-b fts14 lh24 color-5"
              v-html="item.reservation.description"
            ></span>
          </div>
        </v-card>
      </div>
    </v-container>
    <v-card-actions>
      <v-spacer> </v-spacer>
      <v-btn
        color="#3c3c3c"
        text
        :ripple="false"
        class="ftw5 fts14 no-background-hover mr-5"
        @click="$emit('close')"
      >
        닫 기
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { daysOfPeriod } from "@/utils/time.js";

export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    mainOptions(options) {
      return options.filter(i => {
        return !i.is_sub_option;
      });
    },
    subOptions(options) {
      return options.filter(i => {
        return i.is_sub_option;
      });
    },
    getDays(start, end) {
      if (end) {
        return daysOfPeriod(start, end);
      } else {
        return 1;
      }
    }
  }
};
</script>
