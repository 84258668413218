import axios from "axios";
import { AuthConnection, Connection, checkToken } from "./config.js";

/*
    Title:
        Login & Logout APIs
    Auth:
        Token
*/

export function signupcheck(data, status_text) {
  /*  
        Signup check
        comment:
            1. 해당 단지에 존재하는 동, 호 인지 체크.
            2. 해당 세대에 계약자가 존재하는지
            ...
        auth: No Token
        input:
            {
                site_id: 2,
                dong_no: 201,
                ho_no: 101,
                
            }
        output:
            success: {status:200, status_text:'Success'}
            error: {status:400, status_text:에러문구}
    */
  return Connection.post(`/signupcheck`, data);
}

export function serverSignUp(data) {
  /*  
        회원가입
        (Cognito 회원가입 성공 시).then(... signup() 호출)
        input:
            data: {
                email:..,
                name:..,
                mobile:..,
                village_id:..,
                dong_no:..,
                ho_no:..,
                userType:..
            }
        output:
            status: 200
            data: 유저정보

        coment:
    */
  return Connection.post("/signup", data);
}

export function serverSignIn(data) {
  /*  
        로그인
        input:
            data: { email:"sample@sample.com", password:"password" }
        output:
            status: 200
            data: 유저정보

        coment:
    */
  // Set Token on Login
  AuthConnection.defaults.headers.Authorization = data.token;
  return AuthConnection.post("/signin", data);
}

export function serverSignOut() {
  /*  
        로그아웃
        output:
            status: 200
    */
  return AuthConnection.post("/signout");
}

export function emailConfirm(data) {
  /*  
        cognito에 저장된 phone_number를 admin 권한으로 삭제하여 
        이메일로 인증코드를 받을 수 있도록 한다.
        output:
            status: 200
    */
  return Connection.post("/emailConfirm", data);
}

/*

*/
export function withdrawal() {
  /*  
      회원탈퇴
      output: 탈퇴처리 성공시.
        {
          "ResponseMetadata": {
            "RequestId": "07f6a1b9-169c-404a-b311-2dfd5d16a6bb",
            "HTTPStatusCode": 200,
            "HTTPHeaders": {
              "date": "Thu, 15 Jul 2021 08:51:57 GMT",
              "content-type": "application/x-amz-json-1.1",
              "content-length": "2",
              "connection": "keep-alive",
              "x-amzn-requestid": "07f6a1b9-169c-404a-b311-2dfd5d16a6bb"
            },
            "RetryAttempts": 0
          }
        }
    */
  return AuthConnection.post("/withdrawal");
}
