<template>
    <v-container class="pd16">
        <strong class="t-cont-title">계약 품목 내역<span class="ft-r">(부가세 포함)</span></strong>
        <div class="openbox-item">
            <v-list-group :value="false">
                <template v-slot:activator>
                    <strong class="ob-title">발코니 확장공사</strong>
                </template>
                <div sub-group>
                    <table class="open-table table-title">
                        <tr>
                            <th>90.41A - 거실,주방,침실1,침실2,침실3,침실4,침실5,안방</th>
                        </tr>
                    </table>
                    <table class="open-table table1">
                        <colgroup>
                            <col width="33%">
                            <col width="33%">
                            <col width="34%">
                        </colgroup>
                        <tr class="total">
                            <th>합계</th>
                            <td colspan="2">8,800,000</td>
                        </tr>
                        <tr>
                            <th>계약금</th>
                            <td>계약체결시</td>
                            <td>300,000</td>
                        </tr>
                        <tr>
                            <th>중도금</th>
                            <td>2021.07.30</td>
                            <td>300,000</td>
                        </tr>
                        <tr>
                            <th>잔금</th>
                            <td>2021.09.30</td>
                            <td>1,300,000</td>
                        </tr>
                    </table>
                    <table class="open-table table1">
                        <colgroup>
                            <col width="33%">
                            <col width="67%">
                        </colgroup>
                        <tr>
                            <th>비고</th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </v-list-group>
        </div>

        <div class="openbox-item">
            <v-list-group :value="false">
                <template v-slot:activator>
                    <strong class="ob-title">시스템에어컨</strong>
                </template>
                <div sub-group>
                    <table class="open-table table-title">
                        <tr>
                            <th>선택1 - 거실,안방,주방,침실1,2</th>
                        </tr>
                    </table>
                    <table class="open-table table1">
                        <colgroup>
                            <col width="33%">
                            <col width="33%">
                            <col width="34%">
                        </colgroup>
                        <tr class="total">
                            <th>합계</th>
                            <td colspan="2">1,900,000</td>
                        </tr>
                    </table>
                </div>
            </v-list-group>
        </div>

        <div class="openbox-item">
            <v-list-group :value="false">
                <template v-slot:activator>
                    <strong class="ob-title">현관</strong>
                </template>
                <div sub-group>
                    <table class="open-table table-title">
                        <tr>
                            <th>신발장+신발장 브론즈경</th>
                        </tr>
                    </table>
                    <table class="open-table table1">
                        <colgroup>
                            <col width="33%">
                            <col width="33%">
                            <col width="34%">
                        </colgroup>
                        <tr class="total">
                            <th></th>
                            <td colspan="2">1,800,000</td>
                        </tr>
                    </table>
                </div>
            </v-list-group>
        </div>
        
        <div class="openbox-item">
            <v-list-group :value="false">
                <template v-slot:activator>
                    <strong class="ob-title">거실</strong>
                </template>
                <div sub-group>
                    <table class="open-table table-title">
                        <tr>
                            <th>아트월타일+복도벽면타일+우물천정(간접등포함)</th>
                        </tr>
                    </table>
                    <table class="open-table table1">
                        <colgroup>
                            <col width="33%">
                            <col width="33%">
                            <col width="34%">
                        </colgroup>
                        <tr class="total">
                            <th>합계</th>
                            <td colspan="2">1,500,000</td>
                        </tr>
                    </table>
                </div>
            </v-list-group>
        </div>
        
        <div class="openbox-item">
            <v-list-group :value="false">
                <template v-slot:activator>
                    <strong class="ob-title">가전</strong>
                </template>
                <div sub-group>
                    <table class="open-table table-title">
                        <tr>
                            <th>빨래건조대</th>
                        </tr>
                    </table>
                    <table class="open-table table1">
                        <colgroup>
                            <col width="33%">
                            <col width="33%">
                            <col width="34%">
                        </colgroup>
                        <tr class="total">
                            <th></th>
                            <td colspan="2">200,000</td>
                        </tr>
                    </table>
                </div>
            </v-list-group>
        </div>

        <strong class="t-cont-title">납부처 및 납부방법</strong>
        <table class="t-table table5">
            <colgroup>
                <col width="80px">
                <col width="*">
            </colgroup>
            <tr>
                <th>납부기관</th>
                <td>KB국민은행</td>
            </tr>
            <tr>
                <th>납부계좌</th>
                <td>856790-71-001881</td>
            </tr>
            <tr>
                <th>예금주</th>
                <td>(주)우미토건</td>
            </tr>
            <tr>
                <th>납부방법</th>
                <td>무통장 입금</td>
            </tr>
        </table>
    </v-container>
</template>

<script>
export default {
    components: { 
    },
    name: "SetContractingItem",
    data(){
        return {
        }
    }
};
</script>
