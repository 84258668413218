<template>
  <v-container class="bg-gray vh-footer pd20">
    <div class="location-box np">
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">{{ revTitle }}</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text class="pt-0">
        <v-card max-width="750" class="mx-auto bg-gray nops">
          <v-card-text class="">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">단지</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{ villageNameByType }}</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
              >방문예정 일자</strong
            >
            <span class="text-left ds-b fts14 lh24 color-5"
              >{{ date.substr(0, 4) }}년 {{ date.substr(5, 2) }}월
              {{ date.substr(8, 2) }}일</span
            >
            <span class="text-left ds-b fts14 lh24 color-5">{{ timeinfo.text }}</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">이름</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{ name }}</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">전화번호</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{ contact | hyphen }}</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text v-if="comment">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">비고</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{ comment }}</span>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-container class="pd20 ps-ab ps-wf ps-b80 mw-750 mw-reset-750">
      <v-card-actions class="justify-center pd0 mt20">
        <v-btn
          class="pd10 color-1 bgc-3 pwidth49 mr-p2 v-active fts18 ftw7"
          elevation="0"
          min-height="50"
          @click="cancelDialog = true"
          v-show="is_deletable"
          >예약 취소하기</v-btn
        >
        <v-btn
          class="pd10 color-1 bgc-3 pwidth49 mg0 v-active fts18 ftw7"
          elevation="0"
          min-height="50"
          @click="goModify(id)"
          v-show="is_editable"
          >수정하기</v-btn
        >
      </v-card-actions>
      <v-card-actions class="justify-center pd0 mt10">
        <v-btn
          color="primary"
          class="fts18 ftw7 mw-750 pwidth100"
          min-height="50"
          @click="goDashboard()"
          >확인</v-btn
        >
      </v-card-actions>
    </v-container>
    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7"> 예약을 취소하시겠습니까? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="delReservationAPI()"> 예 </v-btn>
          <v-btn color="primary darken-1" text @click="cancelDialog = false">
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getReservationList, delReservation } from "@/api/index.js";
import Back from "@/assets/images/icon/icon-back.svg";
export default {
  name: "CommonReservationView",
  components: {
    Back,
  },
  data() {
    return {
      reservation_code: "", // 계약자 방문예약 타입
      date: "",
      name: "",
      timeinfo: { text: "" },
      contact: "",
      id: "",
      village_name: "",
      dong_no: "",
      ho_no: "",
      comment: "",

      is_deletable: false,
      is_editable: false,

      cancelDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      return this.$store.getters.getHome;
    },
    villageNameByType() {
      if (!this.dong_no) {
        return `${this.village_name}`;
      }
      return `${this.village_name} ${this.dong_no}동 ${this.ho_no}호`;
    },
    revTitle() {
      if (this.reservation_code == "CONTRACT_RESERVATION") {
        return "계약자 방문예약";
      } else if (this.reservation_code == "PAYMENT_RESERVATION") {
        return "중도금대출신청 방문예약";
      } else if (this.reservation_code == "JOINT_RESERVATION") {
        return "공동명의신청 방문예약";
      } else if (this.reservation_code == "WIN_RESERVATION") {
        return "당첨자 방문예약";
      } else if (this.reservation_code == "PRE_WIN_RESERVATION") {
        return "사전청약 당첨자 방문예약";
      } else if (this.reservation_code == "MOVEIN_WIN_RESERVATION") {
        return "예비입주자 방문예약";
      } else if (this.reservation_code == "RESALE_RESERVATION") {
        return "전매(명의변경) 방문예약";
      }

      return "방문예약";
    },
  },
  methods: {
    // APIs
    getReservationListAPI(user_id) {
      let query = `user_id=${user_id}&&code=${this.reservation_code}&&status=['REQUEST_STATUS_REQUESTED', 'REQUEST_STATUS_COMPLETED']`;

      if (
        !["WIN_RESERVATION", "PRE_WIN_RESERVATION", "MOVEIN_WIN_RESERVATION"].includes(
          this.reservation_code
        )
      ) {
        query += `&&home=${this.home.id}`;
      }

      getReservationList(query).then((re) => {
        // List 형식으로 유저의 본 예약코드(사전점검) 내역을 가져온다.
        if (re.data.length) {
          this.name = re.data[0].name;
          this.contact = re.data[0].contact;
          this.id = re.data[0].id;
          this.timeinfo.text = re.data[0].timeinfo.text;
          this.date = re.data[0].date;
          this.village_name = re.data[0].village_name;
          this.dong_no = re.data[0].dong_no;
          this.ho_no = re.data[0].ho_no;
          this.comment = re.data[0].comment;
          this.is_deletable = re.data[0].actions.is_deletable;
          this.is_editable = re.data[0].actions.is_editable;
        } else {
          this.addReservation();
        }
      });
    },
    delReservationAPI() {
      delReservation(this.id)
        .then((re) => {
          this.$store.commit("SUCCESS", "예약이 취소 되었습니다.");
          this.$router.push({ name: "mypage" });
        })
        .catch((err) => {
          console.log("실패...");
          console.log(err);
        });
    },
    goDashboard() {
      this.$router.push({ name: "mypage" });
    },
    goModify(id) {
      this.$router.push({
        name: "commonReservationForm",
        params: { id: id, reservation_code: this.reservation_code },
      });
    },
    addReservation() {
      this.$router.push({
        name: "commonReservationForm",
        params: { id: 0, reservation_code: this.reservation_code },
      });
    },
  },
  created() {
    this.reservation_code = this.$route.query.reservation_code;
    this.getReservationListAPI(this.user.id);
  },
};
</script>
