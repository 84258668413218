<template>
    <v-container class="pd16">
        <div class="monthday">
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="date"
                type="month"
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-dialog>
        </div>
        <div class="openbox-item2">
            <table class="open-table table-title2 done">
                <colgroup>
                    <col width="*">
                    <col width="33%">
                    <col width="40px">
                </colgroup>
                <tr>
                    <th>4월 <span>[미납]</span></th>
                    <td>135,000</td>
                    <td>
                        <router-link to="/SetUnhistoryOpen">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16.243" viewBox="0 0 15 16.243">
                                <g id="Icon_feather-arrow-right-circle" data-name="Icon feather-arrow-right-circle" transform="translate(-10.5 -9.879)">
                                    <path id="패스_591" data-name="패스 591" d="M18,24l6-6-6-6" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    <path id="패스_592" data-name="패스 592" d="M12,18H24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                </g>
                            </svg>
                        </router-link>
                    </td>
                </tr>
            </table>
            <table class="open-table table2">
                <colgroup>
                    <col width="33%">
                    <col width="33%">
                    <col width="*">
                    <col width="40px">
                </colgroup>
                <tr>
                    <th>임대료</th>
                    <td></td>
                    <td>135,000</td>
                    <td></td>
                </tr>
                <tr class="rows"> 
                    <th>연체료</th>
                    <td></td>
                    <td>500</td>
                    <td></td>
                </tr>
                <tr>
                    <th>기타금액</th>
                    <td></td>
                    <td>0</td>
                    <td></td>
                </tr>
                <tr class="total">
                    <th>납기 내 납부시</th>
                    <td>2021.05.10</td>
                    <td>135,000</td>
                    <td></td>
                </tr>
                <tr class="total rows">
                    <th>예정일 납부 시</th>
                    <td>2021.05.25</td>
                    <td>135,000</td>
                    <td>
                        <router-link to="/SetUnhistoryOpen">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.409" height="14.409" viewBox="0 0 14.409 14.409">
                                <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-3.5 -3.5)">
                                    <path id="패스_588" data-name="패스 588" d="M15.162,9.831A5.331,5.331,0,1,1,9.831,4.5,5.331,5.331,0,0,1,15.162,9.831Z" transform="translate(0 0)" fill="none" stroke="#da581f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="패스_589" data-name="패스 589" d="M27.874,27.874l-2.9-2.9" transform="translate(-11.379 -11.379)" fill="none" stroke="#da581f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </router-link>
                    </td>
                </tr>
            </table>
        </div>
        <div class="openbox-item2">
            <table class="open-table table-title2">
                <colgroup>
                    <col width="*">
                    <col width="33%">
                    <col width="40px">
                </colgroup>
                <tr>
                    <th>3월 <span class="sub">[2021년 04.10 납부]</span></th>
                    <td>135,000</td>
                    <td>
                        <router-link to="/SetUnhistoryOpen">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16.243" viewBox="0 0 15 16.243">
                                <g id="Icon_feather-arrow-right-circle" data-name="Icon feather-arrow-right-circle" transform="translate(-10.5 -9.879)">
                                    <path id="패스_591" data-name="패스 591" d="M18,24l6-6-6-6" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    <path id="패스_592" data-name="패스 592" d="M12,18H24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                </g>
                            </svg>
                        </router-link>
                    </td>
                </tr>
            </table>
        </div>
        <div class="openbox-item2">
            <table class="open-table table-title2">
                <colgroup>
                    <col width="*">
                    <col width="33%">
                    <col width="40px">
                </colgroup>
                <tr>
                    <th>2월 <span class="sub">[2021년 04.10 납부]</span></th>
                    <td>135,000</td>
                    <td>
                        <router-link to="/SetUnhistoryOpen">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16.243" viewBox="0 0 15 16.243">
                                <g id="Icon_feather-arrow-right-circle" data-name="Icon feather-arrow-right-circle" transform="translate(-10.5 -9.879)">
                                    <path id="패스_591" data-name="패스 591" d="M18,24l6-6-6-6" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    <path id="패스_592" data-name="패스 592" d="M12,18H24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                </g>
                            </svg>
                        </router-link>
                    </td>
                </tr>
            </table>
        </div>
        <strong class="t-cont-title">납부처 및 납부방법</strong>
        <table class="t-table table1 mb15">
            <colgroup>
                <col width="95px">
                <col width="*">
            </colgroup>
            <tr>
                <th>납부기관</th>
                <td>NH농협은행</td>
            </tr>
            <tr>
                <th>납부계좌</th>
                <td>856790-71-001881</td>
            </tr>
            <tr>
                <th>예금주</th>
                <td>㈜우미케이비뉴스테이제1호<br/>위탁관리부동산투자회사</td>
            </tr>
        </table>
    </v-container>
</template>

<script>
export default {
    components: { 
    },
    name: "SetUnhistory",
    data: () => ({
        picker: new Date().toISOString().substr(0, 10),
    }),
};
</script>
