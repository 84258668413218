import { render, staticRenderFns } from "./BottomNav.vue?vue&type=template&id=34d3f518&scoped=true&"
import script from "./BottomNav.vue?vue&type=script&lang=js&"
export * from "./BottomNav.vue?vue&type=script&lang=js&"
import style0 from "./BottomNav.vue?vue&type=style&index=0&lang=css&"
import style1 from "./BottomNav.vue?vue&type=style&index=1&id=34d3f518&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34d3f518",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBottomNavigation,VBtn,VContainer,VProgressCircular})
