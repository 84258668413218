<template>
  <v-container class="vh-footer pd20 lynn-web-in overout">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="back()" class="back">
        <Back />
      </a>
      <strong class="lo-title">공지사항</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops" style="border-radius: 0">
      <v-autocomplete
        class="sel-cus mt-5"
        :items="villageList"
        item-text="village_name"
        item-value="id"
        v-model="village_id"
        label="단지"
        no-data-text="해당하는 단지가 존재하지 않습니다."
        @change="getBulletinListAPI()"
      ></v-autocomplete>
      <v-card-text class="pd0">
        <div class="sel-inbtn type3 max mt10">
          <v-text-field
            class="sel-cus ft-l"
            style="width: 90%"
            label="검색"
            hide-details
            single-line
            name="name"
            id="id"
            v-model="search"
            v-on:keyup.enter="getBulletinListAPI()"
          ></v-text-field>
          <a @click="getBulletinListAPI()" class="ft-r">
            <v-icon>mdi-magnify</v-icon>
          </a>
        </div>
        <ul class="select-btnlist" v-show="type_show">
          <li v-for="item in noticeSubItems" :key="`noticesubtype-${item.id}`">
            <a
              :class="filter == item.code ? 'active' : ''"
              @click="setFilter(item.code)"
              >{{ item.code_name }}</a
            >
          </li>
        </ul>
        <div class="tab-contents">
          <div>
            <ul class="dan-list">
              <li v-for="item in items" :key="'board-' + item.id">
                <router-link
                  :to="{
                    name: 'noticeIn',
                    params: { id: item.id, type: 'notice' },
                  }"
                  replace
                >
                  <strong class="kitbox"> {{ item.title }}</strong>
                  <div class="bottom-cont">
                    <span class="name">{{ item.writer }}</span>
                    <span class="date">{{ item.createdAtDate }}</span>
                  </div>
                </router-link>
              </li>
              <li v-if="!items.length" class="no-list">
                <router-link to="/notice">
                  등록된 공지사항이 없습니다.
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </v-card-text>

      <div class="text-center">
        <v-pagination
        v-model="page" :length="lastPage"
        :total-visible="5"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import { getBulletinUtils, getBulletinList, getVillageList } from "@/api/index";

export default {
  name: "Notice",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      search: null,
      noticeSubItems: [],

      items: [],
      downloadItems: [],

      village_id: "0",
      villageList: [],
      // Utils
      page: 1,
      lastPage: 1,
      filter: "ALL",

      type_show: true,
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
    tenantHome() {
      return this.$store.getters.getTenantHome;
    },
  },
  watch: {
    page() {
      this.getBulletinListAPI();
    },
  },
  methods: {
    init() {
      // 입주,계약 회원인 경우 유저의 단지를 디폴트로 설정.
      if (this.$route.params.village_id) {
        this.village_id = this.$route.params.village_id;
        this.type_show = false;
      } else if (this.tenantHome) {
        this.village_id = this.tenantHome.village;
        this.type_show = false;
      } else if (this.home) {
        this.village_id = this.home.village;
        this.type_show = false;
      }
      this.filter;
    },
    getBulletinUtilsAPI() {
      getBulletinUtils("parent_type=BULLETIN_LINNLIFE_NOTICE").then((res) => {
        this.noticeSubItems = res.data.subItems;
      });
    },
    getBulletinListAPI() {
      //단지가 0이 아닐 때, type_sub 를 "ALL"로 바꿔주고 영역을 숨긴다.
      this.type_show = true;

      // query
      let query = `type=BULLETIN_LINNLIFE_NOTICE&village_id=${this.village_id}`;
      query += `&page=${this.page}`;
      if (this.search) query += `&search=${this.search}`;
      if (this.filter != "ALL") query += `&type_sub=${this.filter}`;

      getBulletinList(query).then((res) => {
        this.items = res.data.data;
        this.lastPage = res.data.total_page;
      });
    },
    getVillageListAPI() {
      // 단지 정보 세팅
      getVillageList("is_simple=Y&&is_available=true").then((result) => {
        this.villageList = result.data;
        this.villageList.unshift({ village_name: "우미건설 공지", id: "0" });

        this.getBulletinListAPI();
      });
    },
    // Utils
    setFilter(code) {
      this.filter = code;
      this.getBulletinListAPI();
    },
    back() {
      if (this.$route.params.village_id) {
        this.$router.push({
          name: "villageInfo",
          params: { id: this.$route.params.village_id },
        });
      } else {
        this.$router.push({ name: "csCenter" });
      }
    },
  },
  created() {
    this.getVillageListAPI();
    this.getBulletinUtilsAPI();

    // this should be after above API calling
    this.init();
  },
};
</script>
