<template>
    <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
      <div class="web-side"></div>
      <div class="location-box np">
        <div class="web-logo">
          <Weblogo width="55" height="30"/>
        </div>
        <a @click="$router.go(-1)" class="back">
          <Back />
        </a>
        <strong class="lo-title">입주하자 신청 목록</strong>
      </div>
			<v-card max-width="750" class="mx-auto bg-gray nops">
				<v-card-text>
          <div class="complete-box">
						<div class="conin-tbtn text-right mb10">
							<a href="#n">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="14"
									height="14"
									viewBox="0 0 14 14"
								>
									<path
										id="Icon_material-add-circle-outline"
										data-name="Icon material-add-circle-outline"
										d="M10.7,6.5H9.3V9.3H6.5v1.4H9.3v2.8h1.4V10.7h2.8V9.3H10.7ZM10,3a7,7,0,1,0,7,7A7,7,0,0,0,10,3Zm0,12.6A5.6,5.6,0,1,1,15.6,10,5.607,5.607,0,0,1,10,15.6Z"
										transform="translate(-3 -3)"
										fill="#f36628"
									/>
								</svg>
								<span class="ds-ib v_m lh14 fts14 ftw5 ml5"
									><router-link to="/defects">입주하자 등록</router-link></span
								>
							</a>
						</div>
						<v-expansion-panels>
							<v-expansion-panel
								v-for="(item,i) in 10"
								:key="i"
							>
								<v-expansion-panel-header>
									거실-벽지/오른쪽 파손
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<div class="comin-box">
										<div class="comin-cont">
											<strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
												실-부위/상세부위
											</strong>
											<span class="text-left ds-b fts14 lh24 color-5">
												거실-벽지/오른쪽 파손
											</span>
										</div>
										<div class="comin-cont">
											<strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
												접수일시
											</strong>
											<span class="text-left ds-b fts14 lh24 color-5">
												2023년 02월 01일 17:31
											</span>
										</div>
										<div class="comin-cont">
											<strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
												고객민원내용
											</strong>
											<span class="text-left ds-b fts14 lh24 color-5">
												블라블라 내용이 어쩌구 저쩌구 들어갑니다.
											</span>
										</div>
										<div class="comin-cont">
											<strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
												하자 사진
											</strong>
											<span class="text-left ds-b fts14 lh24 color-5">
												사진 끌어오기 얍
												<img src="http://via.placeholder.com/500x300"/>
											</span>
										</div>
										<div class="comin-cont">
											<strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
												완료일시(완료시)
											</strong>
											<span class="text-left ds-b fts14 lh24 color-5">
												2023년 02월 02일 17:31
											</span>
										</div>
										<v-card-actions class="pt-0 pb-0">
											<v-btn
												color="#3c3c3c"
												text
												class="ftw5 fts14 no-background-hover"
												>완료</v-btn
											>
											<v-spacer></v-spacer>
											<v-tooltip top>
												<template v-slot:activator="{ on }">
													<div v-on="on">
														<v-btn
															color="#3c3c3c"
															text
															class="ftw5 fts14 no-background-hover mr-5"
														>
															<v-icon>mdi-delete</v-icon> 취소
														</v-btn>
													</div>
												</template>
												<span>asd</span>
											</v-tooltip>
											<v-tooltip top>
												<template v-slot:activator="{ on }">
													<div v-on="on">
														<v-btn
															color="#f36628"
															text
															class="ftw5 fts14 no-background-hover"
														>
															<v-icon>mdi-pencil</v-icon> 상세·수정
														</v-btn>
													</div>
												</template>
												<span>asd</span>
											</v-tooltip>
										</v-card-actions>
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
						<!-- 신청 내역이 없을 때 -->
						<!--
						<div class="complete-box">
							<div class="comin-box">
								<div class="comin-cont no-mes">
									<span class="text-center ds-b fts14 lh24 color-5">
										신청내역이 없습니다
									</span>
								</div>
							</div>
						</div>
						-->
					</div>
					<v-pagination
						style="margin-top:20px;"
						v-model="page"
						:length="5"
					></v-pagination>
				</v-card-text>
      </v-card>
    </v-container>
  </template>
	
<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

export default {
  name: "PreCheckReservationView",
  components: {Back,Weblogo},
  data() {
    return {
      cancelDialog: false,
			page: 1,
    };
  },
}
</script>