<template>
  <v-container
    max-width="750"
    class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray"
    fluid
  >
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">계약자 입주 예약</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <v-card max-width="750" class="mx-auto bg-gray nops">
          <v-card-text>
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
              >단지</strong
            >
            <span class="text-left ds-b fts14 lh24 color-5"
              >{{ village_name }} {{ dong_no }}동 {{ ho_no }}호</span
            >
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
              >입주예정 일자</strong
            >
            <span class="text-left ds-b fts14 lh24 color-5"
              >{{ date.substr(0, 4) }}년 {{ date.substr(5, 2) }}월
              {{ date.substr(8, 2) }}일</span
            >
            <span class="text-left ds-b fts14 lh24 color-5">{{
              timeinfo.text
            }}</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
              >이름</strong
            >
            <span class="text-left ds-b fts14 lh24 color-5">{{ name }}</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
              >전화번호</strong
            >
            <span class="text-left ds-b fts14 lh24 color-5">{{
              contact | hyphen
            }}</span>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-card-text>
      <v-card-actions class="justify-center pl20 pr20 pt10">
        <v-btn
          class="pd10 color-1 bgc-3 pwidth100 v-active fts18 ftw7 mw-750"
          elevation="0"
          min-height="50"
          @click="cancelConfirm()"
          >예약 취소하기</v-btn
        >
        <!-- <v-btn
          class="pd10 color-1 bgc-3 pwidth49 mg0 v-active fts18 ftw7"
          elevation="0"
          min-height="50"
          @click="goModify(id)"
          >수정하기</v-btn
        > -->
      </v-card-actions>
      <v-card-actions class="justify-center pl20 pr20 pt10">
        <v-btn
          color="primary"
          class="fts18 ftw7 mw-750 pwidth100"
          min-height="50"
          @click="goDashboard()"
          >확인</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          예약을 취소하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="delReservationAPI()">
            예
          </v-btn>
          <v-btn color="primary darken-1" text @click="cancelDialog = false">
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getReservationList,
  delMoveInReservation,
  getMoveInReservation,
} from "@/api/index.js";
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

import { get_now_string, get_time_string } from "@/utils/time.js";
export default {
  name: "ContractReservationView",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      reservation_code: "MOVEIN_RESERVATION", // 계약자 방문예약 타입
      date: "",
      name: "",
      timeinfo: { text: "" },
      contact: "",
      id: "",
      village_name: "",
      dong_no: "",
      ho_no: "",

      cancelDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    homeList() {
      return this.$store.getters.getHomeList;
    },
    home() {
      if (this.$route.query.home) {
        return this.homeList.find((item) => {
          return item.id == Number(this.$route.query.home);
        });
      }
      return this.$store.getters.getHome;
    },
  },
  methods: {
    // APIs
    getReservationListAPI(user_id) {
      let query = `kind_code=MOVEIN_RESERVATION`;

      getMoveInReservation(query).then((re) => {
        if (re.data.length) {
          this.name = re.data[0].name;
          this.contact = re.data[0].contact;
          this.id = re.data[0].id;
          this.timeinfo.text = re.data[0].timeinfo.text;
          this.date = re.data[0].date;
          this.village_name = re.data[0].village_name;
          this.village = re.data[0].village;
          this.dong_no = re.data[0].dong_no;
          this.ho_no = re.data[0].ho_no;
        } else {
          this.addReservation();
        }
      });
    },

    delReservationAPI() {
      /*
            delete
            TODO: 예약 자체를 삭제가 아닌, 삭제 필드를 만들어 patch 해야할듯.
                  현재는 테스트용으로 레코드 삭제.
        */
      delMoveInReservation(this.id)
        .then((re) => {
          this.$store.commit("SUCCESS", "예약이 취소 되었습니다.");
          this.$router.push({ name: "mypage" });
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.$store.commit("SUCCESS", "이미 취소된 예약입니다.");
            this.$router.push({ name: "mypage" });
          }
        });
    },
    goDashboard() {
      this.$router.push({ name: "mypage" });
    },
    goModify(id) {
      this.$router.push({
        name: "moveInReservationForm",
        params: {
          id: id,
          selectVillageId: this.village.id,
          village_name: this.village_name,
        },
      });
    },
    addReservation() {
      this.$router.push({
        name: "moveinReservationList",
        params: { id: 0 },
      });
    },
    cancelConfirm() {
      // 하루 전에는 취소 불가능!!
      var today = get_now_string();
      var tomorrow = get_time_string(1);

      if (this.date == tomorrow) {
        this.$store.commit("ERROR", "하루 전 취소가 불가능합니다.");
        this.cancelDialog = false;
        return;
      }

      this.cancelDialog = true;
    },
  },
  created() {
    this.getReservationListAPI(this.user.id);
  },
};
</script>
