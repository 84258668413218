<template>
  <v-container>
    <ul class="vote-list">
      <li v-for="vote in voteList" :key="'vote' + vote.id">
        <a @click="checkAndMove(vote)">
          <div class="vote-state">
            <span :class="['vote-date', { end: vote.is_done }]">{{
              dayCalculate(vote.to_datetime)
            }}</span>
            <span
              :class="[
                'vote-type',
                { end: vote.vote_yn == 'Y' || vote.is_done },
              ]"
              >{{
                vote.vote_yn == "Y"
                  ? "투표완료" + "-" + vote.voter_name
                  : "미투표"
              }}</span
            >
          </div>
          <div class="vote-text">
            {{ vote.title }}
          </div>
          <div class="vote-name">
            {{ home.village_name }} {{ vote.creator_name }}
          </div>
        </a>
      </li>
      <li class="no-list" v-if="voteList == 0">생성된 투표가 없습니다.</li>
    </ul>

    <v-dialog v-model="votepopup" max-width="320">
      <v-card>
        <div class="vote-popup">
          <p class="vp-text">{{ votepopupmsg }}</p>
          <div class="vp-btnbox">
            <a href="#none" @click="votepopup = false">확인</a>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getVoteList } from "@/api/index";
import moment from "moment";
export default {
  name: "VoteListComponent",
  components: {},
  data() {
    return {
      votepopup: false,
      votepopupmsg: "투표는 세대주만 가능합니다",
      voteList: [],
    };
  },
  computed: {
    home() {
      return this.$store.getters.getTenantHome;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    getVoteList() {
      getVoteList().then((res) => {
        this.voteList = res.data;
      });
    },
    dayCalculate(to_datetime) {
      var now = moment();
      var datetime = moment(to_datetime);
      var day = now.diff(datetime, "days");

      var text = "";
      if (day > 0) {
        text = "투표종료";
      } else if (day == 0) {
        var time = new Date(0, 0, 0, 0, 0, 0, datetime - now);
        text = time.getHours() + "시 " + time.getMinutes() + "분 남음";
      } else {
        text = "D-" + day * -1;
      }

      return text;
    },
    checkAndMove(vote) {
      let leftMinutes = parseInt(
        (new Date(vote.to_datetime).getTime() - new Date().getTime()) /
          (1000 * 60)
      );
      let beforeMinutes = parseInt(
        (new Date(vote.from_datetime).getTime() - new Date().getTime()) /
          (1000 * 60)
      );

      if (leftMinutes < 0) {
        // 기간 만료 시
        this.$router.push({ name: "voteDetail", params: { id: vote.id } });
      } else if (beforeMinutes > 0) {
        // 투표 가능시간 이전
        this.votepopup = true;
        this.votepopupmsg = `투표 시작시간 ${vote.from_datetime} 이후 투표가능합니다.`;
      } else if (vote.vote_yn == "Y") {
        // 이미 투표진행 경우
        this.$router.push({ name: "voteDetail", params: { id: vote.id } });
      } else {
        this.$router.push({ name: "voteDetail", params: { id: vote.id } });
      }
    },
  },
  created() {
    this.getVoteList();
  },
};
</script>

<style>
</style>