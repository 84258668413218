<template>
  <div style="padding-bottom: 100px">
    <!-- 2024.02.01 신규 팝업 생성 st -->
    <div class="loading-box" v-if="isLoading">
      <div class="ldb-inbox">
        <span class="loader"></span>
        <strong class="ldb-text">잠시만 기다려주세요.</strong>
      </div>
    </div>
    <!-- 2024.02.01 신규 팝업 생성 ed -->
    <v-form>
      <v-text-field type="text" :value="hometext" label="방문 단지" readonly></v-text-field>
      <v-subheader class="pd0">방문일자</v-subheader>
      <v-date-picker v-model="form.date" :allowed-dates="allowedDates" @change="sync" :picker-date.sync="pickerDate"
        :day-format="(date) => new Date(date).getDate()" locale="KR" full-width no-title></v-date-picker>
      <v-subheader class="pd0">방문시간</v-subheader>
      <v-slide-group :value="allowed_times.indexOf(form.timeinfo)">

        <v-slide-item class="mr10 nh-50" v-for="timeinfo in        allowed_times       " :key="timeinfo.id"
          v-slot="{ active, toggle }">
          <v-btn :value="timeinfo" :disabled="timeinfo.disabled" :input-value="active" active-class="v-active"
            v-bind:class="[
              {
                'v-active':
                  timeinfo.id === form.timeinfo.id &&
                  form.date === sel_date &&
                  is_modify,
              },
              'pd10 color-2 bgc-3',
            ]" depressed @click="toggle;
form.timeinfo = timeinfo;
sel_date = '';
            ">
            {{ timeinfo.text }} <br />
            {{ timeinfo.tooltip }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>

      <v-text-field v-model=" form.name " label="이름" required readonly></v-text-field>
      <v-text-field type="number" v-model=" form.contact " label="방문자 전화번호" :counter=" 12 " required></v-text-field>
    </v-form>
    <v-btn color="primary" class="fts18 ftw7 mw-400 pwidth100 mt15" min-height="50" v-if=" !is_modify " block
      :disabled=" !is_form_ready " @click=" postReservationAPI ">
      사전점검 예약하기
    </v-btn>
    <v-btn color="primary" class="fts18 ftw7 mw-400 pwidth100 mt15" min-height="50" v-if=" is_modify " block
      :disabled=" !is_form_ready " @click=" putReservationAPI ">
      수정하기
    </v-btn>
  </div>
</template>

<script>
import {
  getReservationMasterList,
  getReservationMaster,
  postReservation,
  getReservation,
  putReservation,
  delReservation,
} from "@/api/index.js";
import { getOpenVillage, getOpenReservationMaster } from "@/api/index.js";
import { get_now_string } from "@/utils/time.js";

export default {
  name: "PreCheckReservationForm",
  data() {
    return {
      reservation_code: "PRE_CHECK_RESERVATION", // 사전예약 타입
      isLoading: false,
      menu: false,
      form: {
        master: 0, // 예약마스터 id
        user: 0, // 유저 id
        time: 0, // 선택한 차수 id
        home: 0,
        date: get_now_string(), // 선택한 일자
        timeinfo: { text: "" }, // 선택한 차수 정보
        name: "",
        contact: "",
      },
      pickerDate: null,

      village: {},
      allowed_dates: [],
      allowed_times: [],

      is_modify: false,
      sel_date: "", // (수정의 경우) 저장된 날짜
      hometext: "",

      reservationID: 0,
      master: 0,
    };
  },
  watch: {
    // pickerDate(newval, oldval) {
    // this.next_prev_month(newval);
    // },
  },
  computed: {
    formed_time() {
      return `${this.form.date} ${this.form.timeinfo.text}`;
    },
    is_form_ready() {
      return (
        this.form.master &&
        this.form.date &&
        this.form.timeinfo.id &&
        this.form.name &&
        this.form.contact
      );
    },
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    // APIs
    init() {
      // 자동완성 및 사용자의 단지정볼르 가져오기 위한 API
      if (this.reservationID) {
        this.getReservationAPI(this.reservationID);
        // this.getReservationListAPI(this.user.id);
      } else {
        // 유저 정보 세팅
        this.form.user = this.user.id;
        this.form.name = this.user.name;
        this.form.contact = this.user.mobile;
        this.village = this.home.village;
        this.getMasterListAPI();
      }
    },
    getReservationAPI(id) {
      getReservation(id)
        .then((re) => {
          this.village = re.data.village;
          this.master = re.data.master;
          this.is_modify = true;
          this.form.contact = re.data.contact;
          this.form.name = re.data.name;
          this.form.master = re.data.master;
          this.form.timeinfo = re.data.timeinfo;
          this.form.date = re.data.date;
          this.form.time = re.data.time;
          this.sel_date = re.data.date;
          this.form.id = re.data.id;
          this.form.home = re.data.home;
          this.form.user = re.data.user;
          this.getMasterListAPI();

        })
        .catch((er) => {
          console.log(er);
        });
    },
    delReservationAPI() {
      /*
                delete
                TODO: 예약 자체를 삭제가 아닌, 삭제 필드를 만들어 patch 해야할듯.
                      현재는 테스트용으로 레코드 삭제.
            */
      delReservation(this.form.id);
    },
    getMasterListAPI() {
      this.isLoading = true
      getOpenVillage("PRE_CHECK_RESERVATION").then((res) => {

        let reservation = res.data.find((village) => village.reservation == this.master);
        if (reservation) {
          this.form.master = reservation.reservation;

          let now = get_now_string();

          if (!this.form.id) {

            this.form.date = reservation.start_date;
            // 시작이 과거이면 오늘로 지정.
            if (this.form.date < now) {
              this.form.date = now;
            }
          }
          this.hometext = reservation.village_name;
          this.getReservationDetailAPI(this.form.date);
        } else {
          this.$store.commit("ERROR", "예약가능한 단지 정보가 없습니다.");
          this.hometext = "예약가능한 단지 정보가 없습니다.";
        }

      }).finally(() => {
        this.isLoading = false
      })
    },
    getReservationDetailAPI(custom_date) {
      // 특정 요청한 날짜의 예약마스터의 상세정보(예약가능 시간 등)을 불러온다.
      if (!this.form.master) return;

      let query;
      if (custom_date) {
        query = `date=${custom_date}`;
        this.form.date = custom_date
      } else {
        if (!this.form.date) return "";
        query = `date=${this.form.date}`;
      }
      this.pickerDate = this.form.date.slice(0, 7);
      this.form.time = 0
      this.form.timeinfo.id = 0

      this.isLoading = true

      query = `date=${this.form.date}&village=${this.village}&kind_code=PRE_CHECK_RESERVATION`//&no_dong=3001&no_ho=1801`
      return getOpenReservationMaster(query).then(item => {
        let data = item.data

        this.allowed_dates = data.allowed_dates;
        this.allowed_times = data.allowed_times;

      }).finally(() => {
        this.isLoading = false
      })
    },
    postReservationAPI() {
      if (this.is_form_ready) {
        this.isLoading = true
        this.form.time = this.form.timeinfo.id;
        this.form.home = this.home.id;
        postReservation(this.form)
          .then((re) => {
            this.$store.commit("SUCCESS", "예약 되었습니다.");
            this.$router.push({ name: "preCheck" });
          })
          .catch((er) => {
            let errors = er.response.data.non_field_errors;
            if (errors) {
              if (errors.length) {
                this.$store.commit("ERROR", errors[0]);
                this.getReservationDetailAPI();
              }
            }
          }).finally(() => {
            this.isLoading = false
          });
      }
    },
    putReservationAPI() {
      if (this.is_form_ready) {
        this.form.time = this.form.timeinfo.id;

        putReservation(this.form.id, this.form)
          .then((re) => {
            this.$store.commit("SUCCESS", "수정 되었습니다.");
            this.$router.push({ name: "preCheck" });
          })
          .catch((er) => {
            let errors = er.response.data.non_field_errors;
            if (errors) {
              if (errors.length) {
                this.$store.commit("ERROR", errors[0]);
                this.getReservationDetailAPI();
              }
            }
          });
      }
    },
    // Utils
    allowedDates(val) {
      return this.allowed_dates.indexOf(val) !== -1;
    },
    sync() {
      this.getReservationDetailAPI(null);
    },
    next_prev_month(newval) {
      this.getReservationDetailAPI(`${newval}-01`);
    },
  },
  created() {
    this.reservationID = Number(this.$route.params.id);
    this.master = Number(this.$route.query.master);
    this.init();
  },
};
</script>
