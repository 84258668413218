<template>
  <v-container>
    <strong class="t-cont-title">
      융자 신청내역
      <span class="ft-r">[단위:원]</span>
    </strong>
    <table class="loan-table">
      <colgroup>
        <col width="95px" />
        <col width="120px" />
        <col width="*" />
      </colgroup>
      <tr class="head">
        <th>알선융자/<br />이자구분</th>
        <th>신청일자/<br />융자사</th>
        <th>신청금액</th>
      </tr>
      <tbody v-for="(loan, idx) in loanList" :key="'loan' + idx">
        <tr class="up">
          <td></td>
          <td>{{ loan.applicationDate }}</td>
          <td>{{ loan.applicationAmount | comma }}</td>
        </tr>
        <tr class="down">
          <td>
            {{ loan.interestType == "후불제" ? "후불이자" : loan.interestType }}
          </td>
          <td colspan="2">{{ loan.bank }}</td>
        </tr>
      </tbody>
      <tr class="total">
        <td>
          {{ amount.loanType }}
        </td>
        <td></td>
        <td>{{ amount.applicationAmount | comma }}</td>
      </tr>
    </table>

    <div class="order-list">
      <p>※실제 융자내역은 은행에 직접 확인하시기 바랍니다.</p>
      <p>
        ※융자신청 금액은 개별 중도 상환 여부에 따라 상기 내역과 상이할 수
        있습니다. 정확한 금액은 대출하신 은행으로 문의하시기 바랍니다.
      </p>
    </div>
  </v-container>
</template>

<script>
import { getLoanApplication } from "@/api/contract.js";
export default {
  components: {},
  name: "SetLoan",
  data() {
    return {
      loanList: [],
      amount: {},
    };
  },
  methods: {
    getLoan() {
      getLoanApplication(this.home.id)
        .then((result) => {
          for (var i = 0; i < result.data.length; i++) {
            var res = result.data[i];
            if (res.loanType != "합계") {
              this.loanList[i] = res;
            } else {
              this.amount = res;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  created() {
    this.getLoan();
  },
};
</script>
