<template>
  <v-container class="vh-footer ps-re lynn-mobile pd0" fluid>
    <v-container class="pb0">
      <v-row>
        <v-col class="ft-c">
          <Nav class="mt14" />
          <strong class="ds-b ft-l fts21 ftw5 ml15 lh48">계약정보</strong>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pd0">
      <v-select
        v-model="e1"
        class="sel-cus"
        :items="items"
        label="계약정보 주소"
        menu-props="auto"
        hide-details
        single-line
      ></v-select>
    </v-container>
    <v-container class="pd0">
      <div class="tabs-box">
        <ul class="tab-menu">
          <li v-bind:class="{ active: openTab === 0 }">
            <a href="#n" v-on:click="openTab = 0">계약현황</a>
          </li>
          <li v-bind:class="{ active: openTab === 1 }">
            <a href="#n" v-on:click="openTab = 1">납부현황</a>
          </li>
          <li v-bind:class="{ active: openTab === 2 }">
            <a href="#n" v-on:click="openTab = 2">융자신청·납부</a>
          </li>
          <li v-bind:class="{ active: openTab === 3 }">
            <a href="#n" v-on:click="openTab = 3">대납이자 납부</a>
          </li>
        </ul>
      </div>
      <div class="tab-contents">
        <div v-if="openTab == 0">
          <div class="tabs-box">
            <ul class="tab-menu">
              <li v-bind:class="{ active2: openTab21 === 0 }">
                <a href="#n" v-on:click="openTab21 = 0">분양계약정보</a>
              </li>
              <li v-bind:class="{ active2: openTab21 === 1 }">
                <a href="#n" v-on:click="openTab21 = 1">별도계약품목</a>
              </li>
            </ul>
          </div>
          <div class="tab-contents">
            <div v-if="openTab21 == 0">
              <SetContracting />
            </div>
            <div v-if="openTab21 == 1">
              <SetContractingItem />
            </div>
          </div>
        </div>
        <div v-if="openTab == 1">
          <div class="tabs-box">
            <ul class="tab-menu">
              <li v-bind:class="{ active2: openTab22 === 0 }">
                <a href="#n" v-on:click="openTab22 = 0">중도금납부조회</a>
              </li>
              <li v-bind:class="{ active2: openTab22 === 1 }">
                <a href="#n" v-on:click="openTab22 = 1">융자신청/납부내역</a>
              </li>
              <li v-bind:class="{ active2: openTab22 === 2 }">
                <a href="#n" v-on:click="openTab22 = 2">대납이자/상환내역</a>
              </li>
            </ul>
          </div>
          <div class="tab-contents">
            <div v-if="openTab22 == 0">
              <SetStorageStatus />
            </div>
            <div v-if="openTab22 == 1">
              <SetStorageStatus />
            </div>
            <div v-if="openTab22 == 2">
              <SetStorageStatus />
            </div>
          </div>
        </div>
        <div v-if="openTab == 2">
          <SetLoan />
        </div>
        <div v-if="openTab == 3">
          <SetPayment />
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<style>
.sel-cus .v-text-field__details {
  min-height: auto;
}
.sel-cus .v-input__slot {
  margin: 0;
}
.sel-cus .v-input input {
  max-height: 40px;
}
.sel-cus .v-input .v-label {
  text-align: center;
}
.sel-cus .v-select__selection {
  max-width: 100%;
  width: 100%;
  padding-left: 28px;
  text-align: center;
}
</style>

<script>
import Nav from "@/components/nav";
import SetContracting from "@/publish/ContractInfo/SetContracting";
import SetContractingItem from "@/publish/ContractInfo/SetContractingItem";
import SetLoan from "@/publish/ContractInfo/SetLoan";
import SetPayment from "@/publish/ContractInfo/SetPayment";
import SetStorageStatus from "@/publish/ContractInfo/SetStorageStatus";

export default {
  components: {
    Nav,
    SetContracting,
    SetContractingItem,
    SetLoan,
    SetPayment,
    SetStorageStatus,
  },
  name: "Contract",
  data() {
    return {
      openTab: 0,
      openTab21: 0,
      openTab22: 0,
      items: ["우미린 1차 201동3201호", "우미린 파주 운정 단지"],
      e1: "우미린 1차 201동3201호",
    };
  },
};
</script>
