<template>
  <v-app>
    <v-container class="ps-fx ps-t0 ps-r0 ds-ib pd0 mr0 z-10 twidth30"></v-container>
    <button v-if="isRefresh">새 버전이 있습니다.</button>
    <HeaderHub v-if="hub" />
    <Header v-else />
    <router-view />
    <OverallComponent />
  </v-app>
</template>
<script>
import Header from "@/components/Header";
import HeaderHub from "@/components/HeaderHub";
import OverallComponent from "@/components/OverallComponent";
export default {
  name: "App",

  components: { Header, HeaderHub, OverallComponent },
  metaInfo: {
    title: "스마트린",
    meta: [
      {
        vmid: "naver-site-verification",
        name: "naver-site-verification",
        content: "40ccdd6f6e6a80d0df3f6276afeb386d1900f015",
      },
      {
        vmid: "description",
        name: "description",
        content: "우미건설 입주민을 위한 스마트한 생활편의 서비스",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: "스마트린, SmartLynn, 린라이프, LynnLife, 우미건설",
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "스마트린",
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://www.smartlynn.co.kr",
      },
      {
        vmid: "og:description",
        property: "og:description",
        content: "우미건설 입주민을 위한 스마트한 생활편의 서비스",
      },
    ],
  },
  data: () => ({
    registration: null,
    isRefresh: false,
    refreshing: false,
  }),
  computed: {
    hub() {
      return this.$store.state.hub;
    },
  },
  updated() {
    //임대허브일때, 웹버전 2뎁스 영역을 없애기 위해 hub class 추가.
    this.$nextTick(function () {
      var webside = document.getElementsByClassName("web-side");
      if (this.hub && webside.length > 0) {
        webside[0].classList.add("hub");
      }
    });
  },
  methods: {
    appUpdateUI: function (e) {
      this.registration = e.detail;
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
  },
  created() {
    // 새로고침에 따라(또는 재접속) localstorage에 저장된 데이터를 최신화
    this.$store.dispatch("USER_REFRESH");

    // url 검사하여 임대허브 메뉴 적용하기 위해 store.state.hub 변경
    var url = location.href;
    var rentUrl = ["stay.lynn.co.kr", "hub.smartlynn.co.kr"];
    var hubCheck = false;
    // url이 rentUrl에 있는 문자열들을 포함하고 있는지 체크
    for (var i = 0; i < rentUrl.length; i++) {
      if (url.indexOf(rentUrl[i]) > 0) {
        hubCheck = true;
      }
    }
    if (hubCheck) {
      // 임대 허브
      this.$store.state.hub = true;
    } else {
      // 일반 스마트린
      this.$store.state.hub = false;
    }

    document.addEventListener("serviceWorkerUpdateEvent", this.appUpdateUI, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
};
</script>
