<template>
  <v-container class="bg-gray vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <v-btn icon class="moveToBack" @click="$router.push({ name: 'facilityHome' })">
        <a  class="back">
            <Back />
        </a>
      </v-btn>
      <!-- <strong class="lo-title">휘트니스</strong> -->
    </div>
    <iframe :src="path" frameborder="0" allowfullscreen class="iframe" id="iframe-view" sandbox="allow-forms allow-same-origin allow-scripts allow-popups allow-modals allow-downloads"></iframe>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import Vote from "@/assets/images/btn/btn-vote.svg";

export default {
  name: "FacilityCIS",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {};
  },
  computed: {
    path (){
        const idToken = this.$store.getters.getIdToken
        const reservation = `https://woomi.dycis.co.kr/API/webviewcall/default.asp?userToken=${idToken}`
        return reservation
    }
  },
  methods: {
  },
  created() {
  },
};
</script>

<style scoped>
.moveToBack{
    z-index: 999;
    position: absolute;
    top:-40px !important;
    left:0px;
}
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  border: none;
}
</style>