<template>
  <div>
    <!-- 스낵바 -->
    <v-snackbar v-model="snackbar.dialog" :color="snackbar.color" :timeout="snackbar.timeout" :left="snackbar.left"
      :right="snackbar.right" :top="snackbar.top" :centered="snackbar.centered" transition="slide-y-reverse-transition"
      app>
      <v-row class="snackbar_text">
        <v-icon class="mx-2" size="18" dark>{{ snackbar.icon }}</v-icon>
        {{ snackbar.text }}
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" icon @click="snackbar_close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 스낵바 끝 -->
    <!-- 다이얼로그 -->
    <v-dialog v-model="dialog.show" max-width="290">
      <v-card>
        <v-card-title class="justify-center">
          {{ dialog.title }}
        </v-card-title>

        <v-card-text class="text-left ftw7">
          {{ dialog.content }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" v-for="button in dialog.button" :key="button.text" text @click="
            button.callback();
          dialog_close();
          ">
            {{ button.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="verificationDialog" max-width="290" :persistent="verificationForced">
      <v-card>
        <v-card-title class="justify-center">

          {{ textByPage }}
        </v-card-title>

        <v-card-text class="text-left ftw7">
          {{ userVerificationInfo.text }}
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn class="ftw7 pwidth48 color-2 bgc-4" min-height="30" @click="verificationBack(true)"
            v-if="verificationForced">
            뒤로가기
          </v-btn>
          <v-btn class="ftw7 pwidth48 color-2 bgc-4" min-height="30" @click="verificationBack(false)" v-else>
            둘러보기
          </v-btn>
          <v-btn color="primary" class="ftw7 pwidth48" min-height="30" @click="loadVerification()">
            실명인증하기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 다이얼로그 끝 -->
    <!-- 푸터 -->
    <BottomNav v-if="showBottomNav && !hub" />
    <BottomNavHub v-if="showBottomNav && hub" />
    <!-- 테스트용 2021.03.19 BSK -->
    <!-- 푸터 끝 -->
    <ContractVerification :forced="verificationForced" />
    <WinVerification :forced="winVerificationForced" />
  </div>
</template>

<script>
import BottomNav from "@/components/BottomNav";
import BottomNavHub from "@/components/BottomNavHub";
import ContractVerification from "@/components/Contract/ContractVerification";
import WinVerification from "@/components/Contract/WinVerification";

export default {
  name: "OverallComponent",

  components: {
    BottomNav,
    BottomNavHub,
    ContractVerification,
    WinVerification,
  },

  data() {
    return {
      verificationDialog: false,
      currentRouterPath: "",

      verificationForced: false,
      winVerificationForced: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    snackbar() {
      return this.$store.getters.getSnackBar;
    },
    showBottomNav() {
      /*
        BottomNav 가 보이는 페이지를 설정함.
        router 에서 meta.bottomNav = true 인 페이지
      */
      return this.$route.meta.bottomNav;
    },
    showBottomNavUsed() {
      /*
        테스트용 2021.03.19 BSK
      */
      return this.$route.meta.bottomNavused;
    },
    dialog() {
      return this.$store.state.dialog;
    },
    onetimeLoad() {
      return this.$store.state.info.onetimeLoad;
    },
    userVerificationInfo() {
      return this.$store.state.data.userVerificationInfo;
    },
    userVerificationInfoDialog() {
      return this.userVerificationInfo.dialog;
    },
    winVerification() {
      return this.$store.state.data.winVerification;
    },
    winVerificationDialog() {
      return this.winVerification.dialog;
    },
    hub() {
      return this.$store.state.hub;
    },
    textByPage() {
      if (this.$route.name == "waitRequestForm") {
        return "실명 인증 안내"
      }
      return "계약자와 입주자 인증 안내"
    }
  },
  watch: {
    $route(to, from) {
      this.currentRouterPath = to.path;
      this.verification_check();
    },
    userVerificationInfoDialog(val) {
      if (val) {
        this.verificationDialog = true;
        this.verificationForced = true;
      }
    },
    winVerificationDialog(val) {
      if (val) {
        this.winVerificationForced = true;
      }
    },
  },
  methods: {
    dialog_close() {
      this.$store.commit("DIALOG_CLOSE");
    },
    snackbar_close() {
      this.$store.commit("SNACKBAR_CLOSE");
    },
    // 실명인증 안내.
    verification_check() {
      this.verificationForced = false;
      // 로그인 시, 1회 계약인증 다이아 로그를 생성.
      if (this.onetimeLoad && this.user && !this.user.is_verified_name) {
        this.$store.commit("INFO_UPDATE", {
          type: "onetimeLoad",
          text: false,
        });

        this.verificationDialog = true;
      } else if (this.userVerificationInfo.dialog) {
        this.$store.state.data.userVerificationInfo.dialog = false;
        this.verificationForced = true;
        this.verificationDialog = true;
      }
    },
    loadVerification(only_verified_name) {
      this.verificationDialog = false;

      this.$store.commit("SET_VERIFICATON", {
        title: "실명인증",
        verified_name_type: true,
        dialog: true,
        redirect: this.userVerificationInfo.redirect,
        is_test: false,
        only_verified_name: this.userVerificationInfo.only_verified_name,
      });
    },
    verificationBack(push = false) {
      this.verificationDialog = false;
      this.$store.state.data.userVerificationInfo.dialog = false;

      if (push) {
        this.$router.push({ path: this.userVerificationInfo.back });
      }
    },
  },
  created() {
    this.verification_check();
  },
};
</script>

<style scoped>
.snackbar_text {
  font-size: 14px;
  font-family: "Noto Sans KR";
  font-weight: 500;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex: 1 1 calc(100% - 30px);
}

.v-snack__content {
  display: flex;
}
</style>
