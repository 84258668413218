<template>
  <v-container class="pd16">
    <strong class="t-cont-title">보증금 납부 현황</strong>
    <div class="openbox-item2">
      <v-list-group :value="false">
        <template v-slot:activator>
          <strong class="ob-title">계약금</strong>
        </template>
        <div sub-group>
          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>금액</th>
              <td>{{ depositInfo.depositDownPayment | comma }}</td>
            </tr>
            <tr>
              <th>납부일</th>
              <td>{{ depositInfo.depositDownPaymentDate }}</td>
            </tr>
          </table>

          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>약정일자</th>
              <td>-</td>
            </tr>
            <tr>
              <th>약정금액</th>
              <td>-</td>
            </tr>
          </table>

          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>일수</th>
              <td>-</td>
            </tr>
            <tr>
              <th>연체금액</th>
              <td>-</td>
            </tr>
            <tr>
              <th>할인금액</th>
              <td>
                {{
                  depositInfo.depositDownDiscount > 0
                    ? depositInfo.depositDownDiscount
                    : "-" | comma
                }}
              </td>
            </tr>
            <tr>
              <th>납부일자</th>
              <td>
                {{
                  depositInfo.depositDownPaymentAmount > 0
                    ? depositInfo.depositDownPayDate
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th>납부금액</th>
              <td>
                {{
                  depositInfo.depositDownPaymentAmount > 0
                    ? depositInfo.depositDownPaymentAmount
                    : "-" | comma
                }}
              </td>
            </tr>
          </table>
        </div>
      </v-list-group>
    </div>
    <div class="openbox-item2" v-if="depositInfo.depositSecondPayment > 0">
      <v-list-group :value="false">
        <template v-slot:activator>
          <strong class="ob-title">중도금</strong>
        </template>
        <div sub-group>
          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>금액</th>
              <td>{{ depositInfo.depositSecondPayment | comma }}</td>
            </tr>
            <tr>
              <th>납부일</th>
              <td>{{ depositInfo.depositSecondPaymentDate }}</td>
            </tr>
          </table>
        </div>
      </v-list-group>
    </div>
    <div class="openbox-item2">
      <v-list-group :value="false">
        <template v-slot:activator>
          <strong class="ob-title">잔금</strong>
        </template>
        <div sub-group>
          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>금액</th>
              <td>{{ depositInfo.depositBalancePayment | comma }}</td>
            </tr>
            <tr>
              <th>납부일</th>
              <td>{{ depositInfo.depositBalancePaymentDate }}</td>
            </tr>
          </table>
        </div>
      </v-list-group>
    </div>
    <strong class="t-cont-title">납부처 및 납부방법</strong>
    <table class="t-table table1 mb15">
      <colgroup>
        <col width="95px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>납부기관</th>
        <td>{{ depositInfo.bankName }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ depositInfo.bankAccount }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ depositInfo.accountHolder }}</td>
      </tr>
    </table>
  </v-container>
</template>

<script>
import { getLeaseDepositInfo } from "@/api/contract.js";
export default {
  components: {},
  name: "SetContracting",
  data() {
    return {
      depositInfo: {},
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    getDepositInfo() {
      let query = `contract_id=${this.home.id}`;
      getLeaseDepositInfo(this.home.id).then((result) => {
        this.depositInfo = result.data;
      });
    },
  },
  mounted() {
    this.getDepositInfo();
  },
};
</script>
