import { AuthConnection } from "./config.js";

export function getContract(contract_id) {
  return AuthConnection.get(`/contracts/contract/${contract_id}`);
}

export function getContractor(contract_id) {
  return AuthConnection.get(`/contracts/contractor/${contract_id}`);
}

export function getOptions(contract_id) {
  return AuthConnection.get(`/contracts/options/${contract_id}`);
}

export function getLeaseReceipt(contract_id, year) {
  return AuthConnection.get(`/charges/lease/receipt/${contract_id}/${year}`);
}

export function getLeaseInvoice(contract_id, year, month) {
  return AuthConnection.get(`/charges/lease/invoice/${contract_id}/${year}/${month}`);
}

export function getLeaseDepositInfo(contract_id) {
  return AuthConnection.get(`/charges/lease/deposit/${contract_id}`);
}

export function getSellInvoice(contract_id) {
  return AuthConnection.get(`/charges/sell/invoice/${contract_id}`);
}

export function getSellOptionInvoice(contract_id, query) {
  return AuthConnection.get(`/charges/sell/options/${contract_id}${query === "" ? "" : "?" + query}`);
}

export function getSellOptionItemInvoice(contract_id, optionId, query) {
  return AuthConnection.get(`/charges/sell/option/${contract_id}/${optionId}?${query}`);
}

export function getChargesCalculator(contract_id, query) {
  return AuthConnection.get(`/charges/calculator/${contract_id}?${query}`);
}

export function getLoanApplication(contract_id) {
  return AuthConnection.get(`/contracts/loan/application/${contract_id}`);
}

export function getChargesSellReceipt(contract_id) {
  return AuthConnection.get(`/charges/sell/receipt/${contract_id}`);
}

export function getChargesSellBalance(contract_id, query) {
  return AuthConnection.get(`/charges/sell/balance/${contract_id}?${query}`);
}

export function getLeaseRenew(contract_id, query) {
  return AuthConnection.get(`/contracts/contract/renew/${contract_id}?${query}`);
}

export function getLeaseNewCondition(contract_id) {
  return AuthConnection.get(`/contracts/lease/condition/${contract_id}`);
}
