<template>
  <v-container class="lease-hub-main pd0">
    <div class="hub-header"></div>
    <div class="main-top">
      <v-carousel
        hide-delimiter-background
        show-arrows-on-hover
        cycle
        :interval="3000"
      >
        <v-carousel-item>
          <img
            class="web-img"
            :src="require('@/assets/images/hub/slide/slide00.jpg')"
          />
          <img
            class="mb-img"
            :src="require('@/assets/images/hub/slide/slide00_m.jpg')"
          />
        </v-carousel-item>

        <v-carousel-item>
          <img
            class="web-img"
            :src="require('@/assets/images/hub/slide/slide01.jpg')"
          />
          <img
            class="mb-img"
            :src="require('@/assets/images/hub/slide/slide01_m.jpg')"
          />
        </v-carousel-item>

        <v-carousel-item>
          <img
            class="web-img"
            :src="require('@/assets/images/hub/slide/slide02.jpg')"
          />
          <img
            class="mb-img"
            :src="require('@/assets/images/hub/slide/slide02_m.jpg')"
          />
        </v-carousel-item>

        <v-carousel-item>
          <img
            class="web-img"
            :src="require('@/assets/images/hub/slide/slide03.jpg')"
          />
          <img
            class="mb-img"
            :src="require('@/assets/images/hub/slide/slide03_m.jpg')"
          />
        </v-carousel-item>

        <v-carousel-item>
          <img
            class="web-img"
            :src="require('@/assets/images/hub/slide/slide04.jpg')"
          />
          <img
            class="mb-img"
            :src="require('@/assets/images/hub/slide/slide04_m.jpg')"
          />
        </v-carousel-item>

        <v-carousel-item>
          <v-sheet>
            <v-row align="center" justify="center">
              <img
                class="web-img"
                :src="require('@/assets/images/hub/slide/slide05.jpg')"
              />
              <img
                class="mb-img"
                :src="require('@/assets/images/hub/slide/slide05_m.jpg')"
              />
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="main-middle">
      <strong>단지안내</strong>
      <ul class="lease-box">
        <li class="lease-item" v-for="village in villageList" :key="village.id">
          <a class="lease-in">
            <div class="img-box">
              <img
                :src="
                  village.image_url
                    ? village.image_url
                    : require('@/assets/images/hub/test.png')
                "
              />
            </div>
            <div class="text-box">
              <div class="text-top">
                <span class="tt-state">{{ village.info.status }}</span>
                <strong class="tt-title">{{ village.display_name }}</strong>
                <p class="tt-side">{{ village.address }}</p>
              </div>
              <ul class="text-state">
                <li class="ts-line">
                  <strong>규모</strong>
                  <span>{{ village.info.scale }}</span>
                </li>
                <li class="ts-line">
                  <strong>입주세대</strong>
                  <span>{{ village.info.home_count }}</span>
                </li>
                <li class="ts-line">
                  <strong>주택형</strong>
                  <span>{{ village.info.room_type }}</span>
                </li>
              </ul>
              <div class="text-btn">
                <a @click="link(village.homepage)">홍보관 바로가기</a>
                <a @click="moveTo(village.id)">자세히 보기</a>
              </div>
            </div>
          </a>
        </li>
        <!-- villageList 개수가 2개일때, 1개일때 빈 공간 -->
        <li class="lease-item ready" v-if="villageList.length < 3">
          <a href="#none" class="lease-in">
            <div class="img-box">
              <img :src="require('@/assets/images/hub/imgloading.png')" />
            </div>
            <div class="text-box">
              <div class="text-top">
                <span class="tt-state">빈공간</span>
                <strong class="tt-title">빈공간</strong>
                <p class="tt-side">빈공간</p>
              </div>
              <ul class="text-state">
                <li class="ts-line">
                  <strong>빈공간</strong>
                  <span>빈공간</span>
                </li>
                <li class="ts-line">
                  <strong>빈공간</strong>
                  <span>빈공간</span>
                </li>
                <li class="ts-line">
                  <strong>빈공간</strong>
                  <span>빈공간</span>
                </li>
              </ul>
              <div class="text-btn">
                <a href="#none">빈공간</a>
                <a href="#none">빈공간</a>
              </div>
            </div>
          </a>
        </li>
        <li class="lease-item ready" v-if="villageList.length == 1">
          <a href="#none" class="lease-in">
            <div class="img-box">
              <img :src="require('@/assets/images/hub/imgloading.png')" />
            </div>
            <div class="text-box">
              <div class="text-top">
                <span class="tt-state">빈공간</span>
                <strong class="tt-title">빈공간</strong>
                <p class="tt-side">빈공간</p>
              </div>
              <ul class="text-state">
                <li class="ts-line">
                  <strong>빈공간</strong>
                  <span>빈공간</span>
                </li>
                <li class="ts-line">
                  <strong>빈공간</strong>
                  <span>빈공간</span>
                </li>
                <li class="ts-line">
                  <strong>빈공간</strong>
                  <span>빈공간</span>
                </li>
              </ul>
              <div class="text-btn">
                <a href="#none">빈공간</a>
                <a href="#none">빈공간</a>
              </div>
            </div>
          </a>
        </li>
        <!-- ////// villageList 개수가 2개일때, 1개일때 빈 공간 -->
      </ul>
    </div>
    <div class="main-bottom">
      <strong>재무건전성, 신용도 모두 업계 최고의 우미건설</strong>
      <ul class="lease-imgcont">
        <li><img :src="require('@/assets/images/hub/item1.png')" /></li>
        <li><img :src="require('@/assets/images/hub/item2.png')" /></li>
        <li><img :src="require('@/assets/images/hub/item3.png')" /></li>
      </ul>
    </div>
    <div class="lease-call">
      <strong>대표번호<span>1588-9707</span></strong>
      <ul>
        <li class="days">평일 9:00 - 17:00</li>
        <li class="text">토요일과 공휴일 및 국경일은 휴무</li>
      </ul>
      <v-btn
        color="primary"
        elevation="0"
        class="fts18 ftw7 mw-700 pwidth100 mt30"
        min-height="50"
        @click="goFaq()"
        >자주 묻는 질문</v-btn
      >
    </div>
    <div class="lease-footer">
      <div class="lf-in">
        <div class="lf-left"><FLogo /></div>
        <div class="lf-center">
          <ul class="lf-top">
            <li>
              <router-link to="/privatePolicy">개인정보취급방침</router-link>
            </li>
          </ul>
          <div class="lf-bottom">
            <p>
              06292 서울시 강남구 언주로 30길 39 린스퀘어(도곡동 467-14)
              <span>대표전화</span> 1588-9707
            </p>
            <p>
              Copyright &copy; 2021 WOOMI CONSTRICTION CORP. ALL rights
              reserved.
            </p>
          </div>
        </div>
        <div class="lf-right">
          <select @change="goSite()" v-model="selSite">
            <option
              v-for="site in familySite"
              :key="site.name"
              :value="site.value"
            >
              {{ site.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import MainImg from "@/assets/images/hub/main.svg"; //메인이미지
import FLogo from "@/assets/images/hub/footer-logo.svg"; //메인이미지
import ImgLoading from "@/assets/images/hub/imgloading.svg"; //메인이미지

export default {
  components: {
    //MainImg,
    FLogo,
    //ImgLoading,
  },
  name: "HubIntro",
  metaInfo: {
    title: "스마트린",
    meta: [
      {
        vmid: "naver-site-verification",
        name: "naver-site-verification",
        content: "3fdd66356d03402072f6a43f803c710f03dac509",
      },
      {
        vmid: "description",
        name: "description",
        content: "우미건설 입주민을 위한 스마트한 생활편의 서비스",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "스마트린, SmartLynn, 린라이프, LynnLife, 우미건설, 파주운정, 우미린, 파주운정우미린더퍼스트, 린더퍼스트, 충북혁신도시우미린스테이, 충북혁신도시, 린스테이, 매교역 푸르지오 SK VIEW",
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "스마트린",
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://hub.smartlynn.co.kr/",
      },
      {
        vmid: "og:description",
        property: "og:description",
        content: "우미건설 입주민을 위한 스마트한 생활편의 서비스",
      },
    ],
  },
  data: () => ({
    selHome: "",
    selSite: "",
    familySite: [
      { name: "패밀리사이트", value: "" },
      { name: "우미린", value: "https://www.lynn.co.kr" },
    ],
  }),
  computed: {
    villageList() {
      return this.$store.state.data.villageList;
    },
  },
  methods: {
    getVillageList() {
      this.$store.dispatch(
        "GET_VILLAGES",
        "is_display=true&&order_by=display_order"
      );
      if (this.user) {
        this.$store.dispatch("GET_INTERESTED_LIST");
      }
    },
    link(link) {
      if (link == null) {
        this.$store.commit("SUCCESS", "사이버홍보관이 없습니다.");
        return;
      }
      window.open(link);
    },
    moveTo(id) {
      this.$router.push({
        name: "villageInfo",
        params: { id: id },
      });
    },
    goSite() {
      if (this.selSite != "") {
        window.open(this.selSite);
      }
    },
    goFaq() {
      this.$router.push({
        name: "faq",
      });
    },
  },
  created() {
    this.$store.state.hub = true;
    this.getVillageList();
  },
};
</script>
