<template>
    <v-container class="pd16">
        <ul class="under-list">
            <li>고객님이 납부하신 단지의 납부정보를 조회하실 수 있습니다</li>
            <li>계약자인 경우에 한해 계약현장의 분양대금 수납조회가 가능합니다</li>
            <li>린라이프에서 제공하는 분양대금조회서비스는 일반적으로 분양대금 입금일 익일에 조회가 가능합니다</li>
        </ul>
        <table class="unline-table">
            <colgroup>
                <col width="95px">
                <col width="*">
            </colgroup>
            <tr>
                <td>사업지&nbsp;&nbsp;|&nbsp;유형</td>
                <td><strong>강남 도곡 우미린 1차&nbsp;&nbsp;|&nbsp;&nbsp;민영주택</strong></td>
            </tr>
            <tr>
                <td>동 / 호&nbsp;&nbsp;|&nbsp;&nbsp;면적</td>
                <td><strong>201동 3201호&nbsp;&nbsp;|&nbsp;&nbsp;90.41 A ㎡</strong></td>
            </tr>
        </table>
        <div class="sel-inbtn">
            <div class="left">
                <v-select v-model="e1" class="sel-cus" :items="items" label="계약정보 주소" menu-props="auto" hide-details single-line></v-select>
            </div>
            <div class="right">
                <a href="#n">기준일자 검색</a>
                <a href="#n">전체보기</a>
            </div>
        </div>
        <strong class="t-cont-title">중도금 납부내역</strong>
        <div class="list-gg">
            <v-slide-group>
                <v-slide-item class="mr10 nh-50">
                    <v-btn active-class="v-active" class="pd10 color-2 bgc-8 nw-100 text-left" depressed>
                        계약금<br/>
                        1/2 회차
                    </v-btn>
                </v-slide-item>
                <v-slide-item class="mr10 nh-50">
                    <v-btn active-class="v-active" class="pd10 color-2 bgc-8 nw-100 text-left" depressed>
                        계약금<br/>
                        2/2 회차
                    </v-btn>
                </v-slide-item>
                <v-slide-item class="mr10 nh-50">
                    <v-btn active-class="v-active" class="pd10 color-2 bgc-8 nw-100 text-left" depressed>
                        중도금<br/>
                        1/6 회차
                    </v-btn>
                </v-slide-item>
                <v-slide-item class="mr10 nh-50">
                    <v-btn active-class="v-active" class="pd10 color-2 bgc-8 nw-100 text-left" depressed>
                        중도금<br/>
                        2/6 회차
                    </v-btn>
                </v-slide-item>
            </v-slide-group>
        </div>
        <div class="storage-table">
            <table>
                <colgroup>
                    <col width="33%">
                    <col width="33%">
                    <col width="34%">
                </colgroup>
                <tr>
                    <th>약정일자</th>
                    <td>2020.06.30</td>
                    <td></td>
                </tr>
                <tr>
                    <th>약정금액</th>
                    <td>64,800,000</td>
                    <td></td>
                </tr>
                <tr>
                    <th>납부일자</th>
                    <td>2021.10.15</td>
                    <td></td>
                </tr>
                <tr>
                    <th>입금액</th>
                    <td>64,800,000</td>
                    <td></td>
                </tr>
                <tr>
                    <th>일수</th>
                    <td>0</td>
                    <td></td>
                </tr>
                <tr>
                    <th>할인금액</th>
                    <td>0</td>
                    <td></td>
                </tr>
                <tr>
                    <th>연체금액</th>
                    <td>0</td>
                    <td></td>
                </tr>
                <tr>
                    <th>납부분양금</th>
                    <td>64,800,000</td>
                    <td></td>
                </tr>
                <tr>
                    <th>납수금액</th>
                    <td>64,800,000</td>
                    <td></td>
                </tr>
                <tr>
                    <th>미수금</th>
                    <td>0</td>
                    <td></td>
                </tr>
            </table>
        </div>
        
        <strong class="t-cont-title">별도계약품목 납부내역</strong>
        <div class="list-gg">
            <v-slide-group>
                <v-slide-item class="mr10 nh-50">
                    <v-btn active-class="v-active" class="pd10 color-2 bgc-8 nw-100 text-left" depressed>
                        계약금<br/>
                        &nbsp;
                    </v-btn>
                </v-slide-item>
                <v-slide-item class="mr10 nh-50">
                    <v-btn active-class="v-active" class="pd10 color-2 bgc-8 nw-100 text-left" depressed>
                        중도금<br/>
                        &nbsp;
                    </v-btn>
                </v-slide-item>
                <v-slide-item class="mr10 nh-50">
                    <v-btn active-class="v-active" class="pd10 color-2 bgc-8 nw-100 text-left" depressed>
                        잔금<br/>
                        &nbsp;
                    </v-btn>
                </v-slide-item>
            </v-slide-group>
        </div>
        <div class="storage-table">
            <table>
                <colgroup>
                    <col width="33%">
                    <col width="33%">
                    <col width="34%">
                </colgroup>
                <tr>
                    <th>약정일자</th>
                    <td>2020.06.30</td>
                    <td></td>
                </tr>
                <tr>
                    <th>약정금액</th>
                    <td>64,800,000</td>
                    <td></td>
                </tr>
                <tr>
                    <th>납부일자</th>
                    <td>2021.10.15</td>
                    <td></td>
                </tr>
                <tr>
                    <th>입금액</th>
                    <td>64,800,000</td>
                    <td></td>
                </tr>
                <tr>
                    <th>일수</th>
                    <td>0</td>
                    <td></td>
                </tr>
                <tr>
                    <th>할인금액</th>
                    <td>0</td>
                    <td></td>
                </tr>
                <tr>
                    <th>연체금액</th>
                    <td>0</td>
                    <td></td>
                </tr>
                <tr>
                    <th>납부분양금</th>
                    <td>64,800,000</td>
                    <td></td>
                </tr>
                <tr>
                    <th>납수금액</th>
                    <td>64,800,000</td>
                    <td></td>
                </tr>
                <tr>
                    <th>미수금</th>
                    <td>0</td>
                    <td></td>
                </tr>
            </table>
        </div>
    </v-container>
</template>

<style>
.list-gg .v-btn__content {align-items: flex-start; text-align:left; flex-flow:column;}
</style>

<script>
export default {
    components: { 
    },
    name: "SetStorageStatus",
    data(){
        return {
            items: [
                '2022.10.15',
                '2022.10.17',
            ],
            e1: '2022.10.15'
        }
    }
};
</script>
