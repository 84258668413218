<template>
  <v-container class="pd0">
    <div class="text-center" v-if="loadingBar">
      <v-progress-circular
        fullscreen
        indeterminate
        color="red"
        style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
      ></v-progress-circular>
    </div>

    <v-bottom-navigation v-model="value" grow app color="#F36628" v-if="switchMode">
      <v-btn value="contract" @click="moveto('/contract')" :ripple="false" :plain="true">
        <span class="mt-1 ds-b web-tools">계약/입주</span>
        <ContOn v-if="value === 'contract'" />
        <ContOff v-if="value !== 'contract'" />
      </v-btn>

      <!-- <v-btn
        value="homein"
        @click="moveto('/homein')"
        :ripple="false"
        :plain="true"
      >
        <span class="mt-1 ds-b web-tools">입주</span>
        <InOn v-if="value === 'homein'" />
        <InOff v-if="value !== 'homein'" />
      </v-btn> -->

      <v-btn value="dwelling" @click="moveto('/dwelling')" :ripple="false" :plain="true">
        <span class="mt-1 ds-b web-tools">린라이프</span>
        <DwellOn v-if="value === 'dwelling'" />
        <DwellOff v-if="value !== 'dwelling'" />
      </v-btn>

      <v-btn value="mypage" @click="moveto('/mypage')" :ripple="false" :plain="true">
        <span class="mt-1 ds-b web-tools">마이페이지</span>
        <MypageOn v-if="value === 'mypage'" />
        <MypageOff v-if="value !== 'mypage'" />
      </v-btn>

      <v-btn value="homeiot" @click="movetoFlutter()" :ripple="false" :plain="true" v-if="$store.state.auth.isLoggedIn && isSmartHome">
        <span class="mt-1 ds-b web-tools">스마트홈</span>
        <IotGoing />
      </v-btn>
    </v-bottom-navigation>

    <v-bottom-navigation v-model="value" grow app color="#F36628" v-if="!switchMode">
      <v-btn value="info" @click="moveto('/villageInfoList')" :ripple="false" :plain="true">
        <span class="mt-1 ds-b web-tools">단지안내</span>
        <ComguideOn v-if="value === 'info'" />
        <ComguideOff v-if="value !== 'info'" />
      </v-btn>

      <v-btn value="schedule" @click="moveto('/parcellingCalendar')" :ripple="false" :plain="true">
        <span class="mt-1 ds-b web-tools">분양일정</span>
        <MovedateOn v-if="value === 'schedule'" />
        <MovedateOff v-if="value !== 'schedule'" />
      </v-btn>

      <v-btn value="introduce" @click="moveto('/smartLynn')" :ripple="false" :plain="true">
        <span class="mt-1 ds-b web-tools">스마트린 소개</span>
        <MyselfOn v-if="value === 'introduce'" />
        <MyselfOff v-if="value !== 'introduce'" />
      </v-btn>

      <v-btn value="mypage" @click="moveto('/mypage')" :ripple="false" :plain="true">
        <span class="mt-1 ds-b web-tools">{{ $store.state.auth.isLoggedIn ? "마이페이지" : "로그인" }}</span>
        <MypageOn v-if="value === 'mypage'" />
        <MypageOff v-if="value !== 'mypage'" />
      </v-btn>

      <v-btn value="homeiot" @click="movetoFlutter()" :ripple="false" :plain="true" v-if="$store.state.auth.isLoggedIn && isSmartHome">
        <span class="mt-1 ds-b web-tools">스마트홈</span>
        <IotGoing />
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<style>
.v-item-group.v-bottom-navigation .v-btn {
  min-width: 56px;
}
</style>

<script>
import ContOn from "@/assets/images/common/btn-menu-cont_on.svg"; // 계약 on
import ContOff from "@/assets/images/common/btn-menu-cont_off.svg"; // 계약 off
import InOn from "@/assets/images/common/btn-menu-in_on.svg"; // 입주 on
import InOff from "@/assets/images/common/btn-menu-in_off.svg"; // 입주 off
import DwellOn from "@/assets/images/common/btn-menu-dwell_on.svg"; // 주거 on
import DwellOff from "@/assets/images/common/btn-menu-dwell_off.svg"; // 주거 off
import MypageOn from "@/assets/images/common/btn-menu-mypage_on.svg"; // 마이페이지 on
import MypageOff from "@/assets/images/common/btn-menu-mypage_off.svg"; // 마이페이지 off

import ComguideOn from "@/assets/images/common/btn-menu-comguide_on.svg"; // 단지안내 on
import ComguideOff from "@/assets/images/common/btn-menu-comguide_off.svg"; // 단지안내 off
import MovedateOn from "@/assets/images/common/btn-menu-movedate_on.svg"; // 분양일정 on
import MovedateOff from "@/assets/images/common/btn-menu-movedate_off.svg"; // 분양일정 off
import MyselfOn from "@/assets/images/common/btn-menu-myself_on.svg"; // 소개 on
import MyselfOff from "@/assets/images/common/btn-menu-myself_off.svg"; // 소개 off
import IotGoing from "@/assets/images/common/btn-menu-switch.svg"; // iot on

export default {
  components: {
    ContOn,
    ContOff,
    //InOn,
    //InOff,
    DwellOn,
    DwellOff,
    MypageOn,
    MypageOff,
    ComguideOn,
    ComguideOff,
    MovedateOn,
    MovedateOff,
    MyselfOn,
    MyselfOff,
    IotGoing,
  },
  name: "BottomNav",
  data() {
    return {
      value: "contract",
      loadingBar: false,
    };
  },
  methods: {
    moveto(path) {
      if (path == "/dwelling") {
        //this.user.is_accepted == true 이면 입주자로 간주됨
        if (!this.user.is_accepted) {
          this.$store.commit("SUCCESS", "입주자를 위한 서비스입니다.");
          return;
        }
      } else if (path == "/contract" && !this.user.is_verified) {
        this.$store.commit("SUCCESS", "계약자를 위한 서비스입니다.");
        return;
      }
      this.loadingBar = false;
      if (!path) return;
      else if (this.$route.path == path) return;

      this.$router.push({ path: path });
    },
    fallbackFunction() {
      //TODO : 안드,아이폰 구분해서 앱스토어 링크 걸어야됨~.~ (현재 링크 없어서..)

      let browserInfo = navigator.userAgent.toLowerCase();
      if (browserInfo.indexOf("android") > 0) {
        window.location.replace("market://details?id=com.woomi.lynnlife");
      } else if (browserInfo.indexOf("iphone") > 0 || browserInfo.indexOf("ipad") > 0) {
        window.location.replace("https://apps.apple.com/app/id1559671877");
      } else {
        this.$store.commit("SUCCESS", "모바일 스마트린 앱을 설치하여야 이용이 가능합니다.");
        return;
      }
    },
    movetoFlutter() {
      try {
        this.loadingBar = true;

        let browserInfo = navigator.userAgent.toLowerCase();
        if (browserInfo.indexOf("wikey_flutter") > 0) {
          // 웹뷰인 경우
          if (this.user.is_accepted) {
            window.flutter_inappwebview
              .callHandler("gotoSmartHome", this.$store.getters.getIdToken)
              .then(function(result) {
                this.loadingBar = false;
                console.log("@@@ flutterInAppWebViewPlatformReady gotoSmartHome  result: ", result);
              });
          } else {
            this.loadingBar = false;
            this.$store.commit("SUCCESS", "입주자인증신청 후 관리사무소로 승인요청해주세요.");
          }
        } else {
          // 모바일 웹
          window.location.href = "woomi://com.woomi.lynnlife";
          setTimeout(this.fallbackFunction, 250); // 설치된 어플이 없을 경우 market으로 이동
          this.loadingBar = false;
        }
      } catch (e) {
        this.loadingBar = false;
        console.log(e);
      }
    },
  },
  computed: {
    menu() {
      return this.$route.meta.activeMenu;
    },
    switchMode() {
      return this.$store.state.switchMode;
    },
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      return this.$store.getters.getHome;
    },
    tenantHome() {
      return this.$store.getters.getTenantHome;
    },
    isSmartHome() {
      if (this.tenantHome) {
        return this.tenantHome.is_iot_enabled;
      } else if (this.home) {
        return this.home.is_iot_enabled;
      } else {
        return true;
      }
    }
  },
  watch: {
    menu(val) {
      this.value = val;
    },
  },
  created() {
    this.value = this.menu;
  },
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  height: 56px;
}
.v-ripple__container {
  display: none !important;
}
</style>
