// Sign: 회원가입, 로그인 등
import login from "@/views/UserViews/Sign/Login.vue";
import signup from "@/views/UserViews/Sign/Signup.vue";
import signupAgreement from "@/views/UserViews/Sign/Signup_Agreement.vue";
import signupConfirm from "@/views/UserViews/Sign/SignupConfirm.vue";
import forgetPassword from "@/views/UserViews/Sign/ForgetPassword.vue";

// Mypage: 마이페이지
import mypage from "@/views/UserViews/Mypage/Mypage.vue";
import memberInfoUpdate from "@/views/UserViews/Mypage/MemberInfoUpdate.vue";
import familyMember from "@/views/UserViews/Mypage/FamilyMember.vue";
import withdrawal from "@/views/UserViews/Mypage/Withdrawal.vue";
import reservationList from "@/views/UserViews/Mypage/ReservationList.vue";
import ResaleReservationList from "@/views/UserViews/Mypage/ResaleReservationList.vue";
import moveinReservationList from "@/views/UserViews/Mypage/MoveinReservationList.vue";
import precheckReservationList from "@/views/UserViews/Mypage/PrecheckReservationList.vue";

var userRoutes = [
  // 회원가입, 로그인 등
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/signup",
    name: "signup",
    component: signup,
  },
  {
    path: "/signupAgreement",
    name: "signupAgreement",
    component: signupAgreement,
  },
  {
    path: "/confirm",
    name: "confirm",
    component: signupConfirm,
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: forgetPassword,
  },

  // 마이페이지
  {
    path: "/mypage",
    name: "mypage",
    component: mypage,
    meta: {
      header: {
        title: "마이페이지",
      },
      requiresAuth: true,
      activeMenu: "mypage",
      bottomNav: true,
    },
  },
  {
    path: "/memberInfoUpdate",
    name: "memberInfoUpdate",
    component: memberInfoUpdate,
    meta: {
      requiresAuth: true,
      activeMenu: "mypage",
      bottomNav: true,
    },
  },
  {
    path: "/familyMember",
    name: "familyMember",
    component: familyMember,
    meta: {
      requiresAuth: true,
      activeMenu: "mypage",
      bottomNav: true,
    },
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: withdrawal,
    meta: {
      requiresAuth: true,
      activeMenu: "mypage",
      bottomNav: true,
    },
  },

  {
    path: "/reservationList",
    name: "reservationList",
    component: reservationList,
    meta: {
      requiresAuth: true,
      bottomNav: true,
      header: {
        title: "당첨자 방문예약",
      },
    },
  },
  {
    path: "/resaleReservationList",
    name: "resaleReservationList",
    component: ResaleReservationList,
    meta: {
      requiresAuth: true,
      bottomNav: true,
      header: {
        title: "전매(명의변경) 방문예약",
      },
    },
  },
  {
    path: "/moveinReservationList",
    name: "moveinReservationList",
    component: moveinReservationList,
    meta: {
      requiresAuth: true,
      bottomNav: true,
      header: {
        title: "입주예약",
      },
    },
  },
  {
    path: "/precheckReservationList",
    name: "precheckReservationList",
    component: precheckReservationList,
    meta: {
      requiresAuth: true,
      bottomNav: true,
      header: {
        title: "사전점검 방문예약",
      },
    },
  },
];

export { userRoutes };
