<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">견본주택 방문예약</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <VisitReservationForm />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import VisitReservationForm from "@/components/Reservation/VisitReservationForm";
import Weblogo from "@/assets/images/common/new-logo.svg";

import { getReservationList, delReservation } from "@/api/index.js";
import Back from "@/assets/images/icon/icon-back.svg";

export default {
  name: "VisitReservation",
  components: {
    VisitReservationForm,
    Back,
    Weblogo,
  },
  data() {
    return {
      reservation_code: "VISIT_RESERVATION", // 예약 타입
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
