<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <div class="cs-center">
      <strong class="t-cont-title">도움말</strong>
      <ul class="list-box">
        <li>
          <router-link to="/faq" class="l-btn">도움말</router-link>
          <router-link to="/faq" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Faq /></span>
            </span>
          </router-link>
        </li>
        <li>
          <router-link to="/notice" class="l-btn">공지사항</router-link>
          <router-link to="/notice" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Notice /></span>
            </span>
          </router-link>
        </li>
      </ul>
      <strong class="t-cont-title">전화번호</strong>
      <div class="complete-box">
        <div class="comin-box">
          <div class="comin-cont">
            <strong class="text-left ds-b fts14 ftw4 lh24 color-6">A/S 대표문의</strong>
            <p class="text-left ds-b fts14 lh24 ftw5 color-1 mb0">1588-9707</p>
            <span class="text-left ds-b fts12 lh24 color-6"
              >평일 오전 9시 ~ 오후 5시<br />토, 일, 법정공휴일은 휴무</span
            >
          </div>
          <div class="comin-cont">
            <strong class="text-left ds-b fts14 ftw5 lh24 color-6 mb10">A/S 문의</strong>
            <p class="text-left ds-b fts14 lh24 ftw4 color-6 mb0">
              경기권 (수도권, 강원권)
            </p>
            <span class="text-left ds-b fts14 lh24 ftw5 color-6 mb10">1833-5369</span>
            <p class="text-left ds-b fts14 lh24 ftw4 color-6 mb0">
              중부권 (충청도, 대전, 세종)
            </p>
            <span class="text-left ds-b fts14 lh24 ftw5 color-6 mb10">1833-7949</span>
            <p class="text-left ds-b fts14 lh24 ftw4 color-6 mb0">
              호남권 (전라도, 광주)
            </p>
            <span class="text-left ds-b fts14 lh24 ftw5 color-6 mb10">1833-8812</span>
            <p class="text-left ds-b fts14 lh24 ftw4 color-6 mb0">
              영남권 (경상도, 대구, 부산)
            </p>
            <span class="text-left ds-b fts14 lh24 ftw5 color-6">1833-5026</span>
          </div>
          <div class="comin-cont">
            <p class="text-left ds-b fts14 lh24 ftw4 color-6 mb0">
              앱 사용문의
            </p>
            <span class="text-left ds-b fts14 lh24 ftw5 color-6 mb10">02-557-6575</span>
          </div>
        </div>
      </div>

      <div class="complete-box mt10">
        <div class="comin-box">
          <div class="comin-cont">
            <strong class="text-left ds-b fts14 ftw4 lh24 color-6"
              ><router-link to="/privatePolicy">개인정보 처리방침</router-link></strong
            >
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Faq from "@/assets/images/cs/faq.svg"; // 도움말,문의
import Notice from "@/assets/images/cs/notice.svg"; // 공지사항,자료실
import Vote from "@/assets/images/btn/btn-vote.svg";

export default {
  components: {
    Faq,
    Notice,
  },
  name: "CsCenter",
  data: () => ({}),
  computed: {
    is_development() {
      return process.env.NODE_ENV !== "production";
    },
    mobile_with_code() {
      return "+82" + this.form.mobile;
    },
  },
};
</script>
