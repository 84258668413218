// Contract: 계약
import contract from "@/views/ContractViews/Contract/Contract.vue";
import contractStatus from "@/views/ContractViews/Contract/ContractStatus.vue";
import contractRequest from "@/views/ContractViews/Contract/ContractRequest.vue";
import contractRequestView from "@/views/ContractViews/Contract/ContractRequestView.vue";
import contractInfo from "@/views/ContractViews/Contract/ContractInfo.vue";
import balancePayment from "@/views/ContractViews/Contract/BalancePayment.vue";

// 방문예약
import commonReservationForm from "@/views/ContractViews/Contract/CommonReservationForm.vue";
import commonReservationView from "@/views/ContractViews/Contract/CommonReservationView.vue";

// PreCheck: 사전점검 예약
import preCheck from "@/views/ContractViews/Contract/PreCheckReservation.vue";
import preCheckForm from "@/views/ContractViews/Contract/PreCheckReservationDetail.vue";
import resaleForm from "@/views/ContractViews/Contract/ResaleReservationDetail.vue";

// VisitReservation: 방문예약
import visitReservation from "@/views/ContractViews/Contract/VisitReservation.vue";
import visitReservationForm from "@/views/ContractViews/Contract/VisitReservationForm.vue";

// HomeIn: 입주
import homein from "@/views/ContractViews/HomeIn/Homein.vue";
import moveInReservationForm from "@/views/ContractViews/Contract/MoveInReservationForm.vue";
import moveInReservationView from "@/views/ContractViews/Contract/MoveInReservationView.vue";
import leaveRequest from "@/views/ContractViews/Contract/LeaveRequest.vue";

var contractRoutes = [
  // 계약
  {
    path: "/contract",
    name: "contract",
    component: contract,
    meta: {
      header: {
        title: "계약",
      },
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },
  {
    path: "/contractStatus",
    name: "contractStatus",
    component: contractStatus,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },
  {
    path: "/contractRequest",
    name: "contractRequest",
    component: contractRequest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractRequestView",
    name: "contractRequestView",
    component: contractRequestView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contractInfo/:tab_id/:sub_tab_id",
    name: "contractInfo",
    component: contractInfo,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },
  {
    path: "/balancePayment",
    name: "balancePayment",
    component: balancePayment,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },

  // 마스터 지정 예약
  {
    path: "/reservation/:master_id",
    name: "Reservation",
    component: commonReservationForm,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },

  // 방문 예약
  {
    path: "/commonReservationForm",
    name: "commonReservationForm",
    component: commonReservationForm,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },
  {
    path: "/commonReservationView",
    name: "commonReservationView",
    component: commonReservationView,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },

  // 사전점검 예약
  {
    path: "/preCheck",
    name: "preCheck",
    component: preCheck,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },
  {
    path: "/preCheckForm/:id",
    name: "preCheckForm",
    component: preCheckForm,
    meta: {
      requiresAuth: true,
      bottomNav: true,
    },
  },

  // 전매 방문예약
  {
    path: "/resaleForm/:id",
    name: "resaleForm",
    component: resaleForm,
    meta: {
      requiresAuth: true,
      bottomNav: true,
    },
  },

  // 방문예약
  {
    path: "/visitReservation",
    name: "visitReservation",
    component: visitReservation,
    meta: {
      requiresAuth: true,
      bottomNav: true,
    },
  },
  {
    path: "/visitReservationForm",
    name: "visitReservationForm",
    component: visitReservationForm,
    meta: {
      requiresAuth: true,
      bottomNav: true,
    },
  },

  // 입주
  {
    path: "/moveInReservationForm",
    name: "moveInReservationForm",
    component: moveInReservationForm,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },
  {
    path: "/moveInReservationView",
    name: "moveInReservationView",
    component: moveInReservationView,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },

  // 퇴거 신청
  {
    path: "/leaveRequest",
    name: "leaveRequest",
    component: leaveRequest,
    meta: {
      requiresAuth: true,
      activeMenu: "contract",
      bottomNav: true,
    },
  },
];

export { contractRoutes };
