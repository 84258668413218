<template>
  <v-container class="vh-full ps-re lynn-mobile" fluid>
    <v-img :src="require('@/assets/contract_status.png')" contain />
  </v-container>
</template>

<script>
export default {
  components: {},
  name: "ContractStatus",
};
</script>
