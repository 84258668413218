<template>
  <div class="btn-itembox">
    <a
      href="#n"
      class="com-btn"
      v-if="village.id != 4489"
      @click="clickInterested(village.id)"
    >
      <span class="icon">
        <v-icon color="#f36628" v-if="checkInterested(village.id)"
          >mdi-check</v-icon
        >
        <!-- <Iconheart v-else /> -->
        <IconheartOff v-else />
      </span>
      <span class="text" v-if="checkInterested(village.id)">내 관심단지</span>
      <span class="text" v-else>관심고객등록</span>
    </a>
    <a
      href="#n"
      class="com-btn"
      v-if="village.id != 4489"
      @click="moveTo('visitReservationForm')"
    >
      <span class="icon"><Iconbild /></span>
      <span class="text">견본주택 방문</span>
    </a>
    <a href="#n" class="com-btn">
      <span class="icon"><Iconcall /></span>
      <a class="text" :href="`tel:${village.contact}`">{{
        village.contact ? village.contact : "-"
      }}</a>
    </a>
    <a @click="mapLink(village.map_link)" class="com-btn">
      <span class="icon"><Iconmap /></span>
      <span class="text">지도보기</span>
    </a>
    <a
      class="com-btn"
      @click="moveTo('moveInReservationForm')"
      v-if="village.reservations.MOVEIN_RESERVATION == 'ON'"
    >
      <span class="icon"><Iconmap /></span>
      <span class="text">입주예약</span>
    </a>

    <v-dialog v-model="interestDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          로그인 후 사용 가능합니다.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="interestDialog = false">
            취소
          </v-btn>
          <v-btn color="primary darken-1" text @click="goLogin()">
            로그인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Iconheart from "@/assets/images/icon/icon-heart.svg";
import IconheartOff from "@/assets/images/icon/icon-heart_off.svg";
import Iconbild from "@/assets/images/icon/icon-bild.svg";
import Iconcall from "@/assets/images/icon/icon-call.svg";
import Iconmap from "@/assets/images/icon/icon-map.svg";
import { postInterestedCustomer, delInterestedCustomer } from "@/api/index.js";

export default {
  components: {
    // Iconheart,
    IconheartOff,
    Iconbild,
    Iconcall,
    Iconmap,
  },
  props: ["village"],
  name: "CorporCom",
  data() {
    return {
      interestDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    interestedList() {
      return this.$store.state.data.interestedList;
    },
  },
  methods: {
    mapLink(map_link) {
      window.open(map_link);
    },
    checkInterested(id) {
      let interestedId = 0;
      this.interestedList.forEach((item) => {
        if (item.village == id) {
          interestedId = item.id;
        }
      });
      return interestedId;
    },
    clickInterested(id) {
      let interestedId = this.checkInterested(id);

      if (!this.user) {
        // 비회원
        this.interestDialog = true;
      } else if (interestedId) {
        // 삭제
        delInterestedCustomer(interestedId).then((res) => {
          this.$store.dispatch("GET_INTERESTED_LIST");
          this.$store.commit("SUCCESS", "관심단지가 취소 되었습니다.");
        });
      } else {
        // 추가
        postInterestedCustomer(this.user.id, id).then((res) => {
          this.$store.dispatch("GET_INTERESTED_LIST");
          this.$store.commit("SUCCESS", "관심단지로 등록 되었습니다.");
        });
      }
    },
    goLogin() {
      this.$router.push({ name: "login" });
    },
    moveTo(path) {
      this.$emit("refresh");

      if (path == "visitReservationForm") {
        /*
            reservation의 상태 
            reservations = {
                VISIT_RESERVATION:ON or OFF or NONE
                ...
            }
            ON: 예약 가능
            OFF: 예약 기간 외
            NONE: 예약이 존재하지 않음 (견본주택이 없을 경우 등)
        */
        if (this.village.reservations.VISIT_RESERVATION == "YET") {
          this.$store.commit("ERROR", "방문예약 가능한 시간이 아닙니다.");
          return;
        } else if (this.village.reservations.VISIT_RESERVATION == "OFF") {
          this.$store.commit("ERROR", "방문예약 기간이 종료되었습니다.");
          return;
        } else if (this.village.reservations.VISIT_RESERVATION == "NONE") {
          this.$store.commit(
            "ERROR",
            "해당 단지는 현재 방문예약을 지원하지 않습니다."
          );
          return;
        }
      } else if (path == "moveInReservationForm") {
        if (this.village.reservations.MOVEIN_RESERVATION == "OFF") {
          this.$store.commit("ERROR", "입주예약 기간이 종료되었습니다.");
          return;
        } else if (this.village.reservations.MOVEIN_RESERVATION == "NONE") {
          this.$store.commit(
            "ERROR",
            "해당 단지는 현재 입주예약을 지원하지 않습니다."
          );
          return;
        }
      }

      this.$router.push({
        name: path,
        params: {
          id: 0,
          selectVillageId: this.village.id,
          village_name: this.village.village_name,
        },
      });
    },
    created() {
      if (this.user) {
        this.$store.dispatch("GET_INTERESTED_LIST");
      }
    },
  },
};
</script>
