<template>
  <div class="corona-text">
    <strong>방문예약 안내사항</strong>
    <p>
      코로나19확산 방지 및 안전하고 쾌적한 관람과 상담 서비를 위해서 사전
      예약제를 진행하고 있습니다.
    </p>
    <ul class="list-set">
      <li class="list-title">유의사항</li>
      <li>예약문자 확인 및 체온 측정 후 입장 가능</li>
      <li>입장제한: 마스크 미착용, 37.5도 이상 발열고객</li>
      <li>방역절차 미협조 고객, 예약시간 경과 고객</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CoronaMessage",
  data: () => ({}),
};
</script>
