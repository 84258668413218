import { AuthConnection } from "@/api/config.js";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { serverSignUp, serverSignIn, serverSignOut } from "@/api/sign";
import store from "../actions";

// cognitoPool
const cognitoUserPool = new CognitoUserPool({
  UserPoolId: process.env.VUE_APP_USER_POOL_ID,
  ClientId: process.env.VUE_APP_CLIENT_ID,
});

// cognito user
function constructUser(cognitoUser, session) {
  return {
    username: cognitoUser.getUsername(),
    tokens: {
      IdToken: session.getIdToken().getJwtToken(),
      AccessToken: session.getAccessToken().getJwtToken(),
      RefreshToken: session.getRefreshToken().getToken(),
    },
    attributes: {},
  };
}

function getHome(state) {
  /*  
        현재 사용자가 선택한 세대 정보는 아래와 같이, 계약자인가 거주자인가, 세대 관리인인가
        등등의 정보를 가지고 있어야함.
        
        selected_home 을 해당 Object로 구현 시, 관련 데이터 변경시마다 업데이트 해줘야 하고,
        이에대한 데이터 불일치를 방지하기 위해 selected_home 은 object 가 아닌 id로 구현하였음.

        호출 시, user.home_list 를 살펴 지정한 id가 있는지 확인하여 불러옴.
        (user데이터는 관련 정보 변경시마다 refresh 되고 있음.)
    
        return {
                id:1,
                is_contractor:ture, 계약자 여부
                is_tenant:false,    입주자 여부
                is_host:false,      세대 관리원
                village:1,          단지 id
                erp_village_id:1,   erp 단지 id
                ...
            }
            또는 null
  */
  if (!state.user) return null;

  try {
    // 기본 적으로는 첫 번째 home
    let home = state.user.home_list[0];
    // 지정된 home 이 있을 시 업데이트
    state.user.home_list.forEach((item) => {
      if (item.id == state.selected_home) home = item;
    });
    return home;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default {
  state: {
    // Cognito
    cognitoUser: null, // Cognito user
    isLoggedIn: false,

    // Server
    user: null, // Server user
    selected_home: null,
    expiredAt: null
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getHome(state) {
      return getHome(state);
    },
    getTenantHome(state) {
      return state.user.home_list.find((item) => {
        return item.is_tenant;
      });
    },
    getHomeList(state) {
      return state.user.home_list;
    },
    getContractList(state) {
      return state.user.home_list.filter((item) => {
        return item.is_contractor;
      });
    },
    getTenantList(state) {
      return state.user.home_list.filter((item) => {
        return item.is_tenant;
      });
    },
    getUserState(state) {
      /*
        1. 'NONMEMBER' - 비회원
        2. 'MEMBER' - 회원
        3. 'CONTRACTOR' - 계약자
        4. 'TENANT' - 입주자

        Explanation:
            해당 세대에 계약자이며 입주자이기도 할 수 있기에,
            3,4 의 체크순서는 보장되어야 한다.
      */
      const home = getHome(state);
      if (!state.user) return "NONMEMBER";
      else if (!home) return "MEMBER";
      else if (home.is_tenant) {
        // 승인(관리사무소 또는 세대관리자로부터) 난 유저에 대해서.
        if (state.user.is_accepted) return "TENANT";
        else if (home.is_contractor) return "CONTRACTOR";
      } else if (home.is_contractor) return "CONTRACTOR";
      return "MEMBER";
    },
  },
  mutations: {
    CLEAR(state) {
      state.cognitoUser = null;
      state.isLoggedIn = false;
      state.selected_home = null;
      state.user = null;
      AuthConnection.defaults.headers.Authorization = null;
    },
    SET_COGNITO_USER(state, cognitoUser) {
      state.cognitoUser = cognitoUser;
      if (cognitoUser == null) state.isLoggedIn = false;
      else state.isLoggedIn = true;
    },
    SET_USER(state, user) {
      /*
            Server 에서 불러온 유저 정보를 가져온다.

            home_list: 사용자가 보유한(계약, 입주) 목록을 불러온다.
                ex) 
                    [
                        {id:1, village:1, erp_village_id:1, is_rent:true, ...},
                        {id:1, village:1, erp_village_id:1, is_rent:false, ...}
                    ]

            selected_home: 여러 세대 중 현재 선택한 세대.
                1. 최초 미 지정시에는 목록중 첫 번째를 지정한다.
                2. 마이페이지 - > 다른 세대 지정 시 업데이트
                3. id 값만 가지고 있으며, 상세정보는 home_list 에서 참조하여 사용.(데이터 변경에 용이하도록)
        */
      state.user = user;
      let now = new Date();
      state.expiredAt = new Date(now.getTime() + 15 * 60000).toString();
    },
    SET_HOME(state, id) {
      state.selected_home = id;
    },
  },
  actions: {
    /* cognito 로그인 */
    authenticateUser({ commit }, payload) {
      const authDetails = new AuthenticationDetails({
        Username: payload.email,
        Password: payload.password,
      });

      const cognitoUser = new CognitoUser({
        Pool: cognitoUserPool,
        Username: payload.email,
      });

      return new Promise((resolve, reject) =>
        cognitoUser.authenticateUser(authDetails, {
          onFailure: (err) => {
            serverSignOut();
            reject(err);
          },
          onSuccess: function (result) {
            commit("SET_COGNITO_USER", constructUser(cognitoUser, result));
            serverSignIn({
              email: payload.email,
              password: payload.password,
              token: result.getIdToken().getJwtToken(),
              fcm_token: payload.fcm_token,
            }).then((res) => {
              commit("SET_USER", res.data);
              resolve(res);
            });
          },
        })
      );
    },
    /* 로그아웃 */
    signOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (
          state.cognitoUser === null ||
          (state.cognitoUser && state.cognitoUser.tokens === null)
        ) {
          console.log("there is no user session.");
        } else {
          const cognitoUser = new CognitoUser({
            Pool: cognitoUserPool,
            Username: state.cognitoUser.username,
          });
          cognitoUser.signOut();
        }
        serverSignOut();

        commit("CLEAR");
        resolve();
      });
    },
    /* 회원가입 */
    signUp({ commit }, userInfo) {
      // phone_number, email
      const userAttributes = Object.keys(userInfo.attributes || {}).map(
        (key) =>
          new CognitoUserAttribute({
            Name: key,
            Value: userInfo.attributes[key],
          })
      );

      return new Promise((resolve, reject) => {
        cognitoUserPool.signUp(
          userInfo.username,
          userInfo.password,
          userAttributes,
          null,
          (err, data) => {
            if (!err) {
              commit("SET_COGNITO_USER", {
                username: data.user.getUsername(),
                tokens: {
                  IdToken: null,
                  AccessToken: null,
                  RefreshToken: null,
                },
                attributes: {},
              });
              resolve(data);
              return;
            }
            reject(err);
          }
        );
      });
    },
    /*  인증코드 확인*/
    confirmRegistration({ commit }, payload) {
      const cognitoUser = new CognitoUser({
        Pool: cognitoUserPool,
        Username: payload.username,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(payload.code, true, (err) => {
          if (!err) {
            serverSignUp(payload.userInfo);
            resolve();
            return;
          }
          reject(err);
        });
      });
    },
    /* 인증코드 재전송 */
    resendConfirmationCode({ commit }, payload) {
      const cognitoUser = new CognitoUser({
        Pool: cognitoUserPool,
        Username: payload.username,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode((err) => {
          if (!err) {
            resolve();
            return;
          }
          reject(err);
        });
      });
    },
    /*  비밀번호 찾기 */
    forgotPassword({ commit }, payload) {
      const cognitoUser = new CognitoUser({
        Pool: cognitoUserPool,
        Username: payload.username,
      });

      return new Promise((resolve, reject) =>
        cognitoUser.forgotPassword({
          onSuccess() {
            resolve();
          },
          onFailure(err) {
            reject(err);
          },
        })
      );
    },
    /* 비밀번호 찾기 - 인증코드 입력 */
    confirmPassword({ commit }, payload) {
      const cognitoUser = new CognitoUser({
        Pool: cognitoUserPool,
        Username: payload.username,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(payload.code, payload.newPassword, {
          onFailure(err) {
            reject(err);
          },
          onSuccess() {
            resolve();
          },
        });
      });
    },
    /* 비밀번호 변경 */
    changePassword({ state }, payload) {
      return new Promise((resolve, reject) => {
        if (
          state.cognitoUser === null ||
          (state.cognitoUser && state.cognitoUser.tokens === null)
        ) {
          reject({
            message: "User is unauthenticated",
          });
          return;
        }

        const cognitoUser = new CognitoUser({
          Pool: cognitoUserPool,
          Username: state.cognitoUser.username,
        });

        cognitoUser.signInUserSession = cognitoUser.getCognitoUserSession(
          state.cognitoUser.tokens
        );

        cognitoUser.changePassword(
          payload.oldPassword,
          payload.newPassword,
          (err) => {
            if (!err) {
              resolve();
              return;
            }
            reject(err);
          }
        );
      });
    },
    /* updateAttributes */
    updateAttributes({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (
          state.cognitoUser === null ||
          (state.cognitoUser && state.cognitoUser.tokens === null)
        ) {
          reject({
            message: "User is unauthenticated",
          });
          return;
        }

        const cognitoUser = new CognitoUser({
          Pool: cognitoUserPool,
          Username: state.cognitoUser.username,
        });

        cognitoUser.signInUserSession = cognitoUser.getCognitoUserSession(
          state.cognitoUser.tokens
        );
        const attributes = Object.keys(payload || {}).map(
          (key) =>
            new CognitoUserAttribute({
              Name: key,
              Value: payload[key],
            })
        );

        cognitoUser.updateAttributes(attributes, (err) => {
          if (!err) {
            resolve();
            return;
          }
          reject(err);
        });
      });
    },
    /* getUserAttributes */
    getUserAttributes({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (
          state.cognitoUser === null ||
          (state.cognitoUser && state.cognitoUser.tokens === null)
        ) {
          reject({
            message: "User is unauthenticated",
          });
          return;
        }

        const cognitoUser = new CognitoUser({
          Pool: cognitoUserPool,
          Username: state.cognitoUser.username,
        });

        cognitoUser.signInUserSession = cognitoUser.getCognitoUserSession(
          state.cognitoUser.tokens
        );

        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
            return;
          }

          const attributesMap = (attributes || []).reduce((accum, item) => {
            accum[item.Name] = item.Value;
            return accum;
          }, {});

          resolve(attributesMap);
        });
      });
    },

    refreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
          Pool: cognitoUserPool,
          Username: state.cognitoUser.username,
        });

        cognitoUser.getSession(function (err, session) {
          var refresh_token = session.getRefreshToken();
          cognitoUser.refreshSession(refresh_token, (err, session) => {
            if (err) {
              console.log(err);
            } else {
              console.log(session);
              commit("SET_COGNITO_USER", constructUser(cognitoUser, session));
              resolve(session);
            }
          });
        });
      });
    },
    getAttributeVerificationCode({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
          Pool: cognitoUserPool,
          Username: state.cognitoUser.username,
        });

        cognitoUser.getSession(function (err, session) {
          cognitoUser.getAttributeVerificationCode(payload.attributeName, {
            onFailure: (err) => {
              reject(err);
            },
            onSuccess: (success) => {
              resolve(success);
            },
          });
        });
      });
    },
    verifyAttribute({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
          Pool: cognitoUserPool,
          Username: state.cognitoUser.username,
        });

        cognitoUser.getSession(function (err, session) {
          cognitoUser.verifyAttribute(
            payload.attributeName,
            payload.verificationCode,
            {
              onFailure: (err) => {
                reject(err);
              },
              onSuccess: (success) => {
                resolve(success);
              },
            }
          );
        });
      });
    },
  },
};
