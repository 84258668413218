//퍼블리싱 라우터 임포트!
import Pslogin from "@/publish/Login.vue";
import dashboard from "@/views/dashboard.vue";
import preCompleteublish from "@/publish/PreCompleteublish.vue";
import waitFormPublish from "@/publish/WaitForm.vue";
import waitcomPublish from "@/publish/WaitCom.vue";
import ContractingPublish from "@/publish/Contracting.vue";
import ContractingComPublish from "@/publish/ContractingCom.vue";
import ContractInfoOut from "@/publish/ContractInfoOut.vue";
import ContractInfoLease from "@/publish/ContractInfoLease.vue";
import SetUnhistoryOpen from "@/publish/ContractInfoLease/SetUnhistoryOpen.vue";

import Balance from "@/publish/BalancePayment.vue";

import VoteList from "@/publish/Vote/VoteList.vue";
import VoteForm from "@/publish/Vote/VoteForm.vue";


// import LoungeList from "@/publish/Lounge/LoungeList.vue";
// import LoungeView from "@/publish/Lounge/LoungeView.vue";
// import LoungeWrite from "@/publish/Lounge/LoungeWrite.vue";
// import LoungeDec from "@/publish/Lounge/LoungeDec.vue";

// 관리비 Expenses
import Expenses from "@/publish/Expenses/Expenses.vue";
import ExpensesDetail from "@/publish/Expenses/ExpensesDetail.vue";

// 신규 주거 메인
import DwellingIntro from "@/publish/Dwelling/DwellingIntro.vue";

// 임대허브 메인
import LeaseMain from "@/publish/hub/index.vue";

// 휴면 계정 화면
import Dormant from "@/views/UserViews/Mypage/dormant.vue";

// 독서실 화면
import ReadingRoom from "@/publish/ReadingRoom/ReadingRoom.vue"
import ReadingRoom2 from "@/publish/ReadingRoom/ReadingRoom2.vue"

// 독서실 화면 - 파주
import PAJU1 from "@/publish/ReadingRoom/ReadingRoom-paju1.vue"
import PAJU2 from "@/publish/ReadingRoom/ReadingRoom-paju2.vue"

//하자 점검 관련 추가 화면
import Inspection from "@/publish/Inspection/inspectionAdd.vue"
import InspectionList from "@/publish/Inspection/inspectionList.vue"

import Defects from "@/publish/Defects/DefectsAdd.vue"
import DefectsList from "@/publish/Defects/DefectsList.vue"

var publishRoutes = [
  //퍼블리싱 라우터 패스
  {
    path: "/publish/login",
    name: "PsLogin",
    component: Pslogin,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: dashboard,
    meta: {
      requiresAuth: true,
      bottomNav: true,
    },
  },
  {
    path: "/precom",
    name: "precom",
    component: preCompleteublish,
  },
  {
    path: "/waitFormPublish",
    name: "waitFormPublish",
    component: waitFormPublish,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/waitcomPublish",
    name: "waitcomPublish",
    component: waitcomPublish,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ContractingPublish",
    name: "ContractingPublish",
    component: ContractingPublish,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ContractingComPublish",
    name: "ContractingComPublish",
    component: ContractingComPublish,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ContractInfoOut",
    name: "ContractInfoOut",
    component: ContractInfoOut,
    meta: {
      requiresAuth: true,
      bottomNav: true,
    },
  },
  {
    path: "/ContractInfoLease",
    name: "ContractInfoLease",
    component: ContractInfoLease,
    meta: {
      requiresAuth: true,
      bottomNav: true,
    },
  },
  {
    path: "/SetUnhistoryOpen",
    name: "SetUnhistoryOpen",
    component: SetUnhistoryOpen,
    meta: {
      requiresAuth: true,
      bottomNav: true,
    },
  },

  {
    path: "/Balance",
    name: "Balance",
    component: Balance,
    meta: {
      bottomNav: true,
    },
  },

  {
    path: "/Expenses",
    name: "Expenses",
    component: Expenses,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/ExpensesDetail",
    name: "ExpensesDetail",
    component: ExpensesDetail,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/DwellingIntro",
    name: "DwellingIntro",
    component: DwellingIntro,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/LeaseMain",
    name: "LeaseMain",
    component: LeaseMain,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/Dormant",
    name: "Dormant",
    component: Dormant,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/ReadingRoom",
    name: "ReadingRoom",
    component: ReadingRoom,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/ReadingRoom2",
    name: "ReadingRoom2",
    component: ReadingRoom2,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/paju1",
    name: "paju1",
    component: PAJU1,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/paju2",
    name: "paju21",
    component: PAJU2,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/inspection",
    name: "inspection",
    component: Inspection,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/inspectionList",
    name: "inspectionList",
    component: InspectionList,
    meta: {
      bottomNav: true,
      activeMenu: "contract",
    },
  },

  {
    path: "/defects",
    name: "defects",
    component: Defects,
    meta: {
      bottomNav: false,
    },
  },

  {
    path: "/defectsList",
    name: "defectsList",
    component: DefectsList,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },

];

export { publishRoutes };
