<template>
    <v-container class="bg-gray vh-footer pd20">
        <v-card max-width="750" class="mx-auto bg-gray nops">
            <v-card-title class="justify-center color-1 fts18 mb">
                계약신청
            </v-card-title>
            <div class="complete-box">
                <div class="conin-tbtn text-right mb10">
                    <a href="#n">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <path id="Icon_material-add-circle-outline" data-name="Icon material-add-circle-outline" d="M10.7,6.5H9.3V9.3H6.5v1.4H9.3v2.8h1.4V10.7h2.8V9.3H10.7ZM10,3a7,7,0,1,0,7,7A7,7,0,0,0,10,3Zm0,12.6A5.6,5.6,0,1,1,15.6,10,5.607,5.607,0,0,1,10,15.6Z" transform="translate(-3 -3)" fill="#f36628"/>
                        </svg>
                        <span class="ds-ib v_m lh14 fts14 ftw5 ml5">계약신청 추가</span>
                    </a>
                </div>
                <div class="comin-box">
                    <div class="comin-cont">
                        <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">계약신청 단지</strong>
                        <span class="text-left ds-b fts14 lh24 color-5">충북 진천군 덕산면 대하로 150 우미 린스테이</span>
                    </div>
                    <div class="comin-cont">
                        <div class="comin-fbox">
                            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">동호수</strong>
                            <span class="text-left ds-b fts14 lh24 color-5">408동 102호</span>
                        </div>
                        <div class="comin-fbox">
                            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">주택형</strong>
                            <span class="text-left ds-b fts14 lh24 color-5">84Bm²</span>
                        </div>
                    </div>
                    <div class="comin-cont">
                        <div class="comin-fbox">
                            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">임대보증금</strong>
                            <span class="text-left ds-b fts14 lh24 color-5">74,700,000 원</span>
                        </div>
                        <div class="comin-fbox">
                            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">월 임대료</strong>
                            <span class="text-left ds-b fts14 lh24 color-5">359,000 원</span>
                        </div>
                    </div>
                    <div class="comin-cont">
                        <div class="comin-fbox">
                            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">신청자</strong>
                            <span class="text-left ds-b fts14 lh24 color-5">김지원</span>
                        </div>
                        <div class="comin-fbox">
                            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">전화번호</strong>
                            <span class="text-left ds-b fts14 lh24 color-5">010-1234-1234</span>
                        </div>
                    </div>
                    <div class="comin-cont text-right pb15">
                        <a href="#n" class="color-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.889" height="14" viewBox="0 0 10.889 14">
                                <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.278,16.945A1.56,1.56,0,0,0,9.833,18.5h6.222a1.56,1.56,0,0,0,1.556-1.556V7.611H8.278ZM18.389,5.278H15.667L14.889,4.5H11l-.778.778H7.5V6.833H18.389Z" transform="translate(-7.5 -4.5)" fill="#3c3c3c"/>
                            </svg>
                            <span class="ds-ib v_m lh14 fts14 ftw5 ml5">취소하기</span>
                        </a>
                        <a href="#n" class="ml25">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.003" height="14.003" viewBox="0 0 14.003 14.003">
                                <path id="Icon_material-mode-edit" data-name="Icon material-mode-edit" d="M4.5,15.583V18.5H7.417l8.6-8.6L13.1,6.98ZM18.276,7.641a.775.775,0,0,0,0-1.1l-1.82-1.82a.775.775,0,0,0-1.1,0L13.935,6.147l2.917,2.917Z" transform="translate(-4.5 -4.496)" fill="#f36628"/>
                            </svg>
                            <span class="ds-ib v_m lh14 fts14 ftw5 ml5">상세보기·수정</span>
                        </a>
                    </div>
                </div>

                <div class="complete-box mt20"> <!--예제 이미로 적용시는 mt20 제거-->
                    <div class="comin-box">
                        <div class="comin-cont no-mes">
                            <span class="text-center ds-b fts14 lh24 color-5">신청내역이 없습니다</span>
                        </div>
                    </div>
                </div>
            </div>
            <v-btn
                color="primary"
                class="fts18 ftw7 mw-400 pwidth100 mt15"
                min-height="50"
                block
                >
                확인
            </v-btn>
        </v-card>
    </v-container>
</template>

<script>
export default {
  name: "WaitCom",
  components: {
  },
  data() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
  created() {
  },
};
</script>
