<template>
  <v-container class="vh-footer pd20 bg-gray lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.push({ name: 'faq' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">도움말</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text class="pd0">
        <strong class="mb3 ds-b fts14 lh20 color-9 ftw4 text-left">{{
          item.type_sub_name
        }}</strong>
        <ul class="board-list mb15">
          <li>
            <router-link to="/faq">Q. {{ item.title }}</router-link>
            <p v-html="item.parsed_contents"></p>
          </li>
        </ul>
        <div class="out-link">
          <router-link :to="`/faq?type_sub_code=${item.type_sub_code}`"
            >{{ item.type_sub_name }} 관련 질문 모두 보기</router-link
          >
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import { getBulletin } from "@/api/index";

export default {
  name: "Faq",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      item: {},
    };
  },
  computed: {},
  methods: {
    getBulletinAPI(id) {
      getBulletin(id).then((res) => {
        console.log(res.data);
        this.item = res.data;
      });
    },
  },
  created() {
    this.getBulletinAPI(this.$route.params.id);
  },
};
</script>
