<template>
    <v-container class="vh-footer ps-re lynn-mobile pd0" fluid>
        <v-container class="pb0">
            <v-row>
                <v-col class="ft-c">
                    <Nav class="mt14" />
                    <strong class="ds-b ft-l fts21 ftw5 ml15 lh48">계약정보</strong>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="pd0">
            <div class="tabs-box">
                <ul class="tab-menu">
                    <li v-bind:class="{active:openTab===0}">
                        <a href="#n" v-on:click="openTab = 0">계약현황</a>
                    </li>
                    <li v-bind:class="{active:openTab===1}">
                        <a href="#n" v-on:click="openTab = 1">보증금·임대료</a>
                    </li>
                    <li v-bind:class="{active:openTab===2}">
                        <a href="#n" v-on:click="openTab = 2">계약변경·갱신</a>
                    </li>
                    <li v-bind:class="{active:openTab===3}">
                        <a href="#n" v-on:click="openTab = 3">계약 해지</a>
                    </li>
                </ul>
            </div>
            <div class="tab-contents">
                <div v-if="openTab == 0">
                    <SetInfo/>
                </div>
                <div v-if="openTab == 1">
                    <div class="tabs-box">
                        <ul class="tab-menu">
                            <li v-bind:class="{active2:openTab21===0}">
                                <a href="#n" v-on:click="openTab21 = 0">보증금 납부내역</a>
                            </li>
                            <li v-bind:class="{active2:openTab21===1}">
                                <a href="#n" v-on:click="openTab21 = 1">임대료 납부/미납 내역</a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-contents">
                        <div v-if="openTab21 == 0">
                            <SetHistory/>
                        </div>
                        <div v-if="openTab21 == 1">
                            <SetUnhistory/>
                        </div>
                    </div>
                </div>
                <div v-if="openTab == 2">
                    <div class="tabs-box">
                        <ul class="tab-menu">
                            <li v-bind:class="{active2:openTab22===0}">
                                <a href="#n" v-on:click="openTab22 = 0">계약변경</a>
                            </li>
                            <li v-bind:class="{active2:openTab22===1}">
                                <a href="#n" v-on:click="openTab22 = 1">계약 갱신</a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-contents">
                        <div v-if="openTab22 == 0">
                            <SetChange/>
                        </div>
                        <div v-if="openTab22 == 1">
                            <SetRenewal/>
                        </div>
                    </div>
                </div>
                <div v-if="openTab == 3">
                    <SetTermination/>
                </div>
            </div>
        </v-container>
    </v-container>
</template>

<style>
.sel-cus .v-text-field__details {min-height:auto;}
.sel-cus .v-input__slot {margin:0;}
.sel-cus .v-input input {max-height:40px;}
.sel-cus .v-input .v-label {text-align:center;}
.sel-cus .v-select__selection {max-width:100%; width:100%; padding-left:28px; text-align:center;}
</style>

<script>
import Nav from "@/components/nav";
import SetInfo from "@/publish/ContractInfoLease/SetInfo"; // 계약현황
import SetHistory from "@/publish/ContractInfoLease/SetHistory"; // 납부내역
import SetUnhistory from "@/publish/ContractInfoLease/SetUnhistory"; // 미납내역
import SetChange from "@/publish/ContractInfoLease/SetChange"; // 계약변경
import SetRenewal from "@/publish/ContractInfoLease/SetRenewal"; // 계약갱신
import SetTermination from "@/publish/ContractInfoLease/SetTermination"; // 계약해지

export default {
    components: { 
        Nav,
        SetInfo,
        SetHistory,
        SetUnhistory,
        SetChange,
        SetRenewal,
        SetTermination,
    },
    name: "Contract",
    data(){
        return {
            openTab: 0,
            openTab21: 0,
            openTab22: 0,
            items: [
                '우미린 1차 201동3201호',
                '우미린 파주 운정 단지',
            ],
            e1: '우미린 1차 201동3201호'
        }
    }
};
</script>
