<template>
  <v-container class="bg-gray vh-full pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">인증코드 확인</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text v-if="userData != null">
        입력하신 휴대폰 번호로({{ userData.mobile | hyphen }}) 인증코드를 보냈습니다. 인증코드 수신이 안 될 경우
        스팸메시지함을 확인하세요<br />
      </v-card-text>
      <v-card-text>
        <v-form>
          <v-text-field v-model.trim="email" label="아이디(이메일)" disabled></v-text-field>
          <v-text-field v-model.trim="code" label="인증코드"></v-text-field>
        </v-form>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <v-card-actions class="justify-center">
        <v-btn width="180px" @click="reConfirm()" elevation="0">
          인증코드 다시 보내기
        </v-btn>
      </v-card-actions>
      <v-card-actions class="justify-center">
        <v-btn color="primary" min-width="200" @click="confirmCode()" elevation="0" block>
          인증하기
        </v-btn>
      </v-card-actions>
      <v-dialog v-model="loginDialog" max-width="290">
        <v-card>
          <v-card-title class="justify-center"> </v-card-title>
          <v-card-text class="text-left ftw7">
            회원가입이 완료되었습니다. 가입하신 아이디로 로그인 해주세요.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="goLogin()">
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { emailConfirm } from "@/api/sign";

import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

export default {
  components: { Back, Weblogo },
  name: "ConfirmPage",
  data() {
    return {
      email: this.$route.params.email,
      code: "",
      userData: this.$route.params.userData,
      loginDialog: false,
    };
  },
  methods: {
    confirmCode() {
      this.$store
        .dispatch("confirmRegistration", {
          username: this.email,
          code: this.code,
          userInfo: this.userData,
        })
        .then(() => {
          this.loginDialog = true;
        })
        .catch((err) => {
          this.$store.commit("ERROR", "인증코드를 확인해주세요.");
          // Logging err
          this.$store.dispatch("LOGGING", {
            error: err,
            tags: `ConfirmPage|cognitoAPI|confirmRegistration(username=${this.email},code=${this.code})`,
            etc: {}, // Put anythings you want to log as object
          });
        });
    },
    reConfirm() {
      this.$store
        .dispatch("resendConfirmationCode", {
          username: this.email,
        })
        .then(() => {
          this.$store.commit("SUCCESS", "인증코드가 발송되었습니다.");
        })
        .catch((err) => {
          this.$store.dispatch("LOGGING", {
            error: err,
            tags: `ConfirmPage|cognitoAPI|resendConfirmationCode(username=${this.email})`,
            etc: {}, // Put anythings you want to log as object
          });
        });
    },
    reConfirmEmail() {
      //To do: API 호출하여 backend에서 이 유저의 cognito phone_number attribute를 삭제한다.
      //call back으로 reConfirm() 호출.
      // 단지 정보 세팅
      emailConfirm({ username: this.email }).then(() => {
        this.reConfirm();
      });
      return true;
    },
    goLogin() {
      this.loginDialog = false;

      this.$router.push({ name: "login" });
    },
  },
  created() {
    if (this.userData == null) {
      this.reConfirm();
    }
  },
};
</script>
