<template>
  <v-container class="pd16">
    <strong class="t-cont-title"
      >계약 품목 내역<span class="ft-r">(부가세 포함)</span></strong
    >
    <div class="openbox-item" v-for="option in options" :key="option.name">
      <v-list-group :value="false">
        <template v-slot:activator>
          <strong class="ob-title" v-if="option.selection == 'P1'">{{
            option.category
          }}</strong>
          <strong class="ob-title" v-else
            >{{ option.category }} - {{ option.selection }}</strong
          >
        </template>
        <div sub-group>
          <!-- <table class="open-table table-title">
            <tr>
              <th v-if="option.selection == option.name">{{ option.name }}</th>
              <th v-else>{{ option.selection }} - {{ option.name }}</th>
            </tr>
          </table> -->

          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="33%" />
              <col width="34%" />
            </colgroup>
            <tr class="total">
              <th>합계</th>
              <td colspan="2">{{ option.price | comma }}</td>
            </tr>
            <tr>
              <th>계약금</th>
              <td>{{ option.contractPaymentDate }}</td>
              <td>{{ option.contractPayment | comma }}</td>
            </tr>
            <tr>
              <th>중도금</th>
              <td>{{ option.intermediatePaymentDate }}</td>
              <td>{{ option.intermediatePayment | comma }}</td>
            </tr>
            <tr>
              <th>잔금</th>
              <td>
                <span
                  v-if="
                    option.balanceStartDate &&
                      option.balanceDate != option.balanceStartDate
                  "
                  >{{ option.balanceStartDate }} ~
                </span>
                {{ option.balanceDate }}
              </td>
              <td>{{ option.balance | comma }}</td>
            </tr>
          </table>

          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>비고</th>
              <td>{{ option.note }}</td>
            </tr>
          </table>
        </div>
      </v-list-group>
    </div>

    <div class="openbox-item" v-if="freeOptions.length > 0">
      <v-list-group :value="false">
        <template v-slot:activator>
          <strong class="ob-title">무상선택품목</strong>
        </template>
        <div
          sub-group
          class="open-table table-title"
          v-for="option in freeOptions"
          :key="option.name"
        >
          <table>
            <tr>
              <th>{{ option.name }}</th>
            </tr>
          </table>

          <table class="open-table table1" v-if="option.note != ''">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>비고</th>
              <td>{{ option.note }}</td>
            </tr>
          </table>
        </div>
      </v-list-group>
    </div>

    <strong class="t-cont-title">납부처 및 납부방법</strong>
    <table class="t-table table5" v-if="bankInfo.extraBankAccount">
      <colgroup>
        <col width="80px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th colspan="2">
          <strong class="t-cont-title">[확장]</strong>
        </th>
      </tr>
      <tr>
        <th>납부기관</th>
        <td>{{ bankInfo.bankName }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ bankInfo.bankAccount }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ bankInfo.bankDepositor }}</td>
      </tr>
      <tr>
        <th colspan="2">
          <strong class="t-cont-title">[추가선택품목]</strong>
        </th>
      </tr>
      <tr>
        <th>납부기관</th>
        <td>{{ bankInfo.extraBank }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ bankInfo.extraBankAccount }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ bankInfo.extraBankDepositor }}</td>
      </tr>
    </table>

    <table class="t-table table5" v-else>
      <colgroup>
        <col width="80px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>납부기관</th>
        <td>{{ bankInfo.bankName }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ bankInfo.bankAccount }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ bankInfo.bankDepositor }}</td>
      </tr>
    </table>
  </v-container>
</template>

<script>
import { getSellOptionInvoice } from "@/api/contract.js";

import { get_now_string } from "@/utils/time.js";

export default {
  components: {},
  name: "Options",
  data() {
    return {
      options: [],
      freeOptions: [],
      bankInfo: {},
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    getOptions() {
      let query = `&want_date=${get_now_string()}`;

      getSellOptionInvoice(this.home.id, query).then((result) => {
        let options = result.data.options;
        console.log("getOptions::", result.data);
        this.bankInfo = {
          bankAccount: options.bankAccount,
          bankDepositor: options.bankDepositor,
          bankName: options.bankName,
        };

        if (options.bankAccount != options.extraBankAccount) {
          this.bankInfo = {
            bankAccount: options.bankAccount,
            bankDepositor: options.bankDepositor,
            bankName: options.bankName,
            extraBank: options.extraBankName,
            extraBankAccount: options.extraBankAccount,
            extraBankDepositor: options.extraBankDepositor,
          };
        } else {
          this.bankInfo = {
            bankAccount: options.bankAccount,
            bankDepositor: options.bankDepositor,
            bankName: options.bankName,
          };
        }

        //this.options = options.options;

        // 합계금액이 0인 품목은 [무상선택품목]으로 표시한다.
        // options : 일반 별도계약품목, freeOptions : 무상선택품목
        options.options.forEach((item) => {
          if (item.price == 0) {
            this.freeOptions.push(item);
          } else {
            if (item.category != "확장") item.category = "추가선택품목";
            this.options.push(item);
          }
        });
      });
    },
  },
  created() {
    this.getOptions();
  },
};
</script>
