<template>
  <v-container class="bg-gray vh-footer pd20">
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-title class="justify-center color-1 fts18 mb">
        <div class="location-box np">
          <a @click="$router.go(-1)" class="back">
            <Back />
          </a>
          <strong class="lo-title">계약신청</strong>
        </div>
      </v-card-title>
      <div class="complete-box">
        <div class="conin-tbtn text-right mb10">
          <a href="#n">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
            >
              <path
                id="Icon_material-add-circle-outline"
                data-name="Icon material-add-circle-outline"
                d="M10.7,6.5H9.3V9.3H6.5v1.4H9.3v2.8h1.4V10.7h2.8V9.3H10.7ZM10,3a7,7,0,1,0,7,7A7,7,0,0,0,10,3Zm0,12.6A5.6,5.6,0,1,1,15.6,10,5.607,5.607,0,0,1,10,15.6Z"
                transform="translate(-3 -3)"
                fill="#f36628"
              />
            </svg>
            <span class="ds-ib v_m lh14 fts14 ftw5 ml5" @click="addRequest()"
              >계약신청 추가</span
            >
          </a>
        </div>
        <!-- 신청내역이 있을 때 -->
        <div
          class="mt20 comin-box"
          v-for="request in requestList"
          :key="request.id"
        >
          <div class="comin-cont">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
              >계약신청 단지</strong
            >
            <span class="text-left ds-b fts14 lh24 color-5">{{
              request.village_name
            }}</span>
          </div>
          <div class="comin-cont">
            <div class="comin-fbox">
              <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                >동호수</strong
              >
              <span class="text-left ds-b fts14 lh24 color-5"
                >{{ request.dong_no }}동 {{ request.ho_no }}호</span
              >
            </div>
            <div class="comin-fbox">
              <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                >주택형</strong
              >
              <span class="text-left ds-b fts14 lh24 color-5"
                >{{ parseInt(request.contract_type.SZ_EXCLUSIVE)
                }}{{ request.contract_type.TY_TYPE }}m²</span
              >
            </div>
          </div>
          <div class="comin-cont">
            <div class="comin-fbox">
              <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                >임대보증금</strong
              >
              <span class="text-left ds-b fts14 lh24 color-5"
                >{{ request.contract_type.AM_SELLING | comma }} 원</span
              >
            </div>
            <div class="comin-fbox">
              <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                >월 임대료</strong
              >
              <span class="text-left ds-b fts14 lh24 color-5"
                >{{ request.contract_type.AM_MLEASE | comma }} 원</span
              >
            </div>
          </div>
          <div class="comin-cont">
            <div class="comin-fbox">
              <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                >신청자</strong
              >
              <span class="text-left ds-b fts14 lh24 color-5">{{
                request.name
              }}</span>
            </div>
            <div class="comin-fbox">
              <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                >전화번호</strong
              >
              <span class="text-left ds-b fts14 lh24 color-5">{{
                request.mobile
              }}</span>
            </div>
          </div>
          <v-card-actions class="pt-0 pb-0">
            <v-btn
              color="#3c3c3c"
              text
              :ripple="false"
              class="ftw5 fts14 no-background-hover"
              >{{ request.status_text }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-tooltip top :disabled="request.actions.is_deletable">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    color="#3c3c3c"
                    text
                    :ripple="false"
                    class="ftw5 fts14 no-background-hover mr-5"
                    :disabled="!request.actions.is_deletable"
                    @click="delPopup(request.id)"
                  >
                    <v-icon>mdi-delete</v-icon> 취소
                  </v-btn>
                </div>
              </template>
              <span>{{ request.actions.no_delete_text }}</span>
            </v-tooltip>
            <v-tooltip top :disabled="request.actions.is_deletable">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    color="#f36628"
                    text
                    :ripple="false"
                    class="ftw5 fts14 no-background-hover"
                    :disabled="!request.actions.is_editable"
                    @click="modRequest(request.id)"
                  >
                    <v-icon>mdi-pencil</v-icon> 상세·수정
                  </v-btn>
                </div>
              </template>
              <span>{{ request.actions.no_edit_text }}</span>
            </v-tooltip>
          </v-card-actions>
        </div>

        <!-- 신청 내역이 없을 때 -->
        <div class="complete-box mt20" v-if="requestList.length == 0">
          <!--예제 이미로 적용시는 mt20 제거-->
          <div class="comin-box">
            <div class="comin-cont no-mes">
              <span class="text-center ds-b fts14 lh24 color-5"
                >신청내역이 없습니다</span
              >
            </div>
          </div>
        </div>
      </div>
      <v-btn
        color="primary"
        class="fts18 ftw7 mw-400 pwidth100 mt15"
        min-height="50"
        block
        @click="goMypage()"
      >
        확인
      </v-btn>
    </v-card>

    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7"> 취소하시겠습니까? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="delRequest(delId)">
            예
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="
              cancelDialog = false;
              delId = '';
            "
          >
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getRentalContractRequestList,
  delRentalContractRequest,
} from "@/api/index";
import Back from "@/assets/images/icon/icon-back.svg";
export default {
  name: "ContractRequestView",
  components: { Back },
  data() {
    return {
      requestList: [],
      delId: "",
      cancelDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    getRentalContractRequestListAPI() {
      // 회원가입 시
      let query = "";
      query = `user_id=${this.user.id}`;
      getRentalContractRequestList(query)
        .then((result) => {
          this.requestList = result.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    delPopup(id) {
      this.delId = id;
      this.cancelDialog = true;
    },
    delRequest(id) {
      delRentalContractRequest(id)
        .then((result) => {
          this.getRentalContractRequestListAPI();
          this.$store.commit("SUCCESS", "취소되었습니다.");
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.cancelDialog = false;
    },
    modRequest(id) {
      this.$router.push({
        name: "contractRequest",
        params: { request_id: id },
      });
    },
    addRequest() {
      this.$router.push({ name: "contractRequest" });
    },
    goMypage() {
      this.$router.push({ name: "mypage" });
    },
  },
  created() {
    if (this.user) {
      this.getRentalContractRequestListAPI();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  filters: {
    comma(text) {
      return String(text).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.no-background-hover::before {
  background-color: transparent !important;
}
</style>
