<template>
    <v-dialog v-model="show" persistent max-width="400px">
        <v-card>
            <v-card-title>
            {{survey.title}}
            </v-card-title>
            <v-card-text v-html="survey.description" class="align-left">
            </v-card-text>
            <v-card-actions>
                <span v-if="survey.popup_period == 'mandatory'"> * 설문 이후 AS 접수가 가능합니다.</span>
                <v-spacer></v-spacer>
                <v-btn text @click="moveToBack" v-if="survey.popup_period == 'mandatory'">뒤로가기</v-btn>
                <v-btn text @click="close" v-else-if="survey.popup_period == 'until_complete'">다음에 하기</v-btn>
                <v-btn text @click="skip" v-else-if="survey.popup_period == 'once'">닫 기</v-btn>
                <v-btn text color="success" @click="moveToSurvey">설문하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'SurveyDialog',
    props: {
        survey: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        show: true
    }),
    computed : {
        skipList (){
            return this.$store.getters.getSurveySkipList
        }
    },
    methods:{
        moveToBack(){
            // router to back
            this.$router.go(-1)
        },
        moveToSurvey(){
            const currentPath = this.$route.fullPath
            console.log(currentPath)
            // router to survey
            this.$router.push({name: 'surveyDetail', params: {id: this.survey.id}, query: {redirect: currentPath}})
        },
        close(){
            this.show = false
        },
        skip(){
            this.$store.commit('ADD_TO_SURVEY_SKIP_LIST', this.survey.id)
            this.show = false
        }
    }

}
</script>

<style scoped>
.align-left {
    text-align: left;
}
</style>
