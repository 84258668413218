<template>
    <v-container class="vh-footer pd20 bg-gray lynn-web-in">
        <div class="web-side"></div>
        <div class="location-box np">
            <div class="web-logo">
                <Weblogo width="55" height="30" />
            </div>
            <a @click="goToRedirect" class="back">
                <Back />
            </a>
            <strong class="lo-title">설문조사</strong>
            <!-- <strong class="lo-title" v-else>자료실</strong> -->
        </div>
        <v-card max-width="750" class="mx-auto bg-white nops">
            <v-card-text>
                <strong class="mb3 ds-b fts14 lh20 color-9 ftw4 text-left">{{
          survey.village_name
        }}</strong>
                <ul class="board-list mb10">
                    <li class="mb0">
                        <router-link to="/noticeVillage" class="pb15 font-weight-bold">
                            {{ survey.title }}
                            <span v-if="!survey|| !survey.id">
                             오픈 전 또는 종료된 설문입니다.
                            </span>
                        </router-link>
                    </li>
                </ul>
            
                <p class="board-text description" v-html="survey.description"></p>
                <p class="board-text description" v-if="!survey|| !survey.id">공유받은 링크 또는 설문 기간을 확인해주세요.</p>
                
              </v-card-text>
            </v-card>
            <v-card max-width="750" class="mx-auto bg-white nops mt-2" v-if="survey&&survey.id">
                <v-card-title class="primary--text">
                    설문 항목

                    <v-spacer></v-spacer>
                    <div class="text-left" style="font-size:12px; color:black;">
                 * 필수응답 항목
                </div>
                </v-card-title>
                
                <v-card-text>
                <v-row class="text-left" :key="'survey-question-'+question.id" v-for="question in survey.questions">

                    <v-col cols="12" class="mt-4" v-if="question.type=='short_answer'">
                        <span :class="'questions'+ (question.is_required ? '-bold' : '')">
                        <span v-if="question.is_required">*</span>
                        {{question.order}}. {{question.text}}</span>
                        <v-text-field label="응답을 입력해주세요." outlined class="mt-2" v-model="question.answer" hide-details/>
                    </v-col>

                    <v-col cols="12" class="mt-4" v-if="question.type=='long_answer'">
                        <span :class="'questions'+ (question.is_required ? '-bold' : '')"><span v-if="question.is_required">*</span>{{question.order}}. {{question.text}}</span>
                        <v-textarea label="응답을 입력해주세요." outlined class="mt-2" v-model="question.answer" hide-details/>
                    </v-col>
                    <v-col cols="12" class="mt-4" v-if="question.type=='single_choice'">
                        <span :class="'questions'+ (question.is_required ? '-bold' : '')"><span v-if="question.is_required">*</span>{{question.order}}. {{question.text}}</span>
                        <v-radio-group class="mt-2" v-model="question.answer">
                            <v-radio v-for="choice in question.choices" :key="`survey-question-${question.id}-choice-${choice.id}`" :label="choice.text" :value="choice" />
                        </v-radio-group>
                        <v-text-field v-model="question.answer_etc" v-if="question.answer.is_self_input" label="직접입력" outlined style="max-inline-size: 10rem;" />
                    </v-col>

                    <v-col cols="12" class="mt-4" v-if="question.type=='multiple_choice'">
                        <span :class="'questions'+ (question.is_required ? '-bold' : '')"><span v-if="question.is_required">*</span>{{question.order}}. {{question.text}}</span>

                        <div class="mt-2" v-for="choice in question.choices" :key="`survey-question-${question.id}-choice-${choice.id}`" >
                            <v-checkbox :label="choice.text" :value="choice" density="compact" v-model="question.answer"/>

                        </div>
                            <v-text-field v-if="question.answer.find(a=>a.is_self_input)" v-model="question.answer_etc" label="기타" outlined class="" style="max-inline-size: 10rem;" />
                    </v-col>

                    <v-col cols="12" class="mt-4" v-if="question.type=='date'">
                      <span :class="'questions'+ (question.is_required ? '-bold' : '')"><span v-if="question.is_required">*</span>{{question.order}}. {{question.text}}</span>
                        <div class="mt-2">
                            <v-menu v-model="question.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="question.answer" prepend-icon="mdi-calendar" label="응답을 입력해주세요." readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker locale="KR"  v-model="question.answer" @input="question.menu = false"></v-date-picker>
                            </v-menu>
                        </div>
                    </v-col>

                    <v-col cols="12" class="mt-4"  v-if="question.type=='time'">
                      <span :class="'questions'+ (question.is_required ? '-bold' : '')"><span v-if="question.is_required">*</span>{{question.order}}. {{question.text}}</span>
                        <div class="mt-2 one-line">
                            <v-select dense v-model="question.answer_hour" :items="hours" label="시" outlined></v-select>
                            <v-select dense v-model="question.answer_minute" :items="minutes" label="분" outlined></v-select>
                        </div>
                    </v-col>                   
                </v-row>
                <v-col cols="12" class="mt-4">
                        <v-btn block color="primary" elevation="0" @click="submit">설문 제출</v-btn>
                    </v-col>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Files from "@/assets/images/cs/file.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import {
    getBulletin
} from "@/api/index";
import {
    copy
} from "@/utils/utils";
import {
    getSurvey, postSurveyResponse
} from "@/api/survey";
export default {
    name: "Faq",
    components: {
        Back,
        Weblogo,
    },
    data() {
        return {
            survey: {
                questions:[]
            },
            ratings: [{
                    title: '계약자 본인',
                    value: '계약자 본인',
                },
                {
                    title: '입주자',
                    value: '입주자',
                },
                {
                    title: '가족',
                    value: '가족',
                },
                {
                    title: '업체',
                    value: '업체',
                },
                {
                    title: '직접입력',
                    value: '직접입력',
                },
            ],
            select: '',
            etc: false,
            date: "",
            menu2: false,
            time: "",
            hours: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
            minutes:["00", "10", "20", "30", "40", "50"],
        };
    },
    computed:{
        isExample() {
            return  this.$route.name === 'surveyDetailExample'
        }
    },
    methods: {
        // Survey
        getSurvey(id) {
            getSurvey(id, this.isExample).then(({
                data
            }) => {
                this.survey = data
            })
        },
        submit() {
            if (this.isExample){
                this.$store.commit("ERROR", "예시 설문은 제출할 수 없습니다.");
                return ;
            }

            const temp = copy(this.survey.questions);
            // 데이터 정리 
            const data = temp.map(q=>{
                let v = {
                    question_id: q.id,
                    answer: q.answer,
                    answer_etc: q.answer_etc,
                    is_required:q.is_required,
                    type: q.type
                }
                if(q.type === 'multiple_choice'){
                    v.answer = q.answer.map(a=>a.id)
                    let has_etc = q.answer.find(a=>a.is_self_input)
                    if (!has_etc){
                        v.answer_etc = ''
                    }


                } else if (q.type === 'single_choice'){
                    v.answer = q.answer.id
                } else if (q.type === 'time'){
                    if (q.answer_hour && q.answer_minute){
                        v.answer = `${q.answer_hour}:${q.answer_minute}`
                    }
                }

                return v
            })

            let noReplyIndex = -1
            let correct = false
            const isAllAnswered = data.every((q, idx)=>{
                if (q.is_required){
                    if (q.type === 'multiple_choice'){
                        correct = q.answer.length > 0
                    } else {
                        correct = q.answer
                    } 
                    if (!correct && noReplyIndex === -1){
                        noReplyIndex = idx
                    }
                    return correct
                } else {
                    return true
                }
            })


            if (!isAllAnswered){

                if (noReplyIndex > -1){
                    this.$store.commit("ERROR", `${noReplyIndex+1}번째 질문에 답변해주세요.`);
                } else {
                    this.$store.commit("ERROR", "필수 항목에 모두 응답 해주세요.");
                }
                return ;
            }

            postSurveyResponse(this.survey.id, data).then(({data})=>{
                this.$store.commit("SUCCESS", "설문이 제출되었습니다.");
                setTimeout(() => {
                    this.goToRedirect();
                    
                }, 1500);
            }).catch(e=>{
                // refresh
                alert("설문 제출에 실패했습니다. 다시 시도해주세요.")
                window.location.reload();
            })

        },
        // Utils
        goToRedirect() {
            if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
            }else{
                this.$router.go(-1);
            }
        },
    },
    created() {
        this.getSurvey(this.$route.params.id);
    },
};
</script>

<style scoped>
.text-left {
    text-align: left;
}

.description {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.questions {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
}

.questions-bold {
    font-size: 14px;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
}

.one-line {
    display: flex;
    gap: 8px; /* 필요한 경우 두 select 사이의 간격 조절 */
    align-items: center;
}
</style>
