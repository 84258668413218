// Dwelling: 주거
import dwelling from "@/views/DwellingViews/DwellingIntro.vue";

// Vote: 투표
import voteList from "@/views/DwellingViews/Vote/VoteList.vue";
import voteDetail from "@/views/DwellingViews/Vote/VoteDetail.vue";

// Lounge: 라운지
import loungeList from "@/views/DwellingViews/Lounge/LoungeList.vue";
import loungeView from "@/views/DwellingViews/Lounge/LoungeView.vue";
import loungeWrite from "@/views/DwellingViews/Lounge/LoungeWrite.vue";
import loungeReport from "@/views/DwellingViews/Lounge/LoungeReport.vue";

// MgmtFee: 관리비
import mgmtFee from "@/views/DwellingViews/Fee/MgmtFee.vue";
import mgmtFeeDetail from "@/views/DwellingViews/Fee/MgmtFeeDetail.vue";

// Facilities: 편의시설
import FacilityInfo from "@/views/DwellingViews/Facility/FacilityInfo.vue";
import FacilityReservation from "@/views/DwellingViews/Facility/FacilityReservation.vue";
import FacilitySeatReservation from "@/views/DwellingViews/Facility/FacilitySeatReservation.vue";
import FacilityHistory from "@/views/DwellingViews/Facility/FacilityHistory.vue";
import FacilityHome from "@/views/DwellingViews/Facility/FacilityHome.vue";
import FacilityCIS from "@/views/DwellingViews/Facility/FacilityCIS.vue";

var dwellingRoutes = [
  // 주거
  {
    path: "/dwelling",
    name: "dwelling",
    component: dwelling,
    meta: {
      header: {
        title: "린라이프",
      },
      requiresAuth: true,
      activeMenu: "dwelling",
      bottomNav: true,
    },
  },

  // 투표
  {
    path: "/voteList",
    name: "voteList",
    component: voteList,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },
  {
    path: "/vote/:id",
    name: "voteDetail",
    component: voteDetail,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },

  //라운지
  {
    path: "/loungeList",
    name: "loungeList",
    component: loungeList,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },

  {
    path: "/loungeView/:id",
    name: "loungeView",
    component: loungeView,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },

  {
    path: "/loungeWrite",
    name: "loungeWrite",
    component: loungeWrite,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },

  {
    path: "/loungeReport",
    name: "loungeReport",
    component: loungeReport,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },

  // 관리비
  {
    path: "/mgmtFee",
    name: "mgmtFee",
    component: mgmtFee,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },

  {
    path: "/mgmtFeeDetail",
    name: "mgmtFeeDetail",
    component: mgmtFeeDetail,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },

  // 시설
  {
    path: "/facilityInfo/:id",
    name: "facilityInfo",
    component: FacilityInfo,
    meta: {
      bottomNav: false,
      activeMenu: "dwelling",
    },
  },

  {
    path: "/facilityReservation/:id",
    name: "facilityReservation",
    component: FacilityReservation,
    meta: {
      bottomNav: false,
      activeMenu: "dwelling",
    },
  },

  {
    path: "/facilitySeatReservation/:id",
    name: "facilitySeatReservation",
    component: FacilitySeatReservation,
    meta: {
      bottomNav: false,
      activeMenu: "dwelling",
    },
  },

  {
    path: "/facilityHistory",
    name: "facilityHistory",
    component: FacilityHistory,
    meta: {
      bottomNav: false,
      activeMenu: "dwelling",
    },
  },

  {
    path: "/facilityHome",
    name: "facilityHome",
    component: FacilityHome,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },
  {
    path: "/facilityCIS",
    name: "facilityCIS",
    component: FacilityCIS,
    meta: {
      bottomNav: true,
      activeMenu: "dwelling",
    },
  },
];

export { dwellingRoutes };
