<template>
  <v-dialog
    v-model="data.dialog"
    persistent
    :overlay="true"
    max-width="400px"
    height="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>등록된 아이디 목록</v-card-title>
      <v-card-text class="">
        <v-card outlined>
          <v-list>
            <v-list-item :key="key" v-for="(email, key) in data.items">
              <v-list-item-content>
                <v-list-item-title style="padding-bottom: 2px">{{
                  email
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  small
                  outlined
                  color="grey darken-2"
                  @click="$emit('login', email)"
                  >로그인</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div v-if="!data.items.length" style="margin-bottom: 20px;">
            {{ data.msg }}
          </div>
        </v-card>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-spacer></v-spacer>
        <v-btn color="primary" min-height="30" @click="$emit('close')">
          닫 기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
export default {
  props: ["data"],
};
</script>
