<template>
  <div id="LoginPage">
    <div class="LoginBox">
      <a href="#none" class="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14.897"
          height="14.897"
          viewBox="0 0 14.897 14.897"
        >
          <g
            id="그룹_320"
            data-name="그룹 320"
            transform="translate(-299.327 -58.469)"
          >
            <g
              id="그룹_318"
              data-name="그룹 318"
              transform="translate(-36.411 -67)"
            >
              <g
                id="그룹_294"
                data-name="그룹 294"
                transform="translate(10.411 88)"
              >
                <line
                  id="선_59"
                  data-name="선 59"
                  y1="0.405"
                  x2="16.566"
                  transform="translate(327.062 38.917) rotate(45)"
                  fill="none"
                  stroke="#3c3c3c"
                  stroke-linecap="round"
                  stroke-width="2"
                />
              </g>
              <g
                id="그룹_318-2"
                data-name="그룹 318"
                transform="translate(298.27 465.693) rotate(-90)"
              >
                <line
                  id="선_59-2"
                  data-name="선 59"
                  y1="0.405"
                  x2="16.566"
                  transform="translate(327.062 38.917) rotate(45)"
                  fill="none"
                  stroke="#3c3c3c"
                  stroke-linecap="round"
                  stroke-width="2"
                />
              </g>
            </g>
          </g>
        </svg>
      </a>
      <div class="LoginForm">
        <strong class="lf-title">린 라이프 회원이신가요?</strong>
        <v-text-field
          v-model="email"
          label="아이디(이메일)"
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="비밀번호"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          required
        ></v-text-field>
        <div class="lf-side" />
        <v-btn width="100%" min-height="50" color="#f36628" elevation="0">
          <p class="lf-inset">로그인</p>
        </v-btn>
        <ul class="forgotBox">
          <li><a href="#none">ID·비밀번호 찾기</a></li>
          <li><a href="#none">신규 회원등록</a></li>
        </ul>
        <div class="notLogBtn">
          <a href="#none">
            <p>비회원으로 둘러보기</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13.414"
              viewBox="0 0 13 13.414"
            >
              <g
                id="Icon_feather-arrow-left"
                data-name="Icon feather-arrow-left"
                transform="translate(20 20.207) rotate(180)"
              >
                <path
                  id="패스_61"
                  data-name="패스 61"
                  d="M19.5,18H7.5"
                  transform="translate(0 -4.5)"
                  fill="none"
                  stroke="#4c4d4f"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="패스_62"
                  data-name="패스 62"
                  d="M13.57,19.5l-6.07-6,6.07-6"
                  fill="none"
                  stroke="#4c4d4f"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginPage",
  data() {
    return {
      showPassword: false,
      valid: false,
      password: "",
      email: "",
    };
  },
};
</script>