<template>
  <v-container class="vh-full bg-gray ps-re lynn-mobile" fluid>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on" class="ps-fx ps-t20 ps-l0">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item><v-list-item-title>메뉴1</v-list-item-title></v-list-item>
        <v-list-item><v-list-item-title>메뉴2</v-list-item-title></v-list-item>
        <v-list-item><v-list-item-title>메뉴3</v-list-item-title></v-list-item>
        <v-list-item><v-list-item-title>메뉴4</v-list-item-title></v-list-item>
        <v-list-item><v-list-item-title>메뉴5</v-list-item-title></v-list-item>
      </v-list>
    </v-menu>
    <v-container>
      <v-row>
        <v-col class="ft-c">
          <h3 class="text-left grow fts18 ftw5 ft-l lh45">
            홍길동님 안녕하세요.
          </h3>
          <Profile v-if="user" />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pad mb15 mb-p3">
      <v-row>
        <v-col>
          <v-container> 영역테스트용 </v-container>
        </v-col>
        <v-col>
          <v-container> 영역테스트용 </v-container>
        </v-col>
        <v-col>
          <v-container> 영역테스트용 </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pad mb15 mb-p3">
      <v-tabs color="accent-4" centered>
        <v-tab>전기세</v-tab>
        <v-tab>관리비</v-tab>
        <v-tab>전력</v-tab>

        <v-tab-item v-for="n in 3" :key="n" class="mt10 mt-p2">
          <v-container fluid>
            <v-row>
              <v-col cols="5">
                <span class="ds-b text-left">3월 누적 전력 소비량</span>
                <span class="ds-b text-center">9,999<span>kwh</span></span>
                <span class="ds-b text-left">9,999,999<span>원</span></span>
              </v-col>
              <v-col cols="7">
                <v-sparkline
                  :value="value"
                  color="#ADADAD"
                  height="120"
                  padding="10"
                  stroke-linecap="round"
                  smooth
                  line-width="1"
                  label-size="14"
                >
                  <template v-slot:label="item">
                    {{ item.value }}
                  </template>
                </v-sparkline>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-container>
    <v-container class="pad mb15 mb-p3">
      <v-row>
        <v-col cols="12">
          <h3 class="text-left">현재위치</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ul class="state-box">
            <li>
              <span>집</span>
              <span>동수</span>
            </li>
            <li>
              <span>외출</span>
              <span>지원, 지현</span>
            </li>
            <li>
              <span>독서실</span>
              <span>동현</span>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Profile from "@/components/Profile";
export default {
  components: { Profile },
  name: "DashBoard",
  data: () => ({
    value: [1, 12, 31],
  }),
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    userState() {
      return this.$store.getters.getUserState;
    },
  },
  methods: {
    moveTo() {
      /*
        1. 'NONMEMBER' - 비휘원
        2. 'MEMBER' - 회원
        3. 'CONTRACTOR' - 계약자
        4. 'TENANT' - 입주자
      */
      console.log(this.userState);
      if (["NONMEMBER", "MEMBER"].includes(this.userState)) {
        this.$router.push({ name: "villageInfoList" });
      } else {
        // Stay here
        // this.$router.push({ name: "Dashboard" });
      }
    },
  },
  created() {
    this.moveTo();
  },
};
</script>
