<template>
  <div class="ft-l">
    <a
      href="#n"
      @click.prevent.stop="drawer = !drawer"
      class="pd0 mg0"
      elevation="0"
    >
      <IconNavback />
    </a>

    <!-- 계약,주거 관련 navigation -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      left
      temporary
      class="pwidth90 pheight100"
      v-if="!['NONMEMBER', 'MEMBER'].includes(userState) && switchMode"
    >
      <div class="nav-header">
        <a @click.stop="drawer = !drawer"><IconNavboxback /></a>
        <strong>SMART LYNN</strong>
      </div>
      <v-list nav dense>
        <v-list-group
          no-action
          :value="menu == 'contract'"
          v-if="!home.is_rent"
        >
          <template v-slot:activator>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                >계약</v-list-item-title
              ></v-list-item-content
            >
          </template>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  to="/contractInfo/status/sell"
                  style="color: #3c3c3c"
                  >계약현황</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  to="/contractInfo/payment/payment"
                  style="color: #3c3c3c"
                  >납부현황</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  to="/contractInfo/loan/loan"
                  style="color: #3c3c3c"
                  >융자현황 조회</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  to="/contractInfo/interest/interest"
                  style="color: #3c3c3c"
                  >대납이자·보증수수료</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  to="/contractInfo/balance/balance"
                  style="color: #3c3c3c"
                  >잔금납부 안내</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-group>
        <v-list-group no-action :value="menu == 'contract'" v-if="home.is_rent">
          <template v-slot:activator>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                >계약</v-list-item-title
              ></v-list-item-content
            >
          </template>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  to="/contractInfo/status/rent"
                  style="color: #3c3c3c"
                  >계약현황</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  to="/contractInfo/receipt/deposit"
                  style="color: #3c3c3c"
                  >보증금 및 임대료</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item v-show="false">
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  to="/contractInfo/request/change"
                  style="color: #3c3c3c"
                  >계약 변경 및 갱신</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item v-show="false">
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  to="/contractInfo/cancel/cancel"
                  style="color: #3c3c3c"
                  >계약 해지</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-group>
        <v-list-group no-action :value="menu == 'homein'">
          <template v-slot:activator>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                >입주</v-list-item-title
              ></v-list-item-content
            >
          </template>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link to="/preCheck" style="color: #3c3c3c"
                  >사전 점검 예약</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                class="text-left text-subtitle-1"
                @click.prevent.stop="wait()"
                >A/S 접수</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                class="text-left text-subtitle-1"
                @click.prevent.stop="wait()"
                >입주 예약</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item v-if="home.is_rent">
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  :to="{ name: 'contractInfo', params: { tabIndex: 3 } }"
                  style="color: #3c3c3c"
                  >퇴거 신청</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-group>
        <v-list-group no-action :value="menu == 'dwelling'">
          <template v-slot:activator>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                >주거</v-list-item-title
              ></v-list-item-content
            >
          </template>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                class="text-left text-subtitle-1"
                @click.prevent.stop="wait()"
                >관리비</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                class="text-left text-subtitle-1"
                @click.prevent.stop="wait()"
                >커뮤니티</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                class="text-left text-subtitle-1"
                @click.prevent.stop="wait()"
                >편의 시설</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-group>
        <v-list-group no-action :value="menu == 'mypage'">
          <template v-slot:activator>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                >마이페이지</v-list-item-title
              ></v-list-item-content
            >
          </template>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  :to="{ name: 'mypage', params: { tabIndex: 3 } }"
                  style="color: #3c3c3c"
                  >나의 예약 및 신청현황</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title
                class="text-left text-subtitle-1"
                @click.prevent.stop="wait()"
                >가족 구성원 관리</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link to="/memberInfoUpdate" style="color: #3c3c3c"
                  >회원정보 수정</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>

      <v-list
        nav
        dense
        v-if="switchMode && !['NONMEMBER', 'MEMBER'].includes(userState)"
      >
        <v-list-item-group>
          <a @click="switchTo('villageInfoList')" style="color: #3c3c3c">
            <v-list-item :ripple="false">
              <v-list-item-icon>
                <v-list-item-title class="text-left text-subtitle-1"
                  >단지 안내 바로가기</v-list-item-title
                >
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </a>
          <router-link to="/CsCenter">
            <v-list-item :ripple="false">
              <v-list-item-icon>
                <v-list-item-title class="text-left text-subtitle-1"
                  >고객지원</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>

      <v-list
        nav
        dense
        v-if="!switchMode && !['NONMEMBER', 'MEMBER'].includes(userState)"
      >
        <v-list-item-group>
          <a style="color: #3c3c3c" @click="switchTo('contract')">
            <v-list-item :ripple="false">
              <v-list-item-icon>
                <v-list-item-title class="text-left text-subtitle-1"
                  >SMART LYNN 바로가기</v-list-item-title
                >
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </a>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Lynn Life 소개 navigation -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      left
      temporary
      class="pwidth90 pheight100"
      v-else
    >
      <div class="nav-header pt24">
        <a @click.stop="drawer = !drawer"><IconNavboxback /></a>
        <strong>SMART LYNN</strong>
      </div>
      <v-list nav dense>
        <v-list-group no-action :value="menu == 'info'">
          <template v-slot:activator>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                >단지안내</v-list-item-title
              ></v-list-item-content
            >
          </template>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><a @click.stop="routerVillage(0)" style="color: #3c3c3c"
                  >분양단지</a
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><a @click.stop="routerVillage(1)" style="color: #3c3c3c"
                  >공사단지</a
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><a @click.stop="routerVillage(2)" style="color: #3c3c3c"
                  >입주단지</a
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-group>
        <v-list-group no-action :value="menu == 'schedule'">
          <template v-slot:activator>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                >분양일정</v-list-item-title
              ></v-list-item-content
            >
          </template>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  :to="{ name: 'parcellingCalendar' }"
                  style="color: #3c3c3c"
                  >분양일정</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-group>
        <v-list-group no-action :value="menu == 'introduce'">
          <template v-slot:activator>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                >SMART LYNN 소개</v-list-item-title
              ></v-list-item-content
            >
          </template>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link :to="{ name: 'smartLynn' }" style="color: #3c3c3c"
                  >SMART LYNN 소개</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          :value="menu == 'mypage'"
          v-if="$store.state.auth.isLoggedIn"
        >
          <template v-slot:activator>
            <v-list-item-content
              ><v-list-item-title class="text-left text-h6"
                >마이페이지</v-list-item-title
              ></v-list-item-content
            >
          </template>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link
                  :to="{ name: 'mypage', params: { tabIndex: 3 } }"
                  style="color: #3c3c3c"
                  >나의 예약 및 신청현황</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><v-list-item-title class="text-left text-subtitle-1"
                ><router-link to="/memberInfoUpdate" style="color: #3c3c3c"
                  >회원정보 수정</router-link
                ></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
        dense
        v-if="!switchMode && !['NONMEMBER', 'MEMBER'].includes(userState)"
      >
        <v-list-item-group>
          <a style="color: #3c3c3c" @click="switchTo('contract')">
            <v-list-item :ripple="false">
              <v-list-item-icon>
                <v-list-item-title class="text-left text-subtitle-1"
                  >계약·입주·주거서비스 가기</v-list-item-title
                >
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </a>
          <router-link to="/CsCenter">
            <v-list-item :ripple="false">
              <v-list-item-icon>
                <v-list-item-title class="text-left text-subtitle-1"
                  >고객지원</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import IconNavback from "@/assets/images/icon/icon-navback.svg";
import IconNavboxback from "@/assets/images/icon/icon-back.svg";

export default {
  components: {
    IconNavback,
    IconNavboxback,
  },
  name: "navigator",
  data: () => ({
    drawer: false,
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    menu() {
      return this.$route.meta.activeMenu;
    },
    userState() {
      return this.$store.getters.getUserState;
    },
    switchMode() {
      return this.$store.state.switchMode;
    },
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    switchTo(link) {
      this.$store.state.switchMode = !this.$store.state.switchMode;
      this.$router.push({ name: link });
    },
    wait() {
      this.$store.commit("SUCCESS", "서비스 준비중입니다.");
    },
    routerVillage(tab) {
      if (this.$route.name == "villageInfoList") {
        this.$store.state.navTabIndex = tab;
        this.drawer = !this.drawer;
      } else {
        this.$router.push({
          name: "villageInfoList",
          params: { tabIndex: tab },
        });
      }
    },
  },
  created() {
    // 비회원, 일반회원인 경우엔 switchMode를 false로 유지
    if (["NONMEMBER", "MEMBER"].includes(this.userState)) {
      this.$store.state.switchMode = false;
    }
  },
};
</script>
