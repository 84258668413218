<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <div class="loading-box" v-if="isLoading">
      <div class="ldb-inbox">
        <span class="loader"></span>
        <strong class="ldb-text">목록을 생성하고 있습니다. 잠시만 기다려주세요.</strong>
      </div>
    </div>
    <v-container class="pad nb mypi-web">
      <div class="mypi-contbox">
        <strong class="t-cont-title">전매(명의변경) 방문예약</strong>
        <span class="no-list" v-if="!request.length"> 예약 가능한 단지가 없습니다. </span>
        <ul class="mypi-long" v-if="request.length">
          <li
            v-for="item in request"
            :key="'win-reservations-' + item.id"
            :class="request.length == 1 ? 'single' : ''"
            @click="moveTo(item)"
          >
            <router-link to>
              <span class="left">
                <Iconsample />
                <span class="sidetop">{{ item.village_name }}</span>
                {{ item.title }}
                <span class="side" v-if="item.status_text">[{{ item.status_text }}]</span>
              </span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="mypi-contbox last">
        <ul class="mypi-nomal">
          <li>
            <a @click.stop="loadVerification()">
              <span class="text color-1 lh24">계약자 인증</span>
              <span class="icon pd0">
                <v-icon color="#f36628">mdi-plus-circle-outline </v-icon></span
              >
            </a>
          </li>
        </ul>

        <v-btn
          color="primary"
          class="fts18 ftw7 mw-400 pwidth100 mt15 mb15"
          min-height="45"
          block
          @click="getRequestList()"
        >
          예약 목록 새로고침
          <v-icon class="ml5">mdi-cached</v-icon>
        </v-btn>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { getReservableList } from "@/api/index.js";
import { getReservationMasterList } from "@/api/index.js";
import { getOpenVillage } from "@/api/index.js";

import Iconsample from "@/assets/images/mypage/icon-sample.svg"; // 견본주택방문
import { get_now_string } from "@/utils/time.js";
export default {
  components: {
    Iconsample,
  },
  name: "ReservationList",
  data: () => ({
    request: [],
    reservation_code: "RESALE_RESERVATION", // 입주예약 타입
    isLoading: false,
  }),
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      return this.$store.getters.getHome;
    },
    isContractor() {
      return !this.home || !this.home.is_contractor;
    },
  },
  methods: {
    moveTo(item) {
      if (!this.home || this.home.village != item.id) {
        this.$store.commit("ERROR", "계약자 인증이 필요한 방문예약 입니다.");
        return;
      }

      this.$router.push({
        name: "resaleForm",
        params: { id: 0 },
        query: { master: item.reservation },
      });
    },
    getRequestList() {
      this.isLoading = true;

      getOpenVillage("RESALE_RESERVATION")
        .then((res) => {
          this.request = res.data.map((village) => {
            village.title = village.village_name;
            return village;
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadVerification() {
      this.$store.commit("SET_VERIFICATON", {
        title: "계약자 실명인증",
        verified_name_type: true,
        dialog: true,
        redirect: "precheckReservationList",
        is_test: false,
      });
    },
  },

  created() {
    this.$store.dispatch("USER_REFRESH", true);
    this.getRequestList();
  },
};
</script>
