<template>
  <v-container class="lease-hub-main pd0">
		<div class="main-top"><img :src="require('@/assets/images/hub/maintest.png')" /></div>
		<div class="main-middle">
			<strong>임대단지</strong>
			<ul class="lease-box">
				<li class="lease-item">
					<a href="#none" class="lease-in">
						<div class="img-box"><v-img :src="require('@/assets/images/hub/test.png')" /></div>
						<div class="text-box">
							<div class="text-top">
								<span class="tt-state">2021.05 분양중</span>
								<strong class="tt-title">파주 운정 우미 린 더 퍼스트</strong>
								<p class="tt-side">파주 운정3지구 A15블록</p>
							</div>
							<ul class="text-state">
								<li class="ts-line">
									<strong>규모</strong>
									<span>지하1층 ~ 지상28층</span>
								</li>
								<li class="ts-line">
									<strong>입주세대</strong>
									<span>846세대</span>
								</li>
								<li class="ts-line">
									<strong>주택형</strong>
									<span>59 ~ 84㎡</span>
								</li>
							</ul>
							<div class="text-btn">
								<a href="#none">홍보관 바로가기</a>
								<a href="#none">자세히 보기</a>
							</div>
						</div>
					</a>
				</li>
				<li class="lease-item">
					<a href="#none" class="lease-in">
						<div class="img-box"><v-img :src="require('@/assets/images/hub/test.png')" /></div>
						<div class="text-box">
							<div class="text-top">
								<span class="tt-state">2021.05 분양중</span>
								<strong class="tt-title">파주 운정 우미 린 더 퍼스트</strong>
								<p class="tt-side">파주 운정3지구 A15블록</p>
							</div>
							<ul class="text-state">
								<li class="ts-line">
									<strong>규모</strong>
									<span>지하1층 ~ 지상28층</span>
								</li>
								<li class="ts-line">
									<strong>입주세대</strong>
									<span>846세대</span>
								</li>
								<li class="ts-line">
									<strong>주택형</strong>
									<span>59 ~ 84㎡</span>
								</li>
							</ul>
							<div class="text-btn">
								<a href="#none">홍보관 바로가기</a>
								<a href="#none">자세히 보기</a>
							</div>
						</div>
					</a>
				</li>
				<li class="lease-item">
					<a href="#none" class="lease-in">
						<div class="img-box"><ImgLoading/></div>
						<div class="text-box">
							<div class="text-top">
								<span class="tt-state">2021.05 분양중</span>
								<strong class="tt-title">파주 운정 우미 린 더 퍼스트</strong>
								<p class="tt-side">파주 운정3지구 A15블록</p>
							</div>
							<ul class="text-state">
								<li class="ts-line">
									<strong>규모</strong>
									<span>지하1층 ~ 지상28층</span>
								</li>
								<li class="ts-line">
									<strong>입주세대</strong>
									<span>846세대</span>
								</li>
								<li class="ts-line">
									<strong>주택형</strong>
									<span>59 ~ 84㎡</span>
								</li>
							</ul>
							<div class="text-btn">
								<a href="#none">홍보관 바로가기</a>
								<a href="#none">자세히 보기</a>
							</div>
						</div>
					</a>
				</li>
				<li class="lease-item">
					<a href="#none" class="lease-in">
						<div class="img-box"><ImgLoading/></div>
						<div class="text-box">
							<div class="text-top">
								<span class="tt-state">2021.05 분양중</span>
								<strong class="tt-title">파주 운정 우미 린 더 퍼스트</strong>
								<p class="tt-side">파주 운정3지구 A15블록</p>
							</div>
							<ul class="text-state">
								<li class="ts-line">
									<strong>규모</strong>
									<span>지하1층 ~ 지상28층</span>
								</li>
								<li class="ts-line">
									<strong>입주세대</strong>
									<span>846세대</span>
								</li>
								<li class="ts-line">
									<strong>주택형</strong>
									<span>59 ~ 84㎡</span>
								</li>
							</ul>
							<div class="text-btn">
								<a href="#none">홍보관 바로가기</a>
								<a href="#none">자세히 보기</a>
							</div>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<div class="main-bottom">
			<strong>재무건전성, 신용도 모두 업계 최고의 우미건설</strong>
			<ul class="lease-imgcont">
				<li><img :src="require('@/assets/images/hub/item1.png')" /></li>
				<li><img :src="require('@/assets/images/hub/item2.png')" /></li>
				<li><img :src="require('@/assets/images/hub/item3.png')" /></li>
			</ul>
		</div>
		<div class="lease-call">
			<strong>대표번호<span>1588-9707</span></strong>
			<ul>
				<li class="days">평일 9:00 - 17:00</li>
				<li class="text">토요일과 공휴일 및 국경일은 휴무</li>
			</ul>
		</div>
		<div class="lease-footer">
			<div class="lf-in">
				<div class="lf-left"><FLogo/></div>
				<div class="lf-center">
					<ul class="lf-top">
						<li><a href="#none">이메일무단수집거부</a></li>
						<li><a href="#none">개인정보취급방침</a></li>
						<li><a href="#none">영상정보처리기기 운영방침</a></li>
					</ul>
					<div class="lf-bottom">
						<p>06292 서울시강남구 언주로 30길 39 린스퀘어(도곡동 467-14) <span>대표전화</span> 1588-9707</p>
						<p>Copyright &copy; 2016 WOOMI CONSTRICTION CORP. ALL rights reserved.</p>
					</div>
				</div>
				<div class="lf-right">
					<select>
						<option>패밀리 사이트</option>
					</select>
				</div>
			</div>
		</div>
  </v-container>
</template>

<script>
import MainImg from "@/assets/images/hub/main.svg"; //메인이미지
import FLogo from "@/assets/images/hub/footer-logo.svg"; //메인이미지
import ImgLoading from "@/assets/images/hub/imgloading.svg"; //메인이미지

export default {
  components: {
		//MainImg,
		FLogo,
		ImgLoading,
  },
  name: "Contract",
  data: () => ({
    selHome: "",
  }),
  computed: {
  },
  methods: {
  },
  created() {
	}
};
</script>
