<template>
  <div class="user-avatar">
    <v-menu class="ft-r" min-width="200px" bottom rounded offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon large v-on="on" class="ft-r">
          <v-avatar class="ft-r" v-if="profile.imageYn">
            <v-img :src="user.imagePath" contain></v-img>
          </v-avatar>
          <v-avatar class="ft-r" v-else :color="profile.color">
            <span class="white--text headline">{{ profile.initials }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list flat class="pt20 pb0">
          <v-avatar v-if="profile.imageYn">
            <v-img :src="user.imagePath" contain></v-img>
          </v-avatar>
          <v-avatar v-else :color="profile.color">
            <span class="white--text headline">{{ profile.initials }}</span>
          </v-avatar>
          <h3 class="mt10 mb20 fts18 lh24 color-2 ftw500">
            {{ profile.name }}님
          </h3>
          <v-divider v-show="false"></v-divider>
          <v-list-item @click="clickEditPhoto()" v-show="false">
            <v-list-item-content>
              <v-list-item-title>프로필 사진 변경</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="goMemberInfoUpdate()">
            <v-list-item-content>
              <v-list-item-title>회원정보 수정</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="signDialog = true">
            <v-list-item-content>
              <v-list-item-title>로그아웃</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <!-- 로그아웃 다이얼로그 -->
    <v-dialog v-model="signDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          로그아웃 하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="signDialog = false">
            취소
          </v-btn>
          <v-btn color="primary darken-1" text @click="signOut()">
            로그아웃
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
      type="file"
      style="display: none"
      ref="editImage"
      accept="image/*"
      @change="onFilePicked"
    />
  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      signDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    profile() {
      if (this.user) {
        return this.user.profile;
      } else {
        return {
          name: "",
          initials: "",
          color: "",
          imageYn: false,
          imagePath: "",
        };
      }
    },
  },
  methods: {
    goMemberInfoUpdate() {
      this.$router.push({
        name: "memberInfoUpdate",
      });
    },
    signOut() {
      this.$store.dispatch("signOut", {}).then(() => {
        this.$store.commit("SUCCESS", "로그아웃 되었습니다.");
        this.$router.push({ name: "intro" });
        this.signDialog = false;
      });
    },
    clickEditPhoto() {
      this.$refs.editImage.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      const file = files[0];
      console.log(file);
      // TODO : files[0] 서버로 전송하는 API
    },
  },
  created() {},
};
</script>
