<template>
    <v-container class="bg-gray vh-footer pd20">
        <v-card max-width="750" class="mx-auto bg-gray nops">
            <v-card-title class="justify-center color-1 fts18">
                계약신청
            </v-card-title>
            <v-card-subtitle class="color-1 fts16 lh24 mt15">계약신청을 하시면 전문 상담원이<br/>안내해 드립니다.</v-card-subtitle>
            <div>
                <v-form>
                    <v-subheader class="pd0 fts16 ftw5 mt15">계약 대상 목적물으 표시</v-subheader>
                    <v-combobox solo 
                        :items="villageList"
                        item-text="village_name"
                        item-value="id"
                        label="계약단지 선택"
                    >
                    </v-combobox>
                    <v-row>
                        <v-col cols="4" class="mr-p2">
                            <v-select
                                :items="dong"
                                item-text="dong_num"
                                item-value="id"
                                label="동"
                            ></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select
                                :items="ho"
                                item-text="ho_num"
                                item-value="id"
                                label="호"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-subheader class="pd0 fts16 ftw5 mt15">구분</v-subheader>
                    <v-radio-group row class="pd0 mg0">
                        <v-radio value="표준형" label="표준형"></v-radio>
                        <v-radio value="전환형I" label="전환형I"></v-radio>
                        <v-radio value="전환형II" label="전환형II"></v-radio>
                    </v-radio-group>
                    <div class="complete-box">
                        <div class="comin-box">
                            <div class="comin-cont">
                                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">주택형</strong>
                                <span class="text-left ds-b fts14 lh24 color-5">-</span>
                            </div>
                            <div class="comin-cont">
                                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">임대보증금</strong>
                                <span class="text-left ds-b fts14 lh24 color-5">-</span>
                            </div>
                            <div class="comin-cont">
                                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">월 임대료</strong>
                                <span class="text-left ds-b fts14 lh24 color-5">-</span>
                            </div>
                        </div>
                    </div>
                    <v-subheader class="pd0 fts16 ftw5 mt15">1. 지정 동·호수 지정 신청 시 납부금액</v-subheader>
                    <div class="complete-box">
                        <div class="comin-box">
                            <div class="comin-cont">
                                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">동·호수 지정 신청금</strong>
                                <span class="text-left ds-b fts14 lh24 color-5">일백만원정 (￦ 1,000,000) ※ 등록완료시 추후 가상계좌를 SMS로 발송예정</span>
                            </div>
                            <div class="comin-cont">
                                <span class="text-left ds-b fts12 ftw4 lh20 color-6 mb5">※계약은 1인 1건을 원칙, 중복계약 시 모든 계약이 취소됨을 양지하시기 바랍니다.</span>
                                <span class="text-left ds-b fts12 ftw4 lh20 color-6">※해당기한내에 계약금(1,000,000원)을 입금하셔야 계약이 성립됩니다. 기한(익일 24시)경과시 가상계좌는 자동소멸됩니다.</span>
                            </div>
                        </div>
                    </div>
                    <v-subheader class="pd0 fts16 ftw5 mt15">2. 지정 동·호수 계약 기한 및 추가 납부금액</v-subheader>
                    <div class="complete-box">
                        <div class="comin-box">
                            <div class="comin-cont">
                                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">계약기한</strong>
                                <span class="text-left ds-b fts14 ftw5 lh24 color-1">2021년 03월 11일</span>
                            </div>
                            <div class="comin-cont">
                                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">임대 계약금</strong>
                                <span class="text-left ds-b fts12 ftw4 lh20 color-5 mb5">· 계약금 일금 일백오십만원정 (￦ 1,500,000 원)</span>
                                <span class="text-left ds-b fts12 ftw4 lh20 color-5 mb5">· 납부계좌 : 추후 SMS 발송예정</span>
                                <span class="text-left ds-b fts12 ftw4 lh20 color-5 mb5">· 예금주 : (주)우미케이비뉴스테이제1호위탁관리부동산투자회사</span>
                                <span class="text-left ds-b fts12 ftw4 lh20 color-5">※단순 심경 변화에 의한 동호지정계약 해지 요청은 인정되지 않고, 귀속처리 됩니다. 또한, 지정된 계약일 (<span class="color-1">2021년 03월 11일 오후 17시까지</span>) 이내에 특별한 사유를 통보치 아니하고 정계약을 전환하지 않을 경우에는 기 납입한 동호지정신청금(총 ￦1,000,000원)은 위약금으로 처리되어 귀사에 귀속되며, 이에 대하여 당사에 어떠한 이의도 제기하지 않을 것임을 확인합니다.</span>
                            </div>
                            <div class="comin-cont">
                                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">명의변경</strong>
                                <span class="text-left ds-b fts14 ftw5 lh24 color-1">직계존·비속에 한해 가족관계증명서 제출/확인자는 1회만 가능함.</span>
                            </div>
                        </div>
                    </div>
                    <v-subheader class="pd0 fts16 ftw5 mt15">동·호수 계약자</v-subheader>
                    <v-text-field
                        type="text"
                        label="이름"
                        required
                    ></v-text-field>
                    <v-row>
                        <v-col cols="5">
                            <v-text-field
                                type="number"
                                label="주민등록번호"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="1">
                            <v-card-text class="fts20">-</v-card-text>
                        </v-col>
                        <v-col cols="6">
                            <v-row>
                                <v-col cols="2" class="mr-p2">
                                    <v-text-field
                                        type="password"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="9" class="text-left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="45" viewBox="0 0 120 45">
                                        <g id="그룹_1247" data-name="그룹 1247" transform="translate(-220 -1493)">
                                            <g id="그룹_1239" data-name="그룹 1239" transform="translate(34 18)">
                                            <rect id="사각형_1755" data-name="사각형 1755" width="20" height="45" transform="translate(186 1475)" fill="none"/>
                                            <circle id="타원_167" data-name="타원 167" cx="3" cy="3" r="3" transform="translate(193 1502)"/>
                                            </g>
                                            <g id="그룹_1242" data-name="그룹 1242" transform="translate(74 18)">
                                            <rect id="사각형_1755-2" data-name="사각형 1755" width="20" height="45" transform="translate(186 1475)" fill="none"/>
                                            <circle id="타원_167-2" data-name="타원 167" cx="3" cy="3" r="3" transform="translate(193 1502)"/>
                                            </g>
                                            <g id="그룹_1244" data-name="그룹 1244" transform="translate(114 18)">
                                            <rect id="사각형_1755-3" data-name="사각형 1755" width="20" height="45" transform="translate(186 1475)" fill="none"/>
                                            <circle id="타원_167-3" data-name="타원 167" cx="3" cy="3" r="3" transform="translate(193 1502)"/>
                                            </g>
                                            <g id="그룹_1240" data-name="그룹 1240" transform="translate(54 18)">
                                            <rect id="사각형_1755-4" data-name="사각형 1755" width="20" height="45" transform="translate(186 1475)" fill="none"/>
                                            <circle id="타원_167-4" data-name="타원 167" cx="3" cy="3" r="3" transform="translate(193 1502)"/>
                                            </g>
                                            <g id="그룹_1241" data-name="그룹 1241" transform="translate(94 18)">
                                            <rect id="사각형_1755-5" data-name="사각형 1755" width="20" height="45" transform="translate(186 1475)" fill="none"/>
                                            <circle id="타원_167-5" data-name="타원 167" cx="3" cy="3" r="3" transform="translate(193 1502)"/>
                                            </g>
                                            <g id="그룹_1243" data-name="그룹 1243" transform="translate(134 18)">
                                            <rect id="사각형_1755-6" data-name="사각형 1755" width="20" height="45" transform="translate(186 1475)" fill="none"/>
                                            <circle id="타원_167-6" data-name="타원 167" cx="3" cy="3" r="3" transform="translate(193 1502)"/>
                                            </g>
                                        </g>
                                    </svg>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-text-field
                        type="email"
                        label="이메일"
                        required
                    ></v-text-field>
                    <v-text-field
                        type="text"
                        label="주소"
                        required
                    ></v-text-field>
                    <v-text-field
                        type="number"
                        label="연락처"
                        :counter="12"
                        required
                    ></v-text-field>
                    <v-text-field
                        type="number"
                        label="휴대폰"
                        :counter="12"
                        required
                    ></v-text-field>
                    <div class="pwidth100">
                        <span class="text-left ds-b fts14 ftw4 lh24 color-6 mb5">※ 본 계약시 준비서류[서류는 계약일로부터 1주일 이내 발급분]</span>
                        <span class="text-left ds-b fts14 ftw4 lh24 color-6 mb5">[본인 방문시] 인감증명서1부, 주민등록등본1부, 인감도장, 신분증, 계약금입금증</span>
                        <span class="text-left ds-b fts14 ftw4 lh24 color-6 mb5">[대리인 추가서류] 계약위임용 인감증명서1부, 위임장(당사양식), 대리인 신분증 및 도장</span>
                    </div>
                    <div class="complete-box">
                        <div class="comin-box">
                            <div class="comin-cont">
                                <v-checkbox color="primary" label="상기 모든 내용을 확인하였으며, 이에 동의합니다."></v-checkbox>
                            </div>
                            <div class="comin-cont">
                                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">동호지정 신청 당일</strong>
                                <span class="text-left ds-b fts14 ftw5 lh24 color-5">2021년 03월 08일</span>
                            </div>
                        </div>
                    </div>
                </v-form>
                
                <v-card-actions class="justify-center pd0 mt20">
                    <v-btn class="pd10 color-2 bgc-4 pwidth48 mr-p4 fts18 ftw7" min-height="50">뒤로가기</v-btn>
                    <v-btn color="primary" class="fts18 ftw7 mw-400 pwidth48 mg0 fts18 ftw7" min-height="50">계약신청</v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-container>
</template>

<style>
.v-input__slot {margin-bottom:5px;}
.v-input--selection-controls {margin:0; padding:0;}
.v-messages {min-height:0;}
</style>

<script>
export default {
  name: "WaitForm",
  components: {
  },
  data() {
    return{
      villageList: [],
      dong: [],
      ho: [],
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    init() {
      this.villageList = [
        {
          village_name: "충북 진천군 덕산면 대하로 150 우미 린스테이",
          id: "1", // master id
        },
        {
          village_name: "파주시 운정로",
          id: "2", // master id
        },
      ];
      this.dong = [
        {
          dong_num: "408동",
          id: "1", // master id
        },
      ]
      this.ho = [
        {
          ho_num: "102호",
          id: "1", // master id
        },
      ]
    },
  },
  created() {
    this.init();
  },
};
</script>
