<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <!-- 2024.02.01 신규 팝업 생성 st -->
    <div class="loading-box" v-if="isLoading">
      <div class="ldb-inbox">
        <span class="loader"></span>
        <strong class="ldb-text">목록을 생성하고 있습니다. 잠시만 기다려주세요.</strong>
      </div>
    </div>
    <!-- 2024.02.01 신규 팝업 생성 ed -->
    <v-container class="pad nb mypi-web">
      <div class="mypi-contbox">
        <strong class="t-cont-title">입주예약</strong>
        <span class="no-list" v-if="!request.length">
          현재 신청 가능한 예약이 없습니다.
        </span>
        <ul class="mypi-long" v-if="request.length">
          <li v-for="item in request" :key="'win-reservations-' + item.id" :class="request.length == 1 ? 'single' : ''"
            @click="moveTo(item)">
            <router-link to>
              <span class="left">
                <Iconsample />
                <span class="sidetop">{{ item.village_name }}</span>
                {{ item.title }}
                <span class="side" v-if="item.status_text">[{{ item.status_text }}]</span>
              </span>
            </router-link>
          </li>

          <li v-if="isSystemCheck" class="single">
            <router-link to>
              <span class="left">
                <Iconsample />
                <span class="sidetop">시스템 안내 : "입주예약" 시스템 변경으로 아래 기간동안 입주예약이
                  제한됩니다.</span>

                <span class="side">2023.07.27 19:00 ~ 2023.07.28 09:00</span>
              </span>
            </router-link>
          </li>
        </ul>
        <!-- 2024.02.01 새로고침 버튼 적용 st -->
        <v-btn color="primary" class="fts18 ftw7 mw-400 pwidth100 mt15 mb15" min-height="45" block
          @click="getRequestList()">
          예약 목록 새로고침
          <v-icon class="ml5">mdi-cached</v-icon>
        </v-btn>
        <!-- 2024.02.01 새로고침 버튼 적용 st -->
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { getOpenMoveInVillage, getLambdaMoveinAPI } from "@/api/index.js";

import Iconsample from "@/assets/images/mypage/icon-sample.svg"; // 견본주택방문
export default {
  components: {
    Iconsample,
  },
  name: "ReservationList",
  data: () => ({
    request: [],
    isLoading: false,
  }),
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    isSystemCheck() {
      let now = new Date();
      let start = new Date("2023-07-27 19:00");
      let end = new Date("2023-07-28 09:00");

      return start <= now && now <= end;
    },
  },
  methods: {
    moveTo(item) {
      this.$router.push({
        name: "moveInReservationForm",
        query: {
          id: 0,
          selectVillageId: item.id,
          village_name: item.village_name,
        },
      });
    },

    getRequestList() {
      this.isLoading = true;
      let params = {}


      getOpenMoveInVillage(params)
        .then((res) => {
          this.request = res.data.map((village) => {
            village.title = village.village_name + " 입주예약";
            return village;
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    isCustomMoveinPeriod() {
      let now = new Date();
      let end = new Date("2024-02-07 00:00:00");
      return now < end
    }
  },

  created() {
    this.$store.dispatch("USER_REFRESH", true);
    this.getRequestList();
  },
};
</script>

<style>
.loading-box {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 60;
  background-color: rgba(255, 255, 255, 0.35);
  justify-content: center;
  align-items: center;
}

.ldb-inbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 50px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
