<template>
    <v-container class="bg-gray vh-full pd20">
        <v-card max-width="750" class="mx-auto bg-gray nops">
            <v-card-title class="justify-center color-1 fts18 mb">
                사전점검 예약
            </v-card-title>
            <v-card-text class="mt20">
                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">방문예정 일자</strong>
                <span class="text-left ds-b fts14 lh24 color-5">2021년 02월 26일</span>
                <span class="text-left ds-b fts14 lh24 color-5">09:00 ~ 10:30</span>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">이름</strong>
                <span class="text-left ds-b fts14 lh24 color-5">김지원</span>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">전화번호</strong>
                <span class="text-left ds-b fts14 lh24 color-5">010-1234-5678</span>
            </v-card-text>
            <v-divider></v-divider>
        </v-card>
        <v-container class="pd20 ps-ab ps-b80 ps-l0">
            <v-card-actions class="justify-center pd0 mt20">
                <v-btn class="pd10 color-1 bgc-3 pwidth48 mr-p4 v-active fts18 ftw7" min-height="50">예약 취소하기</v-btn>
                <v-btn class="pd10 color-1 bgc-3 pwidth48 mg0 v-active fts18 ftw7" min-height="50">수정하기</v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center pd0 mt10">
                <v-btn color="primary" class="fts18 ftw7 mw-400 pwidth100" min-height="50">로그인</v-btn>
            </v-card-actions>
        </v-container>
    </v-container>
</template>

<script>
export default {
    name: "precom",
    data() {
    },
    methods: {
    },
    created() {
    },
    computed: {
    },
};
</script>