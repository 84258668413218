<template>
    <v-container class="pd16">
        <strong class="t-center-title">계약 갱신 신청</strong>
        <strong class="t-cont-title">계약 갱신 안내사항</strong>
        <ul class="bull-list">
            <li>계약 갱신은 임대차 기간 만료 전 지정 기간에 한하여 2년 단위로 연장할 수 있습니다.</li>
            <li>계약 갱신 방문일자 신청 후 서류를 구비하여 통합관리센터로 방문바랍니다.</li>
            <li>문의전화번호: 000-000-0000</li>
        </ul>
        <strong class="t-sub-title">임대 보증금 및 임대료 변동 내역</strong>
        <table class="colorfull-table">
            <colgroup>
                <col width="33%">
                <col width="33%">
                <col width="34%">
            </colgroup>
            <tr>
                <th class="trans"></th>
                <th class="cto1 text-right">임대보증금</th>
                <th class="cto1 text-right">임대료</th>
            </tr>
            <tr>
                <td class="cto2 text-left">표준</td>
                <td class="cto2 text-right">102,400,000</td>
                <td class="cto2 text-right">135,500</td>
            </tr>
            <tr>
                <td class="cto3 text-left">전환 I</td>
                <td class="cto3 text-right">102,400,000</td>
                <td class="cto3 text-right">135,500</td>
            </tr>
            <tr>
                <td class="cto1 text-left">증액</td>
                <td class="cto1 text-right">-100</td>
                <td class="cto1 text-right">500</td>
            </tr>
        </table>
        <strong class="t-cont-title">계약기간</strong>
        <strong class="t-sub-title">구분 : 표준형</strong>
        <table class="colorfull-table">
            <colgroup>
                <col width="50%">
                <col width="50%">
            </colgroup>
            <tr>
                <td class="cto2 text-left none-line">현재</td>
                <td class="cto2 text-right">2021.01.01</td>
            </tr>
            <tr>
                <td class="cto3 text-left none-line">변경</td>
                <td class="cto3 text-right">2022.01.01</td>
            </tr>
        </table>
        <strong class="t-cont-title">체결 기한 및 방법</strong>
        <div class="text-set">
            <p>재계약 일자  : 2021.03.21 ~ 2021.04.14</p>
            <p>장소 : 커뮤니티센터 내 통합관리센터</p>
            <p>구비서류 필참 (서류 없을 시 재계약은 불가합니다)</p>
            <a href="#n">구비서류 보기</a>
        </div>
        <strong class="t-cont-title">계약 변경 방문 예약</strong>
        <div>
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    label="계약 변경 희망일"
                    v-model="date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="date"
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-dialog>
        </div>
        <div class="address-set">
            <p>충북혁신도시 우미린스테이</p>
            <p>405동 1902호</p>
            <p>임차인 홍길동</p>
            <p>신청일 : 2021년 3월 11일</p>
        </div>
        <v-btn color="primary" class="fts18 ftw7 mw-700 pwidth100" min-height="50">계약 갱신 신청하기</v-btn>
    </v-container>
</template>

<script>
export default {
    components: { 
    },
    name: "SetRenewal",
    data(){
        return {
            picker: new Date().toISOString().substr(0, 10),
        }
    }
};
</script>
