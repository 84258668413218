<template>
    <v-container class="vh-footer ps-re lynn-mobile pd16 pt50">
        <strong class="close-title">
            2021년 2월분 임대료 고지서
            <a @click="$router.go(-1)">
                <Back/>
            </a>
        </strong>
        <table class="t-table table1 mb15">
            <colgroup>
                <col width="65px">
                <col width="*">
            </colgroup>
            <tr>
                <th>기간</th>
                <td>2021.03.01 ~ 2021.03.31</td>
            </tr>
            <tr>
                <th>아파트</th>
                <td>90.41 A ㎡</td>
            </tr>
        </table>
        <table class="open-table table2">
            <colgroup>
                <col width="50%">
                <col width="*">
            </colgroup>
            <tr>
                <th>당월 임대료</th>
                <td>135,000</td>
            </tr>
            <tr class="rows"> 
                <th>미납 임대료</th>
                <td>500</td>
            </tr>
            <tr>
                <th>미납 연체료</th>
                <td>0</td>
            </tr>
            <tr>
                <th>미청구 금액</th>
                <td>0</td>
            </tr>
            <tr>
                <th>임대보증수수료</th>
                <td>0</td>
            </tr>
            <tr>
                <th>과오납차감</th>
                <td>0</td>
            </tr>
            <tr class="total">
                <th>납기 내</th>
                <td>135,500</td>
            </tr>
            <tr class="total">
                <th>납부기한</th>
                <td>2021.05.10</td>
            </tr>
            <tr class="total rows">
                <th>납기 후 임대료</th>
                <td>연체료 일할 계산 청구</td>
            </tr>
        </table>
        <p class="text-left mt10 mb30 fts12 color-2">※납부기한을 넘겨 납부하면 연체료가 일할계산되어 다음달 임대료에 포함됩니다.</p>
        <strong class="t-cont-title">납부처 및 납부방법</strong>
        <table class="t-table table1 mb15">
            <colgroup>
                <col width="95px">
                <col width="*">
            </colgroup>
            <tr>
                <th>납부기관</th>
                <td>NH농협은행</td>
            </tr>
            <tr>
                <th>납부계좌</th>
                <td>856790-71-001881</td>
            </tr>
            <tr>
                <th>예금주</th>
                <td>㈜우미케이비뉴스테이제1호<br/>위탁관리부동산투자회사</td>
            </tr>
            <tr>
                <th>사업자등록번호</th>
                <td>321-81-00304</td>
            </tr>
            <tr>
                <th>임대사업자<br/>등록번호</th>
                <td class="v_t">2016-강남구-임대사업자-10441</td>
            </tr>
        </table>
        <strong class="t-cont-title">공지사항</strong>
        <p class="text-left mt10 mb30 fts12 color-2">-</p>
    </v-container>
</template>

<script>
import Back from "@/assets/images/btn/btn-back.svg";

export default {
    components: {
        Back,
    },
    name: "SetUnhistory",
    data: () => ({
        picker: new Date().toISOString().substr(0, 10),
    }),
};
</script>
