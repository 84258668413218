<template>
  <v-container class="pd16">
    <strong class="t-center-title">계약 해지 방문 예약</strong>
    <strong class="t-cont-title">계약 해지 안내사항</strong>
    <ul class="bull-list">
      <li style="font-size: 14px">
        조건이 부적합할시 예약이 취소될수 있음을 안내드립니다.
      </li>
      <li style="font-size: 14px">구비서류를 필히 지참하시어 방문바랍니다.</li>
      <li style="font-size: 14px">
        미입주 계약 해지 시에는 위약금<span v-if="home.penalty_amount != 0"
          >({{ home.penalty_amount | comma }}원)</span
        >이 발생합니다.
      </li>
    </ul>
    <strong class="t-cont-title">계약 해지 신청 구분</strong>
    <div class="mb20">
      <v-radio-group class="pd0 mg0" column mandatory>
        <v-radio label="미입주 해지"></v-radio>
      </v-radio-group>
    </div>
    <strong class="t-cont-title">계약 정보</strong>
    <table class="t-table table1">
      <colgroup>
        <col width="65px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>계약일자</th>
        <td>{{ contractInfo.contractDate }}</td>
      </tr>
      <!-- <tr v-if="contractInfo.leaseInfo != null">
        <th>계약기간</th>
        <td>
          {{
            contractInfo.leaseInfo.rentFrom != null &&
            contractInfo.leaseInfo.rentTo != null
              ? contractInfo.leaseInfo.rentFrom +
                " ~ " +
                contractInfo.leaseInfo.rentTo
              : "임차인이 임대주택을 인도받은 날로부터 24개월이 되는 날까지"
          }}
        </td>
      </tr> -->
      <tr>
        <th>동</th>
        <td>{{ home.dong_no }}동</td>
      </tr>
      <tr>
        <th>호</th>
        <td>{{ home.ho_no }}호</td>
      </tr>
      <tr v-if="contractInfo.leaseInfo != null">
        <th>보증금</th>
        <td>{{ contractInfo.leaseInfo.rentDeposit | comma }} 원</td>
      </tr>
      <tr v-if="contractInfo.leaseInfo != null">
        <th>임대료</th>
        <td>{{ contractInfo.leaseInfo.rent | comma }} 원</td>
      </tr>
      <!-- <tr>
        <th>입주일</th>
        <td>{{ contractInfo.moveInDate }}</td>
      </tr> -->
    </table>

    <strong class="t-cont-title">상세 안내</strong>
    <div class="text-set">
      <p>장소 : 커뮤니티센터 내 통합관리센터</p>
      <p>
        구비서류 필참
        <a href="#n" @click.prevent.stop="dialog = true">구비서류 보기</a>
      </p>
    </div>

    <strong class="t-cont-title">계약 해지 방문 예약</strong>
    <div>
      <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-if="isBalancePaid"
            label="계약 해지 방문 희망일"
            disabled
            value="계약해지 신청은 잔금납부 이전에만 가능합니다."
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
          <v-text-field
            v-else
            label="계약 해지 방문 희망일"
            :disabled="isRequestExist"
            v-model="date"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :allowed-dates="allowedDates"
          :min="today"
          @change="menu = false"
          :day-format="(date) => new Date(date).getDate()"
          locale="KR"
          full-width
          no-title
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="address-set">
      <p>{{ home.village_name }}</p>
      <p>{{ home.dong_no }}동 {{ home.ho_no }}호</p>
      <p>임차인 {{ contractInfo.contractorName }}</p>
      <p>
        신청일 : {{ today.substr(0, 4) }}년 {{ today.substr(5, 2) }}월
        {{ today.substr(8, 2) }}일
      </p>
    </div>
    <div v-if="isRequestExist">
      <v-btn
        color="grey lighten-3"
        class="fts18 ftw7 mw-700 pwidth70 grey--text text--lighten-1"
        min-height="50"
        elevation="0"
        >접수완료</v-btn
      >
      <v-btn
        color="primary"
        class="fts18 ftw7 mw-700 pwidth20 ml-2"
        min-height="50"
        elevation="0"
        @click="cancelDialog = true"
        >신청취소</v-btn
      >
    </div>
    <div v-else>
      <v-btn
        v-if="!is_form_ready"
        color="grey lighten-3"
        class="fts18 ftw7 mw-700 pwidth100 grey--text text--lighten-1"
        min-height="50"
        elevation="0"
        >계약 해지 신청하기</v-btn
      >
      <v-btn
        v-else
        color="primary"
        class="fts18 ftw7 mw-700 pwidth100"
        min-height="50"
        elevation="0"
        :disabled="!is_form_ready"
        @click="postRentalContractChangeAPI()"
        >계약 해지 신청하기</v-btn
      >
    </div>
    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          계약 해지 방문예약을 취소하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="delRentalContractChangeAPI(id)"
          >
            예
          </v-btn>
          <v-btn color="primary darken-1" text @click="cancelDialog = false">
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-container class="pd20 bgc-3 vh-full">
        <strong class="close-title" style="background-color: #ffffff">
          구비서류안내
          <a @click="dialog = false"><Close /></a>
        </strong>
        <div class="pd0 text-left">
          <strong class="ds-b lh24 fts14 ftw5 color-5">본인방문</strong>
          <ul class="pd0 mb24">
            <li class="fts14 ftw24 color-5">·동호포기각서</li>
            <li class="fts14 ftw24 color-5">·환불요청서</li>
            <li class="fts14 ftw24 color-5">·입금증</li>
            <li class="fts14 ftw24 color-5">·신분증 사본</li>
            <li class="fts14 ftw24 color-5">·환불 받을 본인명의 통장사본</li>
            <li class="fts14 ftw24 color-5">·인감증명서 1부</li>
          </ul>

          <strong class="ds-b lh24 fts14 ftw5 color-5">대리인 방문</strong>
          <ul class="pd0 mb24">
            <li class="fts14 ftw24 color-5">·본인방문 구비서류</li>
            <li class="fts14 ftw24 color-5">·대리인 신분증</li>
            <li class="fts14 ftw24 color-5">·위임장</li>
            <li class="fts14 ftw24 color-5">·위임용 인감증명서 1부</li>
            <li class="fts14 ftw24 color-5">·가족관계증명서</li>
          </ul>
        </div>
        <v-btn
          color="primary"
          elevation="0"
          class="fts18 ftw7 mw-700 pwidth100 mt30"
          min-height="50"
          @click="dialog = false"
          >확인</v-btn
        >
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import { getContract, getLeaseDepositInfo } from "@/api/contract.js";
import {
  postRentalContractChange,
  delRentalContractChange,
  getRentalContractChangeList,
} from "@/api/index.js";
import { get_now_string, isWeekend } from "@/utils/time.js";
import Close from "@/assets/images/contract/close.svg";
export default {
  components: { Close },
  name: "ContractCancel",
  data() {
    return {
      isRequestExist: false,
      id: 0,

      picker: new Date().toISOString().substr(0, 10),
      date: "",
      menu: false,
      contractInfo: {},
      today: get_now_string(),
      dialog: false,

      depositInfoBalance: {},
      isBalancePaid: false,

      cancelDialog: false,
    };
  },
  computed: {
    homeList() {
      return this.$store.getters.getHomeList;
    },
    home() {
      if (this.$route.query.home) {
        return this.homeList.find((item) => {
          return item.id == Number(this.$route.query.home);
        });
      }
      return this.$store.getters.getHome;
    },
    user() {
      return this.$store.getters.getUser;
    },
    is_form_ready() {
      return Boolean(this.date && !this.isBalancePaid);
    },
  },
  methods: {
    getRentalContractChangeListAPI() {
      let query = `user_id=${this.user.id}&&home_id=${this.home.id}&&change_type=TERMINATE_BEFORE_CONTRACT&&status=['REQUEST_STATUS_REQUESTED']`;
      getRentalContractChangeList(query).then((res) => {
        if (res.data.length) {
          let item = res.data[0];
          this.isRequestExist = true;
          this.date = item.visit_date;
          this.id = item.id;
        } else {
          this.date = "";
          this.id = 0;
          this.isRequestExist = false;
        }
      });
    },
    getContractInfo() {
      let query = ``;
      getContract(this.home.id).then((result) => {
        this.contractInfo = result.data;
      });
    },
    getLeaseDepositInfoAPI() {
      getLeaseDepositInfo(this.home.id).then((result) => {
        this.depositInfoBalance = result.data.balance;
        if (this.depositInfoBalance.length > 0) {
          if (this.depositInfoBalance[0].paidAmount > 0) {
            this.isBalancePaid = true;
            this.$store.commit("ERROR", "계약해지 신청 기간이 아닙니다.");
          } else if (this.depositInfoBalance.length > 1) {
            if (this.depositInfoBalance[1].paidAmount > 0) {
              this.isBalancePaid = true;
              this.$store.commit("ERROR", "계약해지 신청 기간이 아닙니다.");
            }
          }
        }
      });
    },
    postRentalContractChangeAPI() {
      let data = {
        user: this.user.id,
        home: this.home.id,
        rental_from_type: "",
        rental_to_type: "",
        change_type: "TERMINATE_BEFORE_CONTRACT",
        visit_datetime: this.date,
      };

      postRentalContractChange(data)
        .then((res) => {
          this.$store.commit("SUCCESS", "계약해지 신청되었습니다.");
          this.getRentalContractChangeListAPI();
        })
        .catch((err) => {
          this.$store.commit("ERROR", "계약해지 신청에 실패하였습니다.");
        });
    },
    delRentalContractChangeAPI(id) {
      delRentalContractChange(id)
        .then((re) => {
          this.$store.commit("SUCCESS", "방문예약이 취소 되었습니다.");
          this.cancelDialog = false;
          this.getRentalContractChangeListAPI();
        })
        .catch((err) => {
          console.log("실패...");
          console.log(err);
        });
    },
    allowedDates(val) {
      if (this.home.is_holiday_apply_to_expire_contract) {
        return !isWeekend(val) && !this.home.holidays.includes(val);
      } else {
        return !isWeekend(val);
      }
    },
  },
  created() {
    this.getRentalContractChangeListAPI();
    this.getContractInfo();
    this.getLeaseDepositInfoAPI();
  },
};
</script>
