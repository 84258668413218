<template>
  <v-container class="vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a
        @click="
          $router.push({
            name: 'loungeList',
            params: { search_param: $route.params.search_param },
          })
        "
        class="back"
      >
        <Back />
      </a>
      <strong class="lo-title">라운지</strong>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <Dropdown />
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="user.id == content.author">
            <v-list-item-title
              ><a @click="goModify()" style="color: #3c3c3c"
                >수정</a
              ></v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="user.id == content.author">
            <v-list-item-title
              ><a @click="deletePopup()" style="color: #3c3c3c"
                >삭제</a
              ></v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="user.id != content.author">
            <v-list-item-title
              ><a @click="doReport()">신고하기</a></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-card max-width="750" class="mx-auto nops" v-if="content.contents">
      <v-card-text class="pl0 pr0">
        <div class="lg-title">
          <strong class="in-title">{{ content.title }}</strong>
          <span class="name">{{ content.author_name }}</span>
          <div class="date">
            <span class="start">작성일: {{ date(content.createdAt) }}</span>
            <span class="modi">수정일: {{ date(content.updatedAt) }}</span>
          </div>
        </div>
        <div class="lg-contbox">
          <p v-html="setContents(content.contents)"></p>
          <br />
          <!-- TODO : 이미지 -->
          <!-- <v-img
            :src="require('@/assets/images/lounge/test11.png')"
            contain
            max-width="100%"
          ></v-img> -->
          <div class="lg-dec">
            <a @click="doReport" v-if="user.id != content.author">신고하기</a>
          </div>
          <!-- 신고하기 제일아래 두기 -->
        </div>
        <div class="lg-sympathy">
          <a @click="doLike()" class="lgs-btn" v-if="!content.my_like_id"
            ><HeartbigOff /><span>공감하기</span></a
          >
          <a @click="doLike()" class="lgs-btn active" v-if="content.my_like_id"
            ><HeartbigOn /><span>공감했어요</span></a
          >
          <ul class="lgs-cont">
            <li class="lgsc-left">
              <span>조회 </span>
              <span>{{ content.hit }}</span>
            </li>
            <li class="lgsc-right">
              <span><HeartSmall /> </span>
              <span>{{ content.like_count }}</span>
            </li>
          </ul>
        </div>

        <!-- 댓글입력창 -->
        <div class="lgs-cinput">
          <textarea v-model="reply_contents" />
          <a @click="postReply()"><Comment /></a>
        </div>

        <!-- 댓글 -->
        <ul class="lgs-comment">
          <li v-for="reply in replyList" :key="reply.id">
            <div class="lgs-cuser">
              <strong>{{ reply.author_name }}</strong>
              <span>{{ date(reply.createdAt) }}</span>
              <v-menu bottom left offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    <Dropdown />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="reply.author == user.id">
                    <v-list-item-title>
                      <a @click="delReply(reply.id)">삭제</a>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="reply.author == user.id">
                    <v-list-item-title>
                      <a @click="openModifyReply(reply.id)">수정</a>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="reply.author != user.id">
                    <v-list-item-title>
                      <a @click="doReportReply(reply.id)">신고</a>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <p>{{ reply.contents }}</p>
            <a
              @click="doReplyLike(reply.id, reply.my_like_id)"
              class="lgs-heart"
            >
              <span v-if="reply.my_like_id" class="ok">공감했어요</span>
              <span v-else class="go">공감하기</span>
              <span v-if="reply.like_count != 0" class="heart">
                <HeartSmall />
                <span>{{ reply.like_count }}</span>
              </span>
              <!-- 공감해야 하트생기게 하기 -->
            </a>

            <div
              :id="`mod${reply.id}`"
              style="display: none"
              class="reply_modify"
            >
              <textarea v-model="modReply_content"></textarea>
              <div class="rbtn-box">
                <a @click="modCancel()" class="can">취소</a>
                <a @click="modReply(reply.id)">수정</a>
              </div>
            </div>
          </li>
        </ul>
        <!-- 댓글 끝 -->
      </v-card-text>
    </v-card>

    <!-- 삭제 다이얼로그 -->
    <v-dialog v-model="delDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7"> 삭제하시겠습니까? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="delDialog = false">
            취소
          </v-btn>
          <v-btn color="primary darken-1" text @click="delBoard()">
            삭제
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
.location-box .v-btn {
  position: absolute;
  top: 16px;
  right: 0;
  min-width: auto !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
</style>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import Dropdown from "@/assets/images/lounge/dropdown.svg";
import HeartbigOff from "@/assets/images/lounge/heartbig_off.svg";
import HeartbigOn from "@/assets/images/lounge/heartbig_on.svg";
import HeartSmall from "@/assets/images/lounge/heartsmall.svg";
import Comment from "@/assets/images/lounge/comment.svg";
import {
  getFreeboard,
  delFreeboard,
  postLike,
  delLike,
  getLike,
  getReply,
  postReply,
  patchReply,
  delReply,
} from "@/api/index";
import moment from "moment";

export default {
  name: "LoungeView",
  components: {
    Back,
    Weblogo,
    Dropdown,
    HeartSmall,
    HeartbigOn,
    HeartbigOff,
    Comment,
  },
  data() {
    return {
      content: {},
      replyList: [],
      reply_contents: "",
      modReply_content: "",
      delDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    getContents(id) {
      getFreeboard(id).then((res) => {
        this.content = res.data;
        this.getReply(); // 댓글 가져오기
      });
    },
    setContents(content) {
      content = content.replace(/(?:\r\n|\r|\n)/g, "<br/>"); // 줄바꿈
      var regURL = new RegExp(
        "(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)",
        "gi"
      );
      content = content.replace(
        regURL,
        "<a href='$1://$2' target='_blank'>$1://$2</a>"
      );
      return content;
    },
    date(date) {
      var datetime = new Date(date);
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    goModify() {
      this.$router.push({
        name: "loungeWrite",
        params: { id: this.content.id },
      });
    },
    deletePopup() {
      this.delDialog = true;
    },
    delBoard() {
      //TODO : 다이얼로그 확인창
      this.delDialog = false;
      delFreeboard(this.content.id).then((res) => {
        this.$store.commit("SUCCESS", "삭제되었습니다.");
        this.$router.push({ name: "loungeList" });
      });
    },
    doReport() {
      if (this.content.did_i_report) {
        this.$store.commit("ERROR", "이미 접수된 신고 건이 있습니다.");
        return;
      } else {
        this.$router.push({
          name: "loungeReport",
          params: { id: this.content.id },
        });
      }
    },
    doReportReply(rId) {
      console.log(rId);

      var reported = this.replyList.filter((item) => {
        return item.id === rId;
      })[0].did_i_report;
      if (reported) {
        this.$store.commit("ERROR", "이미 접수된 신고 건이 있습니다.");
        return;
      } else {
        this.$router.push({
          name: "loungeReport",
          params: { id: rId },
        });
      }
    },
    doLike() {
      if (this.content.my_like_id) {
        // 좋아요 누른 상태에선 좋아요 취소
        delLike(this.content.id, this.content.my_like_id).then((res) => {
          this.content.my_like_id = null;
          this.content.like_count = this.content.like_count - 1;
        });
      } else {
        // 좋아요 등록
        postLike(this.content.id).then((res) => {
          this.content.my_like_id = res.data.id;
          this.userLikeId = res.data.id;
          this.content.like_count = this.content.like_count + 1;
        });
      }
    },
    doReplyLike(rpId, likeId) {
      if (likeId) {
        // 좋아요 누른 상태에선 좋아요 취소
        delLike(rpId, likeId).then((res) => {
          this.getReply();
        });
      } else {
        // 좋아요 등록
        postLike(rpId).then((res) => {
          this.getReply();
        });
      }
    },
    getReply() {
      getReply(this.content.id).then((res) => {
        console.log(res.data);
        this.replyList = res.data;
      });
    },
    postReply() {
      let data = {
        contents: this.reply_contents,
      };

      postReply(this.content.id, data).then((res) => {
        this.reply_contents = "";
        this.modReply_content = "";
        this.getReply();
      });
    },
    openModifyReply(id) {
      const modclass = document.getElementsByClassName("reply_modify");
      for (var i = 0; i < modclass.length; i++) {
        modclass[i].style = "display:none";
      }

      document.getElementById("mod" + id).style = "display:block";
    },
    modReply(id) {
      let data = {
        contents: this.modReply_content,
      };
      patchReply(this.content.id, id, data).then((res) => {
        this.$store.commit("SUCCESS", "수정되었습니다.");
        document.getElementById("mod" + id).style = "display:none";
        this.modReply_content = "";
        this.getReply();
      });
    },
    modCancel() {
      const modclass = document.getElementsByClassName("reply_modify");
      for (var i = 0; i < modclass.length; i++) {
        modclass[i].style = "display:none";
      }
    },
    delReply(id) {
      delReply(this.content.id, id).then((res) => {
        this.$store.commit("SUCCESS", "삭제되었습니다.");
        this.getReply();
      });
    },
  },
  created() {
    console.log(this.$route.params.search_param);
    this.getContents(this.$route.params.id);
  },
};
</script>
