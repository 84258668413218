<template>
  <v-container>
    <!--<strong class="t-cont-title">대납금액 현황</strong>-->
    <v-row class="px-2">
      <strong class="t-cont-title">후불이자</strong>
      <table class="t-table table5 mb10">
        <colgroup>
          <col width="140px" />
          <col width="*" />
        </colgroup>
        <tr class="bold">
          <th>대납금액</th>
          <td>{{ sellReceipt.AM_GRACEINTEREST | comma }}</td>
        </tr>
        <tr class="bold">
          <th>상환금액</th>
          <td style="color: #39abb4">
            {{ sellReceipt.AM_LOANINTEREST | comma }}
          </td>
        </tr>
        <tr class="bold">
          <th>잔여금액</th>
          <td>{{ sellReceipt.AM_LOANINTEREST_LEFT | comma }}</td>
        </tr>
      </table>

      <strong class="t-cont-title mt20">보증수수료</strong>
      <table class="t-table table5 mb10">
        <colgroup>
          <col width="140px" />
          <col width="*" />
        </colgroup>
        <tr class="bold">
          <th>대납금액</th>
          <td>{{ sellReceipt.AM_TOTASSUAPPLY | comma }}</td>
        </tr>
        <tr class="bold">
          <th>상환금액</th>
          <td style="color: #39abb4">
            {{ sellReceipt.AM_ASSU_REPLY | comma }}
          </td>
        </tr>
        <tr class="bold">
          <th>잔여금액</th>
          <td>{{ sellReceipt.AM_ASSULEFT | comma }}</td>
        </tr>
      </table>

      <strong class="t-cont-title mt20" v-if="note">비고</strong>
      <div class="order-list" v-if="note">
        <p v-html="note"></p>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { getChargesSellReceipt } from "@/api/contract.js";
import { getVillage } from "@/api/index.js";
export default {
  components: {},
  name: "SetPayment",
  data() {
    return {
      sellReceipt: {},
      note: "",
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    getSellReceipt() {
      getChargesSellReceipt(this.home.id)
        .then((result) => {
          console.log(result);
          this.sellReceipt = result.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getNote() {
      // TODO : 단지의 비고 정보를 가져옵니다. (API)
      getVillage(this.home.village).then((res) => {
        this.note = res.data.note_1;
      });
    },
  },
  created() {
    this.getSellReceipt();
    this.getNote();
  },
};
</script>
