<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="true"
    max-width="500px"
    height="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>{{ winVerification.title }}</v-card-title>
      <v-card-text class="">
        <v-row> </v-row>
        <v-row v-if="isWin">
          <v-col class="mr-2">
            <v-text-field
              label="동"
              v-model="winVerification.dong_no"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="호"
              v-model="winVerification.ho_no"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-list v-if="isWin">
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <div class="text-left color-5 fts16">
                  ※ 아파트 외 동,호수 입력방법
                </div>
              </v-list-item-content>
            </template>

            <v-list-item>
              <v-list-item-content>
                <div class="fts16 lh24 text-left color-6">
                  지하층일 경우 입력방식 : B+층+호실<br />
                  예 : B3층 101호 -> B3101
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center pb20">
        <v-btn
          class="fts18 ftw7 pwidth48 color-2 bgc-4"
          min-height="50"
          @click="close()"
        >
          뒤로가기
        </v-btn>
        <v-btn
          color="primary"
          class="fts18 ftw7 pwidth48"
          min-height="50"
          @click="verify()"
        >
          본인인증하기
        </v-btn>
      </v-card-actions>
      <div class="text-center" v-if="loadingBar">
        <v-progress-circular
          fullscreen
          indeterminate
          color="red"
          style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
        ></v-progress-circular>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// import { checkPreContractor } from "@/api/index.js";
import { loadNICEMain } from "@/api/NICE_id.js";
import { signupcheck } from "@/api/sign";

export default {
  name: "ContractVerification",
  data() {
    return {
      dialog: false,
      loadingBar: false,

      form: {},
    };
  },
  computed: {
    winVerification() {
      return this.$store.state.data.winVerification;
    },
    winVerification_dialog() {
      return this.winVerification.dialog;
    },
    isWin() {
      return this.winVerification.is_win;
    },
    isPreWin() {
      return this.winVerification.is_pre_win;
    },
  },
  watch: {
    winVerification_dialog(val) {
      this.dialog = val;
    },
  },
  methods: {
    verify() {
      // this.LoadMain();

      if (this.isPreWin) {
        this.LoadMain();
      } else {
        this.loadingBar = true;

        this.winVerification.is_erp_check = true;
        signupcheck(this.winVerification)
          .then((res) => {
            this.loadingBar = false;
            if (res.data.status == 200) {
              this.LoadMain();
            } else {
              this.$store.commit("ERROR", res.data.status_text);
            }
          })
          .catch(() => {
            this.loadingBar = false;
          });
      }
    },
    LoadMain() {
      this.loadingBar = true;

      this.winVerification.redirect = `reservationList?reservation_master=${this.winVerification.reservation_master}&reservation_code=${this.winVerification.reservation_code}`;

      loadNICEMain(this.winVerification)
        .then((re) => {
          this.loadingBar = false;
          this.nice_form = re.data;
          this.OpenPage();
          this.close();
        })
        .catch((er) => {
          this.loadingBar = false;
          this.$store.commit("ERROR", "당첨자 인증 호출에 실패하였습니다.");
          this.close();
        });
    },
    // Utils
    OpenPage() {
      window.open(
        "",
        "_self",
        "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      );

      let form = document.createElement("form");

      var EncodeData = document.createElement("input");
      EncodeData.type = "hidden";
      EncodeData.name = "EncodeData";
      EncodeData.value = this.nice_form.enc_data;

      var m = document.createElement("input");
      m.type = "hidden";
      m.name = "m";
      m.value = "checkplusSerivce";

      form.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      form.target = "_self";
      form.appendChild(EncodeData);
      form.appendChild(m);

      document.body.appendChild(form);
      form.submit();
    },
    close() {
      this.$store.commit("SET_WINVERIFICATON", {
        title: "당첨자 인증",
        dialog: false,
      });
    },
  },
  created() {},
};
</script>
