<template>
  <v-container class="bg-gray vh-full pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">비밀번호 찾기</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field v-model.trim="email" label="아이디(이메일)" :rules="emailRules"></v-text-field>
          <v-text-field v-if="step2" v-model.trim="code" label="인증코드"></v-text-field>
          <v-text-field
            v-if="step2"
            v-model.trim="password"
            label="새 비밀번호"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :rules="passwordRules"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <!-- <v-card-text>
        가입시 입력하신 번호로 인증코드를 전달해 드립니다. 인증코드 수신이 안 될
        경우 스팸메시지함을 확인하거나<br />
        '이메일로 인증코드 보내기' 로 인증코드를 확인하세요.
      </v-card-text> -->
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          class="fts18 ftw7 mw-400 pwidth100"
          min-height="50"
          v-if="!step2"
          :disabled="!valid"
          @click="sendCode()"
          >인증코드 전송</v-btn
        >
      </v-card-actions>
      <v-card-text>
        인증코드는 등록된 휴대폰번호로 전달해 드립니다.
      </v-card-text>
      <v-card-actions class="justify-center" v-if="step2">
        <v-btn color="primary" class="fts18 ftw7 mw-400 pwidth100" min-height="50" @click="changePassword()"
          >인증하기</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

import { emailConfirm } from "@/api/sign";
export default {
  components: { Back, Weblogo },
  name: "ForgetPassword",
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [(v) => !!v || "아이디를 입력하세요.", (v) => /.+@.+/.test(v) || "아이디는 이메일 형식이어야합니다."],
      code: "",
      showPassword: false,
      password: "",

      passwordRules: [(v) => !!v || "비밀번호를 입력하세요", (v) => v.length > 7 || "8자 이상으로 입력해주세요."],
      step2: false,
    };
  },
  methods: {
    sendCode() {
      this.$store
        .dispatch("forgotPassword", {
          username: this.email,
        })
        .then(() => {
          alert("등록하신 휴대폰번호 또는 이메일로 인증코드가 전송되었습니다.");
          this.step2 = true;
        })
        .catch((err) => {
          // Logging err
          this.$store.dispatch("LOGGING", {
            error: err,
            tags: `ForgetPassword|cognitoAPI|forgotPassword(username=${this.email})`,
            etc: {}, // Put anythings you want to log as object
          });

          console.log(err);
          if (err.code == "UserNotFoundException") {
            alert("존재하지 않는 회원입니다. 이메일을 확인해주세요.");
          } else if (err.code == "UserNotConfirmedException" || err.code == "InvalidParameterException") {
            alert("인증되지 않은 회원입니다. 인증코드 발송 페이지로 이동합니다.");
            this.$router.push({
              name: "confirm",
              params: { email: this.email },
            });
          } else if (err.code == "LimitExceededException") {
            alert("시도 제한을 초과했습니다. 잠시 후 시도하십시오.");
          } else {
            alert("관리자에게 문의해주세요.");
          }
        });
    },
    reConfirmEmail() {
      //To do: API 호출하여 backend에서 이 유저의 cognito phone_number attribute를 삭제한다.
      //call back으로 sendCode() 호출.
      // 단지 정보 세팅
      emailConfirm({ username: this.email }).then(() => {
        this.sendCode();
      });
      return true;
    },
    changePassword() {
      if (this.password.length < 8) {
        this.$store.commit("ERROR", "비밀번호는 8자 이상 지정가능합니다.");
        return;
      }

      this.$store
        .dispatch("confirmPassword", {
          username: this.email,
          code: this.code,
          newPassword: this.password,
        })
        .then(() => {
          alert("변경되었습니다.");
          this.$router.push({ name: "login" });
        })
        .catch((err) => {
          console.log(err);
          // Logging err
          this.$store.dispatch("LOGGING", {
            error: err,
            tags: `ForgetPassword.vue|cognitoAPI|changePassword(username=${this.email},code=${this.code})`,
            etc: {}, // Put anythings you want to log as object
          });
        });
    },
  },
};
</script>
