<template>
  <v-container class="pd16">
    <ul class="under-list">
      <li>고객님이 계약하신 단지의 납부정보를 조회하실 수 있습니다</li>
      <li>계약자인 경우에 한해 계약현장의 분양대금 수납조회가 가능합니다</li>
      <li>
        린라이프에서 제공하는 분양대금조회서비스는 일반적으로 분양대금 입금일
        익일에 조회가 가능합니다
      </li>
    </ul>
    <table class="unline-table">
      <colgroup>
        <col width="95px" />
        <col width="*" />
      </colgroup>
      <tr>
        <td>사업지&nbsp;&nbsp;</td>
        <td>
          <strong>{{ home.village_name }}&nbsp;&nbsp;</strong>
        </td>
      </tr>
      <tr>
        <td>동 / 호&nbsp;&nbsp;</td>
        <td>
          <strong>{{ home.dong_no }}동 {{ home.ho_no }}호&nbsp;&nbsp;</strong>
        </td>
      </tr>
    </table>

    <strong class="t-cont-title"> 별도계약품목 납부내역 </strong>
    <v-select
      v-model="option"
      :items="options"
      item-value="contractId"
      item-text="name"
      @change="selectCategory()"
    ></v-select>
    <div class="list-gg">
      <v-slide-group v-model="activeItem" mandatory :center-active="true">
        <v-slide-item
          class="mr10 nh-50"
          v-for="(data, i) in paymentData"
          :key="'NAPIP-' + i"
          v-slot="{ active, toggle }"
        >
          <v-btn
            v-if="data.TY_NAPIP"
            active-class="v-active"
            :input-value="active"
            class="pd10 color-2 bgc-8 nw-100 text-left"
            depressed
            v-on:click="toggle"
            @click="
              selectedItem = data;
              selectedIdx = i;
            "
          >
            {{ data.TY_NAPIP }}
            {{
              data.AM_SELLING -
                data.AM_DISCOUNT +
                data.AM_DELAY -
                data.AM_RECEIVED ===
                0 &&
              data.TY_NAPIP != "합계" &&
              data.CHA != "C"
                ? "-완납"
                : setText(data)
            }}<br />
            &nbsp;
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>
    <!-- 납부금 검색필드 -->
    <div
      class="storage-table mb5"
      v-if="
        (paymentData.length > 0 && selectedItem.TY_NAPIP == '합계') ||
          (paymentData.length > 0 &&
            selectedItem.AM_SELLING -
              selectedItem.AM_DISCOUNT +
              select_item.AM_DELAY -
              selectedItem.AM_RECEIVED !==
              0) ||
          (paymentData.length > 0 && selectedItem.CHA == 'C')
      "
    >
      <table>
        <colgroup>
          <col width="130px" />
          <col width="*" />
          <col width="30%" />
        </colgroup>
        <tr>
          <th colspan="3" class="fts16">납부금 계산</th>
        </tr>
        <tr class="">
          <th colspan="3" class="lh24 pb15">
            납부일자에 따라 <b><u>연체금액</u></b
            >이 발생할 수 있습니다. 납부가능한 일자에 맞춰 조회하시길 바랍니다.
          </th>
        </tr>
        <tr>
          <th>납부예정일자</th>
          <td></td>
          <td>
            {{ payDate }}
            <!-- 연체료 계산 달력 -->
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              max-width="290"
              class="ds-ib"
            >
              <template v-slot:activator="{ on, attrs }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.409"
                  height="14.409"
                  viewBox="0 0 14.409 14.409"
                  v-bind="attrs"
                  v-on="on"
                >
                  <g
                    id="Icon_feather-search"
                    data-name="Icon feather-search"
                    transform="translate(-3.5 -3.5)"
                  >
                    <path
                      id="패스_588"
                      data-name="패스 588"
                      d="M15.162,9.831A5.331,5.331,0,1,1,9.831,4.5,5.331,5.331,0,0,1,15.162,9.831Z"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#da581f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="패스_589"
                      data-name="패스 589"
                      d="M27.874,27.874l-2.9-2.9"
                      transform="translate(-11.379 -11.379)"
                      fill="none"
                      stroke="#da581f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </template>
              <v-date-picker
                v-model="payDate"
                @change="menu = false"
                :day-format="(date) => new Date(date).getDate()"
                locale="KR"
                full-width
                no-title
              ></v-date-picker>
            </v-menu>
          </td>
        </tr>
        <tr>
          <th class="pl5 pb5 pl5 pt15" colspan="3">
            <a href="#n" @click="calculator()" class="sub-btn">조회</a>
          </th>
        </tr>
      </table>
    </div>
    <div class="storage-table" v-if="paymentData.length > 0">
      <!-- 전체 옵션일 때 -->
      <table v-if="option == ''">
        <colgroup>
          <col width="130px" />
          <col width="*" />
          <col width="30%" />
        </colgroup>
        <tr>
          <th>약정금액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_SELLING | comma }}
          </td>
        </tr>
        <tr v-if="selectedItem.calculated">
          <th>적용일수</th>
          <td></td>
          <td>
            {{ selectedItem.DD_DAY }}
          </td>
        </tr>
        <tr
          v-if="
            selectedItem.TY_NAPIP != '합계' &&
              selectedItem.DT_RECEIPT != null &&
              !selectedItem.calculated
          "
        >
          <th v-if="selectedItem.calculated && selectedItem.CHA == 'C'">
            납부예정일자
          </th>
          <th v-else>납부일자</th>
          <td></td>
          <td>
            {{ toDateFormat(selectedItem.DT_RECEIPT) }}
          </td>
        </tr>
        <tr v-if="selectedItem.calculated && selectedItem.CHA == 'C'">
          <th>연체금액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_DELAY | comma }}
          </td>
        </tr>
        <tr v-else>
          <th>연체금액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_DELAY | comma }}
          </td>
        </tr>
        <tr v-if="selectedItem.AM_RECEIVED != 0">
          <th>납부금액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_RECEIVED | comma }}
          </td>
        </tr>
        <tr
          v-if="
            (selectedItem.calculated && selectedItem.CHA == 'C') ||
              (selectedItem.calculated && selectedItem.TY_NAPIP == '합계')
          "
        >
          <th>납부예정금액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_RECEIPT | comma }}
          </td>
        </tr>
        <!-- <tr v-if="!selectedItem.calculated && 0 >= selectedItem.AM_MISU">
          <th>잔액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_MISU | comma }}
          </td>
        </tr> -->
      </table>

      <!-- 옵션을 선택했을 때 -->
      <table v-if="option != ''">
        <colgroup>
          <col width="130px" />
          <col width="*" />
          <col width="30%" />
        </colgroup>
        <tr>
          <th>약정금액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_SELLING | comma }}
          </td>
        </tr>
        <tr
          v-if="
            selectedItem.TY_NAPIP != '합계' && selectedItem.DT_AGREE != null
          "
        >
          <th>약정일자</th>
          <td></td>
          <td>
            {{ toDateFormat(selectedItem.DT_AGREE) }}
          </td>
        </tr>
        <tr v-if="selectedItem.calculated">
          <th>적용일수</th>
          <td></td>
          <td>
            {{ selectedItem.DD_DAY }}
          </td>
        </tr>
        <tr
          v-if="
            selectedItem.TY_NAPIP != '합계' && selectedItem.DT_RECEIPT != null
          "
        >
          <th v-if="selectedItem.calculated && selectedItem.CHA == 'C'">
            납부예정일자
          </th>
          <th v-else>납부일자</th>
          <td></td>
          <td>
            {{ toDateFormat(selectedItem.DT_RECEIPT) }}
          </td>
        </tr>
        <tr>
          <th>연체금액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_DELAY | comma }}
          </td>
        </tr>
        <tr>
          <th>납부금액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_RECEIVED | comma }}
          </td>
        </tr>
        <tr v-if="selectedItem.calculated && selectedItem.CHA != 'A'">
          <th>납부예정금액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_RECEIPT | comma }}
          </td>
        </tr>
        <!-- <tr v-if="!selectedItem.calculated && selectedItem.CHA == 'C'">
          <th>잔액</th>
          <td></td>
          <td>
            {{ selectedItem.AM_MISU | comma }}
          </td>
        </tr> -->
      </table>
    </div>

    <strong class="t-cont-title">납부처 및 납부방법</strong>
    <table class="t-table table5" v-if="extraBankAccount">
      <colgroup>
        <col width="80px" />
        <col width="*" />
      </colgroup>

      <tr>
        <th colspan="2">
          <strong class="t-cont-title">[확장]</strong>
        </th>
      </tr>
      <tr>
        <th>납부기관</th>
        <td>{{ bank }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ account }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ holder }}</td>
      </tr>
      <tr>
        <th colspan="2">
          <strong class="t-cont-title">[추가선택품목]</strong>
        </th>
      </tr>
      <tr>
        <th>납부기관</th>
        <td>{{ extraBank }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ extraBankAccount }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ extraHolder }}</td>
      </tr>
    </table>

    <table class="t-table table5" v-else>
      <colgroup>
        <col width="80px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>납부기관</th>
        <td>{{ bank }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ account }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ holder }}</td>
      </tr>
    </table>

    <div class="text-center" v-if="loadingBar">
      <v-progress-circular
        fullscreen
        indeterminate
        color="red"
        style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<style>
.list-gg .v-btn__content {
  align-items: flex-start;
  text-align: left;
  flex-flow: column;
}
</style>

<script>
import {
  getSellOptionInvoice,
  getSellOptionItemInvoice,
} from "@/api/contract.js";
import { get_now_string, toDateFormat } from "@/utils/time.js";
export default {
  components: {},
  name: "OptionPayment",
  data() {
    return {
      activeItem: 0,
      loadingBar: false,
      payDate: get_now_string(),
      menu: false,
      bank: "",
      account: "",
      holder: "",
      extraBank: "",
      extraBankAccount: "",
      extraHolder: "",
      paymentData: [],
      selectedItem: {},
      selectedIdx: "",
      addItem: {
        AM_DELAY: 0,
        AM_DISCOUNT: 0,
        AM_RECEIPT: 0,
        AM_TOTAL: 0,
      },
      select_item: ["전체", "1", "2", "3", "4"],
      option: "",
      options: [],

      payingIndex: "",
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    calculator() {
      this.payingIndex = "";
      let query = `&want_date=${this.payDate}`;
      this.paymentData = [{}, {}, {}, {}]; // 초기화
      if (this.option == "") {
        // 전체인 경우
        getSellOptionInvoice(this.home.id, query).then((result) => {
          console.log("getSellOptionInvoice calculator", result);
          //this.paymentData = result.data.payments;

          if (!result.data.downs.paid || result.data.downs.paid.AM_MISU) {
            this.payingIndex = `down`;
          } else if (
            !result.data.seconds.paid ||
            result.data.seconds.paid.AM_MISU
          ) {
            this.payingIndex = `second`;
          } else if (
            !result.data.balance.paid ||
            result.data.balance.paid.AM_MISU
          ) {
            this.payingIndex = `balance`;
          }

          // sum, downs, seconds, balance 순서대로 paymentData에 list로 넣어줌.
          // 합계(sum)
          if (result.data.sum.paid && result.data.sum.notpaid) {
            // 기납, 미납 둘 다 있는 경우
            this.paymentData[0].AM_SELLING =
              Number(result.data.sum.paid.AM_SELLING) +
              Number(result.data.sum.notpaid.AM_SELLING);
            this.paymentData[0].AM_RECEIVED = result.data.sum.paid.AM_RECEIVED;
            this.paymentData[0].AM_RECEIPT =
              result.data.sum.notpaid.AM_RECEIVED;
            this.paymentData[0].AM_MISU = result.data.sum.paid.AM_MISU;
            this.paymentData[0].AM_DISCOUNT =
              Number(result.data.sum.paid.AM_DISCOUNT) +
              Number(result.data.sum.notpaid.AM_DISCOUNT);
            this.paymentData[0].AM_DELAY =
              Number(result.data.sum.paid.AM_DELAY) +
              Number(result.data.sum.notpaid.AM_DELAY);
          } else if (result.data.sum.paid && !result.data.sum.notpaid) {
            // 기납만 있는 경우
            this.paymentData[0] = result.data.sum.paid;
          } else if (!result.data.sum.paid && result.data.sum.notpaid) {
            // 미납만 있는 경우
            this.paymentData[0] = result.data.sum.notpaid;
          }

          this.paymentData[0].TY_NAPIP = "합계";
          this.paymentData[0].calculated = true;

          // 계약금
          if (result.data.downs.paid && result.data.downs.notpaid) {
            // 기납, 미납 둘 다 있는 경우
            this.paymentData[1] = result.data.downs.paid;
            this.paymentData[1].CHA = "C";
            this.paymentData[1].AM_SELLING =
              Number(result.data.downs.paid.AM_SELLING) +
              Number(result.data.downs.notpaid.AM_SELLING);
            this.paymentData[1].AM_RECEIVED =
              result.data.downs.paid.AM_RECEIVED;
            this.paymentData[1].AM_RECEIPT =
              result.data.downs.notpaid.AM_RECEIVED;
          } else if (result.data.downs.paid && !result.data.downs.notpaid) {
            // 기납만 있는 경우
            this.paymentData[1] = result.data.downs.paid;
          } else if (!result.data.downs.paid && result.data.downs.notpaid) {
            // 미납만 있는 경우
            this.paymentData[1] = result.data.downs.notpaid;
            // AM_RECEIVED 값을 AM_MISU로 옮겨주고, DT_RECEIPT 값을 비워준다.
            // 서버에서는 금일로 조회한 값을 내려주기 땜에,, 여기서 가공해줌
            this.paymentData[1].AM_RECEIPT = this.paymentData[1].AM_RECEIVED;
            this.paymentData[1].AM_RECEIVED = 0;
            this.paymentData[1].DT_RECEIPT = null;
          }

          // 중도금 없는 경우 체크
          if (
            result.data.seconds.paid == null &&
            result.data.seconds.notpaid == null
          ) {
            // 잔금을 바로 넣는다.
            if (result.data.balance.paid && result.data.balance.notpaid) {
              // 기납, 미납 둘 다 있는 경우
              this.paymentData[2] = result.data.balance.paid;
              this.paymentData[2].CHA = "C";
              this.paymentData[2].AM_SELLING =
                Number(result.data.balance.paid.AM_SELLING) +
                Number(result.data.balance.notpaid.AM_SELLING);
              this.paymentData[2].AM_RECEIVED =
                result.data.balance.paid.AM_RECEIVED;
              this.paymentData[2].AM_RECEIPT =
                result.data.balance.notpaid.AM_RECEIVED;
            } else if (
              result.data.balance.paid &&
              !result.data.balance.notpaid
            ) {
              // 기납만 있는 경우
              this.paymentData[2] = result.data.balance.paid;
            } else if (
              !result.data.balance.paid &&
              result.data.balance.notpaid
            ) {
              // 미납만 있는 경우
              this.paymentData[2] = result.data.balance.notpaid;
              // AM_RECEIVED 값을 AM_MISU로 옮겨주고, DT_RECEIPT 값을 비워준다.
              // 서버에서는 금일로 조회한 값을 내려주기 땜에,, 여기서 가공해줌
              this.paymentData[2].AM_RECEIPT = this.paymentData[2].AM_RECEIVED;
              this.paymentData[2].AM_RECEIVED = 0;
              this.paymentData[2].DT_RECEIPT = null;
            }
            this.paymentData.pop(); //3번째 배열은 없앤다.
          } else {
            // 중도금, 잔금 순으로 넣는다.
            //////////// 중도금
            if (result.data.seconds.paid && result.data.seconds.notpaid) {
              // 기납, 미납 둘 다 있는 경우
              this.paymentData[2] = result.data.seconds.paid;
              this.paymentData[2].CHA = "C";
              this.paymentData[2].AM_SELLING =
                Number(result.data.seconds.paid.AM_SELLING) +
                Number(result.data.seconds.notpaid.AM_SELLING);
              this.paymentData[2].AM_RECEIVED =
                result.data.seconds.paid.AM_RECEIVED;
              this.paymentData[2].AM_RECEIPT =
                result.data.seconds.notpaid.AM_RECEIVED;
            } else if (
              result.data.seconds.paid &&
              !result.data.seconds.notpaid
            ) {
              // 기납만 있는 경우
              this.paymentData[2] = result.data.seconds.paid;
            } else if (
              !result.data.seconds.paid &&
              result.data.seconds.notpaid
            ) {
              // 미납만 있는 경우
              this.paymentData[2] = result.data.seconds.notpaid;
            }
            /////////// 잔금
            if (result.data.balance.paid && result.data.balance.notpaid) {
              // 기납, 미납 둘 다 있는 경우
              this.paymentData[3] = result.data.balance.paid;
              this.paymentData[3].CHA = "C";
              this.paymentData[3].AM_SELLING =
                Number(result.data.balance.paid.AM_SELLING) +
                Number(result.data.balance.notpaid.AM_SELLING);
              this.paymentData[3].AM_RECEIVED =
                result.data.balance.paid.AM_RECEIVED;
              this.paymentData[3].AM_RECEIPT =
                result.data.balance.notpaid.AM_RECEIVED;
            } else if (
              result.data.balance.paid &&
              !result.data.balance.notpaid
            ) {
              // 기납만 있는 경우
              this.paymentData[3] = result.data.balance.paid;
            } else if (
              !result.data.balance.paid &&
              result.data.balance.notpaid
            ) {
              // 미납만 있는 경우
              this.paymentData[3] = result.data.balance.notpaid;

              // AM_RECEIVED 값을 AM_MISU로 옮겨주고, DT_RECEIPT 값을 비워준다.
              // 서버에서는 금일로 조회한 값을 내려주기 땜에,, 여기서 가공해줌
              this.paymentData[3].AM_RECEIPT = this.paymentData[3].AM_RECEIVED;
              this.paymentData[3].AM_RECEIVED = 0;
              this.paymentData[3].DT_RECEIPT = null;
            }
          }
          this.selectedItem = this.paymentData[this.selectedIdx];
        });
      } else {
        getSellOptionItemInvoice(this.home.id, this.option, query).then(
          (result) => {
            console.log("getSellOptionItemInvoice calculator", result);

            if (!result.data.downs.paid || result.data.downs.paid.AM_MISU) {
              this.payingIndex = `down`;
            } else if (
              !result.data.seconds.paid ||
              result.data.seconds.paid.AM_MISU
            ) {
              this.payingIndex = `second`;
            } else if (
              !result.data.balance.paid ||
              result.data.balance.paid.AM_MISU
            ) {
              this.payingIndex = `balance`;
            }

            // sum, downs, seconds, balance 순서대로 paymentData에 list로 넣어줌.
            // 합계(sum)
            if (result.data.sum.paid && result.data.sum.notpaid) {
              // 기납, 미납 둘 다 있는 경우
              this.paymentData[0].AM_SELLING =
                Number(result.data.sum.paid.AM_SELLING) +
                Number(result.data.sum.notpaid.AM_SELLING);
              this.paymentData[0].AM_RECEIVED =
                result.data.sum.paid.AM_RECEIVED;
              this.paymentData[0].AM_RECEIPT =
                result.data.sum.notpaid.AM_RECEIVED;
              this.paymentData[0].AM_MISU = result.data.sum.paid.AM_MISU;
              this.paymentData[0].AM_DISCOUNT =
                Number(result.data.sum.paid.AM_DISCOUNT) +
                Number(result.data.sum.notpaid.AM_DISCOUNT);
              this.paymentData[0].AM_DELAY =
                Number(result.data.sum.paid.AM_DELAY) +
                Number(result.data.sum.notpaid.AM_DELAY);

              this.paymentData[0].DD_DAY = Number(result.data.sum.paid.DD_DAY);
            } else if (result.data.sum.paid && !result.data.sum.notpaid) {
              // 기납만 있는 경우
              this.paymentData[0] = result.data.sum.paid;
            } else if (!result.data.sum.paid && result.data.sum.notpaid) {
              // 미납만 있는 경우
              this.paymentData[0] = result.data.sum.notpaid;
            }

            this.paymentData[0].TY_NAPIP = "합계";
            this.paymentData[0].calculated = true;

            // 계약금
            if (result.data.downs.paid && result.data.downs.notpaid) {
              // 기납, 미납 둘 다 있는 경우
              this.paymentData[1] = result.data.downs.paid;

              this.paymentData[1].CHA = "C";
              this.paymentData[1].AM_SELLING =
                Number(result.data.downs.paid.AM_SELLING) +
                Number(result.data.downs.notpaid.AM_SELLING);
              this.paymentData[1].AM_RECEIVED =
                result.data.downs.paid.AM_RECEIVED;
              this.paymentData[1].AM_RECEIPT =
                result.data.downs.notpaid.AM_RECEIVED;
            } else if (result.data.downs.paid && !result.data.downs.notpaid) {
              // 기납만 있는 경우
              this.paymentData[1] = result.data.downs.paid;
            } else if (!result.data.downs.paid && result.data.downs.notpaid) {
              // 미납만 있는 경우
              this.paymentData[1] = result.data.downs.notpaid;
              // AM_RECEIVED 값을 AM_MISU로 옮겨주고, DT_RECEIPT 값을 비워준다.
              // 서버에서는 금일로 조회한 값을 내려주기 땜에,, 여기서 가공해줌
              this.paymentData[1].AM_RECEIPT = this.paymentData[1].AM_RECEIVED;
              this.paymentData[1].AM_RECEIVED = 0;
              this.paymentData[1].DT_RECEIPT = null;
            }

            // 중도금 없는 경우 체크
            if (
              result.data.seconds.paid == null &&
              result.data.seconds.notpaid == null
            ) {
              // 잔금을 바로 넣는다.
              if (result.data.balance.paid && result.data.balance.notpaid) {
                // 기납, 미납 둘 다 있는 경우
                this.paymentData[2] = result.data.balance.paid;

                this.paymentData[2].CHA = "C";
                this.paymentData[2].AM_SELLING =
                  Number(result.data.balance.paid.AM_SELLING) +
                  Number(result.data.balance.notpaid.AM_SELLING);
                this.paymentData[2].AM_RECEIVED =
                  result.data.balance.paid.AM_RECEIVED;
                this.paymentData[2].AM_RECEIPT =
                  result.data.balance.notpaid.AM_RECEIVED;
              } else if (
                result.data.balance.paid &&
                !result.data.balance.notpaid
              ) {
                // 기납만 있는 경우
                this.paymentData[2] = result.data.balance.paid;
              } else if (
                !result.data.balance.paid &&
                result.data.balance.notpaid
              ) {
                // 미납만 있는 경우
                this.paymentData[2] = result.data.balance.notpaid;
                // AM_RECEIVED 값을 AM_MISU로 옮겨주고, DT_RECEIPT 값을 비워준다.
                // 서버에서는 금일로 조회한 값을 내려주기 땜에,, 여기서 가공해줌
                this.paymentData[2].AM_RECEIPT = this.paymentData[2].AM_RECEIVED;
                this.paymentData[2].AM_RECEIVED = 0;
                this.paymentData[2].DT_RECEIPT = null;
              }
              this.paymentData.pop(); //3번째 배열은 없앤다.
            } else {
              // 중도금, 잔금 순으로 넣는다.
              //////////// 중도금
              if (result.data.seconds.paid && result.data.seconds.notpaid) {
                // 기납, 미납 둘 다 있는 경우
                this.paymentData[2] = result.data.seconds.paid;

                this.paymentData[2].CHA = "C";
                this.paymentData[2].AM_SELLING =
                  Number(result.data.seconds.paid.AM_SELLING) +
                  Number(result.data.seconds.notpaid.AM_SELLING);
                this.paymentData[2].AM_RECEIVED =
                  result.data.seconds.paid.AM_RECEIVED;
                this.paymentData[2].AM_RECEIPT =
                  result.data.seconds.notpaid.AM_RECEIVED;
              } else if (
                result.data.seconds.paid &&
                !result.data.seconds.notpaid
              ) {
                // 기납만 있는 경우
                this.paymentData[2] = result.data.seconds.paid;
              } else if (
                !result.data.seconds.paid &&
                result.data.seconds.notpaid
              ) {
                // 미납만 있는 경우
                this.paymentData[2] = result.data.seconds.notpaid;
              }
              /////////// 잔금
              if (result.data.balance.paid && result.data.balance.notpaid) {
                // 기납, 미납 둘 다 있는 경우
                this.paymentData[3] = result.data.balance.paid;
                this.paymentData[3].CHA = "C";
                this.paymentData[3].AM_SELLING =
                  Number(result.data.balance.paid.AM_SELLING) +
                  Number(result.data.balance.notpaid.AM_SELLING);
                this.paymentData[3].AM_RECEIVED =
                  result.data.balance.paid.AM_RECEIVED;
                this.paymentData[3].AM_RECEIPT =
                  result.data.balance.notpaid.AM_RECEIVED;
              } else if (
                result.data.balance.paid &&
                !result.data.balance.notpaid
              ) {
                // 기납만 있는 경우
                this.paymentData[3] = result.data.balance.paid;
              } else if (
                !result.data.balance.paid &&
                result.data.balance.notpaid
              ) {
                // 미납만 있는 경우
                this.paymentData[3] = result.data.balance.notpaid;

                // AM_RECEIVED 값을 AM_MISU로 옮겨주고, DT_RECEIPT 값을 비워준다.
                // 서버에서는 금일로 조회한 값을 내려주기 땜에,, 여기서 가공해줌
                this.paymentData[3].AM_RECEIPT = this.paymentData[3].AM_RECEIVED;
                this.paymentData[3].AM_RECEIVED = 0;
                this.paymentData[3].DT_RECEIPT = null;
              }
            }
            this.selectedItem = this.paymentData[this.selectedIdx];
          }
        );
      }
    },
    getOptionsPayments() {
      this.payingIndex = ``;
      this.loadingBar = true;
      this.paymentData = [{}, {}, {}, {}]; // 초기화
      getSellOptionInvoice(this.home.id, "")
        .then((result) => {
          if (!result.data.downs.paid || result.data.downs.paid.AM_MISU) {
            this.payingIndex = `down`;
          } else if (
            !result.data.seconds.paid ||
            result.data.seconds.paid.AM_MISU
          ) {
            this.payingIndex = `second`;
          } else if (
            !result.data.balance.paid ||
            result.data.balance.paid.AM_MISU
          ) {
            this.payingIndex = `balance`;
          }

          this.options = result.data.options.options;
          this.options.unshift({
            contractId: "",
            category: "전체",
            name: "전체",
          });
          // P1은 name을 '확장'으로 바꿔준다.
          this.options.filter((item) => {
            return item.name == "P1";
          })[0].name = "확장";
          this.options = this.options.filter((item) => {
            return item.price != 0;
          });
          this.loadingBar = false;
          console.log("getOptionsPayments", result);
          this.bank = result.data.options.bankName;
          this.account = result.data.options.bankAccount;
          this.holder = result.data.options.bankDepositor;

          if (this.account != result.data.options.extraBankAccount) {
            this.extraBank = result.data.options.extraBankName;
            this.extraBankAccount = result.data.options.extraBankAccount;
            this.extraHolder = result.data.options.extraBankDepositor;
          }

          // sum, downs, seconds, balance 순서대로 paymentData에 list로 넣어줌.
          // 기본 조회 data는 모두 paid에 담아 내려온다.
          this.paymentData[0] = result.data.sum.paid;
          //AM_RECEIVED에 연체금액이 포함되지 않아서, 미수금이 있을 땐 AM_RECEIVED = AM_SELLING + AM_DELAY - AM_MISU
          if (this.paymentData[0].AM_DELAY > 0) {
            this.paymentData[0].AM_RECEIVED =
              this.paymentData[0].AM_SELLING +
              this.paymentData[0].AM_DELAY -
              this.paymentData[0].AM_MISU;
          }
          this.paymentData[1] = result.data.downs.paid;
          //AM_RECEIVED에 연체금액이 포함되지 않아서, 미수금이 있을 땐 AM_RECEIVED = AM_SELLING + AM_DELAY - AM_MISU
          if (
            this.paymentData[1].AM_MISU == 0 &&
            this.paymentData[1].AM_DELAY > 0
          ) {
            this.paymentData[1].AM_RECEIVED =
              this.paymentData[1].AM_SELLING +
              this.paymentData[1].AM_DELAY -
              this.paymentData[1].AM_MISU;
          }

          // 중도금이 없는 경우도 있음.
          if (
            result.data.seconds.paid == null &&
            result.data.seconds.notpaid == null
          ) {
            this.paymentData[2] = result.data.balance.paid;
            this.paymentData.pop(); //3번째 배열은 없앤다.
          } else {
            this.paymentData[2] = result.data.seconds.paid;
            //AM_RECEIVED에 연체금액이 포함되지 않아서, 미수금이 있을 땐 AM_RECEIVED = AM_SELLING + AM_DELAY - AM_MISU
            if (
              this.paymentData[2].AM_MISU == 0 &&
              this.paymentData[2].AM_DELAY > 0
            ) {
              this.paymentData[2].AM_RECEIVED =
                this.paymentData[2].AM_SELLING +
                this.paymentData[2].AM_DELAY -
                this.paymentData[2].AM_MISU;
            }

            this.paymentData[3] = result.data.balance.paid;
            //AM_RECEIVED에 연체금액이 포함되지 않아서, 미수금이 있을 땐 AM_RECEIVED = AM_SELLING + AM_DELAY - AM_MISU
            if (
              this.paymentData[3].AM_MISU == 0 &&
              this.paymentData[3].AM_DELAY > 0
            ) {
              this.paymentData[3].AM_RECEIVED =
                this.paymentData[3].AM_SELLING +
                this.paymentData[3].AM_DELAY -
                this.paymentData[3].AM_MISU;
            }
          }

          this.activeItem = this.setActiveItem(this.paymentData);

          this.selectedItem = this.paymentData[this.activeItem];
          this.selectedIdx = this.activeItem;
        })
        .catch((e) => {
          this.loadingBar = false;
          console.log(e);
        });
    },
    selectCategory() {
      this.payDate = get_now_string();
      if (this.option == "") {
        this.getOptionsPayments();
        return;
      }

      this.loadingBar = true;
      this.paymentData = [{}, {}, {}, {}]; // 초기화
      getSellOptionItemInvoice(this.home.id, this.option, "")
        .then((result) => {
          console.log("getSellOptionItemInvoice", result);
          this.loadingBar = false;

          // sum, downs, seconds, balance 순서대로 paymentData에 list로 넣어줌.
          // 합계(sum)
          if (result.data.sum.paid && result.data.sum.notpaid) {
            // 기납, 미납 둘 다 있는 경우
            this.paymentData[0].AM_SELLING =
              Number(result.data.sum.paid.AM_SELLING) +
              Number(result.data.sum.notpaid.AM_SELLING);
            this.paymentData[0].AM_RECEIVED = result.data.sum.paid.AM_RECEIVED;
            this.paymentData[0].AM_RECEIPT =
              result.data.sum.notpaid.AM_RECEIVED;
            this.paymentData[0].AM_MISU = result.data.sum.paid.AM_MISU;
            this.paymentData[0].AM_DISCOUNT =
              Number(result.data.sum.paid.AM_DISCOUNT) +
              Number(result.data.sum.notpaid.AM_DISCOUNT);
            this.paymentData[0].AM_DELAY =
              Number(result.data.sum.paid.AM_DELAY) +
              Number(result.data.sum.notpaid.AM_DELAY);
          } else if (result.data.sum.paid && !result.data.sum.notpaid) {
            // 기납만 있는 경우
            this.paymentData[0] = result.data.sum.paid;
          } else if (!result.data.sum.paid && result.data.sum.notpaid) {
            // 미납만 있는 경우
            this.paymentData[0] = result.data.sum.notpaid;
          }
          this.paymentData[0].TY_NAPIP = "합계";
          this.paymentData[0].calculated = false;

          // 계약금
          if (result.data.downs.paid && result.data.downs.notpaid) {
            // 기납, 미납 둘 다 있는 경우
            this.paymentData[1] = result.data.downs.paid;
            this.paymentData[1].CHA = "C";
            this.paymentData[1].AM_SELLING =
              Number(result.data.downs.paid.AM_SELLING) +
              Number(result.data.downs.notpaid.AM_SELLING);
            this.paymentData.AM_RECEIVED = result.data.downs.paid.AM_RECEIVED;
            this.paymentData.AM_MISU = result.data.downs.notpaid.AM_RECEIVED;
          } else if (result.data.downs.paid && !result.data.downs.notpaid) {
            // 기납만 있는 경우
            this.paymentData[1] = result.data.downs.paid;
          } else if (!result.data.downs.paid && result.data.downs.notpaid) {
            // 미납만 있는 경우
            this.paymentData[1] = result.data.downs.notpaid;
            // AM_RECEIVED 값을 AM_MISU로 옮겨주고, DT_RECEIPT 값을 비워준다.
            // 서버에서는 금일로 조회한 값을 내려주기 땜에,, 여기서 가공해줌
            this.paymentData[1].AM_MISU = this.paymentData[1].AM_RECEIVED;
            this.paymentData[1].AM_RECEIVED = 0;
            this.paymentData[1].DT_RECEIPT = null;
          }

          // 중도금 없는 경우 체크
          if (
            result.data.seconds.paid == null &&
            result.data.seconds.notpaid == null
          ) {
            // 잔금을 바로 넣는다.
            if (result.data.balance.paid && result.data.balance.notpaid) {
              // 기납, 미납 둘 다 있는 경우
              this.paymentData[2] = result.data.balance.paid;
              this.paymentData[2].CHA = "C";
              this.paymentData[2].AM_SELLING =
                Number(result.data.balance.paid.AM_SELLING) +
                Number(result.data.balance.notpaid.AM_SELLING);
              this.paymentData.AM_RECEIVED =
                result.data.balance.paid.AM_RECEIVED;
              this.paymentData.AM_MISU =
                result.data.balance.notpaid.AM_RECEIVED;
            } else if (
              result.data.balance.paid &&
              !result.data.balance.notpaid
            ) {
              // 기납만 있는 경우
              this.paymentData[2] = result.data.balance.paid;
            } else if (
              !result.data.balance.paid &&
              result.data.balance.notpaid
            ) {
              // 미납만 있는 경우
              this.paymentData[2] = result.data.balance.notpaid;
              // AM_RECEIVED 값을 AM_MISU로 옮겨주고, DT_RECEIPT 값을 비워준다.
              // 서버에서는 금일로 조회한 값을 내려주기 땜에,, 여기서 가공해줌
              this.paymentData[2].AM_MISU = this.paymentData[2].AM_RECEIVED;
              this.paymentData[2].AM_RECEIVED = 0;
              this.paymentData[2].DT_RECEIPT = null;
            }
            this.paymentData.pop(); //3번째 배열은 없앤다.
          } else {
            // 중도금, 잔금 순으로 넣는다.
            //////////// 중도금
            if (result.data.seconds.paid && result.data.seconds.notpaid) {
              // 기납, 미납 둘 다 있는 경우
              this.paymentData[2] = result.data.seconds.paid;
              this.paymentData[2].CHA = "C";
              this.paymentData[2].AM_SELLING =
                Number(result.data.seconds.paid.AM_SELLING) +
                Number(result.data.seconds.notpaid.AM_SELLING);
              this.paymentData.AM_RECEIVED =
                result.data.seconds.paid.AM_RECEIVED;
              this.paymentData.AM_MISU =
                result.data.seconds.notpaid.AM_RECEIVED;
            } else if (
              result.data.seconds.paid &&
              !result.data.seconds.notpaid
            ) {
              // 기납만 있는 경우
              this.paymentData[2] = result.data.seconds.paid;
            } else if (
              !result.data.seconds.paid &&
              result.data.seconds.notpaid
            ) {
              // 미납만 있는 경우
              this.paymentData[2] = result.data.seconds.notpaid;
            }
            /////////// 잔금
            if (result.data.balance.paid && result.data.balance.notpaid) {
              // 기납, 미납 둘 다 있는 경우
              this.paymentData[3] = result.data.balance.paid;
              this.paymentData[3].CHA = "C";
              this.paymentData[3].AM_SELLING =
                Number(result.data.balance.paid.AM_SELLING) +
                Number(result.data.balance.notpaid.AM_SELLING);
              this.paymentData.AM_RECEIVED =
                result.data.balance.paid.AM_RECEIVED;
              this.paymentData.AM_RECEIPT =
                result.data.balance.notpaid.AM_RECEIVED;
              this.paymentData.AM_RECEIVED =
                result.data.balance.paid.AM_RECEIVED;
              this.paymentData.AM_MISU =
                result.data.balance.notpaid.AM_RECEIVED;
            } else if (
              result.data.balance.paid &&
              !result.data.balance.notpaid
            ) {
              // 기납만 있는 경우
              this.paymentData[3] = result.data.balance.paid;
            } else if (
              !result.data.balance.paid &&
              result.data.balance.notpaid
            ) {
              // 미납만 있는 경우
              this.paymentData[3] = result.data.balance.notpaid;

              // AM_RECEIVED 값을 AM_MISU로 옮겨주고, DT_RECEIPT 값을 비워준다.
              // 서버에서는 금일로 조회한 값을 내려주기 땜에,, 여기서 가공해줌
              this.paymentData[3].AM_MISU = this.paymentData[3].AM_RECEIVED;
              this.paymentData[3].AM_RECEIVED = 0;
              this.paymentData[3].DT_RECEIPT = null;
            }
          }

          this.selectedItem = this.paymentData[0];
          this.selectedIdx = 0;

          this.activeItem = 0;

          // //하나하나.. 골라서.. 값을 바꿔줍니다..
          // this.paymentData[0].AM_RECEIPT = 0;
          // //합계는..만들어줍니다..
          // this.paymentData[0].calculated = false;
          // // 약정금액
          // this.paymentData[0].TY_NAPIP = "합계";
          // this.paymentData[0].AM_SELLING =
          //   Number(
          //     result.data.filter((item) => {
          //       return item.TY_NAPIP == "납부금액";
          //     })[0].AM_SELLING
          //   ) +
          //   Number(
          //     result.data.filter((item) => {
          //       return item.TY_NAPIP == "미수금";
          //     })[0].AM_SELLING
          //   );
          // // 납부금액
          // this.paymentData[0].AM_RECEIVED = result.data.filter((item) => {
          //   return item.TY_NAPIP == "납부금액";
          // })[0].AM_RECEIVED;
          // // 미수금
          // this.paymentData[0].AM_MISU = result.data.filter((item) => {
          //   return item.TY_NAPIP == "미수금";
          // })[0].AM_SELLING;
          // // 연체금액
          // this.paymentData[0].AM_DELAY =
          //   Number(
          //     result.data.filter((item) => {
          //       return item.TY_NAPIP == "납부금액";
          //     })[0].AM_DELAY
          //   ) +
          //   Number(
          //     result.data.filter((item) => {
          //       return item.TY_NAPIP == "미수금";
          //     })[0].AM_DELAY
          //   );
          // // 할인금액
          // this.paymentData[0].AM_DISCOUNT =
          //   Number(
          //     result.data.filter((item) => {
          //       return item.TY_NAPIP == "납부금액";
          //     })[0].AM_DISCOUNT
          //   ) +
          //   Number(
          //     result.data.filter((item) => {
          //       return item.TY_NAPIP == "미수금";
          //     })[0].AM_DISCOUNT
          //   );

          // this.paymentData[1] = result.data.filter((item) => {
          //   return item.TY_NAPIP == "계약금";
          // })[0];

          // // 중도금이 없는 경우도 있어서.. 있는지 판단 후에 중도금,잔금을 순서대로 넣어준다.
          // if (
          //   result.data.filter((item) => {
          //     return item.TY_NAPIP == "중도금";
          //   })[0]
          // ) {
          //   this.paymentData[2] = result.data.filter((item) => {
          //     return item.TY_NAPIP == "중도금";
          //   })[0];

          //   this.paymentData[3] = result.data.filter((item) => {
          //     return item.TY_NAPIP == "잔금";
          //   })[0];
          // } else {
          //   this.paymentData[2] = result.data.filter((item) => {
          //     return item.TY_NAPIP == "잔금";
          //   })[0];
          //   this.paymentData.splice(3, 1);
          // }

          // // 미수인 경우엔 AM_RECEIVED를 AM_MISU에 넣어준다.
          // this.paymentData.forEach((item, i) => {
          //   if (item.CHA == "C") {
          //     this.paymentData[i].AM_MISU = this.paymentData[i].AM_RECEIVED;
          //     this.paymentData[i].AM_RECEIVED = 0;
          //     this.paymentData[i].DT_RECEIPT = null;
          //   }
          // });
        })
        .catch((e) => {
          this.loadingBar = false;
          console.log(e);
        });
    },
    setActiveItem(paymentData) {
      let activeIdx = 0;

      paymentData.forEach((data, idx) => {
        let isDone =
          data.AM_SELLING -
            data.AM_DISCOUNT +
            data.AM_DELAY -
            data.AM_RECEIVED ===
            0 &&
          data.TY_NAPIP != "합계" &&
          data.CHA != "C"
            ? true
            : false;

        if (!isDone && activeIdx == 0) {
          activeIdx = idx;
        }
      });

      return activeIdx;
    },
    setText(invoice) {
      let type = "total";
      if (invoice.TY_NAPIP == "계약금") type = "down";
      else if (invoice.TY_NAPIP == "중도금") type = "second";
      else if (invoice.TY_NAPIP == "잔금") type = "balance";

      let hasC = this.payingIndex == `${type}`;
      return hasC ? "-납부중" : "";
    },
    toDateFormat(val) {
      return toDateFormat(val);
    },
  },
  created() {
    this.getOptionsPayments();
  },
};
</script>
