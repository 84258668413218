<template>
  <v-container class="vh-footer ps-re lynn-mobile pd0" fluid>
    <div class="tab-contents">
      <div>
        <div class="tabs-box">
          <ul class="tab-menu">
            <li
              v-for="(child, i) in content.childs"
              :class="{ active2: selected_child.id === child.id }"
              :key="i"
            >
              <a href="#n" @click="scrollTo(child)">{{ child.title }}</a>
            </li>
          </ul>
        </div>
        <div class="tab-contents">
          <ContractInfo v-if="content.is_rent" :village="village" />
          <v-container class="pd16" v-else>
            <Buttons :village="village" @refresh="$emit('refresh')" />
            <div
              v-for="(child, i) in content.childs"
              :key="i"
              class="com-contbox"
            >
              <strong class="t-cont-title" :id="`target-${child.id}`">{{
                child.title
              }}</strong>
              <div
                v-if="child.content_type == 'HTML'"
                v-html="child.content_raw_html"
              ></div>
              <div v-else>
                <img
                  :src="child.url"
                  width="100%"
                  max-width="100%"
                  contain
                  @click="openDetail(child.url, child.title)"
                />
              </div>
            </div>
          </v-container>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="DetailPopup"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <div class="ps-fx ps-t0 ps-l0 pwidth100 z-2 bgc-3 pd16">
        <strong class="close-title mb0">
          {{ popupName }} 확대보기
          <a
            @click="
              DetailPopup = false;
              onClose();
            "
            class="fts0"
          >
            <PopClose />
          </a>
        </strong>
      </div>
      <v-card style="overflow: auto">
        <v-container class="pd0">
          <div class="pt50">
            <panZoom :options="{ minZoom: 1, maxZoom: 6 }" @init="onInit"
              ><v-img :src="popupUrl"></v-img
            ></panZoom>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Buttons from "@/components/VillageInfo/Buttons"; // 분양단지
import ContractInfo from "@/components/VillageInfo/ContractInfo";
import PopClose from "@/assets/images/common/btn-pop-close.svg";

function getOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

export default {
  components: {
    Buttons,
    ContractInfo,
    PopClose,
    // Step,
  },
  props: ["village", "content"],
  name: "VillageInfo",
  data() {
    return {
      selected_id: 0,
      DetailPopup: false,
      popupUrl: "",
      popupName: "",
      $panZoomInstance: null,
    };
  },
  computed: {
    selected_child() {
      let child = this.content.childs.find(
        (item) => item.id == this.selected_id
      );
      if (!child) child = { id: 0 };
      return child;
    },
  },
  methods: {
    scrollTo(child) {
      this.selected_id = child.id;
      var top = getOffset(document.getElementById(`target-${child.id}`)).top;
      window.scrollTo({
        top: top,
        behavior: "smooth",
      });
    },
    openDetail(url, title) {
      this.DetailPopup = true;
      this.popupUrl = url;
      this.popupName = title;
    },
    onInit: function (panzoomInstance, id) {
      panzoomInstance.moveTo(0, 0);
      console.log("onInit");
      console.log(panzoomInstance);
      this.$panZoomInstance = panzoomInstance;
    },
    onClose() {
      this.$panZoomInstance.moveTo(0, 0);
      this.$panZoomInstance.zoomAbs(0, 0, 1);
    },
  },
  created() {
    try {
      this.selected_id = this.content.childs[0].id;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>
