<template>
  <div :class="`rdroom-item w${seats[0].length}`">
    <div v-for="(line, lineIdx) in seats" :key="`line-${lineIdx}`">
      <div v-for="(seat, seatIdx) in line" :key="`seat-${seatIdx}`">
        <a
          @click="clickItem(seat)"
          :class="getValue(seat).boxClass"
          v-if="seat"
        >
          <div class="num">{{ seat }}</div>
          <div class="rditem-radio">
            <input type="radio" :id="`rd${toStr(seat)}`" name="rdset1" />
            <label :for="`rd${toStr(seat)}`" class="icon-box">
              <img :src="getValue(seat).icon" :class="getValue(seat).class" />
            </label>
          </div>
        </a>
        <div class="block" v-else></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "village", "type"],
  data() {
    return {
      available: require("@/assets/images/common/icons-chair_on.png"),
      disabled: require("@/assets/images/common/icons-chair_d.png"),
    };
  },
  computed: {
    isPaju() {
      return this.village === 1;
    },
    isChungbuk() {
      return this.village === 3;
    },
    seats() {
      /*
        N * M 의 좌석을 Array 로 표현함.
        0 은 빈 공간.
      */
      if (this.isPaju) {
        if (this.type === "READING_ROOM_MALE") {
          return [
            [0, 9, 5, 0, 0, 0],
            [0, 10, 6, 0, 1, 0],
            [0, 11, 7, 0, 2, 0],
            [0, 12, 8, 0, 3, 0],
            [0, 0, 0, 0, 4, 0],
            [0, 13, 0, 0, 0, 0],
            [0, 14, 0, 0, 0, 0],
            [0, 15, 0, 0, 0, 0],
          ];
        } else if (this.type === "READING_ROOM_FEMALE") {
          return [
            [9, 10, 11, 12, 13, 14],
            [0, 0, 0, 0, 0, 0],
            [4, 5, 6, 7, 8, 0],
            [0, 0, 0, 0, 3, 0],
            [0, 0, 0, 0, 2, 0],
            [0, 0, 0, 0, 1, 0],
          ];
        }
      } else if (this.isChungbuk) {
        if (this.type === "READING_ROOM_MALE") {
          return [
            [8, 7, 0, 9, 10],
            [6, 5, 0, 11, 12],
            [0, 0, 0, 0, 0],
            [4, 3, 0, 13, 14],
            [2, 1, 0, 15, 16],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 17, 18],
            [0, 0, 0, 19, 20],
          ];
        } else if (this.type === "READING_ROOM_FEMALE") {
          return [
            [12, 11, 10, 0, 13, 14],
            [9, 8, 7, 0, 15, 16],
            [0, 0, 0, 0, 0, 0],
            [6, 5, 4, 0, 17, 18],
            [3, 2, 1, 0, 19, 20],
            [0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 21, 22],
            [0, 0, 0, 0, 23, 24],
          ];
        }
      }
      return [[]];
    },
  },
  methods: {
    getValue(val) {
      val = this.toStr(val);

      if (!this.item)
        return { boxClass: "item cut", icon: this.disabled, class: "done" };

      if (this.item.available.includes(val)) {
        return { boxClass: "item", icon: this.available, class: "ok" };
      } else if (this.item.used.includes(val)) {
        return { boxClass: "item", icon: this.disabled, class: "ok" };
      } else {
        return { boxClass: "item cut", icon: this.disabled, class: "done" };
      }
    },
    clickItem(val) {
      this.$emit("click", this.toStr(val));
    },
    // Utils
    toStr(seat) {
      return ("0" + String(seat)).slice(-2);
    },
  },
};
</script>
