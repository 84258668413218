<template>
  <v-container class="vh-footer pd20 bg-gray lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.push({ name: 'notice' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">공지사항</strong>
      <!-- <strong class="lo-title" v-else>자료실</strong> -->
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <strong class="mb3 ds-b fts14 lh20 color-9 ftw4 text-left">{{
          item.writer
        }}</strong>
        <ul class="board-list mb10">
          <li class="mb0">
            <router-link to="/notice" class="pb15">
              {{ item.title }}
            </router-link>
          </li>
        </ul>
        <ul
          class="file-downbox"
          v-show="item.files ? item.files.length : false"
        >
          <li v-for="file in item.files" :key="'file-' + file.url">
            <a @click="download(file)" target="_blank">
              {{ file.filename }}
              <Files />
            </a>
          </li>
        </ul>
        <p class="board-text" v-html="setContents(item.parsed_contents)"></p>
        <v-btn
          color="primary"
          elevation="0"
          class="fts18 ftw7 mw-700 pwidth100 mt30"
          min-height="50"
          @click="$router.push({ name: 'notice' })"
          >목록으로</v-btn
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Files from "@/assets/images/cs/file.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import { getBulletin } from "@/api/index";
import { downloadByURL } from "@/utils/utils";

export default {
  name: "Faq",
  components: {
    Back,
    Files,
    Weblogo,
  },
  data() {
    return { item: {} };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  methods: {
    download(file) {
      downloadByURL(file.url, file.filename);
    },
    getBulletinAPI(id) {
      getBulletin(id).then((res) => {
        this.item = res.data;
      });
    },
    setContents(content) {
      if (!content) return;
      var regURL = new RegExp(
        "(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)",
        "gi"
      );
      content = content.replace(
        regURL,
        "<a href='$1://$2' target='_blank'>$1://$2</a>"
      );
      return content;
    },
  },
  created() {
    console.log(this.$route);
    this.getBulletinAPI(this.$route.params.id);
  },
};
</script>
