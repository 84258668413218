<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pad nb mypi-web">
      <div class="mypi-contbox">
        <strong class="t-cont-title">당첨자 방문예약</strong>
        <span class="no-list" v-if="!request.length">
          예약 및 신청 내역이 없습니다.
        </span>
        <ul class="mypi-long" v-if="request.length">
          <li v-for="item in request" :key="'win-reservations-' + item.id" :class="request.length == 1 ? 'single' : ''"
            @click.stop="loadWinVerification(item)">
            <router-link to>
              <span class="left">
                <Iconsample />
                <span class="sidetop">{{ item.village_name }}</span>
                {{ item.title }}
                <span class="side" v-if="item.status_text">[{{ item.status_text }}]</span>
              </span>
            </router-link>
          </li>
        </ul>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { getReservableList } from "@/api/index.js";

import Iconsample from "@/assets/images/mypage/icon-sample.svg"; // 견본주택방문
import { get_now_string } from "@/utils/time.js";
export default {
  components: {
    Iconsample,
  },
  name: "ReservationList",
  data: () => ({
    request: {},
  }),
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    loadWinVerification(item) {
      if (item.is_reserved) {
        this.$store.commit("SUCCESS", "이미 신청한 방문예약 입니다.");
      } else {
        this.$store.commit("SET_WINVERIFICATON", {
          title: item.title,
          dialog: true,
          reservation_code: item.kind_code,
          is_win: item.kind_code === "WIN_RESERVATION",
          is_pre_win:
            item.kind_code === "PRE_WIN_RESERVATION" ||
            item.kind_code === "MOVEIN_WIN_RESERVATION",
          village_id: item.village,
          reservation_master: item.id,
          site_code: item.site_code,
          name: this.user.name,
        });
      }
    },
    getRequestList() {
      // 당첨자/예비당첨자 예약 리스트만 가져옴 home.id=0
      getReservableList(0, "").then((res) => {
        this.request = res.data;
      });
    },
  },

  created() {
    this.$store.dispatch("USER_REFRESH");
    this.getRequestList();

    if (
      this.$route.query.is_verified === "Y" &&
      this.$route.query.reservation_master &&
      this.$route.query.verified_date === get_now_string(true)
    ) {
      this.$router.push({
        name: "Reservation",
        params: {
          master_id: this.$route.query.reservation_master,
        },
        query: {
          verified_date: this.$route.query.verified_date,
          is_verified: this.$route.query.is_verified,
          home: this.$route.query.home,
          reservation_code: this.$route.query.reservation_code,
        },
      });
    } else if (this.$route.query.is_verified === "N") {
      this.$store.commit("ERROR", "해당 세대 또는 단지 당첨자가 아닙니다.");
      this.$router.push({
        name: "reservationList",
      });
    }
  },
};
</script>
