import { render, staticRenderFns } from "./SetUnhistoryOpen.vue?vue&type=template&id=df51c8a2&"
import script from "./SetUnhistoryOpen.vue?vue&type=script&lang=js&"
export * from "./SetUnhistoryOpen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
