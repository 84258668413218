<template>
  <v-container class="vh-footer pd20 lynn-web-in overout bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.push({ name: 'facilityHome' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">도서관 좌석 예약</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops bg-gray" style="border-radius:0;">
      <v-card-text class="pd0">
        <div class="rdroom-box vote-incont">
					<strong class="vote-formtitle ftw4 mt0">날짜선택</strong>
					<v-date-picker :min="min" :max="max" ocale="KR" full-width no-title></v-date-picker>
					<strong class="vote-formtitle ftw4">좌석선택</strong>
          <div class="rd-all-box">
            <ul class="rd-set">
              <li><img :src="require('@/assets/images/common/icons-chair_d.png')"/><span>신청가능(7)</span></li>
              <li><img :src="require('@/assets/images/common/icons-chair_on.png')"/><span>사용중(5)</span></li>
              <li><div class="rd-disa"></div><span>사용금지(12)</span></li>
              <li class="rduser-set">
                <p>사용중 좌석</p>
                <span>5 / 12</span>
              </li>
            </ul>
            <div class="rdroom-item w6">
              <a href="#n" class="item">
                <div class="num">12</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd12" name="rdset1"/>
                  <label for="rd12" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_on.png')" class="ok"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item cut">
                <div class="num">11</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd11" name="rdset1"/>
                  <label for="rd11" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item">
                <div class="num">10</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd10" name="rdset1"/>
                  <label for="rd10" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <div class="block"></div>
              <a href="#n" class="item cut">
                <div class="num">13</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd13" name="rdset1"/>
                  <label for="rd13" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_on.png')" class="ok"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item">
                <div class="num">14</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd14" name="rdset1"/>
                  <label for="rd14" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              
              <!---->
              <a href="#n" class="item cut">
                <div class="num">9</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd09" name="rdset1"/>
                  <label for="rd09" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item">
                <div class="num">8</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd08" name="rdset1"/>
                  <label for="rd08" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item cut">
                <div class="num">7</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd07" name="rdset1"/>
                  <label for="rd07" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <div class="block"></div>
              <a href="#n" class="item">
                <div class="num">17</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd17" name="rdset1"/>
                  <label for="rd17" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_on.png')" class="ok"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item cut">
                <div class="num">18</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd18" name="rdset1"/>
                  <label for="rd18" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              
              <!---->
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              
              <!---->
              <a href="#n" class="item">
                <div class="num">6</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd06" name="rdset1"/>
                  <label for="rd06" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_on.png')" class="ok"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item cut">
                <div class="num">5</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd05" name="rdset1"/>
                  <label for="rd05" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item">
                <div class="num">4</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd4" name="rdset1"/>
                  <label for="rd4" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <div class="block"></div>
              <a href="#n" class="item cut">
                <div class="num">17</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd17" name="rdset1"/>
                  <label for="rd17" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_on.png')" class="ok"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item">
                <div class="num">18</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd18" name="rdset1"/>
                  <label for="rd18" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              
              <!---->
              <a href="#n" class="item cut">
                <div class="num">3</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd03" name="rdset1"/>
                  <label for="rd03" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item">
                <div class="num">2</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd02" name="rdset1"/>
                  <label for="rd02" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item cut">
                <div class="num">1</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd01" name="rdset1"/>
                  <label for="rd01" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              <div class="block"></div>
              <a href="#n" class="item">
                <div class="num">19</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd19" name="rdset1"/>
                  <label for="rd19" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_on.png')" class="ok"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item cut">
                <div class="num">20</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd20" name="rdset1"/>
                  <label for="rd20" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
              
              <!---->
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>

              <!---->
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <a href="#n" class="item">
                <div class="num">21</div>
                <div class="rditem-radio">
                  <input type="radio" id="r21" name="rdset1"/>
                  <label for="rd21" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_on.png')" class="ok"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item cut">
                <div class="num">22</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd22" name="rdset1"/>
                  <label for="rd22" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>

              <!---->
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <div class="block"></div>
              <a href="#n" class="item cut">
                <div class="num">23</div>
                <div class="rditem-radio">
                  <input type="radio" id="r23" name="rdset1"/>
                  <label for="rd23" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_on.png')" class="ok"/>
                  </label>
                </div>
              </a>
              <a href="#n" class="item">
                <div class="num">24</div>
                <div class="rditem-radio">
                  <input type="radio" id="rd24" name="rdset1"/>
                  <label for="rd24" class="icon-box">
                    <img :src="require('@/assets/images/common/icons-chair_d.png')" class="done"/>
                  </label>
                </div>
              </a>
            </div>
          </div>
          <a class="fac-apply">신청하기</a>
				</div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";

export default {
  name: "LoungeList",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
