import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors';
import '@/sass/overrides.sass';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      themes: {
          light: {
              primary: '#F36628',
              secondary: '#FFFFFF',
              accent: '#8c9eff',
              error: '#b71c1c',
          },
      },
  },
});
