<template>
  <v-container class="bg-gray vh-full pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.push({ name: 'mypage' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">대기신청</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <div class="complete-box">
        <div class="conin-tbtn text-right mb10">
          <a href="#n">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <path id="Icon_material-add-circle-outline" data-name="Icon material-add-circle-outline"
                d="M10.7,6.5H9.3V9.3H6.5v1.4H9.3v2.8h1.4V10.7h2.8V9.3H10.7ZM10,3a7,7,0,1,0,7,7A7,7,0,0,0,10,3Zm0,12.6A5.6,5.6,0,1,1,15.6,10,5.607,5.607,0,0,1,10,15.6Z"
                transform="translate(-3 -3)" fill="#f36628" />
            </svg>
            <span class="ds-ib v_m lh14 fts14 ftw5 ml5" @click="addRequest()">대기신청 추가</span>
          </a>
        </div>
        <!-- 신청내역이 있을 때 -->
        <div class="mt20 comin-box" v-for="request in requestList" :key="request.request_id">
          <div class="comin-cont" v-show="request.wait_number"
            v-if="request.show_wait_number && showNumber(request.village)">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-1" style="color: #f36628">
              대기순번 : {{ request.wait_number | comma }}
            </strong>
          </div>
          <div class="comin-cont" v-if="request.show_win_number">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-1" style="color: #4CAF50">
              당첨번호 : {{ request.win_number | comma }}
            </strong>
          </div>
          <div class="comin-cont">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">
              대기신청 단지
            </strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{ request.village_name }} {{ request.room_type_text
              }}</span>
            <span class="text-left ds-b fts14 lh24 color-5" v-if="!noFloorType(request.village)">
              {{
        floorType(request)
      }}
            </span>
          </div>
          <div class="comin-cont">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">신청자</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{ request.name }}({{ request.gender }})
              {{ request.age }}세</span>
          </div>
          <div class="comin-cont">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">전화번호</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{
        request.mobile
      }}</span>
          </div>
          <div class="comin-cont">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">주소</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{
          request.address
        }}</span>
          </div>
          <div class="comin-cont">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">이메일</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{
          request.email
        }}</span>
          </div>
          <v-card-actions class="pt-0 pb-0">
            <v-btn color="#3c3c3c" text :ripple="false" class="ftw5 fts14 no-background-hover">{{ statusText(request)
              }}</v-btn>
            <v-spacer></v-spacer>
            <v-tooltip top :disabled="request.actions.is_deletable">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn color="#3c3c3c" text :ripple="false" class="ftw5 fts14 no-background-hover mr-5"
                    :disabled="!request.actions.is_deletable" @click="delPopup(request.id)">
                    <v-icon>mdi-delete</v-icon> 취소
                  </v-btn>
                </div>
              </template>
              <span>{{ request.actions.no_delete_text }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn color="#f36628" text :ripple="false" class="ftw5 fts14 no-background-hover"
                    @click="modRequest(request.id)" v-show="request.actions.is_editable">
                    <v-icon>mdi-pencil</v-icon> 상세·수정
                  </v-btn>
                </div>
              </template>
              <span>{{ request.actions.no_edit_text }}</span>
            </v-tooltip>
          </v-card-actions>
        </div>
        <!-- 신청 내역이 없을 때 -->
        <div class="complete-box" v-if="requestList.length == 0">
          <div class="comin-box">
            <div class="comin-cont no-mes">
              <span class="text-center ds-b fts14 lh24 color-5">신청내역이 없습니다</span>
            </div>
          </div>
        </div>
      </div>
      <v-btn color="primary" class="fts18 ftw7 mw-400 pwidth100 mt15" min-height="50" block @click="goMypage()">
        확인
      </v-btn>
    </v-card>

    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7"> 취소하시겠습니까? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="delRequest(delId)">
            예
          </v-btn>
          <v-btn color="primary darken-1" text @click="
        cancelDialog = false;
      delId = '';
          ">
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getRentalWaitRequestList, delRentalWaitRequest } from "@/api/index";
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
export default {
  name: "WaitRequestView",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      requestList: [],
      delId: "",
      cancelDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },

  },
  methods: {
    // API
    getRentalWaitRequestListAPI() {
      // 회원가입 시
      let query = "";

      query = `user_id=${this.user.id}`;
      getRentalWaitRequestList(query)
        .then((result) => {
          this.requestList = result.data;
          console.log(this.requestList);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    addRequest() {
      this.$router.push({ name: "waitRequestForm" });
    },
    modRequest(id) {
      this.$router.push({
        name: "waitRequestForm",
        params: { request_id: id },
      });
    },

    delPopup(id) {
      this.delId = id;
      this.cancelDialog = true;
    },
    delRequest(id) {
      delRentalWaitRequest(id)
        .then((result) => {
          this.getRentalWaitRequestListAPI();
          this.$store.commit("SUCCESS", "취소되었습니다.");
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.cancelDialog = false;
    },
    goMypage() {
      this.$router.push({ name: "mypage" });
    },
    floorType(request) {
      if ([4488, 4781].includes(request.village)) {
        return {
          L: "1~5층",
          H: "6층 이상",
        }[request.floor_type];
      }
      return request.floor_type_text;
    },
    statusText(request) {
      if (request.status_text == "접수대기중") {
        return "신청완료";
      }
      return request.status_text;
    },
    showNumber(id) {
      return ![4488, 4781].includes(id);
    },
    noFloorType(id) {
      return [4492, 5150, 5152, 4502, 1].includes(id);

    },
  },
  created() {
    if (this.user) {
      this.getRentalWaitRequestListAPI();
    } else {
      this.$router.push({ name: "login" });
    }
  },
};
</script>
