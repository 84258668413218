import moment from "moment";

export function get_now_string(raw = false) {
  var d1 = new Date();
  var d2 = new Date(d1);

  d2.setMinutes(d1.getMinutes() + 60 * 9);

  let [year_today, month_today, day_today] = d2
    .toISOString()
    .substr(0, 10)
    .split("-");

  if (raw) {
    return `${year_today}${month_today}${day_today}`;
  }

  return `${year_today}-${month_today}-${day_today}`;
}

export function get_time_string(date) {
  /** 현재 날짜에 n일 뒤 */
  var d1 = new Date();
  var d2 = new Date(d1);

  d2.setMinutes(d1.getMinutes() + 60 * 9);
  d2.setDate(d2.getDate() + date);

  let [year_today, month_today, day_today] = d2
    .toISOString()
    .substr(0, 10)
    .split("-");

  return `${year_today}-${month_today}-${day_today}`;
}

export function add_month(date, num) {
  return moment(date)
    .add(num, "months")
    .format("YYYY[-]MM[-]DD");
}

export function add_day(date, num) {
  return moment(date)
    .add(num, "day")
    .format("YYYY[-]MM[-]DD");
}

export function set_date(date, num) {
  return moment(date)
    .set("date", num)
    .format("YYYY[-]MM[-]DD");
}

export function toDateFormat(date) {
  try {
    if (!date) return "";
    return moment(date).format("YYYY[-]MM[-]DD");
  } catch {
    return "";
  }
}

export function isWeekend(date) {
  // return true o Sat, Sun
  return [6, 0].includes(moment(date).day());
}

export function isFuture(date, future) {
  // return date < future
  return moment(future).diff(moment(date), "days") >= 0;
}

export function daysOfPeriod(start, end) {
  return moment(end).diff(moment(start), "days");
}
