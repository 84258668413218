<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30" />
      </div>
      <a @click="$router.push('/contract')" class="back">
        <Back />
      </a>
      <strong class="lo-title">
        AS 접수 목록
        <!--새로고침 버튼 추가 기능 완료시 풀어주세요.-->
        <!--<span class="lo-reload" @click="inRotate"><v-icon :class="{rotate:inrotate}">mdi-cached</v-icon></span>-->
      </strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text style="padding-top:0px; margin-top:0px;">
        <v-row>
          <v-col>
            <v-chip-group v-model="status" mandatory>
              <v-chip small value="ALL" outlined :color="colorByStatus('ALL')">
                전 체
              </v-chip>
              <v-chip small value="REQUESTED" outlined :color="colorByStatus('REQUESTED')">
                <v-icon small>mdi-timer-sand-empty</v-icon>
                접수중
              </v-chip>
              <v-chip small value="OPEN" outlined :color="colorByStatus('OPEN')">
                <v-icon small>mdi-account-hard-hat-outline</v-icon>
                AS처리중
              </v-chip>
              <v-chip small value="CLOSE" outlined :color="colorByStatus('CLOSE')">
                <v-icon small>mdi-check</v-icon>
                AS처리완료
              </v-chip>
              <v-chip small value="CANCELLED" outlined :color="colorByStatus('CANCELLED')">
                <v-icon small>mdi-cancel</v-icon>
                AS미대상
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn v-if="!isXs && !hideDefect" text color="primary" @click="moveToAdd()">
                <v-icon>mdi-plus-circle-outline</v-icon>
                신규 AS 접수
              </v-btn>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-card
          v-for="(item, index) in filteredItems"
          :key="index"
          flat
          ripple
          class="item-card"
          @click="moveToDetail(item.id)"
        >
          <span class="order-style"> {{ item.order }}</span>
          <v-list>
            <v-list-item :key="item.title">
              <v-list-item-avatar>
                <v-icon
                  color="primary"
                  v-if="item.defectStatus == 'OPEN' && item.visitAt && type != 'PRE'"
                >
                  mdi-account-hard-hat-outline
                </v-icon>
                <v-icon color="success" v-else-if="item.defectStatus == 'REQUESTED'">
                  mdi-timer-sand-empty
                </v-icon>
                <v-icon color="primary" v-else-if="item.defectStatus == 'OPEN'">
                  mdi-timer-sand-empty
                </v-icon>
                <v-icon color="red" v-else-if="item.defectStatus == 'CANCELLED'">
                  mdi-cancel
                </v-icon>
                <v-icon color="grey" v-else>
                  mdi-check
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="item.title" style="font-weight:500;"></v-list-item-title>
                <v-list-item-subtitle
                  class="mt-1"
                  v-html="item.createdAtText"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="item.defectStatus == 'OPEN' && item.visitAt && type != 'PRE'"
                  class="font-weight-bold primary--text"
                  v-html="item.visitAtText"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else-if="item.defectStatus == 'REQUESTED'"
                  class="font-weight-bold success--text"
                  v-html="'접수중'"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else-if="item.defectStatus == 'OPEN'"
                  class="font-weight-bold primary--text"
                  v-html="'AS 처리중'"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else-if="item.defectStatus == 'CANCELLED'"
                  class="font-weight-bold red--text"
                  v-html="'AS 미대상'"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else-if="item.defectStatus == 'CLOSE'"
                  class="font-weight-bold grey--text text--darken-2"
                  v-html="'AS 처리완료'"
                ></v-list-item-subtitle>
                <v-list-item-subtitle v-else v-html="item.receiptAtText"></v-list-item-subtitle>
                <!-- <v-list-item-subtitle
                  v-show="item.defectStatus == 'CLOSE'"
                  v-html="item.visitAtText"
                ></v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <li class="no-list mt-5" v-if="isLoading">
          <p>로딩 중 입니다..</p>
        </li>
        <li class="no-list mt-5" v-else-if="!items.length">
          <p>접수된 AS가 없습니다.</p>
        </li>
        <li class="no-list mt-5" v-else-if="!filteredItems.length">
          <p>해당되는 상태의 AS가 없습니다.</p>
        </li>
      </v-card-text>
    </v-card>
    <!-- <v-btn block text @click="loadMore" color="success" v-show="showLoadMore"><v-icon>mdi-plus-circle </v-icon>모든 내역
      조회</v-btn> -->
    <v-btn
      color="primary"
      fab
      dark
      bottom
      right
      fixed
      class="float-add-button"
      v-if="isXs && !hideDefect"
      @click="moveToAdd()"
    >
      <v-icon large>mdi-plus</v-icon>
    </v-btn>

    <SurveyDialog v-if="survey.dialog" :survey="survey" @close="survey.dialog = false" />
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import * as defect from "@/api/defect";
import { get_now_string, get_time_string } from "@/utils/time";
import SurveyDialog from "@/components/Survey/SurveyDialog";
import { checkSurvey } from "@/api/survey";

export default {
  name: "DefectList",
  components: { Back, Weblogo, SurveyDialog },
  data() {
    return {
      type: "AFTER",
      show: true,
      cancelDialog: false,
      page: 1,
      status: "ALL",
      isLoading: true,
      items: [],
      isSimple: true,
      showLoadMore: false,
      scrollY: 0,
      inrotate:false,


      survey:{
        dialog:false,
        popup_period: null
      }
    };
  },
  computed: {
    hideDefect() {
      const now = new Date();

      let defectAddClosed = false;
      if (this.type == "PRE" && this.home.defect_pre_end_at) {
        defectAddClosed = new Date(this.home.defect_pre_end_at) < now;
      } else if (this.type != "PRE" && this.home.defect_after_end_at) {
        defectAddClosed = new Date(this.home.defect_after_end_at) < now;
      }

      let defectMenuOpen = false;

      if (this.type == "PRE") {
        defectMenuOpen = this.home.is_defect_pre_open;
      } else {
        defectMenuOpen = this.home.is_defect_after_open;
      }

      // 입주자 접수

      return defectAddClosed || !defectMenuOpen;
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    filteredItems() {
      if (this.status == "ALL") {
        return this.items;
      }
      return this.items.filter((item) => item.defectStatus == this.status);
    },
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      if (this.type == "AFTER") {
        return this.$store.getters.getTenantHome || this.$store.getters.getHome;
      } else {
        return this.$store.getters.getHome;
      }
    },
    browserInfo() {
      return navigator.userAgent.toLowerCase();
    },
    userState() {
      return this.$store.getters.getUserState;
    },
    is_android() {
      return this.browserInfo.indexOf("android") > 0;
    },
    is_ios() {
      return this.browserInfo.indexOf("iphone") > 0 || this.browserInfo.indexOf("ipad") > 0;
    },
    is_app() {
      return this.browserInfo.indexOf("wikey_flutter") > 0;
    },
    is_mobile() {
      return this.is_ios || this.is_android;
    },
    is_development() {
      return process.env.NODE_ENV !== "production";
    },
  },
  methods: {
    inRotate() {
      this.inrotate = true
      setTimeout(()=>{
        this.inrotate = false
      },1000)
    },
    // APIs
    getItems() {
      let params = {
        sort: "createdAt,desc",
        customerUniqueNumber: this.user?.mobile.replaceAll("-", ""),
        // size: this.isSimple ? 10 : 10000,
        size: 10000,
      };

      if (this.type == "PRE") {
        params.type = "PRE";
        params.dongName = this.home.dong_no;
        params.hoName = this.home.ho_no;
        params.siteCode = this.home.erp_village_id;
      } else {
        params.typeIn = "SECOND_YEAR,THIRD_YEAR,TEN_YEAR,AFTER,MOVE_IN";
      }

      defect
        .getDefects(params)
        .then((res) => {
          if (res.data) {
            this.items = res.data.content.map((item, index) =>
              this.parse(item, index, res.data.totalElements)
            );
            if (res.data.totalElements > 10 && this.isSimple) {
              this.showLoadMore = true;
            }
          } else {
            this.items = [];
          }
        })
        .finally(() => {
          if (this.scrollY) {
            window.scrollTo(0, this.scrollY);
          }

          this.isLoading = false;
        });
    },
    parse(item, index, total) {
      item.order = `00${total - index}`.slice(-3);
      item.createdAtText = `등 록: ${this.timeForm(item.createdAt)}`;
      item.receiptAtText = `접 수: ${this.timeForm(item.receiptAt)}`;
      item.visitAtText = `방 문: ${this.timeForm(item.visitAt)}`;
      // item.completeAtText = `방 문: ${this.timeForm(item.completeAt)}`;
      if (item.defectStatus === "WORK_DONE") {
        item.defectStatus = "OPEN";
      }

      // if (item.defectStatus === "REQUESTED") {
      //   item.defectStatus = "OPEN";
      // }

      item.title = `${item.siteDefectItem.areaName} > ${item.siteDefectItem.partName} > ${item.siteDefectItem.partDetailName}`;
      return item;
    },
    loadMore() {
      this.showLoadMore = false;
      this.isSimple = false;
      this.getItems();
    },
    // Utils
    timeForm(text) {
      if (!text) return "";
      return text.replace("T", " ").slice(0, 16);
    },
    colorByStatus(val) {
      if (val != this.status) return "grey darken-2";
      return (
        {
          ALL: "primary",
          CANCELLED: "red",
          REQUESTED: "success",
          OPEN: "primary",
          WORK_ORDERED: "success",
          CLOSE: "grey ",
        }[this.status] || "primary"
      );
    },
    moveToApp(){
        let type = "";

        if (this.$route.name == "DefectPreList") {
          type = "PRE";
        }

        let dong = this.home.dong_no;
        let ho = this.home.ho_no;
        let siteCode = this.home.erp_village_id;
        let siteName = this.home.village_name;
        let home_id = this.home.id;

        return new Promise((resolve, reject) => {
          try{
            window.flutter_inappwebview
                .callHandler("gotoCustomerDefectRegist", type, siteCode, siteName, dong, ho, home_id)
                .then(function(result) {
                  console.log(
                      "@@@ flutterInAppWebViewPlatformReady gotoCustomerDefectRegist  result: ",
                      result
                  ); // 호출하지 못한 경우 result=null이 내려옴
                  if(result){
                    resolve(result);
                  }else{
                    reject(result);
                  }
                })
                .catch((e) => {
                  reject(e);
                });
          }catch(err) {
            console.log(err);
            // logging
            this.$store.dispatch("LOGGING", {
              error: err,
              tags: `flutter_inappwebview|moveToApp()`,
              etc: {},
            });

            reject(err);
          }
        })


    },
    moveToAdd() {
      // 하자등록은 앱 사용시에만 가능, PC버전에서는 X (2024-11-08) -> 원복 (2024-11-15)
      // if(!this.is_app){
      //   this.$store.commit("ERROR", "AS접수는 모바일 앱에서만 가능합니다. 스마트린 앱을 설치해주세요.");
      //   return;
      // }

      //-------리듬시티 사전점검만 flutter 앱 사용 --------
      if(this.home.erp_village_id == "AA00P0267D" && this.is_app){
        this.moveToApp().then((res)=>{
          console.log("$$$$ moveToApp success");
        }).catch((e)=>{
          //리듬시티의 경우 앱 업데이트 메시지
          alert("새 버전이 있습니다. 앱 업데이트를 실행해주세요.");
          // 각 OS에 맞게 마켓 이동
          try{
            if (this.browserInfo.indexOf("android") > 0) {
              window.location.replace("market://details?id=com.woomi.lynnlife");
            } else if (this.browserInfo.indexOf("iphone") > 0 || this.browserInfo.indexOf("ipad") > 0) {
              window.location.replace("https://apps.apple.com/app/id1559671877");
            }
          }catch(err) {
            console.log("마켓 이동 실패", err);
          }

          //추후 전체사용하게 되면 웹 페이지로 이동
          // this.moveToAddPage();

          // client error 저장
          this.$store.dispatch("LOGGING", {
            error: e,
            tags: `flutter_inappwebview|gotoCustomerDefectRegist()`,
            etc: {},
          });
        });
      }else{
        this.moveToAddPage();
      }
      // ---------------------------------------------------


    },
    // @@@@ 사전점검 행사 중, 웹으로 전환해야할 시 moveToAdd를 아래로 바꿔주세요.
    // moveToAdd() {
    //
    //   this.moveAddPage();
    //
    // },
    moveToAddPage(){
      // 하자등록페이지로 이동(웹)
      if (this.$route.name == "DefectPreList") {
        this.$router.push({ name: "DefectPreAdd", query: { type: this.type } });
      } else {
        this.$router.push({ name: "DefectAdd", query: { type: this.type } });
      }
    },
    moveToDetail(id) {
      if (this.$route.name == "DefectPreList") {
        this.$router.push({
          name: "DefectPreDetail",
          params: { id: id },
          query: { type: this.type, scrollY: window.scrollY },
        });
      } else {
        this.$router.push({
          name: "DefectDetail",
          params: { id: id },
          query: { type: this.type, scrollY: window.scrollY },
        });
      }
    },
    // Survey
    checkSurvey() {
      const query = {
        location: "before_pre",
        skip_ids: this.$store.getters.getSurveySkipList,
      }
      checkSurvey(this.home.id, query).then(({data})=>{
        this.survey = data
        this.survey.dialog = true
      })
    }
  },
  created() {
    

    this.type = this.$route.query.type || "AFTER";
    if (this.$route.query.scrollY) {
      this.scrollY = Number(this.$route.query.scrollY || 0);
    } else {
      try {
        this.scrollY = this.$previousRoute.split("scrollY=")[1];
      } catch {
        this.scrollY = 0;
      }
    }
    // this.scrollY 초기화
    this.$router.replace({ query: { type: this.type } });

    if (this.type == "AFTER") {
      if (!this.home || !this.home.is_defect_after_open) {
        this.$router.push({ name: "dwelling" });
      }
    } else {
      if (!this.home || !this.home.is_defect_pre_open) {
        this.$router.push({ name: "contract" });
      }
    }

   if(this.type == "PRE"){
    this.checkSurvey();
   }

    this.getItems();
    
  },
};
</script>

<style scoped>
.item-card {
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.float-add-button {
  margin-bottom: 50px;
}

.v-list-item__content {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  overflow: hidden;
  padding: 4px 0;
}

.order-style {
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: grey;
}
</style>
